import axios from '../axiosInstances/identityAxiosInstance';

const authenticationApi = {
  async onSignInUser(data) {
    return axios.post('/myid/signin', data);
  },

  async refreshToken(id_token) {
    return axios.post('/myid/create/access-token', { id_token });
  },

  async verifyEmail(token) {
    return axios.post('/auth/verify-email', { token });
  },

  async registerUser(token) {
    return axios.post('/auth/register', { token });
  },

  async activateUser(token, password) {
    return axios.post(`/auth/activate-user?token=${token}`, { password });
  },

  async onForgotPassword(email) {
    return axios.post('/myid/ResetPassword', { email });
  },

  async resetPassword({ otp_id, otp, password, confirm_password }) {
    return axios.post('/myid/SubmitResetPassword', {
      otp_id,
      otp,
      password,
      confirm_password,
    });
  },

  async logOff(refreshToken) {
    return axios.post('/myid/logoff', { refreshToken });
  },
};

export default authenticationApi;
