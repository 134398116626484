export const dataHeadersToPayloadMap = {
  'employee name <first name> <last name>': 'name',
  'employee email': 'email',
  'reference no. (optional)': 'referenceNo',
  'policy year': 'policyYear',
  'vessel type': 'vesselType',
  'insurance provider': 'insuranceProvider',
  'date of incident (dd/mm/yyyy)': 'incidentDate',
  'date of claim approval (dd/mm/yyyy)': 'claimApprovalDate',
  'repatriated': 'repatriated',
  'claim type': 'claimType',
  'cause of claim': 'claimCause',
  'description of incident (optional)': 'incidentDesc',    
  'currency': 'currency',
  'exceeds deductible': 'exceedsDeductable',
  'total approved claim (numbers only without decimal - only if exceeds deductible is no)': 'totalApprovedClaimAmount',
  'total incident cost (numbers only without decimal - only if exceeds deductible is yes)': 'incidentCost',
  'approved insurance claim amount (numbers only without decimal - only if exceeds deductible is yes)': 'approvedInsuranceClaimAmount',
};

export const payloadHeaders = [
  'name',
  'email',
  'policyYear',
  'vesselType',
  'insuranceProvider',
  'incidentDate',
  'claimApprovalDate',
  'repatriated',
  'claimType',
  'claimCause',
  'incidentDesc',
  'currency',
  'exceedsDeductable',
  'totalApprovedClaimAmount',
  'incidentCost',
  'approvedInsuranceClaimAmount'
]

export const dataHeaders = [
  'employee name <first name> <last name>',
  'employee email',
  'reference no. (optional)',
  'policy year',
  'vessel type',
  'insurance provider',
  'date of incident (dd/mm/yyyy)',
  'date of claim approval (dd/mm/yyyy)',
  'repatriated',
  'claim type',
  'cause of claim',
  'description of incident (optional)',    
  'currency',
  'exceeds deductible',
  'total approved claim (numbers only without decimal - only if exceeds deductible is no)',
  'total incident cost (numbers only without decimal - only if exceeds deductible is yes)',
  'approved insurance claim amount (numbers only without decimal - only if exceeds deductible is yes)',
];

export const sampleData = [
  dataHeaders,
  [
    'John Doe',
    'john.doe@email.com',
    'Sample ref',
    '2023',
    'Tankers',
    'AIA',
    '11/5/2023',
    '27/12/2023',
    'Yes',
    'Death',
    'Drowning',
    '',
    'SGD',
    'No',
    '15000',
    '',
    ''
  ],
];
