import * as React from 'react';

const SvgComponent = (props) => (
  <svg
  width="inherit"
  height="inherit"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
  {...props}
  >
    <path
      fill={props.color}
      d="M16 12.666a3.334 3.334 0 1 0 0 6.668 3.334 3.334 0 0 0 0-6.668Zm0 5.002a1.667 1.667 0 1 1 0-3.335 1.667 1.667 0 0 1 0 3.335Z"
    />
    <path
      fill={props.color}
      d="m23.747 17.583-.37-.213a7.587 7.587 0 0 0 0-2.743l.37-.213a2.501 2.501 0 1 0-2.5-4.335l-.371.214A7.483 7.483 0 0 0 18.5 8.925v-.428a2.5 2.5 0 0 0-5.002 0v.428a7.483 7.483 0 0 0-2.374 1.372l-.372-.216a2.502 2.502 0 0 0-2.501 4.334l.37.214a7.586 7.586 0 0 0 0 2.742l-.37.214a2.502 2.502 0 1 0 2.5 4.334l.371-.214a7.485 7.485 0 0 0 2.376 1.369v.428a2.5 2.5 0 0 0 5.002 0v-.428a7.482 7.482 0 0 0 2.374-1.372l.372.215a2.502 2.502 0 0 0 2.501-4.335v.001Zm-2.124-3.148a5.875 5.875 0 0 1 0 3.126.833.833 0 0 0 .387.945l.904.522a.834.834 0 1 1-.834 1.444l-.905-.523a.833.833 0 0 0-1.013.137 5.822 5.822 0 0 1-2.703 1.563.834.834 0 0 0-.626.808v1.045a.834.834 0 0 1-1.667 0v-1.044a.834.834 0 0 0-.626-.808 5.82 5.82 0 0 1-2.703-1.566.834.834 0 0 0-1.013-.138l-.903.523a.834.834 0 1 1-.834-1.444l.904-.522a.833.833 0 0 0 .386-.944 5.875 5.875 0 0 1 0-3.126.833.833 0 0 0-.387-.941l-.904-.522a.834.834 0 1 1 .834-1.445l.905.524a.833.833 0 0 0 1.012-.134 5.822 5.822 0 0 1 2.703-1.563.834.834 0 0 0 .626-.811V8.497a.833.833 0 1 1 1.668 0V9.54a.834.834 0 0 0 .626.807 5.82 5.82 0 0 1 2.703 1.567.834.834 0 0 0 1.013.137l.903-.522a.833.833 0 1 1 .834 1.443l-.904.522a.833.833 0 0 0-.386.941Z"
    />
  </svg>
);

export default SvgComponent;
