import AuthService from 'api/identity/authentication';
import store from 'store';
import { setUser } from 'redux/generalState.actions';
import history from '../history';

export const clearUserStorage = () => {
  sessionStorage.clear();
  localStorage.clear();
};

export const createUserStorage = async (user) => {
  await localStorage.setItem('USER_SAFE_ID', user.safe_id);
  await localStorage.setItem('USER_ID', user.safe_id);
  await localStorage.setItem('USER_SAFE_ID', user.safe_id);
  await localStorage.setItem('USER_INFO', JSON.stringify(user));
};

export const setClientStorage = (client) => {
  localStorage.setItem('ISSUER', client.name);
  localStorage.setItem('ROOT_ID', client._id);
  localStorage.setItem('BR_CLIENT_ID', client._id);
  localStorage.setItem('CLIENT_LOGO', client.logo);
};

export const signOutUser = async (redirect) => {
  const rootId = localStorage.getItem('ROOT_ID');
  console.log("signOutUser")
  try {
    window.Intercom('shutdown');
    clearUserStorage();
    if (redirect) history.push('/');
  } catch (error) {
    clearUserStorage();
    history.push('/');
  }
};

export const refreshUserToken = async (params) => {
  console.log('er refreshUserToken');
  const rtoken = localStorage.getItem('REFRESH_TOKEN');
  if (!rtoken) return signOutUser(true);
  try {
    const parsedToken = JSON.parse(localStorage.getItem('REFRESH_TOKEN'))
    const path = history.location.pathname;
    const { data } = await AuthService.refreshToken(parsedToken?.token);
    store.dispatch(setUser(data.user));
    createUserStorage(data);
    if (path === '/') await history.push('/workforce');
    window.location.reload();
  } catch (error) {
    console.log("signOutUser")
    console.log('er refreshUserToken error', error);
    signOutUser(true);
    history.push({ pathname: '/', search: params });
  }
};

export const checkIfTokenExist = () => !!localStorage.getItem('AUTH_TOKEN');
