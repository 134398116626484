import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'semantic-ui-react';
import { ErrorToast } from 'components';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import IdUserService from 'api/identity/user';
import UserService from 'api/user';

import './userManagement.scss';

import UserManagementForm from './UserManagement_Form';

const UserManagementModal = ({
  toggleModal,
  isModalToggled,
  user,
  deptDropOptions,
  fetchUsers,
  match,
  objDepts
}) => {
  const userInfo = useSelector((state) => state.general.user);

  const [isSaving, setIsSaving] = useState(false);
  
  const onSubmit = async (payload) => {
    try {
      setIsSaving(true);
      const fPayload = formatData(payload);
      const createdUser = await userApiCall(fPayload);
      await assignDepartment({ userId: createdUser.id || createdUser.user_id, departments: payload.department.filter(n => n)})
      await fetchUsers();
      if(payload.email === userInfo.email) await getCurrentUser();
      setIsSaving(false);
      toggleModal(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setIsSaving(false);
    }
  };

  const getCurrentUser = async () => {
    window.location.reload();
  }

  const userApiCall = async (fPayload) => {
    let createdUser;
    if(!!user) {
      const {data: { result }} = await updateUser(fPayload)
      createdUser = result;
    } else {
      const {data: { data }} = await inviteUser(fPayload)
      createdUser = data;
    }
    return createdUser;
  }

  const formatData = (payload) => {
    if(payload?.department?.includes(null)) payload.department = [null]
    if(payload.role === 'admin') payload.department = []
    payload.department = payload.department.filter(n => n)
    const clientId = match.params.id || localStorage.getItem('ROOT_ID');
    let fData = {
      ...payload,
      username: payload.email,
      metadata: { name: payload.name, role: payload.role, rights: formatRightsData(payload) },
      currentClientId: clientId,
      client_id: clientId,
    }

    if(!!user) {
      fData = {...user, ...fData, metadata: {...user.metadata, ...fData.metadata}};
    }
    // TODO: Check why this is being returned by the form
    delete fData.role;
    delete fData.roles;
    delete fData.departments;

    return fData;
  }

  const formatRightsData = (payload) => {
    if(payload.role === 'admin') return {}

    const rights = {};
    payload.department.forEach(deptId => {
      rights[deptId] = payload.rights[deptId];
    })
    return rights;
  }

  const updateUser = (payload) => {
    return IdUserService.updateUser(payload);
  }

  const assignDepartment = (payload) => {
    if(user?.departments?._id) return updateDepartment(user?.departments?._id, payload);
    return UserService.assignDepartment(payload);
  }
  
  const updateDepartment = (departmentId, payload) => {
    return UserService.updateById(departmentId, payload);
  }

  const inviteUser = (payload) => {
    return IdUserService.createUser(payload);
  };
  
  return (
    <Modal
      className="modal-full"
      open={isModalToggled}
      onClose={() => toggleModal(false)}
      closeIcon
      size="tiny"
    >
      <Modal.Header>{!!user ? 'Edit user' : 'Invite new user'}</Modal.Header>
      <Modal.Content>
        <div className="dept-manag-m">
            <UserManagementForm objDepts={objDepts} isEdit={!!user} onSubmit={onSubmit} isLoading={isSaving} deptDropOptions={deptDropOptions} user={user} />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default withRouter(UserManagementModal);
