import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Text, Select, ErrorToast, DatePicker } from 'components';

import DepartmentService from 'api/department';

import DonutGraphs from '../../DonutGraphs/DonutGraphs';
import AreaChartSection from '../../AreaChartSection/AreaChartSection';

import bpSvg from 'assets/new/patientApp/bp.svg';

const VesselChart = ({ fromDate, toDate }) => {
  const { userDepartments } = useSelector((state) => state.general);
  const userRightsObj = useSelector(
    (state) => state.general?.userRightsObj?.insights
  );
  const userInfo = useSelector((state) => state.general.user);

  const [viewingAccount, setViewingAccount] = useState(null);
  const [loadingDepartments, setLoadingDepartments] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [isLoadingGraphData, setIsLoadingGraphData] = useState(false);
  const [selectedTile, setSelectedTile] = useState({
    icon: bpSvg,
    header: 'Blood pressure',
  });

  const [graphTimeScope, setGraphTimeScope] = useState('month');
  const [hasViewRights, setHasViewRights] = useState(false);

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    checkRights();
  }, [viewingAccount]);

  const checkRights = () => {
    const deptKeys = userDepartments.map((dept) => dept._id);
    let tempHasViewRights = false;

    if (userInfo?.metadata?.role === 'admin') {
      tempHasViewRights = true;
    } else if (!viewingAccount) {
      deptKeys.forEach((deptId) => {
        if (userRightsObj?.readDepts?.includes(deptId))
          tempHasViewRights = true;
      });
    } else if (viewingAccount) {
      if (userRightsObj?.readDepts?.includes(viewingAccount))
        tempHasViewRights = true;
    }

    setHasViewRights(tempHasViewRights);
    return { hasViewRights: tempHasViewRights };
  };

  const fetchInitialData = async () => {
    setIsLoading(true);
    const selectedDept = localStorage.getItem('S_DEPT_ID');
    if (selectedDept && selectedDept !== 'null')
      setViewingAccount(selectedDept);
    await fetchDepartments();

    setIsLoading(false);
  };

  const onSetSelectedTile = (data) => {
    if (isLoadingGraphData) return;
    setSelectedTile(data);
  };

  const onChangeViewingAccount = (deptId) => {
    setViewingAccount(deptId);
    localStorage.setItem('S_DEPT_ID', deptId);
  };

  const getDepartmentsFilter = () => {
    const viewRightsDepartments = userRightsObj?.readDepts || [];
    if (viewingAccount) {
      if (userInfo?.metadata?.role !== 'admin') {
        if (!userRightsObj?.readDepts?.includes(viewingAccount)) return 'NA';
      }
      return [viewingAccount];
    }
    if (userInfo?.metadata?.role === 'admin') return null;
    if (!viewingAccount && viewRightsDepartments.length === 0) return null;
    if (!viewingAccount && viewRightsDepartments.length !== 0)
      // return null
      return viewRightsDepartments;
  };

  const fetchDepartments = async () => {
    try {
      setLoadingDepartments(true);
      const userDeptIds = userDepartments?.map((dept) => dept._id);
      const filter = userDeptIds
        ? { filters: { ids: userDeptIds, isDeleted: false } }
        : { filters: { isDeleted: false } };

      const {
        data: { data },
      } = await DepartmentService.getAll(filter);
      const fDepts = formatDepts(data);
      const deptOptions = formatDeptsDropdown(data);
      setDropdownOptions(deptOptions);
      setInitialSelectedDept(userDeptIds, fDepts, deptOptions);
      setLoadingDepartments(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setLoadingDepartments(false);
    }
  };

  const setInitialSelectedDept = (userDeptIds, objDepts, deptOptions) => {
    if (userDeptIds.length === 0) return;
    if (objDepts[viewingAccount]) return;
    setViewingAccount(deptOptions[0]?.value);
  };

  const formatDepts = (depts) => {
    const fDepts = {};

    depts.forEach((dept) => {
      fDepts[dept._id] = dept.name;
    });

    return fDepts;
  };

  const formatDeptsDropdown = (depts) => {
    const options = depts?.map((dept) => ({
      value: dept._id,
      text: dept.name,
    }));
    if (depts.length > 1) options.unshift({ value: null, text: 'All' });
    return options;
  };

  return (
    <>
      <div className="insi-w__view-acc-wrapper">
        <div className="insi-w__view-acc">
          <Text bold size="small" className="mr-2">
            Viewing department:
          </Text>
          <Select
            border
            className="emp-o__view-acc__select"
            placeholder="All"
            search
            selection
            value={viewingAccount}
            onChange={(e, { value }) => onChangeViewingAccount(value)}
            options={dropdownOptions}
          />
        </div>
      </div>
      {hasViewRights ? (
        <>
          <div className="insi-w__content">
            <DonutGraphs
              getDepartmentsFilter={getDepartmentsFilter}
              setSelectedTile={onSetSelectedTile}
              selectedTile={selectedTile}
              fromDate={fromDate}
              toDate={toDate}
              viewingAccount={viewingAccount}
              compare
            />
          </div>
          {/* <AreaChartSection
            getDepartmentsFilter={getDepartmentsFilter}
            setIsLoadingGraphData={setIsLoadingGraphData}
            fromDate={fromDate}
            toDate={toDate}
            viewingAccount={viewingAccount}
            selectedTile={selectedTile}
            setGraphTimeScope={setGraphTimeScope}
            graphTimeScope={graphTimeScope}
            isLoadingGraphData={isLoadingGraphData}
          /> */}
        </>
      ) : (
        <div className="insi-w__content">
          <div className="insi-w__content__no-rights">
            <Text bold center>
              You don't have Insight view rights for this department
            </Text>
          </div>
        </div>
      )}
    </>
  );
};
export default VesselChart;
