import React from 'react';

import { CSVReader } from 'react-papaparse';

import './csvReader.scss';

const CsvReader = (props) => {
  const handleOnFileLoad = (data) => {
    props.onUpload(data);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    props.onError();
  };

  const handleOnRemoveFile = (data) => {
    props.onRemoveFile();
  };

  return (
    <CSVReader
      accept='.csv'
      onDrop={handleOnFileLoad}
      // onUploadAccepted={handleOnFileLoad}
      addRemoveButton
      onError={handleOnError}
      onRemoveFile={handleOnRemoveFile}
      config={{
        header: true,
        skipEmptyLines: true,
      }}
    >
      <span>Drop CSV file here or click to upload.</span>
    </CSVReader>
  );
};

export default CsvReader;
