import React, { useState } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { Table, Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Text, Search, ErrorToast } from 'components';
import { DeleteModal } from 'features';
import ClaimService from 'api/claims';

import history from '../../../../history';

import './claimsTable.scss';

const ClaimsTable = ({
  filteredData = [],
  formattedDepts,
  searchValue,
  onSearchChange,
  isSearching,
  getClaims,
  getClaimsStatData
}) => {
  const [isDelete, setIsDelete] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const user = useSelector(state => state.general.user);

  const onDelete = (e, claim) => {
    if(isSaving) return;
    e.preventDefault();
    e.stopPropagation();
    setIsDelete(claim)
  }

  const deleteClaim = async () => {
    try {
      setIsSaving(true)
      await ClaimService.update(isDelete._id, { ...isDelete, isDeleted: true, deletedBy: user?.metadata?.name })
      await getClaims({});
      await getClaimsStatData();
      setIsDelete(false);
    } catch(error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setIsSaving(false)
    }
  }

  const renderTableData = () => {
    return filteredData?.map((claim) => {
      return (
        <Table.Row
          className="claims-table__row"
          key={claim?._id}
          onClick={() =>
            history.push({
              pathname: `/claims/upload`,
              search: `?id=${claim?._id}&above_deductables=${
                !claim?.totalApprovedClaimAmount ? 1 : 0
              }`,
              state: {
                isAboveDeductables: !claim?.totalApprovedClaimAmount
                  ? true
                  : false,
                edit: true,
              },
            })
          }
        >
          {/* {(userRights?.includes('manageMembers') || !userRights.length) && ( */}
          {/* {hasEditRights && (
            <Table.Cell onClick={(e) => onRowSelect(e, claim._id, claim)}>
              <div>
                <CheckBox
                  className="claims-table__checkbox"
                  checked={!!selectedRows[claim._id]}
                />
              </div>
            </Table.Cell>
          )} */}
          {/* )} */}
          <Table.Cell>
            <Text size="small" bold>
              {claim?.referenceNo || 'N/A'}
            </Text>
          </Table.Cell>
          <Table.Cell>
            <Text uppercase size="tiny" bold>
              {claim?.employee?.firstName ? claim?.employee?.firstName  : ''}
              {claim?.employee?.lastName ? ' '+claim?.employee?.lastName : ''}
            </Text>
            <Text size="tinniest" >
              {claim?.employee?.designation ? claim?.employee?.designation : ''}
              {claim?.employee?.department ? ", " + formattedDepts[claim?.employee?.department] : ''}
            </Text>
          </Table.Cell>
          <Table.Cell>
            <div
              className={`table-status table-status--${
                !!claim?.approvedInsuranceClaimAmount ? 'green' : 'red'
              }`}
            >
              <Text
                size="small"
                color={!!claim?.approvedInsuranceClaimAmount ? 'green' : 'red'}
                bold
              >
                {!!claim?.approvedInsuranceClaimAmount ? "Yes" : "No"}
              </Text>
            </div>
          </Table.Cell>
          <Table.Cell>
            <Text size="small" bold uppercase>
              {claim?.claimType}
            </Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small" bold>
              {`${claim?.currency} `}

              {claim?.approvedInsuranceClaimAmount
                ? new Intl.NumberFormat().format(
                    claim?.approvedInsuranceClaimAmount
                  )
                : ''}
              {claim?.totalApprovedClaimAmount
                ? new Intl.NumberFormat().format(
                    claim?.totalApprovedClaimAmount
                  )
                : ''}
            </Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small" bold>
              {claim?.claimApprovalDate
                ? moment(claim?.claimApprovalDate)?.format('DD-MM-YYYY')
                : ''}
            </Text>
          </Table.Cell>
          <Table.Cell>
            {claim?.deletedBy ? `Deleted by: ${claim?.deletedBy}` : <Icon style={{ cursor: 'pointer' }} name="trash alternate outline" color="red" onClick={(e) => onDelete(e, claim)} />}
          </Table.Cell>
          {/* {hasEditRights && (
            <Table.Cell>
              <Icon
                name="edit outline"
                className="claims-table__icon"
                onClick={() => onEditClick(emp)}
              />
            </Table.Cell>
          )} */}
          {/* )} */}
        </Table.Row>
      );
    });
  };

  const DeleteModalContent = ({ claim }) => {
    return (
      <div>
        <Text style={{ display: 'flex', alignItems: 'center' }}><Text bold size="small" className="mr-2">Employee:</Text> {claim?.employee?.firstName} {claim?.employee?.lastName}</Text>
        <Text style={{ display: 'flex', alignItems: 'center' }}><Text bold size="small" className="mr-2">Type:</Text> {claim.claimType}</Text>
        <Text style={{ display: 'flex', alignItems: 'center' }}><Text bold size="small" className="mr-2">Cause:</Text> {claim.claimCause}</Text>
        <Text style={{ display: 'flex', alignItems: 'center' }}><Text bold size="small" className="mr-2">Created At:</Text> {moment(claim.createdAt).format('DD/MM/YYYY HH:mm')}</Text>
      </div>
    )
  }

  return (
    <div className="claims-table__wrapper">
      {!!isDelete && (
        <DeleteModal
          isModalToggled={!!isDelete}
          toggleModal={setIsDelete}
          onDelete={deleteClaim}
          isSaving={isSaving}
          header="Are you sure you want to delete?"
          content={<DeleteModalContent claim={isDelete} />}
        />
      )}
      <div className="claims-table__menu-bar">
        <Search
          isLoading={isSearching}
          size="fullWidth"
          border
          value={searchValue}
          dropdown={false}
          onSearchChange={(e, { value }) => onSearchChange(value)}
          isFilter={true}
          placeholder="Search"
        />
      </div>
      <Table className="claims-table">
        <Table.Header>
          <Table.Row>
            {/* {(userRights?.includes('manageMembers') || !userRights.length) && ( */}
            {/* {hasEditRights && (
              <Table.HeaderCell className="claims-table__checkbox-cell">
                <div onClick={(e) => selectAllRows(e)}>
                  <CheckBox
                    className="claims-table__checkbox"
                    checked={
                      Object.keys(selectedRows)?.length === filteredData?.length
                    }
                  />
                </div>
              </Table.HeaderCell>
            )} */}
            <Table.HeaderCell>Claim ref. no.</Table.HeaderCell>
            <Table.HeaderCell>Member / Department</Table.HeaderCell>
            <Table.HeaderCell>Insurance claimable</Table.HeaderCell>
            <Table.HeaderCell>Type of claim</Table.HeaderCell>
            <Table.HeaderCell>Claim amount</Table.HeaderCell>
            <Table.HeaderCell>Date of claim filed</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            {/* {(userRights?.includes('manageMembers') || !userRights.length) && ( */}
            {/* {hasEditRights && (
              <Table.HeaderCell className="claims-table__checkbox-cell" />
            )} */}
            {/* )} */}
          </Table.Row>
        </Table.Header>
        <Table.Body>{renderTableData()}</Table.Body>
      </Table>
    </div>
  );
};

export default withRouter(ClaimsTable);
