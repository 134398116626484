import React from 'react';
import PropTypes from 'prop-types';

import errorMessages from '../../utils/errorMessages';

import { Text } from '../index';
// TODO: Move function to utils/helpers
const ErrorToast = (props) => {
  const filteredErrorMessage = () => {
    const { error } = props;

    if (
      !error &&
      !error?.message &&
      !error?.response?.data?.message &&
      !error?.errors
    )
      return 'Something went wrong';

    if (typeof error === 'string') return error;
    if (error?.response?.data?.message) {
      if(Array.isArray(error?.response?.data?.message))
        return error?.response?.data?.message[0];
    
      if (errorMessages[error?.response?.data?.message?.replace('Exception error! ', '')])
        return errorMessages[
          error?.response?.data?.message?.replace('Exception error! ', '')
        ];
      
      return error?.response?.data?.message;
    }

    if (error?.errors) return error.errors[0].message;
    if (error?.message.indexOf(':'))
      return error.message.slice(
        error.message.indexOf(':') + 1,
        error.message.length
      );
    return error.message;
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Text size="small" color="white" dark>
        {props?.message || filteredErrorMessage()}
      </Text>
    </div>
  );
};

ErrorToast.propTypes = {
  error: PropTypes.object,
  message: PropTypes.string,
};

ErrorToast.defaultProps = {
  error: { message: '' },
};

export default ErrorToast;
