import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  LabelList,
  ResponsiveContainer,
} from 'recharts';

import { Text } from 'components';

const BarChartComponent = ({ title, subTitle, data, height }) => {
  const CustomYAxisTick = ({ x, y, payload }) => {
    const text = payload.value;

    return (
      <text
        x={x}
        y={y}
        dx={-60}
        dy={4}
        textAnchor="start"
        fill="#666"
        fontSize={12.5}
      >
        <tspan key={'deje'} x={x - 95} dy={3}>
          {payload.value}
        </tspan>
      </text>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bar-chart__custom-tooltip">
          <p className="bar-chart__custom-tooltip__label">{`${label} : ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="bar-chart">
      <div className="bar-chart__title">
        <Text size="medium" bold className="mb-3">
          {title}
        </Text>
        <Text size="tiny">{subTitle}</Text>
      </div>
      <div className="bar-chart__content">
        {data && data?.length > 0 ? (
          <ResponsiveContainer width="92%" height={height || 130}>
            <BarChart
              width={450}
              height={height || 120}
              data={data}
              layout="vertical"
              margin={{
                top: 0,
                right: 20,
                left: 50,
                bottom: 5,
              }}
            >
              <Bar
                dataKey="count"
                fill="#8884d8"
                barSize={25}
                label={{ position: 'right' }}
              >
                {data?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={entry?.color}
                    cursor="pointer"
                  />
                ))}
                <LabelList dataKey="value" position="left" offset={5} />
              </Bar>
              <XAxis dataKey="count" type="number" tickLine={false} />
              <YAxis
                dataKey="name"
                type="category"
                tickLine={false}
                axisLine={false}
                tick={<CustomYAxisTick />}
                width={115}
              />

              <Tooltip content={<CustomTooltip />} />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Text center>No data</Text>
        )}
      </div>
      <div></div>
    </div>
  );
};
export default BarChartComponent;
