import React from 'react';

import { Text } from 'components';

const Legend = ({ data = [] }) => {
  return (
    <div className="claims-chart__legend">
      {/* <Text dark size='medium'>LEGEND:</Text> */}
      <div className="claims-chart__legend__list">
        {data?.map((value) => (
          <div key={value.label} className="claims-chart__legend__section">
            <div className="claims-chart__legend__header">
              <Text dark size="small">{value.label}</Text>
              <div className='claims-chart__legend__rect' style={{background: value.color}}/>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Legend;
