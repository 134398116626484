import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone'
import * as XLSX from 'xlsx/xlsx.mjs';
import * as Papa from 'papaparse';

import './dropZone.scss';

function Dropzone({ onUpload, accept, sheetName, onRemoveFiles }) {
  const handleOnDrop = (files) => {
    const file = files[0];
    let reader = new FileReader();

    reader.onload = function(e) {
      if(file.name.includes('.xlsx')) {
        let data = new Uint8Array(e.target.result);
        let workbook = XLSX.read(data, {type: 'array'});
        let worksheet = workbook.Sheets[sheetName];

        const excelData = XLSX.utils.sheet_to_json(worksheet);
        onUpload(excelData, '.xlsx')
      } else if(file.name.includes('.csv')) {
        Papa.parse(file, {
          skipEmptyLines: true,
          header: true,
          complete: (results) => onUpload(results.data, '.csv')
        });
      }
    };
    reader.readAsArrayBuffer(file);
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles
  } = useDropzone({
    onDrop: handleOnDrop,
    maxFiles: 1,
    accept,
  });

  useEffect(() => {
    if(!!acceptedFiles.length) onRemoveFiles();
  }, [acceptedFiles])

  const files = acceptedFiles.map((file) => (
      <><b>{file.path}</b>{file.size} bytes</>
  ));

  return (
    <div {...getRootProps({ className: "drop-zone" })}>
      <input className="input-zone" {...getInputProps()} />
      {!!files.length ?
        <>
          {files}
        </> 
      : (
        <>
          {isDragActive ? (
            <p className="dropzone-content">Release to drop the files here</p>
          ) : (
            <p className="dropzone-content">
              Drop CSV or Excel file here or click to upload.
            </p>
          )}
        </>
      )}
    </div>
  );
}

export default Dropzone;
