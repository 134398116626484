const errorMessages = {
  CODE_OK: 'CODE_OK',
  CODE_CANCELLED: 'CODE_CANCELLED',
  CODE_UNKNOWN: 'CODE_UNKNOWN',
  CODE_INVALID_ARGUMENT: 'CODE_INVALID_ARGUMENT',
  CODE_DEADLINE_EXCEEDED: 'CODE_DEADLINE_EXCEEDED',
  CODE_NOT_FOUND: 'CODE_NOT_FOUND',
  CODE_ALREADY_EXISTS: 'CODE_ALREADY_EXISTS',
  CODE_PERMISSION_DENIED: 'CODE_PERMISSION_DENIED',
  CODE_RESOURCE_EXHAUSTED: 'CODE_RESOURCE_EXHAUSTED',
  CODE_FAILED_PRECONDITION: 'CODE_FAILED_PRECONDITION',
  CODE_ABORTED: 'CODE_ABORTED',
  CODE_OUT_OF_RANGE: 'CODE_OUT_OF_RANGE',
  CODE_UNIMPLEMENTED: 'CODE_UNIMPLEMENTED',
  CODE_INTERNAL: 'CODE_INTERNAL',
  CODE_UNAVAILABLE: 'CODE_UNAVAILABLE',
  CODE_DATA_LOSS: 'CODE_DATA_LOSS',
  CODE_UNAUTHENTICATED: 'CODE_UNAUTHENTICATED',
  CODE_CLOSE_GOING_AWAY: 'CODE_CLOSE_GOING_AWAY',
  CODE_BEGIN: 'CODE_BEGIN',
  CODE_MY_ID_SIGN_UP_BEGIN: 'CODE_MY_ID_SIGN_UP_BEGIN',
  CODE_MY_ID_SIGN_UP_USERNAME_IS_INVALID: 'Invalid Usernam',
  CODE_MY_ID_SIGN_UP_USERNAME_IS_EMPTY: 'Username is empty',
  CODE_MY_ID_SIGN_UP_USERNAME_IS_TOO_SHORT: 'Username is too short',
  CODE_MY_ID_SIGN_UP_USERNAME_IS_TOO_LONG: 'Username is too long',
  CODE_MY_ID_SIGN_UP_USERNAME_HAS_ILLEGAL_CHARACTER:
    'Username contains an invalid character',
  CODE_MY_ID_SIGN_UP_USERNAME_HAS_INVALID_PREFIX:
    'Username contains an invalid prefix',
  CODE_MY_ID_SIGN_UP_USERNAME_IS_EXISTED: 'Username already exists',
  CODE_MY_ID_SIGN_UP_PASSWORD_IS_INVALID: 'Password in invalid',
  CODE_MY_ID_SIGN_UP_PASSWORD_IS_EMPTY: 'Password is empty',
  CODE_MY_ID_SIGN_UP_PASSWORD_IS_TOO_SHORT: 'Password is too short',
  CODE_MY_ID_SIGN_UP_PASSWORD_HAS_ILLEGAL_CHARACTER:
    'Password contains an invalid character',
  CODE_MY_ID_SIGN_UP_PASSWORD_HAS_SPACE: 'Password contains a space',
  CODE_MY_ID_SIGN_UP_CONFIRM_PASSWORD_IS_MISMATCH: 'Password mismatch',
  CODE_MY_ID_SIGN_UP_IS_LIMITED: 'Signup limit',
  CODE_MY_ID_SIGN_UP_WITH_FACEBOOK_FAILED:
    'CODE_MY_ID_SIGN_UP_WITH_FACEBOOK_FAILED',
  CODE_MY_ID_SIGN_UP_WITH_FACEBOOK_EXISTED:
    'CODE_MY_ID_SIGN_UP_WITH_FACEBOOK_EXISTED',
  CODE_MY_ID_SIGN_UP_WITH_GOOGLE_FAILED:
    'CODE_MY_ID_SIGN_UP_WITH_GOOGLE_FAILED',
  CODE_MY_ID_SIGN_UP_WITH_GOOGLE_EXISTED:
    'CODE_MY_ID_SIGN_UP_WITH_GOOGLE_EXISTED',
  CODE_MY_ID_SIGN_UP_END: 'CODE_MY_ID_SIGN_UP_END',
  CODE_MY_ID_SIGN_IN_BEGIN: 'CODE_MY_ID_SIGN_IN_BEGIN',
  CODE_MY_ID_SIGN_IN_USERNAME_IS_EMPTY: 'Username is empty',
  CODE_MY_ID_SIGN_IN_PASSWORD_IS_EMPTY: 'Password is empty',
  CODE_MY_ID_SIGN_IN_CREDENTIALS_IS_INVALID:
    'Login failed, invalid email and password combination',
  CODE_MY_ID_SIGN_IN_USER_DOES_NOT_HAVE_EMAIL: "User doesn't have an email",
  CODE_MY_ID_SIGN_IN_STATUS_IS_INVALID: 'Invalid singin',
  CODE_MY_ID_SIGN_IN_USER_IS_INACTIVE: 'User is inactive',
  CODE_MY_ID_SIGN_IN_USER_IS_LOCKED:
    'User is locked due to too many login attempts',
  CODE_MY_ID_SIGN_IN_USER_IS_BANNED: 'User is banned',
  CODE_MY_ID_SIGN_IN_WITH_FACEBOOK_FAILED:
    'CODE_MY_ID_SIGN_IN_WITH_FACEBOOK_FAILED',
  CODE_MY_ID_SIGN_IN_WITH_FACEBOOK_NOT_FOUND:
    'CODE_MY_ID_SIGN_IN_WITH_FACEBOOK_NOT_FOUND',
  CODE_MY_ID_SIGN_IN_WITH_GOOGLE_FAILED:
    'CODE_MY_ID_SIGN_IN_WITH_GOOGLE_FAILED',
  CODE_MY_ID_SIGN_IN_WITH_GOOGLE_NOT_FOUND:
    'CODE_MY_ID_SIGN_IN_WITH_GOOGLE_NOT_FOUND',
  CODE_MY_ID_SIGN_IN_END: 'CODE_MY_ID_SIGN_IN_END',
  CODE_MY_ID_SIGN_OUT_BEGIN: 'CODE_MY_ID_SIGN_OUT_BEGIN',
  CODE_MY_ID_CREATE_ACCESS_TOKEN_BEGIN: 'CODE_MY_ID_CREATE_ACCESS_TOKEN_BEGIN',
  CODE_MY_ID_CREATE_ACCESS_TOKEN_ID_TOKEN_IS_INVALID:
    'CODE_MY_ID_CREATE_ACCESS_TOKEN_ID_TOKEN_IS_INVALID',
  CODE_MY_ID_CREATE_ACCESS_TOKEN_ID_TOKEN_IS_EXPIRED:
    'CODE_MY_ID_CREATE_ACCESS_TOKEN_ID_TOKEN_IS_EXPIRED',
  CODE_MY_ID_CREATE_ACCESS_TOKEN_USER_NOT_FOUND:
    'CODE_MY_ID_CREATE_ACCESS_TOKEN_USER_NOT_FOUND',
  CODE_MY_ID_CREATE_ACCESS_TOKEN_USER_IS_INACTIVE:
    'CODE_MY_ID_CREATE_ACCESS_TOKEN_USER_IS_INACTIVE',
  CODE_MY_ID_CREATE_ACCESS_TOKEN_USER_IS_LOCKED:
    'CODE_MY_ID_CREATE_ACCESS_TOKEN_USER_IS_LOCKED',
  CODE_MY_ID_CREATE_ACCESS_TOKEN_INVALID_KICK_BY_ANOTHER_DEVICE:
    'CODE_MY_ID_CREATE_ACCESS_TOKEN_INVALID_KICK_BY_ANOTHER_DEVICE',
  CODE_MY_ID_CREATE_ACCESS_TOKEN_USER_IS_BANNED:
    'CODE_MY_ID_CREATE_ACCESS_TOKEN_USER_IS_BANNED',
  CODE_MY_ID_CREATE_ACCESS_TOKEN_END: 'CODE_MY_ID_CREATE_ACCESS_TOKEN_END',
  CODE_MY_ID_ME_BEGIN: 'CODE_MY_ID_ME_BEGIN',
  CODE_MY_ID_ME_USER_NOT_FOUND: 'CODE_MY_ID_ME_USER_NOT_FOUND',
  CODE_MY_ID_ME_USER_IS_INACTIVE: 'User is inactive',
  CODE_MY_ID_ME_USER_IS_LOCKED: 'User is locked due to too many login attempts',
  CODE_MY_ID_ME_USER_IS_BANNED: 'User is banned',
  CODE_MY_ID_ME_END: 'CODE_MY_ID_ME_END',
  CODE_MY_ID_CHANGE_PASSWORD_BEGIN: 'CODE_MY_ID_CHANGE_PASSWORD_BEGIN',
  CODE_MY_ID_CHANGE_PASSWORD_USER_NOT_FOUND: 'User not found',
  CODE_MY_ID_CHANGE_PASSWORD_USER_IS_INACTIVE: 'User is inactive',
  CODE_MY_ID_CHANGE_PASSWORD_USER_IS_LOCKED: 'USer is locked',
  CODE_MY_ID_CHANGE_PASSWORD_CURRENT_PASSWORD_IS_EMPTY: 'Password is empty',
  CODE_MY_ID_CHANGE_PASSWORD_CURRENT_PASSWORD_IS_WRONG: 'Password is incorrect',
  CODE_MY_ID_CHANGE_PASSWORD_NEW_PASSWORD_IS_EMPTY: 'Password is empty',
  CODE_MY_ID_CHANGE_PASSWORD_NEW_PASSWORD_IS_INVALID: 'Password is invalid',
  CODE_MY_ID_CHANGE_PASSWORD_NEW_PASSWORD_IS_TOO_SHORT: 'Password is too short',
  CODE_MY_ID_CHANGE_PASSWORD_NEW_PASSWORD_HAS_ILLEGAL_CHARACTER:
    'Password contains an invalid character',
  CODE_MY_ID_CHANGE_PASSWORD_NEW_PASSWORD_HAS_SPACE:
    'Password contains a space',
  CODE_MY_ID_CHANGE_PASSWORD_CONFIRM_PASSWORD_IS_MISMATCH: 'Password mismatch',
  CODE_MY_ID_CHANGE_PASSWORD_NO_CHANGE: 'CODE_MY_ID_CHANGE_PASSWORD_NO_CHANGE',
  CODE_MY_ID_CHANGE_PASSWORD_IS_UNAVAILABLE: 'Password is unavailable',
  CODE_MY_ID_CHANGE_PASSWORD_USER_IS_BANNED: 'User is banned',
  CODE_MY_ID_CHANGE_PASSWORD_END: 'CODE_MY_ID_CHANGE_PASSWORD_END',
  CODE_MY_ID_UPDATE_METADATA_BEGIN: 'CODE_MY_ID_UPDATE_METADATA_BEGIN',
  CODE_MY_ID_UPDATE_METADATA_USER_NOT_FOUND: 'USer not found',
  CODE_MY_ID_UPDATE_METADATA_USER_IS_INACTIVE: 'User is inactive',
  CODE_MY_ID_UPDATE_METADATA_USER_IS_LOCKED: 'User is locked',
  CODE_MY_ID_UPDATE_METADATA_USER_IS_BANNED: 'User is banned',
  CODE_MY_ID_UPDATE_METADATA_END: 'CODE_MY_ID_UPDATE_METADATA_END',
  CODE_MY_ID_UPDATE_EMAIL_BEGIN: 'CODE_MY_ID_UPDATE_EMAIL_BEGIN',
  CODE_MY_ID_UPDATE_EMAIL_EMAIL_IS_EMPTY: 'Email is empty',
  CODE_MY_ID_UPDATE_EMAIL_EMAIL_IS_INVALID: 'Email is invalid',
  CODE_MY_ID_UPDATE_EMAIL_USER_NOT_FOUND: 'User not found',
  CODE_MY_ID_UPDATE_EMAIL_USER_IS_INACTIVE: 'User is inactive',
  CODE_MY_ID_UPDATE_EMAIL_USER_IS_LOCKED: 'User is locked',
  CODE_MY_ID_UPDATE_EMAIL_IS_EXISTED: 'Email is already registered',
  CODE_MY_ID_UPDATE_EMAIL_USER_IS_BANNED: 'User is banned',
  CODE_MY_ID_UPDATE_EMAIL_END: 'CODE_MY_ID_UPDATE_EMAIL_END',
  CODE_MY_ID_VERIFY_EMAIL_BEGIN: 'CODE_MY_ID_VERIFY_EMAIL_BEGIN',
  CODE_MY_ID_VERIFY_EMAIL_IS_UNAVAILABLE: 'Email is unavailable',
  CODE_MY_ID_VERIFY_EMAIL_OTP_IS_INVALID:
    'Verification token invalid, please request a new user invite',
  CODE_MY_ID_VERIFY_EMAIL_OTP_IS_EXPIRED:
    'Verification token expired, please request a new user invite',
  CODE_MY_ID_VERIFY_EMAIL_END: 'CODE_MY_ID_VERIFY_EMAIL_END',
  CODE_WAITRESS_MGET_BEGIN: 'CODE_WAITRESS_MGET_BEGIN',
  CODE_THE_COFFEE_HOUSE_CREATE_USER_BEGIN:
    'CODE_THE_COFFEE_HOUSE_CREATE_USER_BEGIN',
  CODE_THE_COFFEE_HOUSE_CREATE_USER_USERNAME_IS_INVALID: 'Username is invalid',
  CODE_THE_COFFEE_HOUSE_CREATE_USER_USERNAME_IS_EMPTY: 'Username is empty',
  CODE_THE_COFFEE_HOUSE_CREATE_USER_USERNAME_IS_TOO_SHORT:
    'Username is too short',
  CODE_THE_COFFEE_HOUSE_CREATE_USER_USERNAME_IS_TOO_LONG:
    'Username is too long',
  CODE_THE_COFFEE_HOUSE_CREATE_USER_USERNAME_HAS_ILLEGAL_CHARACTER:
    'Username contains invalid characted',
  CODE_THE_COFFEE_HOUSE_CREATE_USER_USERNAME_HAS_INVALID_PREFIX:
    'Username containe invalid prefis',
  CODE_THE_COFFEE_HOUSE_CREATE_USER_USERNAME_IS_EXISTED:
    'User already exists, they might exist in another clinic',
  CODE_THE_COFFEE_HOUSE_CREATE_USER_EMAIL_IS_EMPTY: 'Email is empty',
  CODE_THE_COFFEE_HOUSE_CREATE_USER_EMAIL_IS_INVALID: 'Email in empty',
  CODE_THE_COFFEE_HOUSE_CREATE_USER_EMAIL_IS_EXISTED: 'Email already exists',
  CODE_THE_COFFEE_HOUSE_CREATE_USER_PHONE_NUMBER_IS_INVALID:
    'Invalid phone number',
  CODE_THE_COFFEE_HOUSE_CREATE_USER_PHONE_NUMBER_IS_EXISTED:
    'Phone number is already in use',
  CODE_THE_COFFEE_HOUSE_CREATE_USER_END:
    'CODE_THE_COFFEE_HOUSE_CREATE_USER_END',
  CODE_THE_COFFEE_HOUSE_LIST_USERS_BEGIN:
    'CODE_THE_COFFEE_HOUSE_LIST_USERS_BEGIN',
  CODE_THE_COFFEE_HOUSE_UPDATE_USER_BEGIN:
    'CODE_THE_COFFEE_HOUSE_UPDATE_USER_BEGIN',
  CODE_THE_COFFEE_HOUSE_UPDATE_USER_NOT_FOUND: 'User not found',
  CODE_THE_COFFEE_HOUSE_UPDATE_USER_NO_CHANGE:
    'CODE_THE_COFFEE_HOUSE_UPDATE_USER_NO_CHANGE',
  CODE_THE_COFFEE_HOUSE_UPDATE_USER_EMAIL_IS_INVALID: 'User email is invalid',
  CODE_THE_COFFEE_HOUSE_UPDATE_USER_EMAIL_IS_EXISTED:
    'User email is already in use',
  CODE_THE_COFFEE_HOUSE_UPDATE_USER_PHONE_NUMBER_IS_INVALID:
    'User phone number is invalid',
  CODE_THE_COFFEE_HOUSE_UPDATE_USER_PHONE_NUMBER_IS_EXISTED:
    'User phone number is already in use',
  CODE_THE_COFFEE_HOUSE_UPDATE_USER_END:
    'CODE_THE_COFFEE_HOUSE_UPDATE_USER_END',
  CODE_THE_COFFEE_HOUSE_LOCK_USER_BEGIN:
    'CODE_THE_COFFEE_HOUSE_LOCK_USER_BEGIN',
  CODE_THE_COFFEE_HOUSE_LOCK_USER_ALREADY_LOCKED: 'User is already locked',
  CODE_THE_COFFEE_HOUSE_LOCK_USER_ALREADY_BANNED: 'User is already banned',
  CODE_THE_COFFEE_HOUSE_LOCK_USER_END: 'CODE_THE_COFFEE_HOUSE_LOCK_USER_END',
  CODE_THE_COFFEE_HOUSE_UNLOCK_USER_BEGIN:
    'CODE_THE_COFFEE_HOUSE_UNLOCK_USER_BEGIN',
  CODE_THE_COFFEE_HOUSE_UNLOCK_USER_NOT_LOCKED:
    'CODE_THE_COFFEE_HOUSE_UNLOCK_USER_NOT_LOCKED',
  CODE_THE_COFFEE_HOUSE_UNLOCK_USER_END:
    'CODE_THE_COFFEE_HOUSE_UNLOCK_USER_END',
  CODE_THE_COFFEE_HOUSE_GET_USER_BEGIN: 'CODE_THE_COFFEE_HOUSE_GET_USER_BEGIN',
  CODE_THE_COFFEE_HOUSE_GET_USER_NOT_FOUND: 'User not found',
  CODE_THE_COFFEE_HOUSE_GET_USER_END: 'CODE_THE_COFFEE_HOUSE_GET_USER_END',
  CODE_MY_ID_RESET_PASSWORD_BEGIN: 'CODE_MY_ID_RESET_PASSWORD_BEGIN',
  CODE_MY_ID_RESET_PASSWORD_EMAIL_IS_EMPTY: 'Email is empty',
  CODE_MY_ID_RESET_PASSWORD_EMAIL_IS_INVALID: 'Password is empty',
  CODE_MY_ID_RESET_PASSWORD_PHONE_NUMBER_IS_EMPTY: 'Phone number is empty',
  CODE_MY_ID_RESET_PASSWORD_PHONE_NUMBER_IS_INVALID: 'Phone number is invalid',
  CODE_MY_ID_RESET_PASSWORD_EMAIL_NOT_FOUND: 'Email is not found',
  CODE_MY_ID_RESET_PASSWORD_PHONE_NUMBER_NOT_FOUND: 'Phone number is not found',
  CODE_MY_ID_RESET_PASSWORD_USER_IS_INACTIVE: 'Use is inactive',
  CODE_MY_ID_RESET_PASSWORD_USER_IS_LOCKED: 'User is locked',
  CODE_MY_ID_RESET_PASSWORD_CAPTCHA_IS_INCORRECT: 'Incorrect Captcha',
  CODE_MY_ID_RESET_PASSWORD_IS_UNAVAILABLE: 'Password is unavailable',
  CODE_MY_ID_RESET_PASSWORD_USER_IS_BANNED: 'User is banned',
  CODE_MY_ID_RESET_PASSWORD_END: 'CODE_MY_ID_RESET_PASSWORD_END',
  CODE_MY_ID_VERIFY_RESET_PASSWORD_BEGIN:
    'CODE_MY_ID_VERIFY_RESET_PASSWORD_BEGIN',
  CODE_MY_ID_VERIFY_RESET_PASSWORD_IS_UNAVAILABLE:
    'CODE_MY_ID_VERIFY_RESET_PASSWORD_IS_UNAVAILABLE',
  CODE_MY_ID_VERIFY_RESET_PASSWORD_OTP_IS_INVALID:
    'Verification token invalid, please request a new password reset',
  CODE_MY_ID_VERIFY_RESET_PASSWORD_OTP_IS_EXPIRED:
    'Verification token expired, please request a new password reset',
  CODE_MY_ID_VERIFY_RESET_PASSWORD_END: 'CODE_MY_ID_VERIFY_RESET_PASSWORD_END',
  CODE_MY_ID_SUBMIT_RESET_PASSWORD_BEGIN:
    'CODE_MY_ID_SUBMIT_RESET_PASSWORD_BEGIN',
  CODE_MY_ID_SUBMIT_RESET_PASSWORD_IS_UNAVAILABLE: 'Password is unavailable',
  CODE_MY_ID_SUBMIT_RESET_PASSWORD_OTP_IS_INVALID:
    'Reset token invalid, please request a new password reset',
  CODE_MY_ID_SUBMIT_RESET_PASSWORD_OTP_IS_EXPIRED:
    'Reset token expired, please request a new password reset',
  CODE_MY_ID_SUBMIT_RESET_PASSWORD_NEW_PASSWORD_IS_EMPTY: 'Password is empty',
  CODE_MY_ID_SUBMIT_RESET_PASSWORD_NEW_PASSWORD_IS_INVALID:
    'Password is invalid',
  CODE_MY_ID_SUBMIT_RESET_PASSWORD_NEW_PASSWORD_IS_TOO_SHORT:
    'Password is too short',
  CODE_MY_ID_SUBMIT_RESET_PASSWORD_NEW_PASSWORD_HAS_ILLEGAL_CHARACTER:
    'Password contains an illegal characted',
  CODE_MY_ID_SUBMIT_RESET_PASSWORD_NEW_PASSWORD_HAS_SPACE:
    'Password contains a white space',
  CODE_MY_ID_SUBMIT_RESET_PASSWORD_CONFIRM_PASSWORD_IS_MISMATCH:
    'Password misamtch',
  CODE_MY_ID_SUBMIT_RESET_PASSWORD_NO_CHANGE: "The password wasn't changed",
  CODE_MY_ID_SUBMIT_RESET_PASSWORD_END: 'CODE_MY_ID_SUBMIT_RESET_PASSWORD_END',
  CODE_MY_ID_CONFIRM_SIGN_IN_BEGIN: 'CODE_MY_ID_CONFIRM_SIGN_IN_BEGIN',
  CODE_MY_ID_CONFIRM_SIGN_IN_IS_UNAVAILABLE: 'Sign in is Unavailable',
  CODE_MY_ID_CONFIRM_SIGN_IN_OTP_IS_INVALID: 'OTP is invalid',
  CODE_MY_ID_CONFIRM_SIGN_IN_OTP_IS_EXPIRED: 'OTP has expired',
  CODE_MY_ID_CONFIRM_SIGN_IN_END: 'CODE_MY_ID_CONFIRM_SIGN_IN_END',
  CODE_MY_ID_RESEND_SIGN_IN_OTP_BEGIN: 'CODE_MY_ID_RESEND_SIGN_IN_OTP_BEGIN',
  CODE_MY_ID_RESEND_SIGN_IN_OTP_NOT_FOUND: 'OTP not found',
  CODE_MY_ID_RESEND_SIGN_IN_OTP_END: 'CODE_MY_ID_RESEND_SIGN_IN_OTP_END',
  CODE_MY_ID_CREATE_PASSWORD_BEGIN: 'CODE_MY_ID_CREATE_PASSWORD_BEGIN',
  CODE_MY_ID_CREATE_PASSWORD_IS_UNAVAILABLE: 'Password is not available',
  CODE_MY_ID_CREATE_PASSWORD_OTP_IS_INVALID: 'OTP is invalid',
  CODE_MY_ID_CREATE_PASSWORD_OTP_IS_EXPIRED: 'OTP has expired',
  CODE_MY_ID_CREATE_PASSWORD_NEW_PASSWORD_IS_EMPTY: 'Password is empty',
  CODE_MY_ID_CREATE_PASSWORD_NEW_PASSWORD_IS_INVALID: 'Paassword is invalid',
  CODE_MY_ID_CREATE_PASSWORD_NEW_PASSWORD_IS_TOO_SHORT: 'Password is too short',
  CODE_MY_ID_CREATE_PASSWORD_NEW_PASSWORD_HAS_ILLEGAL_CHARACTER:
    'Password contains an illegal character',
  CODE_MY_ID_CREATE_PASSWORD_NEW_PASSWORD_HAS_SPACE:
    'Password contains white space',
  CODE_MY_ID_CREATE_PASSWORD_CONFIRM_PASSWORD_IS_MISMATCH: 'Password mismatch',
  CODE_MY_ID_CREATE_PASSWORD_USERNAME_IS_INVALID: 'Username is invalid',
  CODE_MY_ID_CREATE_PASSWORD_USERNAME_IS_EMPTY: 'Username is empty',
  CODE_MY_ID_CREATE_PASSWORD_USERNAME_IS_TOO_SHORT: 'Username is too short',
  CODE_MY_ID_CREATE_PASSWORD_USERNAME_IS_TOO_LONG: 'Username is too long',
  CODE_MY_ID_CREATE_PASSWORD_USERNAME_HAS_ILLEGAL_CHARACTER:
    'Username contains an illegal character',
  CODE_MY_ID_CREATE_PASSWORD_USERNAME_HAS_INVALID_PREFIX:
    'Username congtains an invalid prefix',
  CODE_MY_ID_CREATE_PASSWORD_USERNAME_IS_EXISTED: 'Username already exists',
  CODE_MY_ID_CREATE_PASSWORD_END: 'CODE_MY_ID_CREATE_PASSWORD_END',
  CODE_MY_ID_RESEND_CREATE_PASSWORD_OTP_BEGIN:
    'CODE_MY_ID_RESEND_CREATE_PASSWORD_OTP_BEGIN',
  CODE_MY_ID_RESEND_CREATE_PASSWORD_USER_NOT_FOUND: 'User not found',
  CODE_MY_ID_RESEND_CREATE_PASSWORD_INVALID_EMAIL: 'Invalid email',
  CODE_MY_ID_RESEND_CREATE_PASSWORD_UNAVAILABLE: 'Password unavailable',
  CODE_MY_ID_RESEND_CREATE_PASSWORD_OTP_END:
    'CODE_MY_ID_RESEND_CREATE_PASSWORD_OTP_END',
  CODE_MY_ID_UPDATE_PHONE_NUMBER_BEGIN: 'CODE_MY_ID_UPDATE_PHONE_NUMBER_BEGIN',
  CODE_MY_ID_UPDATE_PHONE_NUMBER_PHONE_NUMBER_IS_EMPTY: 'Phone number is empty',
  CODE_MY_ID_UPDATE_PHONE_NUMBER_PHONE_NUMBER_IS_INVALID:
    'Phone number is invalid',
  CODE_MY_ID_UPDATE_PHONE_NUMBER_USER_NOT_FOUND: 'User not found',
  CODE_MY_ID_UPDATE_PHONE_NUMBER_USER_IS_INACTIVE: 'User is inactive',
  CODE_MY_ID_UPDATE_PHONE_NUMBER_USER_IS_LOCKED: 'User is locked',
  CODE_MY_ID_UPDATE_PHONE_NUMBER_IS_EXISTED: 'Phone number already in use',
  CODE_MY_ID_UPDATE_PHONE_NUMBER_USER_IS_BANNED: 'User is banned',
  CODE_MY_ID_UPDATE_PHONE_NUMBER_END: 'CODE_MY_ID_UPDATE_PHONE_NUMBER_END',
  CODE_MY_ID_VERIFY_PHONE_NUMBER_BEGIN: 'CODE_MY_ID_VERIFY_PHONE_NUMBER_BEGIN',
  CODE_MY_ID_VERIFY_PHONE_NUMBER_IS_UNAVAILABLE: 'Phone number is unavailable',
  CODE_MY_ID_VERIFY_PHONE_NUMBER_OTP_IS_INVALID: 'OTP is invalid',
  CODE_MY_ID_VERIFY_PHONE_NUMBER_OTP_IS_EXPIRED:
    'CODE_MY_ID_VERIFY_PHONE_NUMBER_OTP_IS_EXPIRED',
  CODE_MY_ID_VERIFY_PHONE_NUMBER_END: 'Verify phone number',
  CODE_THE_COFFEE_HOUSE_BAN_USER_BEGIN: 'CODE_THE_COFFEE_HOUSE_BAN_USER_BEGIN',
  CODE_THE_COFFEE_HOUSE_BAN_USER_ALREADY_BANNED: 'User is already banned',
  CODE_THE_COFFEE_HOUSE_BAN_USER_END: 'CODE_THE_COFFEE_HOUSE_BAN_USER_END',
  CODE_THE_COFFEE_HOUSE_UNBAN_USER_BEGIN:
    'CODE_THE_COFFEE_HOUSE_UNBAN_USER_BEGIN',
  CODE_THE_COFFEE_HOUSE_UNBAN_USER_NOT_BANNED:
    'CODE_THE_COFFEE_HOUSE_UNBAN_USER_NOT_BANNED',
  CODE_THE_COFFEE_HOUSE_UNBAN_USER_END: 'CODE_THE_COFFEE_HOUSE_UNBAN_USER_END',
  CODE_MY_ID_ADD_CREDENTIAL_BEGIN: 'CODE_MY_ID_ADD_CREDENTIAL_BEGIN',
  CODE_MY_ID_ADD_CREDENTIAL_MY_ID_EXISTED: 'Username already exists',
  CODE_MY_ID_ADD_CREDENTIAL_MY_ID_USERNAME_IS_INVALID: 'Username is invalid',
  CODE_MY_ID_ADD_CREDENTIAL_MY_ID_USERNAME_IS_EMPTY: 'Username is empty',
  CODE_MY_ID_ADD_CREDENTIAL_MY_ID_USERNAME_IS_TOO_SHORT:
    'Username is too short',
  CODE_MY_ID_ADD_CREDENTIAL_MY_ID_USERNAME_IS_TOO_LONG: 'Username is too long',
  CODE_MY_ID_ADD_CREDENTIAL_MY_ID_USERNAME_HAS_ILLEGAL_CHARACTER:
    'Username contains an invalid character',
  CODE_MY_ID_ADD_CREDENTIAL_MY_ID_USERNAME_HAS_INVALID_PREFIX:
    'Username contains an invalid prefix',
  CODE_MY_ID_ADD_CREDENTIAL_MY_ID_USERNAME_IS_DUPLICATED: 'Duplicate username',
  CODE_MY_ID_ADD_CREDENTIAL_MY_ID_PASSWORD_IS_INVALID: 'Password is invalid',
  CODE_MY_ID_ADD_CREDENTIAL_MY_ID_PASSWORD_IS_EMPTY: 'Password is empty',
  CODE_MY_ID_ADD_CREDENTIAL_MY_ID_PASSWORD_IS_TOO_SHORT:
    'Password is too short',
  CODE_MY_ID_ADD_CREDENTIAL_MY_ID_PASSWORD_HAS_ILLEGAL_CHARACTER:
    'Password contains an illegal characted',
  CODE_MY_ID_ADD_CREDENTIAL_MY_ID_PASSWORD_HAS_SPACE:
    'Password contains a white space',
  CODE_MY_ID_ADD_CREDENTIAL_MY_ID_CONFIRM_PASSWORD_IS_MISMATCH:
    'Password mismatch',
  CODE_MY_ID_ADD_CREDENTIAL_FACEBOOK_EXISTED:
    'CODE_MY_ID_ADD_CREDENTIAL_FACEBOOK_EXISTED',
  CODE_MY_ID_ADD_CREDENTIAL_FACEBOOK_FAILED:
    'CODE_MY_ID_ADD_CREDENTIAL_FACEBOOK_FAILED',
  CODE_MY_ID_ADD_CREDENTIAL_FACEBOOK_DUPLICATED:
    'CODE_MY_ID_ADD_CREDENTIAL_FACEBOOK_DUPLICATED',
  CODE_MY_ID_ADD_CREDENTIAL_GOOGLE_EXISTED:
    'CODE_MY_ID_ADD_CREDENTIAL_GOOGLE_EXISTED',
  CODE_MY_ID_ADD_CREDENTIAL_GOOGLE_FAILED:
    'CODE_MY_ID_ADD_CREDENTIAL_GOOGLE_FAILED',
  CODE_MY_ID_ADD_CREDENTIAL_GOOGLE_DUPLICATED:
    'CODE_MY_ID_ADD_CREDENTIAL_GOOGLE_DUPLICATED',
  CODE_MY_ID_ADD_CREDENTIAL_END: 'CODE_MY_ID_ADD_CREDENTIAL_END',
  CODE_THE_COFFEE_HOUSE_RESEND_RESET_PASSWORD_OTP_BEGIN:
    'CODE_THE_COFFEE_HOUSE_RESEND_RESET_PASSWORD_OTP_BEGIN',
  CODE_THE_COFFEE_HOUSE_RESEND_RESET_PASSWORD_OTP_USER_NOT_FOUND:
    'User not found',
  CODE_THE_COFFEE_HOUSE_RESEND_RESET_PASSWORD_OTP_IS_UNAVAILABLE:
    'Reset token unavailable, please request a new password reset',
  CODE_THE_COFFEE_HOUSE_RESEND_RESET_PASSWORD_OTP_END:
    'CODE_THE_COFFEE_HOUSE_RESEND_RESET_PASSWORD_OTP_END',
  CODE_END: 'CODE_END',
};

export default errorMessages;
