import { Text } from 'components';

export const selectDropDownClaimOptions = [
  {
    text: "Death",
    value: "death-claims",
    content: <Text color="black" style={{ borderBottom: 'solid 1px grey ', width: '100%', margin: '-5px', fontWeight: 500 }}>Death</Text>,
    disabled: true
  },
  {
    key: 'vessel accidents',
    text: 'Vessel Accidents',
    value: 'vessel accidents',
    claimType: 'death',
  },
  {
    key: 'falls overboard',
    text: 'Falls Overboard',
    value: 'falls overboard',
    claimType: 'death',
  },
  { key: 'drowning', text: 'Drowning', value: 'drowning', claimtype: 'death' },
  {
    key: 'mechanical failures',
    text: 'Mechanical Failures',
    value: 'mechanical failures',
    claimType: 'death',
  },
  {
    key: 'fire and explosions',
    text: 'Fire and Explosions',
    value: 'fire and explosions',
    claimType: 'death',
  },
  {
    key: 'weather-related incidents',
    text: 'Weather-related Incidents',
    value: 'weather-related incidents',
    claimType: 'death',
  },
  {
    key: 'human error',
    text: 'Human Error',
    value: 'human error',
    claimType: 'death',
  },
  {
    key: 'lack of training and safety procedures',
    text: 'Lack of Training and Safety Procedures',
    value: 'lack of training and safety procedures',
    claimType: 'death',
  },
  {
    key: 'medical emergencies',
    text: 'Medical Emergencies',
    value: 'medical emergencies',
    claimType: 'death',
  },
  {
    key: 'piracy and criminal activity',
    text: 'Piracy and Criminal Activity',
    value: 'piracy and criminal activity',
    claimType: 'death',
  },
  {
    key: 'environmental hazards',
    text: 'Environmental Hazards',
    value: 'environmental hazards',
    claimType: 'death',
  },
  {
    text: "Injury",
    value: "injury-claims",
    content: <Text color="black" style={{ borderBottom: 'solid 1px grey ', width: '100%', margin: '-5px', fontWeight: 500}}>Injury</Text>,
    disabled: true
  },
  {
    key: 'slips, trips, and falls',
    text: 'Slips, Trips, and Falls',
    value: 'slips, trips, and falls',
    claimType: 'injury',
  },
  {
    key: 'falls from heights',
    text: 'Falls from Heights',
    value: 'falls from heights',
    claimType: 'injury',
  },
  {
    key: 'crush injuries',
    text: 'Crush Injuries',
    value: 'crush injuries',
    claimType: 'injury',
  },
  {
    key: 'lacerations and abrasions',
    text: 'Lacerations and Abrasions',
    value: 'lacerations and abrasions',
    claimType: 'injury',
  },
  {
    key: 'strains and sprains',
    text: 'Strains and Sprains',
    value: 'strains and sprains',
    claimType: 'injury',
  },
  {
    key: 'burns and scalds',
    text: 'Burns and Scalds',
    value: 'burns and scalds',
    claimType: 'injury',
  },
  {
    key: 'electric shock',
    text: 'Electric Shock',
    value: 'electric shock',
    claimType: 'injury',
  },
  {
    key: 'entanglement injuries',
    text: 'Entanglement Injuries',
    value: 'entanglement injuries',
    claimType: 'injury',
  },
  {
    key: 'head injuries',
    text: 'Head Injuries',
    value: 'head injuries',
    claimType: 'injury',
  },
  {
    key: 'eye injuries',
    text: 'Eye Injuries',
    value: 'eye injuries',
    claimType: 'injury',
  },
  {
    key: 'hearing loss',
    text: 'Hearing Loss',
    value: 'hearing loss',
    claimType: 'injury',
  },
  {
    key: 'respiratory issues',
    text: 'Respiratory Issues',
    value: 'respiratory issues',
    claimType: 'injury',
  },
  {
    key: 'repetitive strain injuries (rsi)',
    text: 'Repetitive Strain Injuries (RSI)',
    value: 'repetitive strain injuries (rsi)',
    claimType: 'injury',
  },
  {
    key: 'cold or heat-related injuries',
    text: 'Cold or Heat-related Injuries',
    value: 'cold or heat-related injuries',
    claimType: 'injury',
  },
  {
    key: 'occupational diseases',
    text: 'Occupational Diseases',
    value: 'occupational diseases',
    claimType: 'injury',
  },
  {
    key: 'psychological stress',
    text: 'Psychological Stress',
    value: 'psychological stress',
    claimType: 'injury',
  },
  {
    key: 'man overboard injuries',
    text: 'Man Overboard Injuries',
    value: 'man overboard injuries',
    claimType: 'injury',
  },
  {
    key: 'injuries from hazardous materials',
    text: 'Injuries from Hazardous Materials',
    value: 'injuries from hazardous materials',
    claimType: 'injury',
  },
  {
    text: "Illness",
    value: "illness-claims",
    content: <Text color="black" style={{ borderBottom: 'solid 1px grey ', width: '100%', margin: '-5px', fontWeight: 500 }}>Illness</Text>,
    disabled: true
  },
  {
    key: 'seasickness',
    text: 'Seasickness',
    value: 'seasickness',
    claimType: 'illness',
  },
  {
    key: 'respiratory diseases',
    text: 'Respiratory Diseases',
    value: 'respiratory diseases',
    claimType: 'illness',
  },
  {
    key: 'metabolic diseases',
    text: 'Metabolic Diseases',
    value: 'metabolic diseases',
    claimType: 'illness',
  },
  {
    key: 'cardiovascular diseases',
    text: 'Cardiovascular Diseases',
    value: 'cardiovascular diseases',
    claimType: 'illness',
  },
  {
    key: 'skin disorders',
    text: 'Skin Disorders',
    value: 'skin disorders',
    claimType: 'illness',
  },
  {
    key: 'noise-induced hearing loss',
    text: 'Noise-Induced Hearing Loss',
    value: 'noise-induced hearing loss',
    claimType: 'illness',
  },
  {
    key: 'musculoskeletal disorders',
    text: 'Musculoskeletal Disorders',
    value: 'musculoskeletal disorders',
    claimType: 'illness',
  },
  {
    key: 'infectious diseases',
    text: 'Infectious Diseases',
    value: 'infectious diseases',
    claimType: 'illness',
  },
  {
    key: 'gastrointestinal disorders',
    text: 'Gastrointestinal Disorders',
    value: 'gastrointestinal disorders',
    claimType: 'illness',
  },
  {
    key: 'heat-related illnesses',
    text: 'Heat-related Illnesses',
    value: 'heat-related illnesses',
    claimType: 'illness',
  },
  {
    key: 'cold-related illnesses',
    text: 'Cold-related Illnesses',
    value: 'cold-related illnesses',
    claimType: 'illness',
  },
  {
    key: 'mental health issues',
    text: 'Mental Health Issues',
    value: 'mental health issues',
    claimType: 'illness',
  },
  {
    key: 'radiation-related illnesses',
    text: 'Radiation-related Illnesses',
    value: 'radiation-related illnesses',
    claimType: 'illness',
  },
];

export const claimCauses = [
  {
    key: 'vessel accidents',
    text: 'Vessel Accidents',
    value: 'vessel accidents',
    claimType: 'death',
  },
  {
    key: 'falls overboard',
    text: 'Falls Overboard',
    value: 'falls overboard',
    claimType: 'death',
  },
  { key: 'drowning', text: 'Drowning', value: 'drowning', claimtype: 'death' },
  {
    key: 'mechanical failures',
    text: 'Mechanical Failures',
    value: 'mechanical failures',
    claimType: 'death',
  },
  {
    key: 'fire and explosions',
    text: 'Fire and Explosions',
    value: 'fire and explosions',
    claimType: 'death',
  },
  {
    key: 'weather-related incidents',
    text: 'Weather-related Incidents',
    value: 'weather-related incidents',
    claimType: 'death',
  },
  {
    key: 'human error',
    text: 'Human Error',
    value: 'human error',
    claimType: 'death',
  },
  {
    key: 'lack of training and safety procedures',
    text: 'Lack of Training and Safety Procedures',
    value: 'lack of training and safety procedures',
    claimType: 'death',
  },
  {
    key: 'medical emergencies',
    text: 'Medical Emergencies',
    value: 'medical emergencies',
    claimType: 'death',
  },
  {
    key: 'piracy and criminal activity',
    text: 'Piracy and Criminal Activity',
    value: 'piracy and criminal activity',
    claimType: 'death',
  },
  {
    key: 'environmental hazards',
    text: 'Environmental Hazards',
    value: 'environmental hazards',
    claimType: 'death',
  },
  {
    key: 'slips, trips, and falls',
    text: 'Slips, Trips, and Falls',
    value: 'slips, trips, and falls',
    claimType: 'injury',
  },
  {
    key: 'falls from heights',
    text: 'Falls from Heights',
    value: 'falls from heights',
    claimType: 'injury',
  },
  {
    key: 'crush injuries',
    text: 'Crush Injuries',
    value: 'crush injuries',
    claimType: 'injury',
  },
  {
    key: 'lacerations and abrasions',
    text: 'Lacerations and Abrasions',
    value: 'lacerations and abrasions',
    claimType: 'injury',
  },
  {
    key: 'strains and sprains',
    text: 'Strains and Sprains',
    value: 'strains and sprains',
    claimType: 'injury',
  },
  {
    key: 'burns and scalds',
    text: 'Burns and Scalds',
    value: 'burns and scalds',
    claimType: 'injury',
  },
  {
    key: 'electric shock',
    text: 'Electric Shock',
    value: 'electric shock',
    claimType: 'injury',
  },
  {
    key: 'entanglement injuries',
    text: 'Entanglement Injuries',
    value: 'entanglement injuries',
    claimType: 'injury',
  },
  {
    key: 'head injuries',
    text: 'Head Injuries',
    value: 'head injuries',
    claimType: 'injury',
  },
  {
    key: 'eye injuries',
    text: 'Eye Injuries',
    value: 'eye injuries',
    claimType: 'injury',
  },
  {
    key: 'hearing loss',
    text: 'Hearing Loss',
    value: 'hearing loss',
    claimType: 'injury',
  },
  {
    key: 'respiratory issues',
    text: 'Respiratory Issues',
    value: 'respiratory issues',
    claimType: 'injury',
  },
  {
    key: 'repetitive strain injuries (rsi)',
    text: 'Repetitive Strain Injuries (RSI)',
    value: 'repetitive strain injuries (rsi)',
    claimType: 'injury',
  },
  {
    key: 'cold or heat-related injuries',
    text: 'Cold or Heat-related Injuries',
    value: 'cold or heat-related injuries',
    claimType: 'injury',
  },
  {
    key: 'occupational diseases',
    text: 'Occupational Diseases',
    value: 'occupational diseases',
    claimType: 'injury',
  },
  {
    key: 'psychological stress',
    text: 'Psychological Stress',
    value: 'psychological stress',
    claimType: 'injury',
  },
  {
    key: 'man overboard injuries',
    text: 'Man Overboard Injuries',
    value: 'man overboard injuries',
    claimType: 'injury',
  },
  {
    key: 'seasickness',
    text: 'Seasickness',
    value: 'seasickness',
    claimType: 'illness',
  },
  {
    key: 'respiratory diseases',
    text: 'Respiratory Diseases',
    value: 'respiratory diseases',
    claimType: 'illness',
  },
  {
    key: 'metabolic diseases',
    text: 'Metabolic Diseases',
    value: 'metabolic diseases',
    claimType: 'illness',
  },
  {
    key: 'cardiovascular diseases',
    text: 'Cardiovascular Diseases',
    value: 'cardiovascular diseases',
    claimType: 'illness',
  },
  {
    key: 'skin disorders',
    text: 'Skin Disorders',
    value: 'skin disorders',
    claimType: 'illness',
  },
  {
    key: 'noise-induced hearing loss',
    text: 'Noise-Induced Hearing Loss',
    value: 'noise-induced hearing loss',
    claimType: 'illness',
  },
  {
    key: 'musculoskeletal disorders',
    text: 'Musculoskeletal Disorders',
    value: 'musculoskeletal disorders',
    claimType: 'illness',
  },
  {
    key: 'infectious diseases',
    text: 'Infectious Diseases',
    value: 'infectious diseases',
    claimType: 'illness',
  },
  {
    key: 'gastrointestinal disorders',
    text: 'Gastrointestinal Disorders',
    value: 'gastrointestinal disorders',
    claimType: 'illness',
  },
  {
    key: 'heat-related illnesses',
    text: 'Heat-related Illnesses',
    value: 'heat-related illnesses',
    claimType: 'illness',
  },
  {
    key: 'cold-related illnesses',
    text: 'Cold-related Illnesses',
    value: 'cold-related illnesses',
    claimType: 'illness',
  },
  {
    key: 'mental health issues',
    text: 'Mental Health Issues',
    value: 'mental health issues',
    claimType: 'illness',
  },
  {
    key: 'radiation-related illnesses',
    text: 'Radiation-related Illnesses',
    value: 'radiation-related illnesses',
    claimType: 'illness',
  },
  {
    key: 'injuries from hazardous materials',
    text: 'Injuries from Hazardous Materials',
    value: 'injuries from hazardous materials',
    claimType: 'illness',
  },
];

export const claimCausesValues = [
  'vessel accidents',
  'falls overboard',
  'drowning',
  'mechanical failures',
  'fire and explosions',
  'weather-related incidents',
  'human error',
  'lack of training and safety procedures',
  'medical emergencies',
  'piracy and criminal activity',
  'environmental hazards',
  'slips, trips, and falls',
  'falls from heights',
  'crush injuries',
  'lacerations and abrasions',
  'strains and sprains',
  'burns and scalds',
  'electric shock',
  'entanglement injuries',
  'head injuries',
  'eye injuries',
  'hearing loss',
  'respiratory issues',
  'repetitive strain injuries (rsi)',
  'cold or heat-related injuries',
  'occupational diseases',
  'psychological stress',
  'man overboard injuries',
  'seasickness',
  'respiratory diseases',
  'metabolic diseases',
  'cardiovascular diseases',
  'skin disorders',
  'noise-induced hearing loss',
  'musculoskeletal disorders',
  'infectious diseases',
  'gastrointestinal disorders',
  'heat-related illnesses',
  'cold-related illnesses',
  'mental health issues',
  'radiation-related illnesses',
  'injuries from hazardous materials',
];

export const claimTypes = [
  {
    key: 'death',
    text: 'Death',
    value: 'death',
  },
  {
    key: 'injury',
    text: 'Injury',
    value: 'injury',
  },
  {
    key: 'illness',
    text: 'Illness',
    value: 'illness',
  },
];

export const claimTypesValues = [
  'death',
  'injury',
  'illness'
]

export const vesselTypes = [
  {
    key: 'container ships',
    text: 'Container Ships',
    value: 'container ships',
  },
  {
    key: 'tankers',
    text: 'Tankers',
    value: 'tankers',
  },
  {
    key: 'bulk carriers',
    text: 'Bulk Carriers',
    value: 'bulk carriers',
  },
  {
    key: 'offshore supply vessels (osvs)',
    text: 'Offshore Supply Vessels (OSVs)',
    value: 'offshore supply vessels (osvs)',
  },
];

export const vesselTypesValues = [
  'container ships',
  'tankers',
  'bulk carriers',
  'offshore supply vessels (osvs)',
];
