import React, { useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import moment from 'moment';

import { Text } from 'components';

import DonutGraph from '../DonutGraph/DonutGraph';

import './donutGraphContainer.scss';

const DonutGraphContainer = ({ header, icon, color, score, status, activeCount, totalCount, selectedTile, setSelectedTile, isLoading, toDate }) => {
  // const [data, setData] = useState([]);
  const [percent, setPercentage] = useState(0);

  // useEffect(() => {
    // const dat = [
    //   { name: 'filler', value: totalCount - score },
    //   { name: 'data', value: score }, // TODO: assign value
    // ]
    // setData(dat)
    // setPercentage((score / totalCount) * 100)
  // }, [score, totalCount])

  const onClickTile = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    if(selectedTile.header !== data.header) setSelectedTile(data);
  }

  return (
    <div className={`donut-con ${selectedTile?.header === header && 'donut-con--selected'}`} onClick={(e) => onClickTile(e, { header, icon })}>
      <div className='donut-con__header'>
        <img src={icon} alt="icon" className='donut-con__header__icon'/><Text bold size="big">{header}</Text>
      </div>
      {isLoading ? (
        <div className='donut-con__loader'>
          <Loader
            active
            size="small"
            inline
          />
        </div>
      ) : (
        <>
          {!!score.length ? (
            <div className='donut-con__graph'>
              <DonutGraph data={score} color={color} status={status} percent={percent} />
            </div>
          ) : (
            <div className='donut-con__placeholder'>
              <Text center bold>No data</Text>
            </div>
          )}
          <div className='donut-con__sub-text'>
            {/* <Text dark>{score}</Text> */}
            <Text bold style={{ marginLeft: '4px'}}>Inputs from {activeCount} of {totalCount} members</Text> 
            {toDate && <Text style={{ marginLeft: '4px'}} className="mt-1" size="tinniest" color="lightGrey">{moment(toDate).format('DD MMM YY')}</Text>}
          </div>
        </>
      )}
    </div>
  );
}
  
  export default DonutGraphContainer;
  