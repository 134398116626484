export default [
  { text: 'AED', value: 'AED', symbol: '\u062f.\u0625;', name: 'UAE dirham' },
  { text: 'AFN', value: 'AFN', symbol: 'Afs', name: 'Afghan afghani' },
  { text: 'ALL', value: 'ALL', symbol: 'L', name: 'Albanian lek' },
  { text: 'AMD', value: 'AMD', symbol: 'AMD', name: 'Armenian dram' },
  {
    text: 'ANG',
    value: 'ANG',
    symbol: 'NA\u0192',
    name: 'Netherlands Antillean gulden',
  },
  { text: 'AOA', value: 'AOA', symbol: 'Kz', name: 'Angolan kwanza' },
  { text: 'ARS', value: 'ARS', symbol: '$', name: 'Argentine peso' },
  { text: 'AUD', value: 'AUD', symbol: '$', name: 'Australian dollar' },
  { text: 'AWG', value: 'AWG', symbol: '\u0192', name: 'Aruban florin' },
  { text: 'AZN', value: 'AZN', symbol: 'AZN', name: 'Azerbaijani manat' },
  {
    text: 'BAM',
    value: 'BAM',
    symbol: 'KM',
    name: 'Bosnia and Herzegovina konvertibilna marka',
  },
  { text: 'BBD', value: 'BBD', symbol: 'Bds$', name: 'Barbadian dollar' },
  { text: 'BDT', value: 'BDT', symbol: '\u09f3', name: 'Bangladeshi taka' },
  { text: 'BGN', value: 'BGN', symbol: 'BGN', name: 'Bulgarian lev' },
  {
    text: 'BHD',
    value: 'BHD',
    symbol: '.\u062f.\u0628',
    name: 'Bahraini dinar',
  },
  { text: 'BIF', value: 'BIF', symbol: 'FBu', name: 'Burundi franc' },
  { text: 'BMD', value: 'BMD', symbol: 'BD$', name: 'Bermudian dollar' },
  { text: 'BND', value: 'BND', symbol: 'B$', name: 'Brunei dollar' },
  { text: 'BOB', value: 'BOB', symbol: 'Bs.', name: 'Bolivian boliviano' },
  { text: 'BRL', value: 'BRL', symbol: 'R$', name: 'Brazilian real' },
  { text: 'BSD', value: 'BSD', symbol: 'B$', name: 'Bahamian dollar' },
  { text: 'BTN', value: 'BTN', symbol: 'Nu.', name: 'Bhutanese ngultrum' },
  { text: 'BWP', value: 'BWP', symbol: 'P', name: 'Botswana pula' },
  { text: 'BYR', value: 'BYR', symbol: 'Br', name: 'Belarusian ruble' },
  { text: 'BZD', value: 'BZD', symbol: 'BZ$', name: 'Belize dollar' },
  { text: 'CAD', value: 'CAD', symbol: '$', name: 'Canadian dollar' },
  { text: 'CDF', value: 'CDF', symbol: 'F', name: 'Congolese franc' },
  { text: 'CHF', value: 'CHF', symbol: 'Fr.', name: 'Swiss franc' },
  { text: 'CLP', value: 'CLP', symbol: '$', name: 'Chilean peso' },
  {
    text: 'CNY',
    value: 'CNY',
    symbol: '\u00a5',
    name: 'Chinese/Yuan renminbi',
  },
  { text: 'COP', value: 'COP', symbol: 'Col$', name: 'Colombian peso' },
  { text: 'CRC', value: 'CRC', symbol: '\u20a1', name: 'Costa Rican colon' },
  { text: 'CUC', value: 'CUC', symbol: '$', name: 'Cuban peso' },
  { text: 'CVE', value: 'CVE', symbol: 'Esc', name: 'Cape Verdean escudo' },
  { text: 'CZK', value: 'CZK', symbol: 'K\u010d', name: 'Czech koruna' },
  { text: 'DJF', value: 'DJF', symbol: 'Fdj', name: 'Djiboutian franc' },
  { text: 'DKK', value: 'DKK', symbol: 'Kr', name: 'Danish krone' },
  { text: 'DOP', value: 'DOP', symbol: 'RD$', name: 'Dominican peso' },
  {
    text: 'DZD',
    value: 'DZD',
    symbol: '\u062f.\u062c',
    name: 'Algerian dinar',
  },
  { text: 'EEK', value: 'EEK', symbol: 'KR', name: 'Estonian kroon' },
  { text: 'EGP', value: 'EGP', symbol: '\u00a3', name: 'Egyptian pound' },
  { text: 'ERN', value: 'ERN', symbol: 'Nfa', name: 'Eritrean nakfa' },
  { text: 'ETB', value: 'ETB', symbol: 'Br', name: 'Ethiopian birr' },
  { text: 'EUR', value: 'EUR', symbol: '\u20ac', name: 'European Euro' },
  { text: 'FJD', value: 'FJD', symbol: 'FJ$', name: 'Fijian dollar' },
  {
    text: 'FKP',
    value: 'FKP',
    symbol: '\u00a3',
    name: 'Falkland Islands pound',
  },
  { text: 'GBP', value: 'GBP', symbol: '\u00a3', name: 'British pound' },
  { text: 'GEL', value: 'GEL', symbol: 'GEL', name: 'Georgian lari' },
  { text: 'GHS', value: 'GHS', symbol: 'GH\u20b5', name: 'Ghanaian cedi' },
  { text: 'GIP', value: 'GIP', symbol: '\u00a3', name: 'Gibraltar pound' },
  { text: 'GMD', value: 'GMD', symbol: 'D', name: 'Gambian dalasi' },
  { text: 'GNF', value: 'GNF', symbol: 'FG', name: 'Guinean franc' },
  {
    text: 'GQE',
    value: 'GQE',
    symbol: 'CFA',
    name: 'Central African CFA franc',
  },
  { text: 'GTQ', value: 'GTQ', symbol: 'Q', name: 'Guatemalan quetzal' },
  { text: 'GYD', value: 'GYD', symbol: 'GY$', name: 'Guyanese dollar' },
  { text: 'HKD', value: 'HKD', symbol: 'HK$', name: 'Hong Kong dollar' },
  { text: 'HNL', value: 'HNL', symbol: 'L', name: 'Honduran lempira' },
  { text: 'HRK', value: 'HRK', symbol: 'kn', name: 'Croatian kuna' },
  { text: 'HTG', value: 'HTG', symbol: 'G', name: 'Haitian gourde' },
  { text: 'HUF', value: 'HUF', symbol: 'Ft', name: 'Hungarian forint' },
  { text: 'IDR', value: 'IDR', symbol: 'Rp', name: 'Indonesian rupiah' },
  { text: 'ILS', value: 'ILS', symbol: '\u20aa', name: 'Israeli new sheqel' },
  { text: 'INR', value: 'INR', symbol: '\u20B9', name: 'Indian rupee' },
  { text: 'IQD', value: 'IQD', symbol: '\u062f.\u0639', name: 'Iraqi dinar' },
  { text: 'IRR', value: 'IRR', symbol: 'IRR', name: 'Iranian rial' },
  { text: 'ISK', value: 'ISK', symbol: 'kr', name: 'Icelandic kr\u00f3na' },
  { text: 'JMD', value: 'JMD', symbol: 'J$', name: 'Jamaican dollar' },
  { text: 'JOD', value: 'JOD', symbol: 'JOD', name: 'Jordanian dinar' },
  { text: 'JPY', value: 'JPY', symbol: '\u00a5', name: 'Japanese yen' },
  { text: 'KES', value: 'KES', symbol: 'KSh', name: 'Kenyan shilling' },
  {
    text: 'KGS',
    value: 'KGS',
    symbol: '\u0441\u043e\u043c',
    name: 'Kyrgyzstani som',
  },
  { text: 'KHR', value: 'KHR', symbol: '\u17db', name: 'Cambodian riel' },
  { text: 'KMF', value: 'KMF', symbol: 'KMF', name: 'Comorian franc' },
  { text: 'KPW', value: 'KPW', symbol: 'W', name: 'North Korean won' },
  { text: 'KRW', value: 'KRW', symbol: 'W', name: 'South Korean won' },
  { text: 'KWD', value: 'KWD', symbol: 'KWD', name: 'Kuwaiti dinar' },
  { text: 'KYD', value: 'KYD', symbol: 'KY$', name: 'Cayman Islands dollar' },
  { text: 'KZT', value: 'KZT', symbol: 'T', name: 'Kazakhstani tenge' },
  { text: 'LAK', value: 'LAK', symbol: 'KN', name: 'Lao kip' },
  { text: 'LBP', value: 'LBP', symbol: '\u00a3', name: 'Lebanese lira' },
  { text: 'LKR', value: 'LKR', symbol: 'Rs', name: 'Sri Lankan rupee' },
  { text: 'LRD', value: 'LRD', symbol: 'L$', name: 'Liberian dollar' },
  { text: 'LSL', value: 'LSL', symbol: 'M', name: 'Lesotho loti' },
  { text: 'LTL', value: 'LTL', symbol: 'Lt', name: 'Lithuanian litas' },
  { text: 'LVL', value: 'LVL', symbol: 'Ls', name: 'Latvian lats' },
  { text: 'LYD', value: 'LYD', symbol: 'LD', name: 'Libyan dinar' },
  { text: 'MAD', value: 'MAD', symbol: 'MAD', name: 'Moroccan dirham' },
  { text: 'MDL', value: 'MDL', symbol: 'MDL', name: 'Moldovan leu' },
  { text: 'MGA', value: 'MGA', symbol: 'FMG', name: 'Malagasy ariary' },
  { text: 'MKD', value: 'MKD', symbol: 'MKD', name: 'Macedonian denar' },
  { text: 'MMK', value: 'MMK', symbol: 'K', name: 'Myanma kyat' },
  { text: 'MNT', value: 'MNT', symbol: '\u20ae', name: 'Mongolian tugrik' },
  { text: 'MOP', value: 'MOP', symbol: 'P', name: 'Macanese pataca' },
  { text: 'MRO', value: 'MRO', symbol: 'UM', name: 'Mauritanian ouguiya' },
  { text: 'MUR', value: 'MUR', symbol: 'Rs', name: 'Mauritian rupee' },
  { text: 'MVR', value: 'MVR', symbol: 'Rf', name: 'Maldivian rufiyaa' },
  { text: 'MWK', value: 'MWK', symbol: 'MK', name: 'Malawian kwacha' },
  { text: 'MXN', value: 'MXN', symbol: '$', name: 'Mexican peso' },
  { text: 'MYR', value: 'MYR', symbol: 'RM', name: 'Malaysian ringgit' },
  { text: 'MZM', value: 'MZM', symbol: 'MTn', name: 'Mozambican metical' },
  { text: 'NAD', value: 'NAD', symbol: 'N$', name: 'Namibian dollar' },
  { text: 'NGN', value: 'NGN', symbol: '\u20a6', name: 'Nigerian naira' },
  { text: 'NIO', value: 'NIO', symbol: 'C$', name: 'Nicaraguan c\u00f3rdoba' },
  { text: 'NOK', value: 'NOK', symbol: 'kr', name: 'Norwegian krone' },
  { text: 'NPR', value: 'NPR', symbol: 'NRs', name: 'Nepalese rupee' },
  { text: 'NZD', value: 'NZD', symbol: 'NZ$', name: 'New Zealand dollar' },
  { text: 'OMR', value: 'OMR', symbol: 'OMR', name: 'Omani rial' },
  { text: 'PAB', value: 'PAB', symbol: 'B./', name: 'Panamanian balboa' },
  { text: 'PEN', value: 'PEN', symbol: 'S/.', name: 'Peruvian nuevo sol' },
  { text: 'PGK', value: 'PGK', symbol: 'K', name: 'Papua New Guinean kina' },
  { text: 'PHP', value: 'PHP', symbol: '\u20b1', name: 'Philippine peso' },
  { text: 'PKR', value: 'PKR', symbol: 'Rs.', name: 'Pakistani rupee' },
  { text: 'PLN', value: 'PLN', symbol: 'z\u0142', name: 'Polish zloty' },
  { text: 'PYG', value: 'PYG', symbol: '\u20b2', name: 'Paraguayan guarani' },
  { text: 'QAR', value: 'QAR', symbol: 'QR', name: 'Qatari riyal' },
  { text: 'RON', value: 'RON', symbol: 'L', name: 'Romanian leu' },
  { text: 'RSD', value: 'RSD', symbol: 'din.', name: 'Serbian dinar' },
  { text: 'RUB', value: 'RUB', symbol: 'R', name: 'Russian ruble' },
  { text: 'SAR', value: 'SAR', symbol: 'SR', name: 'Saudi riyal' },
  { text: 'SBD', value: 'SBD', symbol: 'SI$', name: 'Solomon Islands dollar' },
  { text: 'SCR', value: 'SCR', symbol: 'SR', name: 'Seychellois rupee' },
  { text: 'SDG', value: 'SDG', symbol: 'SDG', name: 'Sudanese pound' },
  { text: 'SEK', value: 'SEK', symbol: 'kr', name: 'Swedish krona' },
  { text: 'SGD', value: 'SGD', symbol: 'S$', name: 'Singapore dollar' },
  { text: 'SHP', value: 'SHP', symbol: '\u00a3', name: 'Saint Helena pound' },
  { text: 'SLL', value: 'SLL', symbol: 'Le', name: 'Sierra Leonean leone' },
  { text: 'SOS', value: 'SOS', symbol: 'Sh.', name: 'Somali shilling' },
  { text: 'SRD', value: 'SRD', symbol: '$', name: 'Surinamese dollar' },
  { text: 'SYP', value: 'SYP', symbol: 'LS', name: 'Syrian pound' },
  { text: 'SZL', value: 'SZL', symbol: 'E', name: 'Swazi lilangeni' },
  { text: 'THB', value: 'THB', symbol: '\u0e3f', name: 'Thai baht' },
  { text: 'TJS', value: 'TJS', symbol: 'TJS', name: 'Tajikistani somoni' },
  { text: 'TMT', value: 'TMT', symbol: 'm', name: 'Turkmen manat' },
  { text: 'TND', value: 'TND', symbol: 'DT', name: 'Tunisian dinar' },
  { text: 'TRY', value: 'TRY', symbol: 'TRY', name: 'Turkish new lira' },
  {
    text: 'TTD',
    value: 'TTD',
    symbol: 'TT$',
    name: 'Trinidad and Tobago dollar',
  },
  { text: 'TWD', value: 'TWD', symbol: 'NT$', name: 'New Taiwan dollar' },
  { text: 'TZS', value: 'TZS', symbol: 'TZS', name: 'Tanzanian shilling' },
  { text: 'UAH', value: 'UAH', symbol: 'UAH', name: 'Ukrainian hryvnia' },
  { text: 'UGX', value: 'UGX', symbol: 'USh', name: 'Ugandan shilling' },
  { text: 'USD', value: 'USD', symbol: 'US$', name: 'United States dollar' },
  { text: 'UYU', value: 'UYU', symbol: '$U', name: 'Uruguayan peso' },
  { text: 'UZS', value: 'UZS', symbol: 'UZS', name: 'Uzbekistani som' },
  { text: 'VEB', value: 'VEB', symbol: 'Bs', name: 'Venezuelan bolivar' },
  { text: 'VND', value: 'VND', symbol: '\u20ab', name: 'Vietnamese dong' },
  { text: 'VUV', value: 'VUV', symbol: 'VT', name: 'Vanuatu vatu' },
  { text: 'WST', value: 'WST', symbol: 'WS$', name: 'Samoan tala' },
  {
    text: 'XAF',
    value: 'XAF',
    symbol: 'CFA',
    name: 'Central African CFA franc',
  },
  { text: 'XCD', value: 'XCD', symbol: 'EC$', name: 'East Caribbean dollar' },
  { text: 'XDR', value: 'XDR', symbol: 'SDR', name: 'Special Drawing Rights' },
  { text: 'XOF', value: 'XOF', symbol: 'CFA', name: 'West African CFA franc' },
  { text: 'XPF', value: 'XPF', symbol: 'F', name: 'CFP franc' },
  { text: 'YER', value: 'YER', symbol: 'YER', name: 'Yemeni rial' },
  { text: 'ZAR', value: 'ZAR', symbol: 'R', name: 'South African rand' },
  { text: 'ZMK', value: 'ZMK', symbol: 'ZK', name: 'Zambian kwacha' },
  { text: 'ZWR', value: 'ZWR', symbol: 'Z$', name: 'Zimbabwean dollar' },
];
