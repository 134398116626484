import React, { useState, useEffect } from 'react';

import TextInfoTile from 'pages/claims/components/textInfoTile/TextInfoTile';
import PieInfoTile from 'pages/claims/components/pieInfoTile/PieInfoTile';

import { getColor } from '../AreaChartSection/helpers/helpers';

import BarGraphInfoTile from 'pages/claims/components/barGraphInfoTile/BarGraphInfoTile';
import { claimCauses, claimTypes } from 'helpers/claimData';
import { orderBy } from 'lodash';
import { HorizontalScroller } from 'components';

import './infoTiles.scss';

const InfoTiles = ({
  claimStatFilteredData,
  claimSettings,
  fromDate,
  toDate,
}) => {
  const [totalCostToCompany, setTotalCostToCompany] = useState(0);
  const [claimTypesInfo, setClaimTypesInfo] = useState([]);
  const [claimCausesInfo, setClaimCausesInfo] = useState([]);

  useEffect(() => {
    getTotalCostToCompany();
    getClaimTypesInfo();
    getClaimCausesInfo();
  }, [
    claimStatFilteredData,
    claimSettings,
    fromDate,
    toDate,
    totalCostToCompany,
  ]);

  const getTotalCostToCompany = () => {
    if (
      !claimStatFilteredData ||
      claimStatFilteredData?.length < 1 ||
      !claimSettings?.insuranceDeductiblesAmount
    ) {
      setTotalCostToCompany(0.0);
      return 0.0;
    }
    let total = 0;
    claimStatFilteredData?.forEach((claim) => {
      if(claim.isDeleted) return;
      const hasApprovedAndIncidentCost = !!(claim?.incidentCost && claim?.approvedInsuranceClaimAmount);
      const totalApprovedInsuranceClaimCalc = claim?.incidentCost - claim?.approvedInsuranceClaimAmount + claimSettings?.insuranceDeductiblesAmount

      if (hasApprovedAndIncidentCost) {
        total += totalApprovedInsuranceClaimCalc
      } else if (claim?.totalApprovedClaimAmount) {
        total += claim?.totalApprovedClaimAmount;
      }
    });
    setTotalCostToCompany(total);
    return total;
  };

  const getClaimTypesInfo = () => {
    const res = [];
    claimTypes.forEach((type) => {
      const filteredClaims = claimStatFilteredData?.filter(
        (claim) => (claim?.claimType === type?.value) && !claim.isDeleted
      );

      let totalCostToCompany = 0;
      if (
        filteredClaims &&
        filteredClaims?.length > 0 &&
        claimSettings?.insuranceDeductiblesAmount
      ) {
        filteredClaims?.forEach((claim) => {
          if(claim.isDeleted) return;
          const hasApprovedAndIncidentCost = !!(claim?.incidentCost && claim?.approvedInsuranceClaimAmount);
          const totalApprovedInsuranceClaimCalc = claim?.incidentCost - claim?.approvedInsuranceClaimAmount + claimSettings?.insuranceDeductiblesAmount
  
          if (hasApprovedAndIncidentCost) {
            totalCostToCompany += totalApprovedInsuranceClaimCalc;
          } else if (claim?.totalApprovedClaimAmount) {
            totalCostToCompany += claim?.totalApprovedClaimAmount;
          }
        });
      }

      res.push({
        name: type.value,
        count: filteredClaims?.length || 0,
        companyCost: totalCostToCompany || 0,
        color: getColor(type.value),
      });
    });

    setClaimTypesInfo(res);
    return res;
  };

  const getClaimCausesInfo = () => {
    const res = [];
    claimCauses.forEach((cause) => {
      const filteredClaims = claimStatFilteredData?.filter(
        (claim) => claim?.claimCause === cause?.value
      );

      let totalCostToCompany = 0;
      if (
        filteredClaims &&
        filteredClaims?.length > 0 &&
        claimSettings?.insuranceDeductiblesAmount
      ) {
        filteredClaims?.forEach((claim) => {
          if (claim?.incidentCost && claim?.approvedInsuranceClaimAmount) {
            totalCostToCompany +=
              claim?.incidentCost -
              claim?.approvedInsuranceClaimAmount +
              claimSettings?.insuranceDeductiblesAmount;
          } else if (claim?.totalApprovedClaimAmount) {
            totalCostToCompany += claim?.totalApprovedClaimAmount;
          }
        });
      }

      const darkness = (res.length + 1) * 70;
      const hexValue = darkness.toString(16);
      const color = `#${hexValue}${hexValue}${hexValue}`;

      res.push({
        name: cause.value,
        count: filteredClaims?.length || 0,
        companyCost: totalCostToCompany || 0,
        color,
      });
    });
    let sortedArr = orderBy(res, ['companyCost'], ['desc']);
    let top5Causes = sortedArr
      ?.slice(0, 5)
      ?.filter((cause) => Boolean(cause?.companyCost));
    setClaimCausesInfo(top5Causes);
    return res;
  };

  return (
    <HorizontalScroller>
      <TextInfoTile
        key="medical_costs_to_company_to-date"
        title="Medical costs to company to-date"
        // subTitle="current month"
        quantity={totalCostToCompany}
        unit={claimSettings?.currency}
        type="money"
        updatedOn={toDate}
      />
      <BarGraphInfoTile
       key="no_of_claims"
        title="No. of claims"
        // subTitle="current month"
        data={claimTypesInfo}
        updatedOn={toDate}
      />
      <PieInfoTile
      key="type_of_claims"
        title="Type of claims"
        // subTitle="current month"
        data={claimTypesInfo?.filter((c) => Boolean(c?.count))}
        dataKey={'count'}
        updatedOn={toDate}
      />
      <PieInfoTile
        key="costs_of_claims_to_company"
        title="Costs of claims to company"
        // subTitle="current month"
        data={claimTypesInfo?.filter((c) => Boolean(c?.companyCost))}
        dataKey={'companyCost'}
        isMoney
        currency={claimSettings?.currency}
        showDataKeyValue
        updatedOn={toDate}
      />
      <PieInfoTile
        key="top_5_causation_of_claims"
        title="Top 5 causation of claims"
        // subTitle="current month"
        data={claimCausesInfo?.filter((c) => Boolean(c?.companyCost))}
        dataKey={'companyCost'}
        secondaryDataKey={'count'}
        isMoney
        showDataKeyValue
        showSecondaryDataKeyValue
        currency={claimSettings?.currency}
        updatedOn={toDate}
      />
    </HorizontalScroller>
  );
};

export default InfoTiles;
