import bmiSvg from 'assets/new/patientApp/bmi.svg'
import bpSvg from 'assets/new/patientApp/bp.svg'
import cardioSvg from 'assets/new/patientApp/cardiovascular.svg'
import diabetesSvg from 'assets/new/patientApp/diabetes.svg'
import hypertesionSvg from 'assets/new/patientApp/hypertension.svg'
import pulseSvg from 'assets/new/patientApp/o2.svg'
import liverSvg from 'assets/new/patientApp/nafl.svg'
import chdSvg from 'assets/new/patientApp/chd.svg'

const blue = "#becaf6"
const green = "#00C48C"
const greenish = "#0e8301"
const greenish2 = "#12b001"
const yellow = "#FFD443"
const orange = "#ffaf3e"
const red = "#FF6666"
const red2 = "#ff3a3a"
const red3 = "#ff0000"
const grey = "#9FABBD"

const keys = ['bloodPressureStatus', 'bmiStatus', 'hypertensionStatus', 'diabetesStatus', 'cardiovascularStatus', 'coronaryHeartDiseaseStatus', 'liverStatus', 'pulseOxStatus'];

const dataSkeleton = {
    bloodPressureStatus: {
      count: 0,
      score: 0,
      color: red,
      icon: bpSvg,
      header: 'Blood pressure',
      status: 'Elevated'
    },
    bmiStatus: {
      count: 0,
      score: 0,
      color: red,
      icon: bmiSvg,
      header: 'Body mass index',
      status: 'Elevated'
    },
    hypertensionStatus: {
      count: 0,
      score: 0,
      color: red,
      icon: hypertesionSvg,
      header: 'Hypertension',
      status: 'Elevated'
    },
    diabetesStatus: {
      count: 0,
      score: 0,
      color: orange,
      icon: diabetesSvg,
      header: 'Diabetes',
      status: 'Normal'
    },
    cardiovascularStatus: {
      count: 0,
      score: 0,
      color: red,
      icon: cardioSvg,
      header: 'Cardiovascular',
      status: 'Elevated'
    },
    coronaryHeartDiseaseStatus: {
      count: 0,
      score: 0,
      color: orange,
      icon: chdSvg,
      header: 'Coronary Heart Disease',
      status: 'Normal'
    },
    liverStatus: {
      count: 0,
      score: 0,
      color: orange,
      icon: liverSvg,
      header: 'Non-alcholic Fatty Liver',
      status: 'Normal'
    },
    pulseOxStatus: {
      count: 0,
      score: 0,
      color: orange,
      icon: pulseSvg,
      header: 'Pulse Oxy',
      status: 'Normal'
    },
}

const getColor = (status) => {
  if(!status) return grey;
  const lowerCaseStatus = status.toLowerCase();
  switch (lowerCaseStatus) {
    case null:
    //   return grey;
    // case 'no user inputs':
      return grey;
    case 'underweight':
      return orange;
    case 'low':
      return greenish;
    case 'normal':
      return greenish;
    case 'elevated':
      return yellow;
    case 'overweight':
      return yellow;
    case 'borderline':
      return yellow;
    case 'intermediate':
      return orange;
    case 'high':
      return red;
    case 'high risk':
      return red;
    case 'obese':
      return orange;
    case 'very obese':
      return red;
    case 'high blood pressure':
      return red;
    case 'very high risk':
      return red3;
    case 'hypertension crisis':
      return red3;
    default:
      return blue;
  }
}

const formatDonutData = (data) => {
  const fData = keys.map((key) => {
      const value = dataSkeleton[key]
      const statusData = data[key];
      let count = 0;
      let totalCount = 0;
      if(!value || !statusData) return null;

      let noData = null;

      const subData = statusData.map(dat => {
        if(!!dat._id) count += dat.count;
        totalCount += dat.count;
        if(!dat._id) return;
        const val = {
          name: dat._id ? dat._id[0].toUpperCase() + dat._id?.slice(1) : 'No user input',
          value: dat.count,
          color: getColor(dat._id)
        }

        if(!dat._id) {
          noData = val;
          return null;
        }

        return val
      })

      subData.push(noData);
      value.count = count;
      value.totalCount = totalCount;
      value.score = subData.filter(n => n);
      return value
  })
  return fData.filter(n => n)
}

export { formatDonutData }