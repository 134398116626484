import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { signOutUser } from 'auth/authentication';
import LoaderPage from '../pages/loaderPage';

const ProtectedRoute = ({ component: Component, restricted, ...props }) => {
  const userInfo = useSelector((state) => state.general.user);
  // return <Route {...props} render={(props) => <Component {...props} />} />;

  if (!localStorage.getItem('AUTH_TOKEN')) {
    signOutUser(userInfo.id);
    return <Route {...props} render={() => <LoaderPage />} />;
  }

  if (restricted?.includes(userInfo.role)) return <Redirect to="/workforce" />;
  return <Route {...props} render={(props) => <Component {...props} />} />;
};

export default ProtectedRoute;
