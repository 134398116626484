import React, { useRef, useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { Icon } from 'semantic-ui-react';

import './horizontalScroller.scss';
import 'react-horizontal-scrolling-menu/dist/styles.css';

const HorizontalScroller = ({ children = [] }) => {
  const [selected, setSelected] = useState([]);
  // const [position, setPosition] = useState(0);

  const scrollDivRef = useRef();

  const isItemSelected = (id) => !!selected.find((el) => el === id);

  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);

      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== id)
          : currentSelected.concat(id)
      );
    };

  //To check if there are enough items to add scroll buttons
  const scrollContainerClientWidth =
    scrollDivRef.current?.scrollContainer?.current?.clientWidth;
  const scrollContainerScrollWidth =
    scrollDivRef.current?.scrollContainer?.current?.scrollWidth;
  const isScrollable = scrollContainerClientWidth < scrollContainerScrollWidth;

  return (
    <ScrollMenu
      LeftArrow={isScrollable ? LeftArrow : <></>}
      RightArrow={isScrollable ? RightArrow : <></>}
      apiRef={scrollDivRef}
    >
      {children.map((child, index) => (
        <Card
          itemId={index} // NOTE: itemId is required for track items
          title={index}
          key={index}
          onClick={handleClick(index)}
          selected={isItemSelected(index)}
          child={child}
        />
      ))}
    </ScrollMenu>
  );
};

function Card({ onClick, selected, title, itemId, child }) {
  const visibility = React.useContext(VisibilityContext);

  return (
    <div onClick={() => onClick(visibility)} tabIndex={0}>
      {child}
    </div>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);
  return (
    <div
      className="h-scroller__arrow h-scroller__arrow--left"
      disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
    >
      <Icon
        className="h-scroller__arrow__img"
        size="large"
        color="grey"
        name="angle left"
      />
    </div>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <div
      className="h-scroller__arrow h-scroller__arrow--right"
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
    >
      <Icon
        className="h-scroller__arrow__img"
        size="large"
        color="grey"
        name="angle right"
      />
    </div>
  );
}

export default HorizontalScroller;
