import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import jwt from 'jwt-decode'; // import dependency
import { Divider } from 'semantic-ui-react';

import {
  Input,
  Button,
  Modal,
  ErrorToast,
  SuccessToast,
} from 'components';
import IdAuthService from 'api/identity/authentication';
import IdUserService from 'api/identity/user';
import ClientService from 'api/client/client';
import DepartmentService from 'api/department';
// import ClientService from 'api/clients';
import PasswordReset from '../passwordReset/PasswordReset';
import ActivateUser from '../activateUser/ActivateUser';
import { createUserStorage, setClientStorage } from 'auth/authentication';

import logo from 'assets/logo-icons/bedrock.svg';

import { setUser, setUserRights, setUserDepartments, setClientInformation } from 'redux/generalState.actions';

import history from '../../history';
import './login.scss';

const LoginPage = (props) => {
  const dispatch = useDispatch();

  const clientInformation = useSelector(
    (state) => state.general.clientInformation
  );
  const clientBranding = useSelector((state) => state.general.clientBranding);

  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [isModalToggled, setIsModalToggled] = useState('');
  const [isResetting, setIsResetting] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginType, setLoginType] = useState('provider');
  const [disableEmail, setDisableEmail] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    if (props.location?.state?.email) setEmail(props.location.state.email);
    if (params.get('email')) {
      setEmail(decodeURI(params.get('email')));
    }
    if (params.get('external')) {
      setDisableEmail(true);
    }
    const type = localStorage.getItem('LOGIN_TYPE');

    if (params.get('type') === 'p') setLoginType('patient');
    if (type && !params.get('type')) setLoginType(type);
  }, []);

  useEffect(() => {
    localStorage.setItem('LOGIN_TYPE', loginType);
  }, [loginType]);

  const resetPassword = async () => {
    setIsResetting(true);
    try {
      // const rootId = localStorage.getItem('ROOT_ID');
      // await AuthService.onForgotPassword(resetEmail, rootId);
      await IdAuthService.onForgotPassword(resetEmail);
      toast.success(<SuccessToast message="Reset email sent" />, {
        hideProgressBar: true,
        autoClose: 4000,
        pauseOnHover: true,
      });
      setIsModalToggled(false);
      setResetEmail('');
    } catch (error) {
      toast.error(<ErrorToast message="User account does not exist" />);
    }

    setIsResetting(false);
  };

  // const fetchClientDetails = async (clientId) => {
  //   try {
  //     const { data } = await ClientService.getClient(clientId);
  //     dispatch(setClientInformation(data));
  //   } catch (error) {
  //     console.log('fetchClientDetails', error);
  //   }
  // };

  const login = async () => {
    setIsLoggingIn(true);
    try {
      const authResp = await IdAuthService.onSignInUser({
        my_id: { password, username: email },
      });
      if (!authResp) {
        setIsLoggingIn(false);
        return toast.error(
          <ErrorToast message="Login failed, you have entered an invalid username or password." />
        );
      }

      const parsedLocalToken = jwt(authResp.data.access_token);
      const parsedRefreshToken = jwt(authResp.data.id_token);

      const localToken = {
        token: authResp.data.access_token,
        expires: parsedLocalToken.StandardClaims.exp,
      };
      const refreshToken = {
        token: authResp.data.id_token,
        expires: parsedRefreshToken.StandardClaims.exp,
      };
      await localStorage.setItem('AUTH_TOKEN', JSON.stringify(localToken));
      await localStorage.setItem('USER_ID', authResp.data.user_id);
      await localStorage.setItem('REFRESH_TOKEN', JSON.stringify(refreshToken));
      const {
        data: { user, department },
      } = await IdUserService.getCurrentUser();
      dispatch(setUser(user));
      if(user?.metadata?.role === 'admin') await fetchDepartments();
      else {
        dispatch(setUserRights(user?.metadata?.rights || []));
        dispatch(setUserDepartments(department));
      }
      await createUserStorage(user);
      const {
        data: { client },
      } = await ClientService.getCurrentClient();
      dispatch(setClientInformation(client));
      setClientStorage(client);
      history.push('/workforce');
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setIsLoggingIn(false);
    }
  };

  const fetchDepartments = async () => {
    try {
      const filter = {filters: { isDeleted: false }};

      const {
        data: { data },
      } = await DepartmentService.getAll(filter);
      dispatch(setUserDepartments(data));

      const userRights = {};
      
      data.forEach((dept) => {
        userRights[dept._id] = [
          "manageUsers",
          "manageDepartments",
          "manageMembers",
          "viewMembers",
          "viewStats",
        ]
      })

      dispatch(setUserRights(userRights));
    } catch (error) {
      dispatch(setUserDepartments([]));
      dispatch(setUserRights({}));
      toast.error(<ErrorToast error={error} />);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      login();
    }
  };

  const toggleForgotPassword = () => {
    setResetEmail(email);
    setIsModalToggled(true);
  };
  return (
    <div className="login">
      {/* {window.location.hostname.split('.')[0] !== 'elab' && (
        <img className="login__bckg" src={backgroundGraphic} alt="bkcg" />
      )} */}

      <div className="login__card">
        <img
          className="login__card__logo"
          alt="logo"
          src={logo}
        />
        <div className="login__card__content">
          <Switch>
            <Route
              path="/password-reset"
              exact
              component={() => (
                <PasswordReset clientInformation={clientInformation} />
              )}
            />
            <Route
              path="/reset-password/get"
              exact
              component={() => (
                <PasswordReset clientInformation={clientInformation} />
              )}
            />
            <Route
              path="/user-activate"
              exact
              component={() => (
                <ActivateUser clientInformation={clientInformation} />
              )}
            />
            <Route
              path="/activation/get"
              exact
              component={() => (
                <ActivateUser clientInformation={clientInformation} />
              )}
            />
            <Route
              path="/activate"
              exact
              component={() => (
                <ActivateUser clientInformation={clientInformation} />
              )}
            />
            <Route
              path="/"
              exact
              render={() => (
                <>
                  {/* <div className="login__card__header">eLab Portal</div> */}
                  <Input
                    border
                    label="Email"
                    className="login__card__input"
                    placeholder="mail@domain.com"
                    size="fullWidth"
                    disabled={disableEmail}
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="username"
                  />
                  <Input
                    border
                    label="Password"
                    className="login__card__input"
                    placeholder="**********"
                    size="fullWidth"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    autoComplete="password"
                  />
                  <Button
                    className="login__card__button"
                    onClick={() => login()}
                    bold
                    color="blue"
                    isLoading={isLoggingIn}
                    disabled={isLoggingIn}
                  >
                    Login
                  </Button>
                  {loginType === 'patient' && (
                    <div className="mt-3">
                      <Divider horizontal>Or</Divider>
                      <Button
                        className="mt-1"
                        size="fullWidth"
                        onClick={() =>
                          history.push({
                            pathname: '/p/create-account-v1',
                            search: props.location.search,
                          })
                        }
                        bold
                        color="blue"
                        inverted
                      >
                        Create Account
                      </Button>
                    </div>
                  )}
                  <div className="login__card__forgot-wrapper">
                    <Button
                      className="login__card__forgot"
                      size="medium"
                      onClick={() => toggleForgotPassword()}
                      inverted
                      color="white"
                    >
                      <span
                        className="login__card__forgot__text"
                        style={{
                          color: clientBranding?.color || '#5076ff',
                        }}
                      >
                        Forgot password?
                      </span>
                    </Button>
                  </div>
                </>
              )}
            />
          </Switch>
        </div>
      </div>
      {/* <div className="login__image">
        <img
          src={background + '.jpg'}
          alt="background"
          onLoad={() => setImageLoaded(true)}
        />
        {!imageLoaded && (
          <img
            src={background + '-mini.jpg'}
            alt="background"
            className="login__image--mini"
          />
        )}
      </div> */}
      {isModalToggled && (
        <Modal
          closeIcon
          closeModal={() => setIsModalToggled(false)}
          header="Reset your password"
          text="You forgot your password? Enter your email below to recieve a password reset link."
          className="login-modal"
          button={
            <Button
              size="fullWidth"
              onClick={() => {
                resetPassword();
              }}
              bold
              color="blue"
              isLoading={isResetting}
            >
              Reset Password
            </Button>
          }
        >
          <Input
            border
            placeholder="Email"
            size="fullWidth"
            type="email"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
          />
        </Modal>
      )}
    </div>
  );
};

export default withRouter(LoginPage);
