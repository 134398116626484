import React, { useState } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { Text, Select } from 'components';
import { Table, Icon } from 'semantic-ui-react';

import './overviewTable.scss';

const statuses = {
  approved: {
    color: 'green',
    name: 'APPROVED',
  },
  'pending review': {
    color: 'grey',
    name: 'PENDING REVIEW',
  },
  'in review': {
    color: 'yellow',
    name: 'IN REVIEW',
  },
  declined: {
    color: 'red',
    name: 'DECLINED',
  },
};

const Status = ({ status }) => {
  const stat = statuses[status];
  return (
    <div className={`table-status table-status--${stat?.color}`}>
      <div className="table-status__dot" />
      <Text size="small" color={stat?.color} bold>
        {stat?.name}
      </Text>
    </div>
  );
};

const OverviewTable = ({
  filteredData,
  order,
  setOrder,
  downloadCertificate,
}) => {
  const [selectedStatus, setStatus] = useState(null);
  const statusOptions = [
    {
      text: 'APPROVED',
      value: 'approved',
    },
    {
      text: 'PENDING REVIEW',
      value: 'pending review',
    },
    {
      text: 'DECLINED',
      value: 'declined',
    },
  ];

  const getDepts = (depts) => {
    return depts.map((dpt) => dpt).join(', ');
  };

  const onStatusChange = (value) => {
    setStatus(value);
  };

  const renderTableData = () => {
    return filteredData.map((emp) => {
      return (
        <Table.Row key={emp?._id}>
          <Table.Cell>
            <div>
              <Text size="small" className="mr-1" bold>
                {emp?.fullName}
              </Text>
              <Text size="small">{getDepts(emp?.departments)}</Text>
            </div>
          </Table.Cell>
          <Table.Cell>
            <Text size="tiny" bold>
              {emp?.dateOfBirth || ''}
            </Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small" bold>
              {emp?.position || ''}
            </Text>
          </Table.Cell>
          <Table.Cell>
            <Status status={emp?.status} />
          </Table.Cell>
          <Table.Cell>
            <Text size="small" bold>
              {emp?.joiningDate
                ? moment(emp?.joiningDate).format('DD MMM YYYY')
                : '-'}
            </Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small" bold>
              {emp?.expiredDate
                ? moment(emp?.expiredDate).format('DD MMM YYYY')
                : '-'}
            </Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small" bold>
              {emp?.dateApproved
                ? moment(emp?.dateApproved).format('DD MMM YYYY')
                : '-'}
            </Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small" bold>
              {emp?.createdAt
                ? moment(emp?.createdAt).format('DD MMM YYYY')
                : '-'}
            </Text>
          </Table.Cell>
          <Table.Cell>
            {emp?.certification && (
              <Icon
                onClick={(e) => downloadCertificate(e, emp?._id, emp?.fullName)}
                name="download"
                className="overview-table__icon ml-3"
              />
            )}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <Table className="overview-table" striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Crew/ Vessel</Table.HeaderCell>
          <Table.HeaderCell>Date of Birth</Table.HeaderCell>
          <Table.HeaderCell>Position</Table.HeaderCell>
          <Table.HeaderCell>
            {/* <Select
              // disabled={loadingDepartments}
              border
              size="small"
              className="mental-Peme__view-acc__select"
              placeholder="Status"
              search
              selection
              value={selectedStatus}
              onChange={(e, { value }) => onStatusChange(value)}
              options={statusOptions}
            /> */}
            Status
          </Table.HeaderCell>
          <Table.HeaderCell>
            <div style={{ display: 'flex', gap: '10px' }}>
              Joining Date
              {order === 'asc' ? (
                <Icon
                  onClick={() => setOrder('desc')}
                  className="overview-table__icon"
                  name="sort content ascending"
                />
              ) : (
                <Icon
                  onClick={() => setOrder('asc')}
                  className="overview-table__icon"
                  name="sort content descending"
                />
              )}
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell>Exp. Date</Table.HeaderCell>
          <Table.HeaderCell>Date Approved</Table.HeaderCell>
          <Table.HeaderCell>Date Submitted</Table.HeaderCell>
          <Table.HeaderCell>Certificate</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderTableData()}</Table.Body>
    </Table>
  );
};

export default withRouter(OverviewTable);
