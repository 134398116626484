const bpLegendKeys = ['normal','elevated', 'normal-high', 'high blood pressure','hypertension crisis']
const oxyLegendKeys = ['normal','borderline','high risk','very high risk']
const bmiLegendKeys = ['underweight','normal','overweight','obese','very obese']
const cardioLegendKeys = ['low','borderline','intermediate','high']
const hypertensionLegendKeys = ['low','intermediate','high']
const coronaryLegendKeys = ['low','intermediate','high']
const nafldLegendKeys = ['low','intermediate','high']
const diabetesLegendKeys = ['normal','high']

const bpLegend = [
  {
    status: 'normal',
    ranges: [
      { label: 'systolic', value: '<120mmHg' },
      { label: 'diastolic', value: '<80mmHg' },
      { label: 'heart rate', value: '40-100' }
    ]
  },
  {
    status: 'elevated',
    ranges: [
      { label: 'systolic', value: '120-129mmHg' },
      { label: 'diastolic', value: '80-89mmHg' },
      { label: 'heart rate', value: '101-109' }
    ]
  },
  {
    status: 'normal-high',
    ranges: [
      { label: 'systolic', value: '120-129mmHg' },
      { label: 'diastolic', value: '80-89mmHg' },
      { label: 'heart rate', value: '101-109' }
    ]
  },
  {
    status: 'high blood pressure',
    ranges: [
      { label: 'systolic', value: '130-179mmHg' },
      { label: 'diastolic', value: '90-119mmHg' },
      { label: 'heart rate', value: '110-129' }
    ]
  },
  {
    status: 'hypertension crisis',
    ranges: [
      { label: 'systolic', value: '≥180' },
      { label: 'diastolic', value: '≥120mmHg' },
      { label: 'heart rate', value: '<40 or ≥130' }
  ]
  },
  // {
  //   status: 'No user inputs',
  // }
]

const oxyLegend = [
    {
      status: 'normal',
      ranges: [
        { label: 'pulse ox', value: '≥96' },
        { label: 'perfusion index', value: '0.4-20' },
        { label: 'heart rate', value: '40-100' }
      ]
    },
    {
      status: 'borderline',
      ranges: [
        { label: 'pulse ox', value: '95' },
        { label: 'perfusion index', value: '0-0.4 or >20' },
        { label: 'heart rate', value: '101-109' }
      ]
    },
    {
      status: 'high risk',
      ranges: [
        { label: 'pulse ox', value: '≤92' },
        { label: 'perfusion index', value: '0-0.4 or >20' },
        { label: 'heart rate', value: '110-129' }
      ]
    },
    {
      status: 'very high risk',
      ranges: [
        { label: 'pulse ox', value: '≤92' },
        { label: 'perfusion index', value: '0-0.4 or >20' },
        { label: 'heart rate', value: '<40 or ≥130' }
      ]
    },
    // {
    //   status: 'No user inputs',
    // }
    // {
    //   status: 'Low Heart Rate',
    //   ranges: [
    //     { label: 'pulse ox', value: '<120mmHg' },
    //     { label: 'perfusion index', value: '0-0.4 or >20' },
    //     { label: 'heart rate', value: '<40' }
    //   ]
    // },
]

const bmiLegend = [
    {
      status: 'underweight',
      ranges: [
        { label: 'BMI', value: '<18.5' },
      ]
    },
    {
      status: 'normal',
      ranges: [
        { label: 'BMI', value: '18.5-24.9' },
      ]
    },
    {
      status: 'overweight',
      ranges: [
        { label: 'BMI', value: '25-29.9' },
      ]
    },
    {
      status: 'obese',
      ranges: [
        { label: 'BMI', value: '30-34.9' },
      ]
    },
    {
      status: 'very obese',
      ranges: [
        { label: 'BMI', value: '≥35' },
      ]
    },
    // {
    //   status: 'No user inputs',
    // }
]

const cardioRange = [
  {
    status: 'low',
    ranges: [
      { label: 'Risk:', value: '0 - 5' },
    ]
  },
  {
    status: 'borderline',
    ranges: [
      { label: 'Risk:', value: '5 - 7.5' },
    ]
  },
  {
    status: 'intermediate',
    ranges: [
      { label: 'Risk:', value: '7.5 - 20' },
    ]
  },
  {
    status: 'high',
    ranges: [
      { label: 'Risk:', value: '20 - 100' },
    ]
  },
]

const hypertensionRange = [
  {
    status: 'low',
    ranges: [
      { label: 'Risk:', value: '0-5%' },
    ]
  },
  {
    status: 'intermediate',
    ranges: [
      { label: 'Risk:', value: '5-10%' },
    ]
  },
  {
    status: 'high',
    ranges: [
      { label: 'Risk:', value: '10-100%' },
    ]
  },
  // {
  //   status: 'No user inputs',
  // }
]

const coronaryRange = [
  {
    status: 'low',
    ranges: [
      { label: 'Risk:', value: '0-10%' },
    ]
  },
  {
    status: 'intermediate',
    ranges: [
      { label: 'Risk:', value: '10-20%' },
    ]
  },
  {
    status: 'high',
    ranges: [
      { label: 'Risk:', value: '20-100%' },
    ]
  },
  // {
  //   status: 'No user inputs',
  // }
]

const nafldRange = [
  {
    status: 'low',
    ranges: [
      { label: 'Risk:', value: '0-10%' },
    ]
  },
  {
    status: 'intermediate',
    ranges: [
      { label: 'Risk:', value: '10-25%' },
    ]
  },
  {
    status: 'high',
    ranges: [
      { label: 'Risk:', value: '25-100%' },
    ]
  },
  // {
  //   status: 'No user inputs',
  // }
]

const diabetesRange = [
  {
    status: 'normal',
    ranges: [
      { label: 'Risk:', value: '0-5.6%' },
    ]
  },
  {
    status: 'high',
    ranges: [
      { label: 'Risk:', value: '5.6-100%' },
    ]
  },
  // {
  //   status: 'No user inputs',
  // }
]

export { bpLegend, oxyLegend, bmiLegend, cardioRange, hypertensionRange, coronaryRange, nafldRange, diabetesRange,
  bpLegendKeys,
  oxyLegendKeys,
  bmiLegendKeys,
  cardioLegendKeys,
  hypertensionLegendKeys,
  coronaryLegendKeys,
  nafldLegendKeys,
  diabetesLegendKeys
}
