import React, { useState, useEffect } from 'react';
import { Text } from 'components';
import { PieChart, Pie, Sector, Cell, Legend } from 'recharts';

import { useMediaQuery } from 'react-responsive';

import './pieInfoTile.scss';

const PieInfoTiles = ({
  title,
  subTitle,
  data,
  updatedOn,
  dataKey,
  showDataKeyValue,
  showSecondaryDataKeyValue,
  secondaryDataKey,
  isMoney,
  currency,
  innerRadius,
  width,
  legend,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const trim = (str, n) => {
    if (str.length <= n) return str;
    const start = str.substring(0, n);
    return start + '...';
  };

  const renderActiveShape = (props) => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    return (
      <g>
        <text
          font-weight="bold"
          x={cx}
          y={cy}
          dy={-7}
          textAnchor="middle"
          fill={fill}
          style={{ textTransform: 'capitalize', width: '100px' }}
        >
          {trim(payload?.name, 18)}
        </text>
        <text
          font-weight="500"
          x={cx}
          y={cy}
          dy={12}
          textAnchor="middle"
          fill={fill}
        >
          {showSecondaryDataKeyValue && secondaryDataKey
            ? payload?.[secondaryDataKey]
            : ''}
        </text>
        <text
          font-weight="500"
          x={cx}
          y={cy}
          dy={showSecondaryDataKeyValue ? 30 : 12}
          textAnchor="middle"
          fill={fill}
        >
          {showDataKeyValue
            ? isMoney
              ? currency +
                ' ' +
                new Intl.NumberFormat().format(payload?.[dataKey])
              : payload?.[dataKey]
            : `${Math.round(percent * 1000) / 10}%`}
        </text>

        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    );
  };

  const renderCustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul style={{ listStyleType: 'none', padding: 0, marginLeft: '-85px' }}>
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ marginBottom: '35px' }}>
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="pie-info-tiles">
      <div className="pie-info-tiles__title">
        <Text size="medium" bold className="mb-3">
          {title}
        </Text>
        <Text size="tiny">{subTitle}</Text>
      </div>
      <div className="pie-info-tiles__content">
        {data && data?.length > 0 ? (
          <PieChart width={width || 200} height={200}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={innerRadius || 70}
              outerRadius={90}
              fill="#8884d8"
              dataKey={dataKey || 'value'}
              onMouseEnter={onPieEnter}
              startAngle={-270}
            >
              {data?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            {legend && isDesktopOrLaptop && (
              <Legend
                content={renderCustomLegend}
                layout="vertical"
                verticalAlign="top"
                align="left"
              />
            )}
          </PieChart>
        ) : (
          <Text center>No data</Text>
        )}
      </div>
      <div>
        {/* {updatedOn ? (
          <Text size="tiny">
            Updated on: {moment(updatedOn).format('DD MMM YYYY')}
          </Text>
        ) : (
          ''
        )} */}
      </div>
    </div>
  );
};

export default PieInfoTiles;
