import * as React from 'react';
const SVGComponent = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1_148)">
      <mask
        id="mask0_1_148"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={32}
        height={32}
      >
        <path d="M0 0H32V32H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1_148)">
        <path
          d="M19.4607 14.4722C19.2055 10.9759 17.9134 7.88342 16 5.72929C13.8567 8.14229 12.493 11.7328 12.493 15.7495C12.493 16.3558 12.524 16.9524 12.5841 17.5366"
          stroke={props?.color || 'black'}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.4408 16.3006C8.62915 13.4889 5.16121 11.9302 1.97177 11.7414C2.16058 14.9309 3.71927 18.3988 6.53096 21.2105C9.34265 24.0222 12.8106 25.5809 16 25.7697C15.8112 22.5802 14.2525 19.1123 11.4408 16.3006Z"
          stroke={props?.color || 'black'}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9068 11.5949C11.4996 10.0854 9.87851 8.94757 8.19876 8.25757C7.75233 9.86113 7.63301 11.6569 7.86808 13.5146"
          stroke={props?.color || 'black'}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.22669 19.3395C1.61181 19.4592 1.02306 19.6288 0.46875 19.8454C1.71969 22.1859 4.10088 24.2605 7.19931 25.4045C10.2977 26.5485 13.4557 26.5191 15.9274 25.5531"
          stroke={props?.color || 'black'}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.7733 19.3395C30.3882 19.4593 30.9769 19.6288 31.5313 19.8454C30.2803 22.1859 27.8991 24.2605 24.8007 25.4045C21.7023 26.5485 18.5443 26.5191 16.0726 25.5531"
          stroke={props?.color || 'black'}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5592 16.3006C23.3708 13.4889 26.8388 11.9302 30.0282 11.7414C29.8394 14.9309 28.2807 18.3988 25.469 21.2105C22.6573 24.0222 19.1894 25.5809 16 25.7697C16.1888 22.5802 17.7475 19.1123 20.5592 16.3006Z"
          stroke={props?.color || 'black'}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.0932 11.5949C20.5004 10.0854 22.1215 8.94757 23.8012 8.25757C24.2477 9.86113 24.367 11.6569 24.1319 13.5146"
          stroke={props?.color || 'black'}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1_148">
        <rect width={32} height={32} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SVGComponent;
