import React, { Suspense, useEffect, useState, lazy } from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

import Login from '../pages/login';
import LoaderPage from '../pages/loaderPage';

import ProtectedRoute from './ProtectedRoute';

import PageWrapper from '../pages/pageWrapper';

import Settings from '../pages/settings';

import Insights from '../pages/insights/InsightsWrapper';

import CompareInsights from '../pages/insights/features/Compare/comapre';

import EncounterDashboard from 'pages/encounter/encounter';

import WellnessKPI from 'pages/wellness/wellness';

import MentalPeme from 'pages/mentalPeme/mentalPeme';

import Claims from '../pages/claims/ClaimsWrapper';

import EmpoloyeesOverview from '../pages/employees/employeesOverview/EmployeesOverview';

import {
  signOutUser,
  refreshUserToken,
  setClientStorage,
} from 'auth/authentication';

import idUserService from 'api/identity/user';
import DepartmentService from 'api/department';
import ClientService from 'api/client/client';
import {
  setUser,
  setUserRights,
  setUserDepartments,
  setClientInformation,
} from 'redux/generalState.actions';

import history from '../history';

export const MainRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const userInfo = useSelector((state) => state.general.user);

  const [isLoading, setIsLoading] = useState(true);

  // Todo add a check to see if user is authenticated
  useEffect(() => {
    getInitialData();
  }, []); //eslint-disable-line

  useEffect(() => {
    window.Intercom('update');
  }, [location]);

  useEffect(() => {
    if (isMobile) return;
    window.Intercom('boot', {
      app_id: 'a1z5uy25',
      name: userInfo.name,
      email: userInfo.email,
      user_id: userInfo.id,
      alignment: 'right',
      horizontal_padding: 0,
      vertical_padding: 0,
    });
  }, [userInfo]);

  const getInitialData = async () => {
    await Promise.all([fetchClientDetails(), checkAppUrl()]);
    setIsLoading(false);
  };

  const checkAppUrl = async () => {
    localStorage.setItem('CUSTOM_DOMAIN', 'RIVERR');
    await checkIfAuthenticated();
  };

  const checkIfAuthenticated = async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      if (params.get('external')) return;
      const authToken = JSON.parse(localStorage.getItem('AUTH_TOKEN'));
      const refreshToken = JSON.parse(localStorage.getItem('REFRESH_TOKEN'));
      const path = history.location.pathname;
      if (!authToken) {
        if (
          path === '/activation/get' ||
          // TODO: get rid of this, skips auth for patient pages
          path === '/reset-password/get' ||
          path.includes('/patient-form') ||
          path.includes('/p/create-account')
        )
          return;

        signOutUser(localStorage.getItem('USER_SAFE_ID'));
        return history.push({ pathname: '/', search: window.location.search });
      }

      if (moment.utc(authToken.expires).isAfter(moment())) {
        if (
          path === '/' &&
          !(path === '/activation/get' || path === '/reset-password/get')
        ) {
          history.push('/workforce');
        }
        await fetchUserDetails();
      } else if (
        refreshToken &&
        moment.utc(refreshToken.expires).isAfter(moment())
      ) {
        if (path !== '/activation/get' && path !== '/reset-password/get')
          await refreshUserToken(window.location.search);
        await fetchUserDetails();
      }
    } catch (error) {}
  };

  const fetchClientDetails = async () => {
    try {
      const {
        data: { client },
      } = await ClientService.getCurrentClient();
      dispatch(setClientInformation(client));
      setClientStorage(client);
    } catch (error) {
      console.log('router on start error', error);
    }
  };

  const fetchUserDetails = async () => {
    try {
      const userId = localStorage.getItem('USER_SAFE_ID');
      if (!userId) return;
      const {
        data: { user, department },
      } = await idUserService.getCurrentUser();
      window.intercomSettings = {
        name: user?.metadata?.name,
        email: user?.email,
        user_id: user?.safe_id,
      };
      dispatch(setUser(user));
      if (user?.metadata?.role === 'admin') await fetchDepartments();
      else {
        dispatch(setUserRights(user?.metadata?.rights || {}));
        dispatch(setUserDepartments(department));
      }
    } catch (error) {
      console.log('router on start error', error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const filter = { filters: { isDeleted: false } };

      const {
        data: { data },
      } = await DepartmentService.getAll(filter);
      dispatch(setUserDepartments(data));

      const userRights = {};

      data.forEach((dept) => {
        userRights[dept._id] = [
          'manageUsers',
          'manageDepartments',
          'manageMembers',
          'viewMembers',
          'viewStats',
        ];
      });

      dispatch(setUserRights(userRights));
    } catch (error) {
      dispatch(setUserDepartments([]));
      dispatch(setUserRights({}));
      toast.error(<ErrorToast error={error} />);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoaderPage />
      ) : (
        <Suspense fallback={<LoaderPage />}>
          <Switch>
            <Route path="/password-reset" exact component={() => <Login />} />
            <Route
              path="/reset-password/get"
              exact
              component={() => <Login />}
            />
            <Route path="/activate" exact component={() => <Login />} />
            <Route path="/activation/get" exact component={() => <Login />} />
            <Route path="/user-activate" exact component={() => <Login />} />
            <Route exact path="/" component={() => <Login />} />

            <PageWrapper>
              <Switch>
                {/* {(userRights?.includes('viewMembers') || userRights?.includes('manageMembers') || !userRights?.length) && ( */}
                <ProtectedRoute
                  path="/workforce"
                  exact
                  component={() => <EmpoloyeesOverview />}
                />
                {/* )}
                {(userRights?.includes('settings') || !userRights?.length) && ( */}
                <ProtectedRoute
                  path="/settings"
                  component={() => <Settings />}
                />
                {/* )}
                {(userRights?.includes('viewInsights') || !userRights?.length) && ( */}
                <ProtectedRoute
                  path="/insights"
                  exact
                  component={() => <Insights />}
                />
                <ProtectedRoute
                  path="/insights/compare"
                  exact
                  component={() => <CompareInsights />}
                />
                <ProtectedRoute
                  path="/encounter"
                  component={() => <EncounterDashboard />}
                />
                <ProtectedRoute
                  path="/wellness"
                  component={() => <WellnessKPI />}
                />
                <ProtectedRoute
                  path="/mental-health"
                  component={() => <MentalPeme />}
                />
                {/* )} */}
                {/* )}
                {(userRights?.includes('viewInsights') || !userRights?.length) && ( */}
                <ProtectedRoute path="/claims" component={() => <Claims />} />
                {/* )} */}
              </Switch>
            </PageWrapper>
            <Redirect to="/" />
          </Switch>
        </Suspense>
      )}
    </>
  );
};
