import axios from './axiosInstances/identityAxiosInstance';

const api = {
  async getPEMEStatusSummaryData({ departments }) {
    const res = await axios.get('insight/peme/status', {
      params: { departments },
    });
    return res.data;
  },
  async getPEMESubmissions(params) {
    const res = await axios.get('insight/peme/user-questionnaires', {
      params,
    });
    return res.data;
  },
  async downloadPemeCertificate(userQuestionnaireId) {
    const res = await axios.post(`insight/peme/download-url`, {
      userQuestionnaireId,
    });
    return res.data;
  },
};
export default api;
