import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';

import { Input, Select, Button } from 'components';
import { DeleteModal } from 'features';
import { useCustomForm } from 'hooks';

import { schema } from './schema';

import './memberInvite.scss';

const initialData = {
  firstName: '',
  lastName: '',
  nationality: '',
  email: '',
  idType: 'NID',
  ssn: '',
  department: '',
  designation: '',
};

const identifications = [
  { text: '(PPN) Passport No.', value: 'PPN' },
  { text: '(NID) National Identification', value: 'NID' },
  { text: '(SSN) Social Security No.', value: 'SSN' },
  { text: '(DLN) Drivers License No.', value: 'DLN' },
  { text: '(IRN) Insurance Reference No.', value: 'IRN' },
  { text: '(CID) Company ID', value: 'CID' },
];

const MemberInvite = ({
  onSubmit,
  isSaving,
  member,
  depts,
  onDelete,
  isDeleting,
}) => {
  const [departments, setDepartments] = useState([]);
  const [isModalToggled, setToggleModal] = useState(false);

  useEffect(() => {
    if (depts) {
      const fData = formatData(depts);
      setDepartments(fData);
    }
  }, [depts]);

  useEffect(() => {
    if (member) {
      const fMember = formatMemeberData(member);
      reset(fMember);
    }
  }, [member]);

  const formatMemeberData = (memb) => {
    const output = {
      firstName: memb.firstName,
      lastName: memb.lastName,
      nationality: memb.nationality,
      email: memb.email,
      designation: !!memb.designation ? memb.designation : '',
      department: !!memb.departments.length ? memb.departments[0] : '',
    };
    if (memb.idNo) {
      output.idType = memb.idType;
      output.ssn = memb.idNo;
    } else if (memb.passNo) {
      output.idType = 'PPN';
      output.ssn = memb.passNo;
    }
    return output;
  };

  const {
    Controller,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useCustomForm(schema, initialData);

  const formatData = (data) => {
    const depts = data.map((dept) => ({
      text: dept.name,
      value: dept._id,
    }));
    return depts;
  };

  const onDeleteClick = () => {
    onDelete(member);
  };

  return (
    <>
      {isModalToggled && (
        <DeleteModal
          header="Delete member"
          content={'Are you sure you want to delete this member?'}
          toggleModal={setToggleModal}
          isModalToggled={isModalToggled}
          onDelete={onDeleteClick}
        />
      )}
      <div className="memb-invite__form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="memb-invite__form__grid">
            <div className="memb-invite__form__row">
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    border
                    className="memb-invite__input"
                    label="First name"
                    placeholder="John"
                    showErrorMessage={false}
                    size="fullWidth"
                    value={value}
                    invalid={errors?.firstName}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    border
                    className="memb-invite__input"
                    label="Last name"
                    placeholder="Doe"
                    showErrorMessage={false}
                    size="fullWidth"
                    value={value}
                    invalid={errors?.lastName}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            {/* <div className="memb-invite__form__row">
              <Controller
                name="nationality"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="Nationality"
                    border
                    placeholder="Singapore"
                    value={value}
                    onChange={(e, { value }) =>
                      onChange(value)
                    }
                    options={nationalities}
                    search
                    showErrorMessage={false}
                    size="fullWidth"
                    selection
                    className="memb-invite__input"
                    invalid={errors?.nationality}
                  />
                )}
              />
              </div> */}
            <div className="memb-invite__form__row">
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    border
                    className="memb-invite__input"
                    label="Email address"
                    placeholder="ben.wang@riverr.ai"
                    showErrorMessage={false}
                    size="fullWidth"
                    value={value}
                    disabled={!!member?.email}
                    invalid={errors?.email}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className="memb-invite__form__row">
              <Controller
                name="idType"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    border
                    label="ID Type"
                    placeholder="(NID) National Identification"
                    value={value}
                    onChange={(e, { value }) => onChange(value)}
                    options={identifications}
                    search
                    showErrorMessage={false}
                    size="fullWidth"
                    selection
                    className="memb-invite__input"
                    invalid={errors?.idType}
                  />
                )}
              />
            </div>
            <div className="memb-invite__form__row">
              <Controller
                name="ssn"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    border
                    label="ID Number"
                    className="memb-invite__input"
                    placeholder="C1324567H"
                    showErrorMessage={false}
                    size="fullWidth"
                    value={value}
                    invalid={errors?.ssn}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className="memb-invite__form__row">
              <Controller
                name="designation"
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    optional
                    border
                    label="Designation"
                    className="memb-invite__input"
                    placeholder="Engineer"
                    showErrorMessage={false}
                    size="fullWidth"
                    value={value}
                    invalid={errors?.designation}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className="memb-invite__form__row">
              <Controller
                name="department"
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    optional
                    label="Department"
                    border
                    placeholder="Select a department"
                    value={value}
                    onChange={(e, { value }) => {
                      onChange(value);
                    }}
                    options={departments}
                    search
                    showErrorMessage={false}
                    size="fullWidth"
                    selection
                    className="memb-invite__input"
                    invalid={errors?.department}
                  />
                )}
              />
            </div>
          </div>
          <Button
            type="submit"
            className="mt-5 memb-invite__button"
            size="fullWidth"
            color="blue"
            isLoading={isSaving}
          >
            Submit
          </Button>
        </form>

        {member && (
          <>
            <Divider />
            <Button
              color="red"
              onClick={() => setToggleModal(true)}
              size="fullWidth"
              isLoading={isDeleting}
            >
              Delete member
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default withRouter(MemberInvite);
