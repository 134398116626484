import React, { useState } from 'react';
import { Modal, Icon, Table } from 'semantic-ui-react';
import moment from 'moment';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useSelector } from 'react-redux';
import downloadFile from 'js-file-download';

import { Button, Text, ErrorToast, SuccessToast, DropZone } from 'components';
import { dataHeadersToPayloadMap, dataHeaders } from './sampleData';
import { vesselTypesValues, claimCausesValues, claimTypesValues } from 'helpers/claimData';

import ClaimService from 'api/claims';

import './bulkClaimUploadModal.scss';

const BulkClaimUploadModal = ({ isModalToggled, toggleModal, getClaims }) => {
  const { _id: clientId = clientId } = useSelector(
    (state) => state.general.clientInformation
  );
  const [formattedData, setFormattedData] = useState([]);
  const [dataErrors, setDataErrors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isApiError, setIsApiError] = useState(false);
  console.log("formattedData", formattedData)
  const onSave = async () => {
    setIsSaving(true);
    await bulkCreateClaims(formattedData)
    setIsSaving(false);
  }

  const downloadTemplate = async () => {
    try {
      const resp = await axios({
        url: 'https://bed-rock.s3.ap-southeast-1.amazonaws.com/CSV_templates/claims_upload_template.xlsx',
        method: 'GET',
        responseType: 'blob',
      });
      downloadFile(resp.data, 'claims_upload_template.xlsx');
    } catch (error) {
      console.log("error", error)
      toast.error(<ErrorToast message="Error downloading template" />);
    }
  };

  const excelSerialDateToDate = (serial) => {
    const excelEpoch = new Date(1899, 11, 30); // December 30, 1899
    const excelEpochAsUnixTimestamp = excelEpoch.getTime();
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const serialDateAsUnixTimestamp = excelEpochAsUnixTimestamp + (serial * millisecondsPerDay);
    return new Date(serialDateAsUnixTimestamp);
  }

  const lowercaseHeaders = (data) => {
    return data.map(item => {
      const lowercasedItem = {};
      for (const key in item) {
        lowercasedItem[key.toLowerCase().trim()] = item[key];
      }
      return lowercasedItem;
    });
  }

  const onUpload = (data, format) => {
    const errors = [];
    const dataWithLowerCase = lowercaseHeaders(data);
    const fData = dataWithLowerCase.map(row => {
      const feddata = formatCsvRow(row, format);
      if(feddata.errors) errors.push(feddata.errors);
      return feddata.data
    })
    setDataErrors(errors);
    setFormattedData(fData);
  }

  const formatCsvRow = (data, format) => {
    const rowData = {};
    dataHeaders.forEach(header => {
      let placedHeader = dataHeadersToPayloadMap[header];
      if(!placedHeader) placedHeader = header;
      rowData.clientId = clientId;
      let currentData = String(data[header])?.trim();
      if(currentData === 'undefined') currentData = null;
      if(!currentData) return rowData[placedHeader] = null;
      currentData = currentData.trim();
      if(header === 'date of incident (dd/mm/yyyy)' || header === 'date of claim approval (dd/mm/yyyy)'){
        rowData[placedHeader] = format === '.xlsx' ? excelSerialDateToDate(currentData) : formatToDDMMYYYY(currentData)
      }
      else rowData[placedHeader] = currentData;

      if(header === 'vessel type' || header === 'cause of claim' || header === 'claim type') rowData[placedHeader] = currentData.toLowerCase();
    })

    const errors = validateData(rowData);
    return {errors, data: rowData}
  }

  const validateData = (data) => {
    const payloadHeaders = Object.keys(data);
    const payload = {};
    const missingData = [];
    const formattingError = [];
    
    payloadHeaders.forEach(header => {
      const currentValue = data[header];
      if(!currentValue && (header === 'incidentCost' || header === 'approvedInsuranceClaimAmount' || header === 'totalApprovedClaimAmount')) {
        if(data?.exceedsDeductable === 'Yes') {
          if(header === 'incidentCost' || header === 'approvedInsuranceClaimAmount') {
            const dat = currentValue;
            if(isNaN(dat)) formattingError.push(`${header} is not a number`)
            missingData.push(header);
          }
        } else {
          if(header === 'totalApprovedClaimAmount') missingData.push(header);
        } 
      } else if(!currentValue && header !== 'incidentDesc') {
        missingData.push(header);
      }

      if(header === 'email' && !validateEmail(currentValue)) formattingError.push('Email malformatted')
      if(header === 'policyYear' && !moment(currentValue, "YYYY", true).isValid()) formattingError.push('Policy year malformatted')
      if(header === 'incidentDate' && !moment(currentValue).isValid()) formattingError.push('Incident date malformatted')
      if(header === 'claimApprovalDate' && !moment(currentValue).isValid()) formattingError.push('Claim approval date malformatted')
      if(data?.exceedsDeductable === 'Yes' && header === 'incidentCost' && isNaN(currentValue)) formattingError.push('Incident Cost is not a number')
      if(data?.exceedsDeductable === 'Yes' && header === 'approvedInsuranceClaimAmount' && isNaN(currentValue)) formattingError.push('Approved Insurance Claim amount is not a number')
      if(data?.exceedsDeductable === 'No' && header === 'totalApprovedClaimAmount' && isNaN(currentValue)) formattingError.push('Total Approved Claim amount is not a number')
      if(header === 'vesselType' && !vesselTypesValues.includes(currentValue)) formattingError.push('Not a valid Vessel Type')
      if(header === 'claimType' && !claimTypesValues.includes(currentValue)) formattingError.push('Not a valid Claim Type')
      if(header === 'claimCause' && !claimCausesValues.includes(currentValue)) formattingError.push('Not a valid Claim Cause')
    })
    if(!!missingData.length) payload.missingData = missingData;
    if(!!formattingError.length) payload.formattingError = formattingError;
    if(!!Object.keys(payload).length) return {data, errors: payload};

    return null;
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const formatToDDMMYYYY = (dateString) => {
    const inputFormats = ['DD/MM/YYYY', 'D/M/YYYY', 'DD/M/YYYY', 'D/MM/YYYY', 'DD/MM/YY', 'D/M/YY', 'DD/M/YY', 'D/MM/YY'];
    const date = moment(dateString, inputFormats, true);
    if (!date.isValid()) {
        return null;
    }
    const alignedDate = date.format('DD/MM/YYYY');
    return moment(alignedDate, 'DD/MM/YYYY').toDate();
  }

  const bulkCreateClaims = async(csvData) => {
    if(!Boolean(csvData.length)) return
    try {
      const { data } = await ClaimService.bulkCreateClaims(csvData);
      await getClaims({});

      const rejectedSaves = data.filter(claim => {
        if(claim.status === 'rejected') return true;
      })

      if(!!rejectedSaves.length) {
        const errorData = rejectedSaves.map(claimError => claimError.reason)
        setDataErrors(errorData)
        setIsApiError(true);
        toast.error(<ErrorToast message="Some claims failed saving" />);
      }
      else {
        toast.success(<SuccessToast message="Claims uploaded" />);
        toggleModal(false);
      }
    } catch (error) {
      toast.error(<ErrorToast message="Upload failed" />);
    } 
  }

  const renderTableData = () => {
    return dataErrors.map((row) => (
      <Table.Row key={row?.data?.email}>
        <Table.Cell>
          <Text size="small">{row?.data?.name}</Text>
        </Table.Cell>
        <Table.Cell>
          {row?.errors?.missingData && 
            <>
            <Text bold size="tiny" className="mb-1">Missing Data:</Text>
            {row?.errors?.missingData?.map(error => <Text size="tiny" uppercase>- {error}</Text>)}
            </>
            }
          {row?.errors?.formattingError && 
            <>
            <Text bold size="tiny" className="mb-1 mt-2">Formatting Errors:</Text>
            {row?.errors?.formattingError?.map(error => <Text size="tiny" uppercase>- {error}</Text>)}
            </>
            }
        </Table.Cell>
      </Table.Row>
    ));
  };

  return (
    <Modal
      open={isModalToggled}
      onClose={() => toggleModal(false)}
      closeIcon
      size="tiny"
    >
      <Modal.Content style={{ paddingTop: '10px' }}>
        <div className="bcum__content__header">
          <Text size="small" bold>
            Upload a CSV file with claims
          </Text>
          <Button
              size="small"
              color="green"
              inverted
              onClick={downloadTemplate}
              fontSize="tiny"
              style={{ width: '220px', height: '33px' }}
            >
              <>
                <Icon name="download" className="mr-3" />
                Download template
              </>
            </Button>
        </div>
        <div className="bcum__content__dropzone">
          <DropZone
            onUpload={onUpload}
            sheetName="Claim upload template"
            accept={{
              "text/csv": [".csv"],
              "application/vnd.ms-excel": [".xlsx"]
            }}
            onRemoveFiles={() => {
              setDataErrors([])
              setFormattedData([])
              setIsApiError(false);
            }}
          />
        </div>
        {isApiError && (
          <>
            <Text color="green" bold className="mt-4 mb-1">All claims not listed below were successfully saved!</Text>
            <Text bold size="small" className="mb-3">Don't upload successfully saved claims again</Text>
          </>
        )}
        {!!dataErrors?.length && (
          <div className="bcum__content__table-w">
            <Table className="sbcum__content__table" striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Employee</Table.HeaderCell>
                  <Table.HeaderCell>Errors</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{renderTableData()}</Table.Body>
            </Table>
          </div>
        )}
        <Button
          size="fullWidth"
          color="blue"
          disabled={isSaving || !formattedData.length || !!dataErrors.length}
          onClick={onSave}
          isLoading={isSaving}
        >
          <Icon name="upload" /> Upload data
        </Button>
      </Modal.Content>
    </Modal>
  );
};

export default BulkClaimUploadModal;
