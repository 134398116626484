import React, { useState, useEffect } from 'react';
import { Modal, Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import EmployeeSmallTable from '../employeeSmallTable/EmployeeSmallTable';
import { ErrorToast, Button, Text, CsvReader, Select } from 'components';
import EmployeeService from 'api/employees';

import './assignClinicModal.scss';

const AssignClinicModal = ({ toggleModal, isModalToggled, selectedEmps }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [clinic, setClinic] = useState(null);
  const [options, setOptions] = useState([]);
  const [employees, setEmployees] = useState([]);

  const clientInfo = useSelector((state) => state.general.clientInformation);

  useEffect(() => {
    formatClinicOptions();
    setEmployeeData();
  }, [])

  const setEmployeeData = async () => {
    const objKeys = Object.keys(selectedEmps);

    const emps = [];
    objKeys.forEach(key => {
      emps.push(selectedEmps[key])
    });

    setEmployees(emps)
  }

  const onAssign = async () => {
    try {
      setIsSaving(true);
        await EmployeeService.assignClinic({ids: employees.map(emp => emp.patientId), clinic})
        toggleModal(false);
    } catch(error) {
        toast.error(<ErrorToast error={error} />)
        setIsSaving(false)
    }
  }

  const formatClinicOptions = () => {
    if(!clientInfo?.connectedClinics) return;
    const opts = clientInfo?.connectedClinics?.map(clinic => ({ text: clinic.name, value: { ...clinic }, key: clinic.id }))
    setOptions(opts);
  }

  return (
    <Modal
    className="assign-clinic-m"
    closeIcon
    size="small"
    open={isModalToggled}
    onClose={() => toggleModal(false)}
  >
    <Modal.Header>
      <Text dark>Assign Clinic</Text>
    </Modal.Header>
    <Modal.Content className="assign-clinic-m__content">
        <div className='assign-clinic-m__table-wrapper'>
          <EmployeeSmallTable data={employees} noDepts={true} />
        </div>
      <Select
        className="mt-4 mb-5"
        label="Clinic"
        border
        optional
        placeholder="Select a clinic"
        value={clinic}
        onChange={(e, { value }) => {
          console.log("value", value)
          setClinic(value)
        }}
        options={options}
        search
        showErrorMessage={false}
        size="fullWidth"
        selection
      />
      <Button
        size="fullWidth"
        color="blue"
        disabled={!clinic}
        onClick={onAssign}
        isLoading={isSaving}
      >
        <Icon name="medkit" /> Assign Clinic
      </Button>
    </Modal.Content>
  </Modal>
  );
};

export default AssignClinicModal;
