import { useState, useEffect } from 'react';
import countries from 'helpers/countries';

export const useCountries = options => {
  const [countriesList, setCountriesList] = useState([]);

  useEffect(() => {
    const formattedCons = countries.map(con => {
      if (!options) return con;

      return {
        key: con.key,
        text: con.text,
        value: { nationality: con.text, countryCode: con.key },
      };
    });

    setCountriesList(formattedCons);
  }, [options]);

  return { countriesList };
};
