import React, { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { Text, ErrorToast } from 'components';
import { DeleteModal } from 'features';
import { Table, Dropdown } from 'semantic-ui-react';

import DepartmentService from 'api/department';
import EmployeeService from 'api/employees';

import threeDotsSvg from 'assets/new/threeDots.svg';

import './departmentManagement.scss';
import SuccessToast from 'components/SuccessToast/SuccessToast';

const statuses = {
  'ACTIVE': {
    color: 'green'
  },
  'DELETED': {
    color: 'red'
  },
}

const SignUpStatus = ({ status }) => {
  const stat = statuses[status];
  return (
    <div className={`table-status table-status--${stat?.color}`}>
    <div className="table-status__dot" />
      <Text size="small" color={stat?.color} bold>{status}</Text>
    </div>
  )
}

const DeparmentManagementTable = ({ filteredData, setDeptCreateToggle, setEditDept, fetchData }) => {
  const writeDepts = useSelector((state) => state.general?.userRightsObj?.departments?.writeDepts);
  const userInfo = useSelector((state) => state.general.user);

  const [isDeleteModalToggled, toggleDeleteModal] = useState(false)
  const [selectedAction, setSelectedAction] = useState(false)
  const [selectedDept, setSelectedDept] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const toggleDelete = async (id, action) => {
    if(id) {
      setSelectedDept(id)
      setSelectedAction(action)
    }
    else {
      setSelectedAction(null)
      setSelectedDept(null)
    }
    toggleDeleteModal(!isDeleteModalToggled);
  }
  
  const onUnlink = async () => {
    try {
      await EmployeeService.removeDepartmentFromAll(selectedDept);
      await fetchData();
      toast.success(<SuccessToast message='Removed all users from department' />);
    } catch (error) {
        toast.error(<ErrorToast error={error} />);
    }
  }

  const onDelete = async () => {
    try {
      setLoading(true);
      await DepartmentService.delete(selectedDept)
      await fetchData();
      setLoading(false);
      toggleDeleteModal(false);
    } catch (error) {
      setLoading(false);
      toast.error(<ErrorToast error={error} />);
    }
  }

  const updateDepartment = async (id, payload) => {
    try {
      await DepartmentService.update(id, payload)
      await fetchData();
    } catch (error) {
      setLoading(false);
      toast.error(<ErrorToast error={error} />);
    }
  }

  const modalData = {
    unlink: {
      header: "Are you sure you want to remove all members?",
      content: "This will remove all members from this department. This action can not be undone.",
      action: onUnlink,
      buttonText: 'Remove'
    },
    delete: {
      header: "Are you sure you want to delete this department?",
      content: "Deleted departments can later be restored.",
      action: onDelete,
      buttonText: 'Delete'
    }
  }

  const renderTableData = () => {
    return filteredData.map((dept) => {
      return (
        <Table.Row key={dept?._id}>
          <Table.Cell>
            <Text size="small" bold>{dept?.name}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small" bold>{!!dept?.tags?.length ? dept?.tags.join(', ') : ''}</Text>
          </Table.Cell>
          <Table.Cell>
            <SignUpStatus status={dept?.isDeleted ? 'DELETED' : 'ACTIVE'} />
          </Table.Cell>
          <Table.Cell>
            <Text size="small" bold>{moment(dept?.createdAt).format('DD MMM YYYY')}</Text>
          </Table.Cell>
          <Table.Cell className="dept-manag-t__small-cell">
          {(writeDepts?.includes(dept._id) ||  userInfo?.metadata?.role === 'admin') && (
            <div className="dept-manag-t__small-dots">
              <Dropdown
                selectOnBlur={false}
                icon={null}
                floating
                direction='left'
                trigger={
                  <img src={threeDotsSvg} alt="dots" />
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => {
                    setDeptCreateToggle(true)
                    setEditDept(dept);
                  }}>
                    <Text>Edit</Text>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => toggleDelete(dept._id, 'unlink')}>
                    <Text>Remove all members</Text>
                  </Dropdown.Item>
                  {dept.status === 'DELETED' ? 
                    <Dropdown.Item onClick={() => updateDepartment(dept, { isDeleted: false })}>
                      <Text>Restore</Text>
                    </Dropdown.Item>
                  :
                    <Dropdown.Item onClick={() => toggleDelete(dept._id, 'delete')}>
                      <Text color="red">Delete</Text>
                    </Dropdown.Item>
                  }
                </Dropdown.Menu>
              </Dropdown>
              </div>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <>
      {!!modalData[selectedAction] && (
        <DeleteModal
        toggleModal={toggleDelete}
        isModalToggled={isDeleteModalToggled}
        header={modalData[selectedAction].header}
        content={<Text center>{modalData[selectedAction].content}</Text>}
        onDelete={modalData[selectedAction].action}
        buttonText={modalData[selectedAction].buttonText}
        isSaving={isLoading}
        />
      )}
      <Table className="dept-manag-t" striped>
        <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Department</Table.HeaderCell>
          <Table.HeaderCell>#Tag</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Active since</Table.HeaderCell>
          <Table.HeaderCell textAlign='center' className="dept-manag-t__small-cell">Action</Table.HeaderCell>
        </Table.Row>
        </Table.Header>
        <Table.Body>{renderTableData()}</Table.Body>
      </Table>
    </>
  );
};

export default DeparmentManagementTable;
