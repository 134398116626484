import * as yup from 'yup';

export const schema = ({ minCost = 0 }) => {
  return yup.object().shape({
    employee: yup.object().shape({
      id: yup.string().required('required'),
      firstName: yup.string().required('required').max(40),
      lastName: yup.string().required('required').max(40),
      email: yup.string().email().required('required'),
      department: yup.string().nullable(),
      designation: yup.string().nullable(),
    }),
    referenceNo: yup.string().optional(),
    policyYear: yup.string().required('required'),
    vesselType: yup.string().required('required'),
    insuranceProvider: yup.string().required('required'),
    incidentDate: yup.string().required('required'),
    claimApprovalDate: yup.string().required('required'),
    repatriated: yup.string().required('required'),
    claimType: yup.string().required('required'),
    claimCause: yup.string().required('required'),
    incidentDesc: yup.string().optional(),
    currency: yup.string().required('required'),
    incidentCost: yup
      .number()
      .min(minCost, `Must be more than ${minCost}`)
      .required(`Must be more than ${minCost}`),
    approvedInsuranceClaimAmount: yup
      .number()
      .positive()
      .required('required'),
  });
}

export const isAboveDeductablesSchema = yup.object().shape({
  employee: yup.object().shape({
    id: yup.string().required('required'),
    firstName: yup.string().required('required').max(40),
    lastName: yup.string().required('required').max(40),
    email: yup.string().email().required('required'),
    department: yup.string().nullable(),
    designation: yup.string().nullable(),
  }),
  referenceNo: yup.string().optional(),
  policyYear: yup.string(),
  vesselType: yup.string().required('required'),
  insuranceProvider: yup.string(),
  incidentDate: yup.string().required('required'),
  claimApprovalDate: yup.string().required('required'),
  repatriated: yup.string().required('required'),
  claimType: yup.string().required('required'),
  claimCause: yup.string().required('required'),
  incidentDesc: yup.string().optional(),
  currency: yup.string().required('required'),
  totalApprovedClaimAmount: yup
    .number()
    .positive()
    .required('required'),
});
