import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { debounce } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { useCustomForm } from 'hooks';

import {
  Button,
  DatePicker,
  ErrorToast,
  Icon,
  Input,
  Select,
  Text,
  TextArea,
} from 'components';

import EmployeeService from 'api/employees';
import ClaimService from 'api/claims';

import { isAboveDeductablesSchema, schema } from './schema';
import history from '../../../../history';
import currencies from 'helpers/currencies';
import { claimCauses, claimTypes, vesselTypes } from 'helpers/claimData';

import './uploadClaims.scss';
import SuccessToast from 'components/SuccessToast/SuccessToast';

const UploadClaims = ({ formattedDepts, location, claimSettings }) => {
  const urlSearchParams = new URLSearchParams(location.search);

  const isAboveDeductables =
    location.state?.isAboveDeductables ||
    Boolean(Number(urlSearchParams.get('above_deductables')));
  const isEdit = location.state?.edit;
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [filteredClaimCauseOptions, setFilteredClaimCauseOptions] = useState(
    []
  );
  const [employees, setEmployees] = useState([]);
  const [loadingEmps, setLoadingEmps] = useState(false);
  const [allCurrencies, setAllCurrencies] = useState([]);
  const [createClaimsLoading, setCreateClaimsLoading] = useState(false);
  const [selectedClaimantInfo, setSelectedClaimantInfo] = useState(null);
  const [claim, setClaim] = useState(null);

  const { _id: clientId } = useSelector(
    (state) => state.general.clientInformation
  );

  const {
    Controller,
    handleSubmit,
    formState: { errors },
    getValues,
    control,
    reset,
  } = useCustomForm(isAboveDeductables ? schema({minCost: claimSettings.insuranceDeductiblesAmount}) : isAboveDeductablesSchema, {});

  useEffect(() => {
    let resetData = {
      currency: claimSettings.currency,
      policyYear: !isAboveDeductables? '' : getValues('policyYear') || claimSettings?.policyYear || '',
      insuranceProvider:
        !isAboveDeductables ? '' :  getValues('insuranceProvider') ||
        claimSettings?.insuranceProvider ||
        '',
    };

    if (claim) {
      resetData = {
        ...claim,
        currency: claimSettings.currency,
        incidentDate: moment(claim?.incidentDate).toDate(),
        claimApprovalDate: moment(claim?.claimApprovalDate).toDate(),
      };
      reset(resetData);
      const filteredClaimCauses = filterClaimCauses(claim?.claimType);
      setFilteredClaimCauseOptions(filteredClaimCauses);
      setSelectedClaimantInfo(claim?.employee);
    } else {
      reset(resetData);
    }
    reset(reset);
  }, [claimSettings, claim]);

  useEffect(() => {
    getOneClaim(urlSearchParams.get('id'));
    mapCurrencies();
  }, []);
  
  useEffect(() => {
    if(!!formattedDepts) fetchEmployees('');
  }, [formattedDepts]);

  const mapCurrencies = () => {
    const results = currencies.map(({ value, text, name }) => {
      return {
        value,
        text: `${name} (${text})`,
      };
    });
    setAllCurrencies(results);
  };

  const fetchEmployees = async (search) => {
    setLoadingEmps(true);
    try {
      const filters = { isDeleted: false };
      const {
        data: { data },
      } = await EmployeeService.getAll({ filters, search });
      setEmployees(data);

      const filteredOptions = data?.map((emp) => {
        const value = {
          id: emp?._id,
          firstName: emp?.firstName || '',
          lastName: emp?.lastName || '',
          email: emp?.email || '',
          department:
            emp?.departments?.length
              ? emp?.departments[0]
              : '',
          designation: emp?.designation || '',
        };
        const option = {
          key: emp?._id,
          text: `${emp?.firstName || ''} ${emp?.lastName || ''}`,
          value,
        };

        return option;
      });
      setFilteredOptions(filteredOptions);
      return data;
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setLoadingEmps(false);
    }
  };

  const onSearchEmpChange = async (e, { searchQuery }) => {
    handleSearchEmp(searchQuery);
  };

  const handleSearchEmp = useCallback(
    debounce((search) => fetchEmployees(search), 500),
    []
  );

  const updateClaim = async (data) => {
    setCreateClaimsLoading(true);
    data.clientId = clientId;
    const claimId = urlSearchParams.get('id');
    try {
      if (claim) await ClaimService.update(claimId, data);
      else await ClaimService.create(data);
      history.push('/claims');
      toast.success(<SuccessToast message={'Claim has been added'} />);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setCreateClaimsLoading(false);
    }
  };

  const getOneClaim = async (id) => {
    if (!id) return;
    try {
      const { data } = await ClaimService.getById(id);
      setClaim(data);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const filterClaimCauses = (claimType) => {
    return (
      claimCauses?.filter((cause) => cause?.claimType === claimType) || []
    );
  };

  return (
    <div className="upload-claims">
      <div className="upload-claims__wrapper">
        <div className="upload-claims__header mb-4">
          <div className="upload-claims__header__title">
            <Button
              circular
              color="blue"
              size="small"
              onClick={() => history.goBack()}
            >
              <Icon color="white" name="arrowLeft" className="mt-2" />
            </Button>
            <Text bold center size="big">
              Upload claim
            </Text>
          </div>
          <div className="upload-claims__header__settings">
            <Button
              color="white"
              size="wide"
              onClick={() => history.push('/claims/settings')}
            >
              <Text color="#5076ff" underline center size="small">
                Update claim settings
              </Text>
            </Button>
          </div>
        </div>
        <form
          className="upload-claims__form"
          onSubmit={handleSubmit(updateClaim)}
        >
          <div className="upload-claims__form__claimant">
            <div className="upload-claims__form__row">
              <div className="upload-claims__form__col">
                <Controller
                  name="employee"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      border
                      className="upload-claims__form__input"
                      selectClassName="upload-claims__form__select"
                      label={
                        <Text capitalize size="small" dark className="mb-2">
                          Details of claimant
                        </Text>
                      }
                      placeholder="search employee name or email"
                      showErrorMessage={false}
                      options={filteredOptions}
                      onSearchChange={onSearchEmpChange}
                      search
                      size="fullWidth"
                      value={value}
                      invalid={errors?.employee}
                      onChange={(e, { value }) => {
                        onChange(value);
                        setSelectedClaimantInfo(value);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="upload-claims__form__claimant-row">
              {/* <div className="upload-claims__form__claimant-col">
                <img
                  className="upload-claims__form__claimant-img"
                  src=""
                  alt=""
                />
                <Text center size="small">
                  {selectedClaimantInfo?.firstName &&
                  selectedClaimantInfo?.lastName
                    ? `${selectedClaimantInfo?.firstName} ${selectedClaimantInfo?.lastName}`
                    : 'full name'}
                </Text>
              </div> */}
              <div className="upload-claims__form__claimant-col">
                <Text center size="small">
                  {selectedClaimantInfo?.email || 'email address'}
                </Text>
              </div>
              <div className="upload-claims__form__claimant-col">
                <Text center size="small">
                  {formattedDepts[selectedClaimantInfo?.department] || 'No department'}
                </Text>
              </div>
              <div className="upload-claims__form__claimant-col">
                <Text center size="small">
                  {selectedClaimantInfo?.designation || 'No vessel'}
                </Text>
              </div>
            </div>
          </div>
          <div className="upload-claims__form__name mb-3 mt-3">
            <Text bold size="small" color="lightGrey" capitalize>
              General details
            </Text>
          </div>
          <div className="upload-claims__form__row">
            <div className="upload-claims__form__col">
              <Controller
                name="referenceNo"
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    border
                    className="upload-claims__form__input"
                    label="Reference no."
                    placeholder="Riverr"
                    showErrorMessage={false}
                    size="fullWidth"
                    value={value}
                    invalid={errors?.referenceNo}
                    optional
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className="upload-claims__form__col">
              <Controller
                name="policyYear"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    onChange={(date) => onChange(date)}
                    className="upload-claims__form__input"
                    value={value}
                    border
                    label="Policy year"
                    labelTextId=""
                    placeholder="YYYY"
                    format={'y'}
                    minDate={moment().subtract(5, 'years').toDate()}
                    maxDate={moment().toDate()}
                    size="fullWidth"
                    maxDetail="decade"
                    invalid={errors?.policyYear}
                    showErrorMessage={false}
                    disabled={!isAboveDeductables}
                  />
                )}
              />
            </div>
          </div>
          <div className="upload-claims__form__row">
            <div className="upload-claims__form__col">
              <Controller
                name="vesselType"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    border
                    className="upload-claims__form__input"
                    selectClassName="upload-claims__form__select"
                    label="Vessel type"
                    placeholder="Tanker"
                    showErrorMessage={false}
                    size="fullWidth"
                    options={vesselTypes}
                    value={value}
                    invalid={errors?.vesselType}
                    onChange={(e, { value }) => onChange(value)}
                  />
                )}
              />
            </div>
            <div className="upload-claims__form__col">
              <Controller
                name="insuranceProvider"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    border
                    className="upload-claims__form__input"
                    label="Insurance Provider"
                    placeholder="Riverr"
                    showErrorMessage={false}
                    size="fullWidth"
                    value={value}
                    invalid={errors?.insuranceProvider}
                    onChange={onChange}
                    disabled={!isAboveDeductables}
                  />
                )}
              />
            </div>
          </div>
          <div className="upload-claims__form__name mb-3 mt-3">
            <Text bold size="small" color="lightGrey" capitalize>
              Cause of claim
            </Text>
          </div>
          <div className="upload-claims__form__row">
            <div className="upload-claims__form__col">
              <Controller
                name="incidentDate"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    onChange={(date) => onChange(date)}
                    value={value}
                    border
                    label="Date of incident"
                    labelTextId=""
                    placeholder="DD-MM-YYYY"
                    size="fullWidth"
                    maxDate={new Date()}
                    invalid={errors?.incidentDate}
                    showErrorMessage={false}
                  />
                )}
              />
            </div>
            <div className="upload-claims__form__col">
              <Controller
                name="claimApprovalDate"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    onChange={(date) => onChange(date)}
                    value={value}
                    border
                    label="Date of claim approval"
                    labelTextId=""
                    placeholder="DD-MM-YYYY"
                    size="fullWidth"
                    maxDate={new Date()}
                    invalid={errors?.claimApprovalDate}
                    showErrorMessage={false}
                  />
                )}
              />
            </div>
            <div className="upload-claims__form__col">
              <Controller
                name="repatriated"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    border
                    className="upload-claims__form__input"
                    selectClassName="upload-claims__form__select"
                    label="Repatriated?"
                    placeholder="Yes"
                    showErrorMessage={false}
                    size="fullWidth"
                    options={[
                      { key: 'yes', text: 'Yes', value: 'Yes' },
                      { key: 'no', text: 'No', value: 'No' },
                    ]}
                    value={value}
                    invalid={errors?.repatriated}
                    onChange={(e, { value }) => onChange(value)}
                  />
                )}
              />
            </div>
          </div>
          <div className="upload-claims__form__row">
            <div className="upload-claims__form__col">
              <Controller
                name="claimType"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    border
                    className="upload-claims__form__input"
                    selectClassName="upload-claims__form__select"
                    label="Claim type"
                    placeholder="Illness"
                    showErrorMessage={false}
                    size="fullWidth"
                    options={claimTypes}
                    value={value}
                    invalid={errors?.claimType}
                    onChange={(e, { value }) => {
                      const filtered = filterClaimCauses(value);
                      setFilteredClaimCauseOptions(filtered);
                      onChange(value);
                    }}
                  />
                )}
              />
            </div>
            <div className="upload-claims__form__col">
              <Controller
                name="claimCause"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    border
                    className="upload-claims__form__input"
                    selectClassName="upload-claims__form__select"
                    label="Cause of claim"
                    placeholder="Seasickness"
                    showErrorMessage={false}
                    size="fullWidth"
                    options={filteredClaimCauseOptions}
                    value={value}
                    invalid={errors?.claimCause}
                    onChange={(e, { value }) => onChange(value)}
                  />
                )}
              />
            </div>
          </div>
          <div className="upload-claims__form__row">
            <div className="upload-claims__form__col">
              <Controller
                name="incidentDesc"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <TextArea
                    border
                    className="upload-claims__form__input"
                    label="Description of incident"
                    placeholder=""
                    showErrorMessage={false}
                    size="fullWidth"
                    value={value}
                    invalid={errors?.incidentDesc}
                    optional
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </div>
          <div className="upload-claims__form__name mb-3 mt-3">
            <Text bold size="small" color="lightGrey" capitalize>
              Claim details
            </Text>
          </div>
          <div className="upload-claims__form__row">
            <div
              className="upload-claims__form__col"
              style={{ flexGrow: 0, flexBasis: '50%' }}
            >
              <Controller
                name="currency"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    border
                    className="upload-claims__form__input"
                    selectClassName="upload-claims__form__select"
                    label="Currency"
                    placeholder="Singapore Dollars (SGD)"
                    showErrorMessage={false}
                    search
                    size="fullWidth"
                    options={allCurrencies}
                    value={value}
                    invalid={errors?.currency}
                    onChange={(e, { value }) => onChange(value)}
                  />
                )}
              />
            </div>
          </div>
          <div className="upload-claims__form__row">
            {isAboveDeductables ? (
              <>
                <div className="upload-claims__form__col">
                  <Controller
                    name="incidentCost"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value, error } }) => (
                      <Input
                        border
                        className="upload-claims__form__input"
                        label="Total incident cost"
                        placeholder="500,000.00"
                        // showErrorMessage={false}
                        size="fullWidth"
                        value={value}
                        invalid={errors?.incidentCost}
                        onChange={onChange}
                        errorMessage={errors?.incidentCost?.message}
                      />
                    )}
                  />
                </div>
                <div className="upload-claims__form__col">
                  <Controller
                    name="approvedInsuranceClaimAmount"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        border
                        className="upload-claims__form__input"
                        label="Approved insurance claim amount"
                        placeholder="300,000.00"
                        showErrorMessage={false}
                        size="fullWidth"
                        value={value}
                        invalid={errors?.approvedInsuranceClaimAmount}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
              </>
            ) : (
              <div className="upload-claims__form__col">
                <Controller
                  name="totalApprovedClaimAmount"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      border
                      className="upload-claims__form__input"
                      label="Total approved claim"
                      type="number"
                      placeholder="1200.00"
                      showErrorMessage={false}
                      size="fullWidth"
                      value={value}
                      invalid={errors?.totalApprovedClaimAmount}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            )}
          </div>
          <Button
            color="blue"
            size="fullWidth"
            className="mt-3"
            isLoading={createClaimsLoading}
          >
            Save
          </Button>
        </form>
      </div>
    </div>
  );
};

export default withRouter(UploadClaims);
