import axios from './axiosInstances/identityAxiosInstance';

const methods = {
  async assignDepartment(payload) {
    return axios.post('/myemployee/user/AssignDepartments', payload);
  },
  async updateById(id, payload) {
    return axios.post(`/myemployee/user/UpdateById/${id}`, payload);
  },
};

export default methods;
