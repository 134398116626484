import React, { useEffect } from 'react';

import { useCustomForm } from 'hooks';
import { Button, Input } from 'components';

import { schema } from './schema'

import './departmentManagement.scss';

const initialData = {
    name: '',
    tag: ''
}

const DepartmentManagementForm = ({
    onSubmit,
    isLoading,
    editDept
}) => {
    const {
        Controller,
        handleSubmit,
        formState: { errors },
        control,
        reset,
    } = useCustomForm(schema, initialData);

    useEffect(() => {
        if(!!editDept) reset({name: editDept.name, tag: editDept.tags[0]});
    }, [])
    
  return (
        <form  onSubmit={handleSubmit(onSubmit)} className='dept-manag-f'>
        <div className='dept-manag-f__tag-wrapper'>
        <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
                <Input
                    border
                    className='dept-manag-f__input'
                    size="fullWidth"
                    label="Department Name"
                    placeholder="Starlight"
                    value={value}
                    invalid={errors?.name}
                    errorMessage={false}
                    onChange={onChange}
                />
            )}/>
        </div>
        <div className='dept-manag-f__tag-wrapper'>
            {/* <Text dark className='dept-manag-f__tag'>#</Text> */}
            <Controller
            name="tag"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
                <Input
                border
                className='dept-manag-f__input'
                size="fullWidth"
                label="Tag"
                placeholder="Reference name that is recognised by your organisation"
                value={value}
                invalid={errors?.tag}
                errorMessage={false}
                onChange={onChange}
                />
            )}/>
        </div>
        <Button type="submit" isLoading={isLoading} className='dept-manag-f__button' color="blue" size="fullWidth">
            Save
        </Button>
    </form>
  );
};

export default DepartmentManagementForm;
