import React from 'react';
import { Modal } from 'semantic-ui-react';
import { Button } from 'components';
import history from '../../../../history';

import './claimUploadModal.scss';

const ClaimUploadModal = ({ isModalToggled, toggleModal, deductableAmount, currency }) => {
  return (
    <Modal
      open={isModalToggled}
      onClose={() => toggleModal(false)}
      closeIcon
      size="tiny"
    >
      <Modal.Header
        style={{
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          textAlign: 'start',
        }}
      >
        Does this Claim exceed deductibles of {currency} {deductableAmount.toLocaleString()}?
      </Modal.Header>
      <Modal.Content style={{ paddingTop: '10px' }}>
        <div className="cum-btns">
          <Button
            color="red"
            size="small"
            className="mr-3"
            onClick={() =>
              history.push({
                pathname: '/claims/upload',
                state: { isAboveDeductables: true },
              })
            }
          >
            Yes
          </Button>
          <Button
            color="green"
            size="small"
            onClick={() =>
              history.push({
                pathname: '/claims/upload',
                state: { isAboveDeductables: false },
              })
            }
          >
            No
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ClaimUploadModal;
