import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { Text, Select, ErrorToast, DatePicker, Button, Icon } from 'components';

import DepartmentService from 'api/department';
import bpSvg from 'assets/new/patientApp/bp.svg';
import VesselChart from './components/VesselChart';
import InsightsIcon from 'assets/new/insights';

import history from '../../../../history';

import './compare.scss';

const CompareVessels = () => {
  const { userDepartments } = useSelector((state) => state.general);
  const userRightsObj = useSelector(
    (state) => state.general?.userRightsObj?.insights
  );
  const userInfo = useSelector((state) => state.general.user);

  const [fromDate, setFromDate] = useState(
    moment().subtract(3, 'month').toDate()
  );
  const [toDate, setToDate] = useState(moment().toDate());
  const [hasViewRights, setHasViewRights] = useState(false);

  useEffect(() => {
    checkRights();
  }, []);

  const checkRights = () => {
    const deptKeys = userDepartments.map((dept) => dept._id);
    let tempHasViewRights = false;

    if (userInfo?.metadata?.role === 'admin') {
      tempHasViewRights = true;
    } else if (!viewingAccount) {
      deptKeys.forEach((deptId) => {
        if (userRightsObj?.readDepts?.includes(deptId))
          tempHasViewRights = true;
      });
    } else if (viewingAccount) {
      if (userRightsObj?.readDepts?.includes(viewingAccount))
        tempHasViewRights = true;
    }

    setHasViewRights(tempHasViewRights);
    return { hasViewRights: tempHasViewRights };
  };

  return (
    <>
      <div className="insi-w">
        <div className="insi-w__header">
          <Button
            circular
            color="blue"
            size="small"
            onClick={() => history.goBack()}
          >
            <Icon color="white" name="arrowLeft" />
          </Button>
          <Text bold size="bigger" className="ml-3">
            Compare Insights
          </Text>
        </div>
        <div className="insi-w__view-acc-wrapper compare-insights__date_bar">
          <div className="insi-w__filters">
            <div style={{ display: 'flex' }}>
              <div className="mr-3" style={{ position: 'relative' }}>
                <Text
                  size="tinniest"
                  color="lightGrey"
                  className="insi-w__filters__date-tag"
                >
                  From
                </Text>
                <DatePicker
                  onChange={(date) => setFromDate(date)}
                  value={fromDate}
                  disabled={!hasViewRights}
                  maxDate={toDate}
                />
              </div>
              <div style={{ position: 'relative' }}>
                <Text
                  size="tinniest"
                  color="lightGrey"
                  className="insi-w__filters__date-tag"
                >
                  To
                </Text>
                <DatePicker
                  onChange={(date) => setToDate(date)}
                  value={toDate}
                  disabled={!hasViewRights}
                  minDate={fromDate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="compare-insights">
          <div className="compare-insights__component">
            <VesselChart fromDate={fromDate} toDate={toDate} />
          </div>
          <div className="compare-insights__component">
            <VesselChart fromDate={fromDate} toDate={toDate} />
          </div>
        </div>
      </div>
    </>
  );
};
export default CompareVessels;
