import React from 'react';

import { Text } from 'components';
import LikertScale from '../LikertScale/LikertScale';

import './TabContent.scss';

const TabContent = ({ data, header }) => {
  const generateScale = () => {
    const axisLabels = [];
    for (let i = -90; i <= 90; i += 10) {
      axisLabels.push(
        <div
          key={i}
          className="tab-content__axis__label"
          style={{ color: getScaleColor(i) }}
        >
          {Math.abs(i)}
        </div>
      );
    }
    return axisLabels;
  };

  const getScaleColor = (i) => {
    if (i < 0) return 'red';
    if (i > 0) return 'green';
    return 'black';
  };

  const RenderSections = ({ sections, sectlen, legend }) => {
    return sections?.map((sect, index) => (
      <div
        className={`tab-content__wrap ${
          sectlen === index + 1 && 'tab-content__wrap__divider'
        }`}
      >
        <div className="tab-content__questions-col">
          <Text darker size="medium">
            {sect?.text}:
          </Text>
          <Text size="tiny">{sect?.question}</Text>
        </div>
        <div className="tab-content__scale-col">
          <LikertScale
            data={sect}
            legend={legend}
            showLegend={sectlen === index + 1}
          />
        </div>
      </div>
    ));
  };

  return (
    <div className="tab-content">
      <div className="tab-content__header">
        <div className="tab-content__header__tg"></div>
        <Text bold size="bigish">
          {header}
        </Text>
      </div>
      <div className="tab-content__scale">
        <div style={{ flex: 0.7 }}></div>
        <div className="tab-content__axis-wrapper">
          <div className="tab-content__axis">{generateScale()}</div>
        </div>
      </div>
      {data?.length &&
        data?.map((dt) => (
          <RenderSections
            sections={dt.sections}
            sectlen={dt.sections.length}
            legend={dt.values}
          />
        ))}
    </div>
  );
};
export default TabContent;
