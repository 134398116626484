import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Icon, Modal } from 'semantic-ui-react';
import axios from 'axios';
import downloadFile from 'js-file-download';

import EmployeeService from 'api/employees';

import EmployeeSmallTable from '../employeeSmallTable/EmployeeSmallTable';
import { Button, CsvReader, ErrorToast, Text } from 'components';

import './bulkInviteModal.scss';

const BulkInviteModal = ({ toggleModal, isModalToggled, fetchEmployees }) => {
  const [uploadPatients, setUploadPatients] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const upPatients = defaultCsvFormat();
    setUploadPatients(upPatients);
  }, [csvData]);

  const formatDepsData = useMemo(() => {
      const depts = {}
      uploadPatients?.forEach((emp) => {
        depts[emp?.departments[0]] = emp?.departments[0]
      })
      return depts;
    },
    [uploadPatients])


  const onUpload = (data) => {
    const jsonData = data?.map((row) => row.data);
    setCsvData(jsonData);
  };

  const onCreateEmployees = async () => {
    try {
      setIsSaving(true);
      const clientId = localStorage.getItem('ROOT_ID');

      if (!uploadPatients) return;
      const emps = uploadPatients.map((pat) => {
        return {...pat, clientId};
      });
      const result = await bulkUpload(emps);
      const dataResult = result?.data?.data || []
      const empsWithstatus = emps.map( (emp, index) => {
        return {
          ...emp,
          status: dataResult[index]?.status || '',
          error: dataResult[index] ?.error ||' '
        }
      })
      setUploadPatients(empsWithstatus)
      await fetchEmployees();
      setIsSaved(true)
      setIsSaving(false);
    } catch (error) {
      setIsSaved(false)
      setIsSaving(false);
      toast.error(<ErrorToast error={error} />);
    }
  };

  const onCloseModal = () => {
    toggleModal(false);
    setCsvData([]);
  }

  const bulkUpload = async (payload) => {
    return await EmployeeService.bulkUpload(payload)
  }

  const defaultCsvFormat = () => {
    setHasError(false);
    let hasError = false;

    const fData = csvData?.map((data) => {
      let lHasError = false;

      if (!data['First Name'] || !data['Last Name']) {
        toast.error(<ErrorToast message="Patient firs and last name is required" />, {
          toastId: 'pat-name',
        });
        lHasError = true;
      }

      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.Email)) {
        toast.error(<ErrorToast message={`Invalid email: ${data.Email}`} />, {
          toastId: 'pat-email',
        });
        lHasError = true;
      }

      if (!data['National ID'] && !data['Passport No']) {
        toast.error(
          <ErrorToast message={`No ID or Pass number provided for member: ${data['First Name']} ${data['Last Name']}`}/>,
          {
            toastId: 'pat-idNo',
          }
        );
        lHasError = true;
      }

      if (lHasError) {
        hasError = true;
        return false;
      }
      return {
        firstName: data['First Name'],
        lastName: data['Last Name'],
        email: data.Email,
        passNo: data['Passport No'],
        idNo: data['National ID'],
        idType: 'NID',
        designation: data['Designation'],
        departments: [data['Department']],
        action: data['Action'],
        sendActivationEmail: data['Send Activation Email']?.toLowerCase().trim() === 'true',
      };
    });

    if(hasError) return [];
    else return fData;
  };

  const downloadSampleFile = async () => {
    try {
      setIsDownloading(true);
      const resp = await axios({
        url: 'https://bed-rock.s3.ap-southeast-1.amazonaws.com/CSV_templates/bedrock_member_upload.csv',
        method: 'GET',
        responseType: 'blob',
      });
      downloadFile(resp.data, 'bedrock_member_invite.csv');
      setIsDownloading(false);
    } catch (error) {
      console.log("error", error)
      setIsDownloading(false);
      toast.error(<ErrorToast message="Error downloading template" />);
    }
  };

  return (
    <Modal
      className="bulk-inv-m"
      closeIcon
      size="small"
      open={isModalToggled}
      onClose={() => toggleModal(false)}
    >
      <Modal.Header>
        <Text dark>Bulk members invite</Text>
      </Modal.Header>
      <Modal.Content className="bulk-inv-m__content">
        <div className="bulk-inv-m__content__header">
          <Text size="small" bold>
            Upload a CSV file with member details
          </Text>
          <Button
            size="small"
            color="green"
            inverted
            onClick={downloadSampleFile}
            isLoading={isDownloading}
            fontSize="tiny"
            style={{ width: '220px', height: '33px' }}
          >
            <>
              <Icon name="download" className="mr-3" />
              Download template
            </>
          </Button>
        </div>
        {!uploadPatients.length ? (
          <div className="bulk-inv-m__content__dropzone">
            <CsvReader
              onUpload={onUpload}
              onRemoveFile={() => setCsvData([])}
            />
          </div>
        ) : (
          <div className="bulk-inv-m__table-wrapper">
            <EmployeeSmallTable
              data={uploadPatients}
              formattedDepts={formatDepsData}
            />
          </div>
        )}
        <Button
          size="fullWidth"
          color="blue"
          disabled={!uploadPatients.length || hasError}
          onClick={isSaved ? onCloseModal : onCreateEmployees}
          isLoading={isSaving}
        >
          {isSaved ? (
            'Continue'
          ) : (
            <>
              <Icon name="upload" /> Invite members
            </>
          )}
        </Button>
      </Modal.Content>
    </Modal>
  );
};

export default BulkInviteModal;
