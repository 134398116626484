import React from 'react';
import { Modal } from 'semantic-ui-react';

import { Button, Text } from 'components'

import './deleteModal.scss';

const DeleteModal = ({
  header,
  content,
  onDelete,
  buttonText,
  onCancel = () => {},
  toggleModal,
  isModalToggled,
  children,
  isSaving
}) => {
  const handleCancelClick = () => {
      onCancel()
      toggleModal(false);
  }
  const handleDeleteClick = async () => {
      await onDelete()
  }

  return (
    <>
      {!!children && (
        <div className="del-modal-trigger">
          {children}
        </div>
      )}
      <Modal
        className="modal-full"
        open={isModalToggled}
        onClose={() => toggleModal(false)}
        closeIcon
        size="tiny"
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>
          <div className="del-modal">
          <div className="del-modal__content">
            {content}
          </div>
          <div className="del-modal__buttons">
              <Button color="black" inverted onClick={handleCancelClick}>Cancel</Button>
              <Button color="red" onClick={handleDeleteClick} isLoading={isSaving} disabled={isSaving}>{buttonText || 'Delete'}</Button>
          </div>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default DeleteModal;
