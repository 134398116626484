import React, { useCallback, useEffect, useState } from 'react';
import './LikertScale.scss';
import { Popup } from 'semantic-ui-react';


const LikertScale = ({ data, legend, showLegend }) => {
  const [positves, setPositives] = useState([]);
  const [negatives, setNegatives] = useState([]);

  const [NEGATIVE_ORDER, setNEGATIVE_ORDER] = useState([]);
  const [POSTIVE_ORDER, setPOSTIVE_ORDER] = useState([]);

  const DO_NOT_DISPLAY = ['F1-Q34'];

  useEffect(() => {
    const postiveValues = [];
    const negativeValues = [];
    if (legend?.length === 2) {
      negativeValues.push(legend[0]);
      postiveValues.push(legend[1]);
    } else {
      legend.forEach((val, index) => {
        const value = val;
        if (index < 3) negativeValues.push(value);
        if (index > 1) postiveValues.push(value);
      });
    }
    setNEGATIVE_ORDER(negativeValues);
    setPOSTIVE_ORDER(postiveValues);
  }, [data]);

  useEffect(() => {
    groupData();
  }, [POSTIVE_ORDER, NEGATIVE_ORDER]);

  const groupData = () => {
    const posValues = [];
    const negValues = [];
    data.values.forEach((dt) => {
      if (NEGATIVE_ORDER.includes(dt.value)) {
        negValues.push(dt);
      }
      if (POSTIVE_ORDER.includes(dt.value)) {
        posValues.push(dt);
      }

      setNegatives(
        negValues.sort(
          (a, b) =>
            NEGATIVE_ORDER.indexOf(a.value) - NEGATIVE_ORDER.indexOf(b.value)
        )
      );
      setPositives(
        posValues.sort(
          (a, b) =>
            POSTIVE_ORDER.indexOf(a.value) - POSTIVE_ORDER.indexOf(b.value)
        )
      );
    });
  };

  const getApparentWidth = (data) => {
    if (data.value === NEGATIVE_ORDER[2]) return data.percentage / 2;
    return data?.percentage;
  };

  const getColor = (value) => {
    switch (value) {
      case NEGATIVE_ORDER[0]:
        return '#FFB6A2';
      case NEGATIVE_ORDER[1]:
        return '#FFDBD1';
      case NEGATIVE_ORDER[2]:
        return '#EEEEEE';
      case POSTIVE_ORDER[1]:
        return '#BADEB8';
      case POSTIVE_ORDER[0]:
        return '#82C889';
      default:
        return '#a5d6a7';
    }
  };

  const getLegend = (label) => {
    const text = label.split('(');
    if (text.length > 1) return text[0];

    return label;
  };

  const getPercentages = (value, id) => {
    if (DO_NOT_DISPLAY.includes(id)) return '';
    return value + '%';
  };

  return (
    <div className="likert-scale">
      <div className="likert-scale__scale-container">
        <div className="likert-scale__percentage likert-scale__negative">
          {getPercentages(
            data?.negativePercentage || 0,
            data?.questionFriendlyId
          )}
        </div>
        <div className="likert-scale__scale-neg">
          {negatives.map((item, index) => (
            <div
              className="likert-scale__scale-item-wrapper"
              style={{
                width: `${getApparentWidth(item)}%`,
              }}
            >
              <div
                key={index}
                className="likert-scale__scale-item"
                style={{
                  //   width: `${getApparentWidth(item)}%`,
                  backgroundColor: getColor(item.value),
                }}
              ></div>
              {item.value !== NEGATIVE_ORDER[2] && (
                <span className="likert-scale__percentage">
                  {item.percentage}%
                </span>
              )}
            </div>
          ))}
        </div>
        <div className="likert-scale__scale-pos">
          {positves.map((item, index) => (
            <div
              className="likert-scale__scale-item-wrapper"
              style={{
                width: `${getApparentWidth(item)}%`,
              }}
            >
              <div
                key={index}
                className="likert-scale__scale-item"
                style={{
                  backgroundColor: getColor(item.value),
                }}
              ></div>
              <span
                style={
                  item.value === NEGATIVE_ORDER[2]
                    ? { justifyContent: 'flex-start' }
                    : {}
                }
                className="likert-scale__percentage"
              >
                {item.percentage}%
              </span>
            </div>
          ))}
        </div>
        <div className="likert-scale__percentage likert-scale__positive">
          {getPercentages(
            data?.positivePercentage || 0,
            data?.questionFriendlyId
          )}
        </div>
      </div>
      {showLegend && (
        <div className="likert-scale__labels">
          {legend?.map((item, index) => (
            <Popup
              content={item}
              trigger={
                <div key={index} className="likert-scale__label">
                  <span
                    className="likert-scale__color-box"
                    style={{ backgroundColor: getColor(item) }}
                  ></span>
                  <span>{getLegend(item)}</span>
                </div>
              }
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default LikertScale;
