export const PIE_COLORS = [
  '#00406F',
  '#0053CE',
  '#B3B3B3',
  '#0075CA',
  '#77B9E8',
];

export const ENCOUNTER_DATA_ORDER = [
  {
    name: 'totalConsultations',
    title: 'Patient Type #',
    type: 'bar',
    order: ['Crew', 'Guest', 'Contractor'],
  },
  {
    name: 'totalConsultationPercentage',
    title: 'Patient Type %',
    type: 'pie',
    order: ['Crew', 'Guest', 'Contractor'],
  },
  {
    name: 'encounterType',
    title: 'Case Type',
    type: 'bar',
    order: ['New Case', 'Follow-up'],
  },
  {
    name: 'scheduleCall',
    title: 'Encounter Type',
    type: 'bar',
    order: ['Medical Encounter', 'Wellness Review'],
  },
  {
    name: 'encounterReason',
    title: 'Encounter Reason',
    type: 'bar',
    order: ['Illness', 'Injury'],
  },
  {
    name: 'typeOfIllness',
    title: 'Type Of Illness',
    type: 'bar',
    order: [
      'Communicable Disease',
      'Chronic Disease',
      'Mental Health',
      'Other',
    ],
  },
  {
    name: 'illnessEncounter',
    title: 'Type of Communicable Disease',
    type: 'bar',
    order: ['AGE', 'ARD', 'Other'],
  },
  {
    name: 'encounterOutcome',
    title: 'Encounter Outcome',
    type: 'bar',
    order: [
      'Follow-up Consultation',
      'Ship Admission',
      'Shoreside Referral',
      'Medical Disembarkation',
    ],
  },
];
