import {
  SET_USER,
  SET_USER_CLIENTS,
  SET_CLIENT_INFORMATION,
  SET_CLIENT_BRANDING,
  SET_CLIENT_CLINICS,
  SET_DOCUMENT_TYPES,
  SET_DEV_MODE,
  SET_SHOW_SIDE_BAR,
  SET_CUSTOM_URL_CLIENT,
  SET_CLINIC_OPTIONS,
  SET_USER_RIGHTS,
  SET_USER_DEPARTMENTS,
} from './generalState.actions';

const initialState = {
  clientInformation: {},
  user: {},
  clientBranding: {},
  userClients: [],
  clientClinics: [],
  templates: [],
  documentTypes: [],
  docTypesObj: {},
  isDevMode: false,
  hasDeltaResults: false,
  showSideBar: true,
  customUrlClient: null,
  userRights: [],
  userDepartments: [],
  userRightsObj: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };

    case SET_USER_CLIENTS:
      return { ...state, userClients: action.payload };

    case SET_CLIENT_INFORMATION:
      return {
        ...state,
        clientInformation: action.payload,
        templates: [],
      };

    case SET_CLIENT_BRANDING:
      return { ...state, clientBranding: action.payload };

    case SET_CLIENT_CLINICS:
      return { ...state, clientClinics: action.payload };

    case SET_DOCUMENT_TYPES: {
      const docTypesObj = {};
      action.payload.forEach((doc) => {
        docTypesObj[doc.subType] = doc.name;
      });
      return { ...state, documentTypes: action.payload, docTypesObj };
    }

    case SET_DEV_MODE:
      return { ...state, isDevMode: action.payload };

    case SET_SHOW_SIDE_BAR:
      return { ...state, showSideBar: action.payload };

    case SET_CUSTOM_URL_CLIENT:
      return { ...state, customUrlClient: action.payload };

    case SET_CLINIC_OPTIONS:
      return { ...state, clinicOptions: action.payload };
    
    case SET_USER_RIGHTS: 
    {
      const rightsObj = formatUserRights(action.payload);
      return { ...state, userRights: action.payload, userRightsObj: rightsObj };
    }

    case SET_USER_DEPARTMENTS:
      return { ...state, userDepartments: action.payload };

    default:
      return state;
  }
};

const formatUserRights = (userRights) => {
  const finalRights = {};
  const featureKeys = ['employees', 'insights', 'users', 'departments']
  const rights = { 
    employees: {
      write: 'manageMembers',
      read: 'viewMembers'
    }, 
    insights: {
      read: 'viewStats'
    },
    users: {
      write: 'manageUsers'
    },
    departments: {
      write: 'manageDepartments'
    }
  };
  const deptKeys = Object.keys(userRights);

  featureKeys.forEach(key => {
    finalRights[key] = { readDepts: [], writeDepts: [] };
  })
  let readDepts = []
  let writeDepts = []

  featureKeys.forEach(feature => {
    deptKeys.forEach(deptId => {
      const deptRights = userRights[deptId];
      const hasWriteRights = deptRights.includes(rights[feature].write);
      const hasReadRights = deptRights.includes(rights[feature].read);
      if(hasWriteRights) {
        writeDepts.push(deptId)
        readDepts.push(deptId)
      } else if(hasReadRights) {
        readDepts.push(deptId)
      }
      finalRights[feature][deptId] = {
        write: hasWriteRights,
        read: hasWriteRights || hasReadRights
      }
    })
    finalRights[feature].writeDepts = writeDepts;
    finalRights[feature].readDepts = readDepts;
    writeDepts = []
    readDepts = []
  })

  return finalRights;
}
