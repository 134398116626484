import React from 'react';
import { Text } from 'components';
// import moment from 'moment';
import Logo from 'assets/images/riverr-logo.png';

import { useSelector } from 'react-redux';

import './statBox.scss'

const colors = {
  red: '#E51D1D',
  green: '#249F2A',
  blue: '#411CC2',
  grey: '#EFEFEF',
  black: '#000000',
  gold: '#F69C1C',
};

const StatBox = ({ header, value = "0", unit = "%", valueText, valueName, count, totalCount, color, updatedAt }) => {
  const clientInfo = useSelector((state) => state.general.clientInformation);
  return (
    <div className="stat-box">
      <div className="stat-box__header-wrapper">
        <img
          src={clientInfo.logo || Logo}
          className="stat-box__header-wrapper__logo"
          alt="logo"
        />
        <Text className="stat-box__header" dark>
          {header}
        </Text>
      </div>
      <div className="stat-box__percent-wrap">
        <div
          className="stat-box__percent-wrap__line"
          style={{ background: !value ? '#EFEFEF' : colors[color] }}
        />
        <Text
          className="stat-box__percent-wrap__perc"
          color={!value ? '#EFEFEF' : color}
          darker
        >
          {!value ? 0 : value}
          {unit}
        </Text>
        <Text size="tiny">{valueText}</Text>
      </div>
      <div className="stat-box__calc-wrap">
        {count ? (
          <>
            <Text
              darker
              className="mr-1"
              size="big"
              color={!count ? '#EFEFEF' : 'black'}
            >
              {count}
            </Text>
            <Text>/</Text>
          </>
        ) : (
          <Text className="mr-1" color="black">
            {totalCount && 'Of'}
          </Text>
        )}
        <Text className="ml-1 mr-1">{totalCount}</Text> {valueName}
      </div>
      {/* {updatedAt && <Text>Updated on {moment(updatedAt).format('DD MMM YYYY')}</Text>} */}
    </div>
  );
};

export default StatBox;
