import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Tab, TabPane } from 'semantic-ui-react';
import { groupBy } from 'lodash';
import CircleLoader from 'react-spinners/CircleLoader';

import { Text, Select, DatePicker, Button, Icon, ErrorToast } from 'components';

import WellnessIcon from 'assets/new/wellness';
import LikertScale from './components/LikertScale/LikertScale';
import TabContent from './components/TabContent/TabContent';
import { WELLNESS_CATEGORY_ORDER } from 'helpers/wellnessData';

import WellnessService from 'api/encounter';
import DepartmentService from 'api/department';

import './wellness.scss';

const WellnessKPI = () => {
  const { userDepartments } = useSelector((state) => state.general);

  const [viewingAccount, setViewingAccount] = useState(null);
  const [loadingDepartments, setLoadingDepartments] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [fromDate, setFromDate] = useState(
    moment().subtract(1, 'year').toDate()
  );
  const [toDate, setToDate] = useState(moment().toDate());
  const [wellnessStatQuery, setWellnessStatQuery] = useState({
    departments: [],
    from: fromDate.toISOString(),
    to: toDate.toISOString(),
  });
  const [activeDateIndex, setDateIndex] = useState(2);
  const [statsData, setStatsData] = useState({});
  const [sections, setSections] = useState([]);

  const TIME_FRAMES = ['WTD', 'MTD', 'YTD'];

  useEffect(() => {
    fetchDepartments();
  }, []);

  useEffect(() => {
    fetchWellnesKPI();
  }, [wellnessStatQuery]);

  const onChangeViewingAccount = (deptId) => {
    setViewingAccount(deptId);
    localStorage.setItem('S_DEPT_ID', deptId);
  };

  const onTimeFrameClick = (action, index) => {
    setDateIndex(index);
    let from,
      to = moment().toDate();

    if (action === TIME_FRAMES[0]) {
      from = moment().subtract(1, 'week').toDate();
    }
    if (action === TIME_FRAMES[1]) {
      from = moment().subtract(1, 'month').toDate();
    }
    if (action === TIME_FRAMES[2]) {
      from = moment().subtract(1, 'year').toDate();
    }

    setFromDate(from);
    setToDate(to);
    setWellnessStatQuery({
      ...wellnessStatQuery,
      from: moment(from).toISOString(),
      to: moment(to).toISOString(),
    });
  };

  const fetchWellnesKPI = async () => {
    try {
      setIsLoading(true);
      let query = wellnessStatQuery;
      if (!viewingAccount) {
        query.departments = [];
      }
      const { data } = await WellnessService.getWellnessKPI(query);
      groupData(data?.groups);
    } catch (error) {
      console.log(error?.response?.data);
      if (error?.response?.data?.status === 400) {
        toast.info(<ErrorToast error={error?.response?.data?.message} />);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const groupData = (data) => {
    const groupedItems = groupBy(data, ({ sections }) => sections[0].category);
    const cats = Object.keys(groupedItems);
    console.log(groupedItems);
    setSections(cats);
    setStatsData(groupedItems);
  };

  const formatDeptsDropdown = (depts) => {
    const options = depts?.map((dept) => ({
      value: dept._id,
      text: dept.name,
    }));
    if (depts.length > 1) options.unshift({ value: null, text: 'All' });
    return options;
  };

  const fetchDepartments = async () => {
    try {
      setLoadingDepartments(true);
      const userDeptIds = userDepartments?.map((dept) => dept._id);
      const filter = userDeptIds
        ? { filters: { ids: userDeptIds, isDeleted: false } }
        : { filters: { isDeleted: false } };

      const {
        data: { data },
      } = await DepartmentService.getAll(filter);
      const deptOptions = formatDeptsDropdown(data);
      setDropdownOptions(deptOptions);
      setLoadingDepartments(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setLoadingDepartments(false);
    }
  };

  const renderPanes = () => {
    const panes = sections.map((sect) => ({
      menuItem: WELLNESS_CATEGORY_ORDER.find((cat) => cat.name === sect).title,
      render: () => (
        <TabPane attached={false}>
          <TabContent header={sect} data={statsData[sect]} />
        </TabPane>
      ),
    }));

    return panes;
  };

  return (
    <div className="wellness">
      <div className="wellness__header">
        <WellnessIcon className="wellness__header__icon" color="black" />
        <Text bold size="bigger" className="">
          Crew Wellness KPI
        </Text>
      </div>
      <div className="wellness__view-acc-wrapper">
        <div className="wellness__view-acc">
          <Text>Viewing entity: </Text>
          <Select
            size="small"
            label=""
            disabled={loadingDepartments}
            border
            className="ml-3"
            placeholder="All"
            search
            selection
            value={viewingAccount}
            onChange={(e, { value }) => {
              setWellnessStatQuery({
                ...wellnessStatQuery,
                departments: [value],
              });
              onChangeViewingAccount(value);
            }}
            options={dropdownOptions}
          />
        </div>
        <div className="wellness__right">
          <div className="wellness__filters">
            <div style={{ display: 'flex' }}>
              <div className="wellness__date-btns-grp">
                {TIME_FRAMES.map((tf, index) => (
                  <Button
                    className={`mr-3 wellness__date-btn ${
                      index === activeDateIndex
                        ? 'wellness__date-btn__active'
                        : ''
                    }`}
                    size="medium"
                    onClick={() => onTimeFrameClick(tf, index)}
                  >
                    <Text size="small" bold>
                      {tf}
                    </Text>
                  </Button>
                ))}
              </div>

              <div className="mr-3" style={{ position: 'relative' }}>
                <Text
                  size="tinniest"
                  color="lightGrey"
                  className="wellness__filters__date-tag"
                >
                  From
                </Text>
                <DatePicker
                  noCloseIcon={true}
                  onChange={(date) => {
                    setFromDate(date);
                    setWellnessStatQuery({
                      ...wellnessStatQuery,
                      from: moment(date).toISOString(),
                      to: moment(toDate).toISOString(),
                    });
                  }}
                  value={fromDate}
                  // disabled={!hasViewRights}
                  maxDate={toDate}
                />
              </div>
              <div style={{ position: 'relative' }}>
                <Text
                  size="tinniest"
                  color="lightGrey"
                  className="wellness__filters__date-tag"
                >
                  To
                </Text>
                <DatePicker
                  noCloseIcon={true}
                  onChange={(date) => {
                    setToDate(date);
                    setWellnessStatQuery({
                      ...wellnessStatQuery,
                      from: moment(toDate).toISOString(),
                      to: moment(date).toISOString(),
                    });
                  }}
                  value={toDate}
                  minDate={fromDate}
                  maxDate={new Date()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wellness__main-wrapper">
        {isLoading ? (
          <div className="wellness__loader">
            <CircleLoader color="#455af7" />
          </div>
        ) : (
          <Tab menu={{ secondary: true }} panes={renderPanes()} />
        )}
      </div>
    </div>
  );
};

export default WellnessKPI;
