import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { debounce } from 'lodash';
import { Route, Switch, withRouter } from 'react-router-dom';

import DepartmentService from 'api/department';
import ClaimService from 'api/claims';
import { ErrorToast } from 'components';
import ClaimsSettings from './features/ClaimsSettings/ClaimsSettings';
import UploadClaims from './features/UploadClaims/UploadClaims';
import ClaimsDashboard from './features/Dashboard/ClaimsDashboard';

import './claims.scss';

const ClaimsWrapper = () => {
  const { userDepartments } = useSelector((state) => state.general);
  const userRightsObj = useSelector(
    (state) => state.general?.userRightsObj?.insights
  );
  const userInfo = useSelector((state) => state.general.user);

  const { _id: clientId } = useSelector(
    (state) => state.general.clientInformation
  );

  const [viewingAccount, setViewingAccount] = useState(null);
  const [loadingDepartments, setLoadingDepartments] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGraphData, setIsLoadingGraphData] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [claimSettings, setClaimSettings] = useState({});
  const [loadingClaims, setLoadingClaims] = useState(false);
  const [graphTimeScope, setGraphTimeScope] = useState('month');

  const [fromDate, setFromDate] = useState(
    moment().subtract(3, 'month').toDate()
  );
  const [toDate, setToDate] = useState(moment().toDate());
  const [hasViewRights, setHasViewRights] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [claims, setClaims] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [claimStatFilteredData, setClaimStatFilteredData] = useState([]);
  const [claimStatQuery, setClaimStatQuery] = useState({
    limit: 0,
    startDate: fromDate.toISOString(),
    endDate: toDate.toISOString(),
    version: 'noc'
  });
  const [searchValue, setSearchValue] = useState('');
  const [editClaim, setEditClaim] = useState(null);
  const [updateClaimModalToggle, setUpdateClaimModalToggle] = useState(false);
  const [formattedDepts, setFormattedDepts] = useState([]);
  const [claimsGraphdata, setClaimsGraphdata] = useState([]);

  useEffect(() => {
    fetchInitialData();
    getClaims({});
    getClaimSettings();
  }, []);

  useEffect(() => {
    if(!!claimSettings) getClaimsStatData();
  }, [claimStatQuery, claimSettings, graphTimeScope]);

  useEffect(() => {
    checkRights();
  }, [viewingAccount]);

  const checkRights = () => {
    const deptKeys = userDepartments.map((dept) => dept._id);
    let tempHasViewRights = false;

    if (userInfo?.metadata?.role === 'admin') {
      tempHasViewRights = true;
    } else if (!viewingAccount) {
      deptKeys.forEach((deptId) => {
        if (userRightsObj?.readDepts?.includes(deptId))
          tempHasViewRights = true;
      });
    } else if (viewingAccount) {
      if (userRightsObj?.readDepts?.includes(viewingAccount))
        tempHasViewRights = true;
    }

    setHasViewRights(tempHasViewRights);
    return { hasViewRights: tempHasViewRights };
  };

  const getDepartmentsFilter = () => {
    const viewRightsDepartments = userRightsObj?.readDepts || [];
    if (viewingAccount) {
      if (userInfo?.metadata?.role !== 'admin') {
        if (!userRightsObj?.readDepts?.includes(viewingAccount)) return 'NA';
      }
      return [viewingAccount];
    }
    if (userInfo?.metadata?.role === 'admin') return null;
    if (!viewingAccount && viewRightsDepartments.length === 0) return null;
    if (!viewingAccount && viewRightsDepartments.length !== 0)
      // return null
      return viewRightsDepartments;
  };

  const fetchInitialData = async () => {
    setIsLoading(true);
    const selectedDept = localStorage.getItem('S_DEPT_ID');
    if (selectedDept && selectedDept !== 'null')
      setViewingAccount(selectedDept);
    await fetchDepartments();

    setIsLoading(false);
  };

  const onChangeViewingAccount = (deptId) => {
    setViewingAccount(deptId);
    localStorage.setItem('S_DEPT_ID', deptId);
  };

  const fetchDepartments = async () => {
    try {
      setLoadingDepartments(true);
      const userDeptIds = userDepartments?.map((dept) => dept._id);
      const filter = userDeptIds
        ? { filters: { ids: userDeptIds, isDeleted: false } }
        : { filters: { isDeleted: false } };

      const {
        data: { data },
      } = await DepartmentService.getAll(filter);
      const fDepts = formatDepts(data);
      setFormattedDepts(fDepts);
      const deptOptions = formatDeptsDropdown(data);
      setDropdownOptions(deptOptions);
      setInitialSelectedDept(userDeptIds, fDepts, deptOptions);
      setLoadingDepartments(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setLoadingDepartments(false);
    }
  };

  const getClaims = async (query) => {
    setLoadingClaims(true);
    try {
      const {
        data: { data },
      } = await ClaimService.getAll(query);
      setClaims(data);
      setFilteredData(data);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setLoadingClaims(false);
    }
  };

  const getClaimsStatData = async () => {
    setIsLoadingGraphData(true);
    try {
      const {
        data: { data },
      } = await ClaimService.getAll(claimStatQuery);
      setClaimStatFilteredData(data);
      getClaimsGraphdata({ version: claimStatQuery.version }, data);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setIsLoadingGraphData(false);
    }
  };

  const getClaimsGraphdata = (query = { version: 'noc' }, data) => {
    const { claimType, claimCause, version } = query;
    let filteredData = data || [...claimStatFilteredData];
    if (claimType) {
      filteredData = filteredData.filter(
        (claim) => claim?.claimType === claimType
      );
    }
    if (claimCause) {
      filteredData = filteredData?.filter(
        (claim) => claim?.claimCause === claimCause
      );
    }

    const graphTimeSpan = graphTimeScope === 'month' ? getGraphMonths(fromDate, toDate) : getGraphQuarters(fromDate, toDate);
    let res = [];
    graphTimeSpan.forEach((timePoint) => {
      const dateFilteredData = filteredData.filter(
        (claim) =>
        graphTimeScope === 'month' ? moment(claim?.claimApprovalDate).format('MMMM YYYY') === timePoint : `${moment(claim?.claimApprovalDate).quarter()} ${moment(claim?.claimApprovalDate).year()}` === timePoint
      );

      const claimTypes = ['death', 'injury', 'illness']
      const claimTypeObjs = {
        'death': {
          count: 0,
          repatration: 0,
          cost: 0,
          insurance: 0
        },
        'injury': {
          count: 0,
          repatration: 0,
          cost: 0,
          insurance: 0
        },
        'illness': {
          count: 0,
          repatration: 0,
          cost: 0,
          insurance: 0
        }
      }

      if (
        dateFilteredData &&
        dateFilteredData?.length > 0 &&
        claimSettings?.insuranceDeductiblesAmount
      ) {
        dateFilteredData?.forEach((claim) => {
          if(claim.isDeleted) return;
          const hasApprovedAndIncidentCost = !!(claim?.incidentCost && claim?.approvedInsuranceClaimAmount);
          const totalApprovedInsuranceClaimCalc = claim?.incidentCost - claim?.approvedInsuranceClaimAmount + claimSettings?.insuranceDeductiblesAmount

          claimTypes.forEach((type) => {
            const claimType = claim?.claimType

            if (claimType === type) {
              if (hasApprovedAndIncidentCost) {
                claimTypeObjs[type].cost += totalApprovedInsuranceClaimCalc;
              } else if (claim?.totalApprovedClaimAmount) {
                claimTypeObjs[type].cost += claim?.totalApprovedClaimAmount;
              }

              claimTypeObjs[type].insurance += claim?.approvedInsuranceClaimAmount;
              claimTypeObjs[type].count++;
              if (claim.repatriated === 'Yes') claimTypeObjs[type].repatration++;
            }
          })
        });

        const getValue = (claimType, version) => {
          if (version === 'noc')
            return claimTypeObjs[claimType].count;
          else if (version === 'coc')
            return claimTypeObjs[claimType].cost;
          else if (version === 'nor') 
            return claimTypeObjs[claimType].repatration;
          else if (version === 'total_insuranace')
            return claimTypeObjs[claimType].insurance;
   
          return 0;
        };

        res.push({
          name: graphTimeScope === 'month' ? timePoint?.split(' ')?.at(0) : `Q${timePoint}`,
          death: getValue('death', version),
          injury: getValue('injury', version),
          illness: getValue('illness', version),
          keys: ['death', 'injury', 'illness'],
        });
      }
    });
    setClaimsGraphdata(res);
    return res;
  };

  function getGraphMonths(startDate, endDate) {
    const months = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const monthName = currentDate.toLocaleString('default', {
        month: 'long',
        year: 'numeric',
      });
      months.push(monthName);

      // Move to the next month
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    return months;
  }

  function getGraphQuarters(startDate, endDate) {
    let current = moment(startDate).startOf('month');
    const end = moment(endDate).endOf('month');
    const quarters = [];

    while (current.isBefore(end)) {
        let quarterStart = moment(current);
        quarters.push(`${quarterStart.quarter()} ${quarterStart.year()}`);
        current = current.add(3, 'months');
    }

    return quarters;
  }

  const setInitialSelectedDept = (userDeptIds, objDepts, deptOptions) => {
    if (userDeptIds.length === 0) return;
    if (objDepts[viewingAccount]) return;
    setViewingAccount(deptOptions[0]?.value);
  };

  const formatDepts = (depts) => {
    const fDepts = {};

    depts.forEach((dept) => {
      fDepts[dept._id] = dept.name;
    });

    return fDepts;
  };

  const formatDeptsDropdown = (depts) => {
    const options = depts?.map((dept) => ({
      value: dept._id,
      text: dept.name,
    }));
    if (depts.length > 1) options.unshift({ value: null, text: 'All' });
    return options;
  };

  const onRowSelect = (e, id, emp) => {
    e.stopPropagation();
    e.preventDefault();

    if (selectedRows[id]) delete selectedRows[id];
    else selectedRows[id] = emp;

    setSelectedRows({ ...selectedRows });
  };

  const selectAllRows = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let rows;

    if (Object.keys(selectedRows)?.length === filteredData?.length) {
      setSelectedRows({});
      rows = {};
    } else {
      rows = {};
      filteredData.forEach((element) => {
        rows[element._id] = element;
      });
      setSelectedRows(rows);
    }
  };

  const onEditClick = (claim) => {
    setUpdateClaimModalToggle(true);
    setEditClaim(claim);
  };

  const onSearchChange = async (value) => {
    setSearchValue(value);
    handleSearch(value);
  };

  const handleSearch = useCallback(
    debounce((search) => getClaims({ search }), 500),
    []
  );

  const getClaimSettings = async () => {
    try {
      const { data } = await ClaimService.getOneClaimSettings(clientId);
      setClaimSettings(data);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  return (
    <Switch>
      <Route
        path="/claims/settings"
        render={() => (
          <ClaimsSettings
            formattedDepts={formattedDepts}
            claimSettings={claimSettings}
          />
        )}
      />
      <Route
        path="/claims/upload"
        render={() => (
          <UploadClaims
            formattedDepts={formattedDepts}
            claimSettings={claimSettings}
          />
        )}
      />
      <Route
        index
        render={() => (
          <ClaimsDashboard
            fromDate={fromDate}
            toDate={toDate}
            setToDate={setToDate}
            setFromDate={setFromDate}
            setClaimStatQuery={setClaimStatQuery}
            claimStatQuery={claimStatQuery}
            formattedDepts={formattedDepts}
            claimSettings={claimSettings}
            claimsGraphdata={claimsGraphdata}
            viewingAccount={viewingAccount}
            loadingDepartments={loadingDepartments}
            isLoadingGraphData={isLoadingGraphData}
            dropdownOptions={dropdownOptions}
            hasViewRights={hasViewRights}
            filteredData={filteredData}
            searchValue={searchValue}
            onSearchChange={onSearchChange}
            selectAllRows={selectAllRows}
            onEditClick={onEditClick}
            onRowSelect={onRowSelect}
            getDepartmentsFilter={getDepartmentsFilter}
            getClaimsGraphdata={getClaimsGraphdata}
            claimStatFilteredData={claimStatFilteredData}
            getClaims={getClaims}
            getClaimsStatData={getClaimsStatData}
            graphTimeScope={graphTimeScope}
            setGraphTimeScope={setGraphTimeScope}
            onChangeViewingAccount={onChangeViewingAccount}
          />
        )}
      />
    </Switch>
  );
};

export default withRouter(ClaimsWrapper);
