import headerBar from './HeaderBar/HeaderBar';
import sideBar from './SideBar/SideBar';
import labResultBasicInfo from './LabResultBasicInfo/LabResultBasicInfo';
import healthValueRange from './HealthValueRange/HealthValueRange';
import healthValueOverview from './HealthValueOverview/HealthValueOverview';
import statBox from './statistics/statBox/StatBox';
import deleteModal from './DeleteModal/DeleteModal';

export const HeaderBar = headerBar;
export const SideBar = sideBar;
export const LabResultBasicInfo = labResultBasicInfo;
export const HealthValueRange = healthValueRange;
export const HealthValueOverview = healthValueOverview;
export const StatBox = statBox;
export const DeleteModal = deleteModal;
