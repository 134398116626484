import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import { Dropdown, Loader, Pagination } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

import { Text, ErrorToast, Search, Button, Icon } from 'components';

import EmployeeService from 'api/employees';
import SharedProfileService from 'api/sharedProfile';
import EmployeesTable from './EmployeesTable';

import MemberInviteModal from '../../forms/MemberInvite/MemberInvite_Modal';
import UpdateDepartmentModal from './components/updateDepartmentModal/UpdateDepartmentModal';
import BulkInviteModal from './components/bulkInviteModal/BulkInviteModal';
import ReinviteModal from './components/reInviteModal/ReInviteModal';
import AssignClinicModal from './components/assignClinicModal/AssignClinicModal';

// import history from '../../../history';
import './employeesTable.scss';

const EmplyeeOverview = ({
  departments,
  objDepts,
  viewingAccount,
  hasWriteRights,
  hasViewRights,
  getDepartmentsFilter,
}) => {
  const [isSearching, setIsSearching] = useState(false);
  const [loadingEmps, setLoadingEmps] = useState(false);
  const [memberInviteToggled, toggleMemberInvite] = useState(false);
  const [departmentModalToggled, toggleDepartmentModal] = useState(false);
  const [assignClinicalModalToggled, toggleAssignClinicModal] = useState(false);
  const [bulkInvToggled, toggleBulkInvModal] = useState(false);
  const [reInvToggled, toggleReInvModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [editMember, setEditMember] = useState(null);
  const [employees, setEmployees] = useState();
  const [formattedWriteDepts, setFormattedWriteDepts] = useState([]);
  const [isDeleting, setDeleting] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [activePage, setActivePage] = useState(1);

  const { writeDepts } = useSelector(
    (state) => state.general?.userRightsObj?.employees
  );

  useEffect(() => {
    setUrlValuesToState();
  }, []);

  useEffect(() => {
    fetchInitialData();
  }, [viewingAccount, activePage]);

  useEffect(() => {
    setWriteDepts();
  }, [departments]);

  useEffect(() => {
    if (!memberInviteToggled) setEditMember(null);
  }, [memberInviteToggled]);

  const setWriteDepts = () => {
    if (!departments) return;
    const _writeDepts = [];

    departments.forEach((dept) => {
      if (writeDepts.includes(dept._id)) _writeDepts.push(dept);
    });
    setFormattedWriteDepts(_writeDepts);
  };

  const fetchInitialData = async () => {
    const data = await fetchEmployees(searchValue);
    setEmployees(data);
  };

  const fetchEmployees = async (search) => {
    try {
      setLoadingEmps(true);
      const filters = getDepartmentsFilter();
      if (search || Object.keys(filters).length) setActivePage(1);
      if (filters === 'NA') return;
      filters.isDeleted = false;
      const _activePage = activePage || 1;
      const limit = 30;
      const offset = limit * (_activePage - 1);
      const {
        data: { data, totalRecord },
      } = await EmployeeService.getAll({
        filters,
        search,
        limit,
        offset,
      });
      setFilteredData(data);
      setTotalPages(Math.ceil(totalRecord / limit) || 0);
      setLoadingEmps(false);
      return data;
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setLoadingEmps(false);
    }
  };

  const onRowSelect = (e, id, emp) => {
    e.stopPropagation();
    e.preventDefault();

    if (selectedRows[id]) delete selectedRows[id];
    else selectedRows[id] = emp;

    setSelectedRows({ ...selectedRows });
  };

  const deleteEmployee = async (emp) => {
    try {
      setDeleting(true);
      await EmployeeService.delete({
        id: emp._id,
        userId: emp?.patientId,
        clientId: emp?.clientId,
      });
      fetchEmployees(searchValue);
      toggleMemberInvite(false);
      setDeleting(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setDeleting(false);
    }
  };

  const selectAllRows = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let rows;

    if (Object.keys(selectedRows)?.length === filteredData?.length) {
      setSelectedRows({});
      rows = {};
    } else {
      rows = {};
      filteredData.forEach((element) => {
        rows[element._id] = element;
      });
      setSelectedRows(rows);
    }
  };

  const handleDropdownSelect = (value) => {
    if (value === 'invite') return toggleMemberInvite(true);
    if (value === 'bulk_invite') return toggleBulkInvModal(true);
    if (value === 're-invite') return toggleReInvModal(true);
    if (value === 'department') return toggleDepartmentModal(true);
    if (value === 'assignClinic') return toggleAssignClinicModal(true);
  };

  const onSearchChange = async (value) => {
    setSearchValue(value);
    handleSearch(value);
  };

  const handleSearch = useCallback(
    debounce((search) => fetchEmployees(search), 500),
    []
  );

  const setUrlValuesToState = () => {
    const params = new URLSearchParams(window.location.search);
    let page = params.get('page');
    if (!page || page < 1) page = 1;
    setActivePage(Number(page));
    updateSearchParams(page);
  };

  const updateSearchParams = (page) => {
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?page=${page}`;
    window.history.pushState({ path: newurl }, '', newurl);
  };

  const onEditClick = (emp) => {
    toggleMemberInvite(true);
    setEditMember(emp);
  };

  const goToPreviousPage = () => {
    if (activePage <= 1) return;
    const page = Math.max(1, activePage - 1);
    setActivePage(page);
    updateSearchParams(page);
  };

  const goToNextPage = () => {
    const page = Math.min(totalPages, activePage + 1);
    setActivePage(page);
    updateSearchParams(page);
  };
  const onPageClick = (page) => {
    updateSearchParams(page);
    setActivePage(page);
  };

  return (
    <div>
      <div className="emp-table-wrapper">
        {memberInviteToggled && (
          <MemberInviteModal
            toggleModal={toggleMemberInvite}
            isModalToggled={memberInviteToggled}
            member={editMember}
            fetchEmployees={fetchEmployees}
            departments={formattedWriteDepts}
            onDelete={deleteEmployee}
            isDeleting={isDeleting}
          />
        )}
        {departmentModalToggled && (
          <UpdateDepartmentModal
            toggleModal={toggleDepartmentModal}
            isModalToggled={departmentModalToggled}
            selectedEmps={selectedRows}
            departments={formattedWriteDepts}
            fetchEmployees={fetchEmployees}
            formattedDepts={objDepts}
          />
        )}
        {assignClinicalModalToggled && (
          <AssignClinicModal
            toggleModal={toggleAssignClinicModal}
            isModalToggled={assignClinicalModalToggled}
            selectedEmps={selectedRows}
            departments={formattedWriteDepts}
          />
        )}
        {bulkInvToggled && (
          <BulkInviteModal
            toggleModal={toggleBulkInvModal}
            isModalToggled={bulkInvToggled}
            departments={formattedWriteDepts}
            fetchEmployees={fetchEmployees}
          />
        )}
        {reInvToggled && (
          <ReinviteModal
            toggleModal={toggleReInvModal}
            isModalToggled={reInvToggled}
            selectedEmps={selectedRows}
          />
        )}
        {!hasViewRights ? (
          <Text bold center>
            You don't have Member view rights for this department
          </Text>
        ) : (
          <>
            {!loadingEmps && !employees?.length ? (
              <div className="emp-table-wrapper__placeholder">
                <Text bold center>
                  Currently there are no members invited.
                </Text>
                <Text bold center>
                  Start the setup by inviting someone from the workforce.
                </Text>
                <Button
                  className="mt-3"
                  size="wide"
                  color="blue"
                  onClick={() => handleDropdownSelect('invite')}
                >
                  Invite member now
                </Button>
                <Button
                  className="mt-2"
                  size="wide"
                  color="blue"
                  inverted
                  onClick={() => handleDropdownSelect('bulk_invite')}
                >
                  Upload members CSV
                </Button>
              </div>
            ) : (
              <>
                <div className="emp-table-wrapper__search">
                  <Search
                    isLoading={isSearching}
                    size="fullWidth"
                    border
                    value={searchValue}
                    dropdown={false}
                    onSearchChange={(e, { value }) => onSearchChange(value)}
                    isFilter={true}
                    placeholder="Search"
                  />
                  {/* {(userRights?.includes('manageMembers') || !userRights.length) && ( */}
                  {hasWriteRights && (
                    <Dropdown
                      className="button icon emp-table-wrapper__dropdown"
                      onChange={handleDropdownSelect}
                      selectOnBlur={false}
                      floating
                      direction="left"
                      // disabled={Object.keys(selectedRows)?.length === 0}
                      trigger={
                        <Text size="small" color="white">
                          Actions
                        </Text>
                      }
                    >
                      <Dropdown.Menu>
                        <Dropdown.Header content="Department" />
                        <Dropdown.Divider />
                        <Dropdown.Item
                          onClick={() => handleDropdownSelect('department')}
                          disabled={!Object.keys(selectedRows)?.length}
                        >
                          Update department
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleDropdownSelect('assignClinic')}
                          disabled={!Object.keys(selectedRows)?.length}
                        >
                          Assign medical clinic
                        </Dropdown.Item>
                        <Dropdown.Header content="Invitation" />
                        <Dropdown.Divider />
                        <Dropdown.Item
                          onClick={() => handleDropdownSelect('invite')}
                        >
                          Invite new member
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleDropdownSelect('bulk_invite')}
                        >
                          Bulk invite members
                        </Dropdown.Item>
                        <Dropdown.Item
                          disabled={!Object.keys(selectedRows)?.length}
                          onClick={() => handleDropdownSelect('re-invite')}
                        >
                          Re-invite member(s)
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
                <div className="mt-3">
                  {loadingEmps ? (
                    <div className="mt-5">
                      <Loader active inline="centered" />
                    </div>
                  ) : (
                    <EmployeesTable
                      hasEditRights={hasWriteRights}
                      formattedDepts={objDepts}
                      filteredData={filteredData}
                      selectedRows={selectedRows}
                      onRowSelect={onRowSelect}
                      selectAllRows={selectAllRows}
                      onEditClick={onEditClick}
                    />
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
      {!loadingEmps && parseInt(totalPages) > 0 && filteredData.length > 0 && (
        <div className="emp-table-wrapper__pagination">
          <Text color="grey">{'Page ' + activePage + ' of ' + totalPages}</Text>
          {parseInt(totalPages) > 1 && (
            <div className="emp-table-wrapper__pagination__section">
              <div className="emp-table-wrapper__pagination__section__angles">
                <Pagination
                  activePage={activePage}
                  firstItem={null}
                  lastItem={null}
                  pointing
                  secondary
                  totalPages={totalPages}
                  onPageChange={(e, { activePage }) => onPageClick(activePage)}
                />
                {/* <div
                  id="left_angle"
                  className={`emp-table-wrapper__pagination__section__angle ${
                    activePage === 1
                      ? 'emp-table-wrapper__pagination__section__angle__disabled'
                      : ''
                  }`}
                  onClick={() => goToPreviousPage()}
                >
                  <Icon fontSize="20px" name="angleLeft" />
                </div>
                <div
                  className={`emp-table-wrapper__pagination__section__angle ${
                    activePage === totalPages
                      ? 'emp-table-wrapper__pagination__section__angle__disabled'
                      : ''
                  }`}
                  onClick={() => goToNextPage()}
                >
                  <Icon fontSize="20px" name="angleRight" />
                </div> */}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(EmplyeeOverview);
