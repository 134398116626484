import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { Text, ErrorToast, Select } from 'components';
import { StatBox } from 'features';

import EmployeeTableWrapper from './components/EmployeesTableWrapper';

import DepartmentService from 'api/department';
import EmployeeService from 'api/employees';

import GroupIcon from 'assets/new/peopleGroup.js'

// import history from '../../../history';
import './employeesOverview.scss';

const EmplyeeOverview = () => {
  const { userDepartments } = useSelector((state) => state.general);
  const { clientInformation } = useSelector((state) => state.general);
  const userInfo = useSelector((state) => state.general.user);
  const userRightsObj = useSelector((state) => state.general?.userRightsObj?.employees);

  const [loadingDepartments, setLoadingDepartments] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewingAccount, setViewingAccount] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [objDepts, setObjDepts] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [bouncedCount, setBouncedCount] = useState(0);
  const [deptCount, setDeptCount] = useState(0);
  
  const [isInitialRender, setIsInitialRender] = useState(true);

  // If all user depts have rights to manage members
  const [hasWriteRights, setHasWriteRights] = useState(false);
  const [hasViewRights, setHasViewRights] = useState(false);

  useEffect(() => {
    fetchInitialData();
  }, []);
  
  useEffect(() => {
    const { hasViewRights } = checkRights();
    if(isInitialRender) return setIsInitialRender(false);
    if(hasViewRights) fetchStats();
    else resetStats();
  }, [viewingAccount]);
  
  const checkRights = () => {
    const deptKeys = userDepartments.map(dept => dept._id);
    let tempHasWriteRights = true;
    let tempHasViewRights = false;

    if(userInfo?.metadata?.role === 'admin') {
      tempHasWriteRights = true;
      tempHasViewRights = true;
    } else if(!viewingAccount) {
      // If no viewing account is selected, but the user has 1 department with view/edit rights and 1 with no view rights at all, when data is fetched it will fetch for the 1 department with rights. So it should allow for editing
      let overideWriteRights = true;
      deptKeys.forEach(deptId => {
        if(!userRightsObj?.writeDepts?.includes(deptId)) tempHasWriteRights = false;
        // Check if at least 1 department has view rights
        if(userRightsObj?.readDepts?.includes(deptId) || userRightsObj?.writeDepts?.includes(deptId)) tempHasViewRights = true;
        if(userRightsObj?.readDepts?.includes(deptId) && !userRightsObj?.writeDepts?.includes(deptId)) overideWriteRights = false;
      })
      if(overideWriteRights) tempHasWriteRights = overideWriteRights;
    } else if(viewingAccount) {
      if(!userRightsObj?.writeDepts?.includes(viewingAccount)) tempHasWriteRights = false;
      if(userRightsObj?.readDepts?.includes(viewingAccount)) tempHasViewRights = true;
    }

    setHasWriteRights(tempHasWriteRights)
    setHasViewRights(tempHasViewRights)
    return { hasViewRights: tempHasViewRights, hasWriteRights: tempHasWriteRights }
  }

  const getDepartmentsFilter = () => {
    const viewRightsDepartments = userRightsObj?.readDepts || [];
    if(viewingAccount) {
      if(userInfo?.metadata?.role !== 'admin') {
        if(!userRightsObj?.writeDepts?.includes(viewingAccount) && !userRightsObj?.readDepts?.includes(viewingAccount)) return 'NA'
      }
      return { departments: [viewingAccount] };
    }
    if(userInfo?.metadata?.role === 'admin') return {};
    if(!viewingAccount && viewRightsDepartments.length === 0) return {}
    if(!viewingAccount && viewRightsDepartments.length !== 0)
      return {
       departments: viewRightsDepartments
      }
  }

  const configViewingAccount = () => {
    let hasDept = false;
    let finalDept = null;
    const localDept = localStorage.getItem('DEPT_ID');

    if(!!userDepartments.length) {
      if(localDept && localDept !== "null") {
        userDepartments.forEach(dept => {
          if(dept._id === localDept) hasDept = true;
        })
        if(hasDept) finalDept = localDept
      }
      
      if(!hasDept && userDepartments.length === 1) {
        const deptId = userDepartments[0]._id
        finalDept = deptId
      }

      if(!hasDept && userDepartments.length > 1) {
        finalDept = null;
      }
    }

    if(!userDepartments.length) {
      finalDept = null
    }

    setViewingAccount(finalDept)
    return finalDept;
  }

  const fetchInitialData = async () => {
    setIsLoading(true);
    await fetchDepartments(); 
    const finalDept = configViewingAccount();
    if(!finalDept) fetchStats();
    setIsLoading(false);
  }

  // TODO: Move depts to redux + hook
  const fetchDepartments = async () => {
    try {
      setLoadingDepartments(true);
      const userDeptIds = userDepartments.map(dept => dept._id);
      const filter = userDeptIds ? {filters: { ids: userDeptIds, isDeleted: false }} : {filters: { isDeleted: false }};
      
      const {
        data: { data },
      } = await DepartmentService.getAll(filter);
      setDeptCount(data.length);
      const fDepts = formatDepts(data);
      const deptOptions = formatDeptsDropdown(data);
      setObjDepts(fDepts);
      setDepartments(data);
      setDropdownOptions(deptOptions)
      setInitialSelectedDept(userDeptIds, fDepts, deptOptions)
      setLoadingDepartments(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setLoadingDepartments(false);
    }
  };

  const setInitialSelectedDept = (userDeptIds, objDepts, deptOptions) => {
    if(userDeptIds.length === 0) return;
    if(objDepts[viewingAccount]) return;
    setViewingAccount(deptOptions[0]?.value)
  }

  const formatDepts = (depts) => {
    const fDepts = {};

    depts.forEach(dept => {
      fDepts[dept._id] = dept.name;
    })

    return fDepts
  }

  const formatDeptsDropdown = (depts) => {
    const options = depts.map(dept => (
      { value: dept._id, text: dept.name }
    ))
    if(depts.length > 1)options.unshift({ value: null, text: 'All'})
    return options;
  }

  const onChangeViewingAccount = (deptId) => {
    setViewingAccount(deptId);
    localStorage.setItem('DEPT_ID', deptId);
  }

  const resetStats = () => {
    setTotalCount(0);
    setActiveCount(0);
    setBouncedCount(0);
  }

  const fetchStats = () => {
    const filter = getDepartmentsFilter();
    fetchTotalCount(filter);
    fetchTotalActive(filter);
    fetchTotalBounced(filter);
  }
  
  const fetchTotalCount = async (filter) => {
    try {
      const clientId = clientInformation._id;
      const {data: { totalRecord }} = await EmployeeService.getStatCount({clientId, ...filter})
      setTotalCount(Number(totalRecord));
    } catch(error) {
      console.log("err", error)
      // toast.error(<ErrorToast error={error} />)
    }
  }

  const fetchTotalActive = async (filter) => {
    try {
      const clientId = clientInformation._id;
      const {data: { totalRecord }} = await EmployeeService.getStatCount({clientId, signupStatuses: ['ACTIVE'], ...filter})
      setActiveCount(Number(totalRecord));
    } catch(error) {
      console.log("err", error)
      // toast.error(<ErrorToast error={error} />)
    }
  }

  const fetchTotalBounced = async (filter) => {
    try {
      const clientId = clientInformation._id;
      const {data: { totalRecord }} = await EmployeeService.getStatCount({clientId, signupStatuses: ['BOUNCED'], ...filter})
      setBouncedCount(Number(totalRecord));
    } catch(error) {
      console.log("err", error)
      // toast.error(<ErrorToast error={error} />)
    }
  }

  return (
    <div className="emp-o">
      <div className="emp-o__header">
        <GroupIcon className="emp-o__header__icon" color="black" />
        <Text bold size="bigger">
          Members
        </Text>
      </div>
      <div className="emp-o__view-acc-wrapper">
        <div className="emp-o__view-acc">
          <Text bold size="small" className="mr-2">
            Viewing department:
          </Text>
          <Select
            disabled={loadingDepartments}
            border
            className="emp-o__view-acc__select"
            placeholder="All"
            search
            selection
            value={viewingAccount}
            onChange={(e, { value }) => onChangeViewingAccount(value)}
            options={dropdownOptions}
          />
        </div>
      </div>
      <div className="emp-o__stats">
        <StatBox
          color="blue"
          header="Total invited"
          value={
            activeCount && totalCount
              ? Math.round((activeCount / totalCount) * 10000) / 100
              : 0
          }
          unit="%"
          valueText="completion"
          count={activeCount}
          totalCount={totalCount}
          valueName="pax"
        />
        <StatBox
          color="green"
          header="Total active"
          value={
            activeCount && totalCount
              ? Math.round((activeCount / totalCount) * 10000) / 100
              : 0
          }
          unit="%"
          count={activeCount}
          totalCount={totalCount}
          valueName="pax"
        />
        <StatBox
          color="red"
          header="Total bounced invites"
          value={bouncedCount}
          unit={null}
          totalCount={totalCount}
          valueName="pax"
        />
        <StatBox
          color="black"
          header="Total Departments"
          value={deptCount || 0}
          unit={null}
          totalCount={''}
          valueName=""
        />
      </div>
      {!isLoading && (
        <div className="emp-o__table-wrapper">
          <EmployeeTableWrapper
            objDepts={objDepts}
            departments={departments}
            viewingAccount={viewingAccount}
            getDepartmentsFilter={getDepartmentsFilter}
            hasWriteRights={hasWriteRights}
            hasViewRights={hasViewRights}
            viewingRightDepts={userRightsObj?.readDepts || []}
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(EmplyeeOverview);
