import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Text } from 'components';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { getColor } from '../../features/AreaChartSection/helpers/helpers';

import './barGraphInfoTile.scss';

const legend = [
  {
    color: getColor('death'),
    colorHex: getColor('death').replace('#', ''),
    label: 'death',
  },
  {
    color: getColor('injury'),
    colorHex: getColor('injury').replace('#', ''),
    label: 'injury',
  },
  {
    color: getColor('illness'),
    colorHex: getColor('illness').replace('#', ''),
    label: 'illness',
  },
];

const BarGraphInfoTile = ({ title, subTitle, data, updatedOn }) => {
  return (
    <div className="bar-graph-info-tiles">
      <div className="bar-graph-info-tiles__title">
        <Text size="medium" bold className="mb-3">
          {title}
        </Text>
        <Text size="tiny">{subTitle}</Text>
      </div>
      <div className="bar-graph-info-tiles__content">
        {/* <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="2 2" />
            <XAxis dataKey="name" />
            <YAxis domain={[0, 100]} unit="%" />
            {legend.map((value) => (
              <Bar
                key={value.label}
                fill={`#${value.colorHex}`}
                dataKey={value.label}
                radius={[10, 10, 0, 0]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer> */}

        <div className="bar-graph-info-tiles__content__info">
          {data?.map((item) => (
            <div className="bar-graph-info-tiles__content__info__bar">
              <Text size="big" dark uppercase>
                {item?.name}
              </Text>
              <Text bold>{item?.count}</Text>
            </div>
          ))}
        </div>
      </div>
      <div>
        {/* {updatedOn ? (
          <Text size="tiny">
            Updated on: {moment(updatedOn).format('DD MMM YYYY')}
          </Text>
        ) : (
          ''
        )} */}
      </div>
    </div>
  );
};

export default BarGraphInfoTile;
