import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CustomTooltip = ({ active, payload, label, getToolTipData }) => {
  const info = getToolTipData(label);
  if (active && payload && payload.length && info) {
    return (
      <div className="claims-chart__chart__tooltip">
        <div className="claims-chart__chart__tooltip__time">
          <p>{`${label}`}</p>
        </div>
        {/* <div className="claims-chart__chart__tooltip__inputs">
          <p>{`${info.total} active inputs `}</p>
        </div> */}

        {!!info?.values && !!info?.values?.length && info?.values?.map((val) => (
          <p style={{ color: val.color, fontWeight: 'bold' }}>{val.text}: {val.value}</p>
        ))}
      </div>
    );
  }

  return null;
};

const CustomDot = () => {
  return (
    <div className="claims-chart__chart__dot"/>
  );
};

const AreaChartWrapper = ({ data = [], legend, getToolTipData }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
      height={400}
      data={data}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0,
      }}
      >
        <defs>
          {legend?.map(value => (
            <linearGradient id={`color${value.colorHex}`} x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={'#'+value.colorHex} stopOpacity={0.9}></stop>
              <stop offset="75%" stopColor={'#'+value.colorHex} stopOpacity={0}></stop>
            </linearGradient>
          ))}
        </defs>
        <XAxis dataKey="name" />
        <YAxis domain={[0]} unit='' />
        <CartesianGrid strokeDasharray="2 2" />
        <Tooltip content={<CustomTooltip getToolTipData={getToolTipData} />} />
        {legend?.map(value => (
          <Line fill={`url(#color${value.colorHex})`} type="monotone" dataKey={value.label} stackId="1" stroke={'#'+value.colorHex} strokeWidth={2} activeDot={{ r: 7 }} dot={{ r: 3 }}  />
        ))} 
      </LineChart>
    </ResponsiveContainer>
  );
}

export default AreaChartWrapper;
