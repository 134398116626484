import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import general from './redux/generalState.reducer';

//Siderbar state management
import { setGlobalVariable } from 'helpers/windowHelpers';

const reducer = combineReducers({
  general,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
if (process.env.NODE_ENV === 'development') {
  store.subscribe(() => {
    setGlobalVariable('redux', store.getState());
  });
}

export default store;
