import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from 'semantic-ui-react'

import { ErrorToast, Button, SuccessToast } from 'components';

import IdClientService from 'api/client/client';

import ClientDetailsForm from './ClientDetails_Form';

import './clientDetails.scss';

const ClientInfo = ({isModalToggled, toggleModal}) => {
  const [isSaving, setIsSaving] = useState(false);

  const formatPayload = (payload) => {
    const formattedPayload = {
      name: payload.name,
      industry: payload.industry,
      country: payload.country,
      langauge: 'en',
      identifiers: {
        regNo: payload.registrationNo,
        classNo: payload.classificationNo,
      },
      support: {
        email: payload.supportEmail,
        phone: payload.supportPhone,
      },
      contact: {
        website: payload.website,
        fax: payload.faxNo,
      },
      logo: payload?.logo,
    };
      
    return formattedPayload
  }

  const createClient = async (payload) => {
    try {
      setIsSaving(true);
      const fPayload = formatPayload(payload);
      await IdClientService.createClient(payload);
      setIsSaving(false);
      toggleModal(false);
      toast.success(<SuccessToast message="Client created" />);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setIsSaving(false);
    }
  }

  return (
    <Modal
      className="modal-full"
      open={isModalToggled}
      onClose={() => toggleModal(false)}
      closeIcon
      size="tiny"
    >
      <Modal.Header>Create new client</Modal.Header>
      <Modal.Content>
        <div className="client-details-m">
            <ClientDetailsForm onSubmit={createClient} isSaving={isSaving} isSmall={true} 
            custButton={
              <div className="client-det-f__buttons-wrapper">
                <Button
                    type="submit"
                    color="blue"
                    disabled={isSaving}
                    isLoading={isSaving}
                    size="fullWidth"
                >
                    Save
                </Button>
              </div>
            }
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default withRouter(ClientInfo);
