import axios from './axiosInstances/identityAxiosInstance';
// import axios from './axiosInstances/testAxios';

const api = {
  async getById(id) {
    return await axios.get(`/bedrock/claims/GetById/${id}`);
  },

  async getAll(filters) {
    if (!filters?.search) filters.search = '';
    if (!filters?.offset) filters.offset = 0;
    if (!filters?.limit) filters.limit = 1000;
    return await axios.post(`/bedrock/claims/GetAll`, filters);
  },

  async create(payload) {
    return await axios.post(`/bedrock/claims/Create`, payload);
  },

  async bulkCreateClaims(payload) {
    return await axios.post(`/bedrock/claims/BulkCreate`, payload);
  },

  async getOneClaimSettings(clientId) {
    return await axios.get(`/bedrock/claim-settings/GetOne/${clientId}`);
  },

  async update(id, payload) {
    delete payload?._v;
    delete payload?._id;
    return axios.post(`/bedrock/claims/UpdateById/${id}`, payload);
  },

  async createClaimSettings(payload) {
    return axios.post(`/bedrock/claim-settings/Create`, payload);
  },
};

export default api;
