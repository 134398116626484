export const WELLNESS_CATEGORY_ORDER = [
  {
    name: 'General Work Conditions, Work Schedule, and Resting Hours Onboard',
    title: 'General Work Conditions',
  },
  {
    name: 'Dietary Considerations and Physical Exercise Opportunities Onboard',
    title: 'Diet & Exercise',
  },
  {
    name: 'Alcohol, Caffeine and Smoking Habits Among Crew Members Onboard',
    title: 'Habits Onboard',
  },
  {
    name: 'Interpersonal Dynamics and Relationships Onboard',
    title: 'Interpersonal Relations',
  },
  {
    name: 'Contract Length, Family Time, and Staying in Touch',
    title: 'Contract, Family, Communication',
  },
  {
    name: 'Mental Health and Availability of Support Onboard',
    title: 'Mental Health',
  },
];
