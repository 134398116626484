import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';

import { Button, ErrorToast } from 'components';
import DepartmentManagement_Table from './DepartmentManagement_Table';
import DepartmentManagement_Modal from './DepartmentManagement_Modal';

import { setUserRights, setUserDepartments } from 'redux/generalState.actions';
import DepartmentService from 'api/department'

import './departmentManagement.scss';

const DepartmentManagement = ({}) => {
  const userInfo = useSelector((state) => state.general.user);
  const dispatch = useDispatch();

    const [isDeptCreateToggled, setDeptCreateToggle] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [editDept, setEditDept] = useState(null);

    useEffect(() => {
        fetchDepartments()
    }, [])
    
    useEffect(() => {
        if(!isDeptCreateToggled) setEditDept(null);
    }, [isDeptCreateToggled])

    // const fetchDepartments = async () => {
    //     try {
    //         const {data: { data }} = await DepartmentService.getAll({});
    //         setDepartments(data);
    //     } catch(error) {
    //         toast.error(<ErrorToast error={error} />);
    //     }
    // }

    const fetchDepartments = async () => {
        try {
          const filter = {filters: { isDeleted: false }};
    
          const {
            data: { data },
          } = await DepartmentService.getAll(filter);
          dispatch(setUserDepartments(data));
          setDepartments(data);
        } catch (error) {
          dispatch(setUserDepartments([]));
          dispatch(setUserRights({}));
          toast.error(<ErrorToast error={error} />);
        }
      };
    
    const onCreateNew = () => {
        setDeptCreateToggle(true);
    }
   
  return (
    <div className='dept-manag'>
        {isDeptCreateToggled && <DepartmentManagement_Modal toggleModal={setDeptCreateToggle} isModalToggled={isDeptCreateToggled} fetchData={fetchDepartments} editDept={editDept} />}
        <DepartmentManagement_Table filteredData={departments} setDeptCreateToggle={setDeptCreateToggle} setEditDept={setEditDept} fetchData={fetchDepartments} />
        {userInfo?.metadata?.role === 'admin' && (
            <div className="client-det-f__buttons-wrapper">
                <Button
                type="submit"
                color="blue"
                size="fluid"
                onClick={onCreateNew}
                >
                    Create new department
                </Button>
            </div>
        )}
   </div>
  );
};

export default DepartmentManagement;
