import * as React from "react"
const SvgComponent = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="inherit"
  height="inherit"
  fill="none"
  viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill={props.color}
      d="M16 27c-4.789 0-8.986-3.05-10.456-7.595a.466.466 0 0 1-.024-.064l-.002.001a10.553 10.553 0 0 1-.489-2.598A9.512 9.512 0 0 1 5 16C5 9.934 9.934 5 16 5s11 4.934 11 11c0 1.087-.155 2.14-.461 3.132-.01.056-.036.166-.092.29-.215.7-.544 1.434-.96 2.137A11.02 11.02 0 0 1 16 27Zm-9.62-7.972c.011.022.02.045.028.069A10.035 10.035 0 0 0 16 26.082a10.1 10.1 0 0 0 8.697-4.988c.38-.644.681-1.315.894-1.996a.876.876 0 0 0 .061-.199c.29-.942.431-1.905.431-2.9 0-5.56-4.523-10.083-10.083-10.083C10.44 5.917 5.917 10.44 5.917 16c0 .226.008.452.025.68.053.822.2 1.61.438 2.348Z"
    />
    <path
      fill={props.color}
      d="M5.953 19.657a.459.459 0 0 1-.02-.916c3.423-.144 5.32-1.128 6.588-1.968.61-.402 1.148-.824 1.67-1.235 1.471-1.159 2.863-2.252 5.24-2.288h.057c2.165 0 3.497 1.704 4.674 3.211.713.912 1.386 1.775 2.132 2.12a.457.457 0 1 1-.385.83c-.947-.438-1.687-1.384-2.469-2.386-1.107-1.418-2.287-2.872-3.996-2.858-2.067.03-3.223.94-4.686 2.09-.536.423-1.09.86-1.732 1.28-1.366.905-3.404 1.966-7.052 2.12h-.021Z"
    />
    <path
      fill={props.color}
      d="M18.52 20.583c-2.52 0-4.374-1.528-6.058-3.093l-.305-.283c-1.18-1.1-2.2-2.052-3.203-2.052l-.045.001c-1.186.033-1.897.725-2.651 1.457-.153.149-.306.297-.462.44a.46.46 0 0 1-.62-.677c.15-.136.296-.28.443-.421.807-.783 1.721-1.671 3.264-1.715 1.378-.048 2.61 1.094 3.899 2.297l.303.281c1.615 1.5 3.246 2.848 5.436 2.848 2.467 0 3.834-.276 5.04-.518.776-.156 1.509-.304 2.435-.369a.459.459 0 0 1 .064.915c-.868.06-1.573.203-2.318.353-1.247.25-2.662.536-5.221.536Z"
    />
    <path
      fill={props.color}
      d="M16.917 24.25c-2.184 0-3.407-.966-4.702-1.99-1.471-1.165-2.994-2.368-6.272-2.566a.46.46 0 0 1-.43-.485c.015-.252.204-.453.485-.43 3.566.215 5.277 1.569 6.786 2.762 1.217.962 2.268 1.793 4.133 1.793 1.79 0 3.495-.707 5.144-1.39.935-.388 1.9-.788 2.858-1.046.276-.114.632.104.632.419v.009c0 .209-.14.39-.342.442-.917.242-1.83.621-2.798 1.021-1.731.72-3.521 1.462-5.494 1.462Z"
    />
  </svg>
)
export default SvgComponent
