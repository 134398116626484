import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup.string().required('required'),
  role: yup.string().required('required'),
  email: yup.string().required('required'),
  department: yup.array().min(1).when('role', {
    is: 'admin',
    then: yup.array().nullable()
  }),
});
