import React, { useState, useEffect } from 'react';
import {
  Input,
  Text,
  Button,
  Icon,
  Select,
  ErrorToast,
  DatePicker,
  SuccessToast,
} from 'components';
import history from '../../../../history';

import './claimsSettings.scss';
import { useCustomForm } from 'hooks';
import ClaimService from 'api/claims';
import { schema } from './schema';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import currencies from 'helpers/currencies';

const ClaimsSettings = ({}) => {
  const [updateClaimSettingsLoading, setUpdateClaimSettingsLoading] =
    useState(false);
  const [claimSettings, setClaimSettings] = useState({});
  const [allCurrencies, setAllCurrencies] = useState([]);

  const { _id: clientId } = useSelector(
    (state) => state.general.clientInformation
  );

  const {
    Controller,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useCustomForm(schema, {});
  console.log('errors', errors);

  useEffect(() => {
    getClaimSettings();
    mapCurrencies();
  }, [clientId]);

  useEffect(() => {
    reset(claimSettings);
  }, [claimSettings]);

  const mapCurrencies = () => {
    const results = currencies.map(({ value, text, name }) => {
      return {
        value,
        text: `${name} (${text})`,
      };
    });
    setAllCurrencies(results);
  };

  const updateClaimSettings = async (data) => {
    setUpdateClaimSettingsLoading(true);
    data.clientId = clientId;

    try {
      await ClaimService.createClaimSettings(data);
      toast.success(<SuccessToast message={'Settings are updated'} />);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setUpdateClaimSettingsLoading(false);
    }
  };

  const getClaimSettings = async () => {
    try {
      const { data } = await ClaimService.getOneClaimSettings(clientId);
      setClaimSettings(data);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  return (
    <div className="claims-settings">
      <div className="claims-settings__wrapper">
        <div className="claims-settings__header mb-4">
          <Button
            circular
            color="blue"
            size="small"
            onClick={() => history.goBack()}
          >
            <Icon color="white" name="arrowLeft" className="mt-2" />
          </Button>
          <Text bold center size="big">
            Claim Settings
          </Text>
        </div>
        <form
          className="claims-settings__form"
          onSubmit={handleSubmit(updateClaimSettings)}
        >
          <div className="claims-settings__form__name mb-3">
            <Text bold size="small" color="lightGrey" capitalize>
              Insurance details
            </Text>
          </div>
          <div className="claims-settings__form__row">
            <div className="claims-settings__form__col">
              <Controller
                name="insuranceProvider"
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    border
                    className="claims-settings__form__input"
                    label="Insurance provider"
                    placeholder="Riverr"
                    showErrorMessage={false}
                    size="fullWidth"
                    value={value}
                    invalid={errors?.insuranceProvider}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className="claims-settings__form__col">
              <Controller
                name="policyYear"
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    onChange={(date) => onChange(date)}
                    className="upload-claims__form__input"
                    value={value ? moment(value).toDate() : moment().toDate()}
                    border
                    label="Policy year"
                    labelTextId=""
                    placeholder="YYYY"
                    format={'y'}
                    minDate={moment().subtract(5, 'years').toDate()}
                    maxDate={moment().toDate()}
                    size="fullWidth"
                    maxDetail="decade"
                    invalid={errors?.policyYear}
                    showErrorMessage={false}
                  />
                )}
              />
            </div>
          </div>
          <div className="claims-settings__form__row">
            <div className="claims-settings__form__col">
              <Controller
                name="insuranceDeductiblesAmount"
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    border
                    className="claims-settings__form__input"
                    label="Insurance deductibles amount"
                    placeholder="10000"
                    showErrorMessage={false}
                    size="fullWidth"
                    value={value}
                    invalid={errors?.insuranceDeductiblesAmount}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className="claims-settings__form__col">
              <Controller
                name="currency"
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    border
                    className="upload-claims__form__input"
                    selectClassName="upload-claims__form__select"
                    label="Currency"
                    placeholder="Singapore Dollars (SGD)"
                    showErrorMessage={false}
                    search
                    size="fullWidth"
                    options={allCurrencies}
                    value={value}
                    invalid={errors?.currency}
                    onChange={(e, { value }) => onChange(value)}
                  />
                )}
              />
            </div>
          </div>
          <Button
            color="blue"
            size="fullWidth"
            className="mt-3"
            isLoading={updateClaimSettingsLoading}
          >
            Save
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ClaimsSettings;
