import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

import { Text } from '../components';
import colors from '../styles/colors';

const InputComponent = (props) => {
  const clientBranding = useSelector((state) => state.general.clientBranding);
  return (
    <Wrapper size={props.size} style={props.style} className={props.className}>
      {props.label && <Text type="label">{props.label}</Text>}
      {props.optional && (
        <Text size="tinniest" color="blue" className="input__optional-label">
          {props.optionalLabel || 'Optional'}
        </Text>
      )}
      <Select
        invalid={props.invalid}
        border={props.border}
        multiple={props.multiple}
        placeholder={props.placeholder}
        clientBranding={clientBranding}
        autoComplete="off"
      >
        <Dropdown
          className={`select-dropdown ${props.selectClassName}`}
          autoComplete="off"
          clearable={props.clearable}
          placeholder={props.placeholder}
          multiple={props.multiple}
          search={!!props.search}
          selection
          onSearchChange={props.onSearchChange}
          options={props.options}
          onChange={props.onChange}
          value={!props.value && props.multiple ? [] : props.value}
          text={props.text}
          disabled={props.disabled}
          onClose={props.onClose}
        />
      </Select>
      {props.invalid && props.showErrorMessage && (
        <Text className="input-error-text" color="red" size="tiny">
          {props?.errorMessage ?? 'This field is required'}
        </Text>
      )}
    </Wrapper>
  );
};

InputComponent.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
};

InputComponent.defaultProps = {
  size: 'normal',
  onChange: () => {},
  placeholder: '',
  multiple: false,
  search: '',
  options: [],
  invalid: false,
  clearable: false,
  showErrorMessage: true,
  onSearchChange: () => {},
};

const getSize = (size) => {
  if (size === 'tiny') return { width: '100px' };
  if (size === 'small') return { width: '200px' };
  if (size === 'normal') return { width: '300px' };
  if (size === 'large') return { width: '400px' };
  if (size === 'fullWidth') return { width: '100%' };
};

const getBorder = (border, invalid) => {
  if (border) {
    if (invalid)
      return `border: solid 1px #ff5252 !important; border-radius: 10px !important;`;
    return `border: solid 1px ${colors.GREY} !important; border-radius: 10px !important;`;
  }

  if (invalid)
    return `border-bottom: solid 1px #ff5252 !important; border-radius: 0 !important;`;
  return `border-bottom: solid 1px ${colors.GREY} !important; border-radius: 0 !important;`;
};

const getBorderHighlight = (border, clientColor) => {
  if (border)
    return `outline: none !important; border: solid 1px ${
      clientColor || colors.HIGHLIGHT_BLUE
    } !important;`;

  return `outline: none !important; border-bottom: solid 1px ${
    clientColor || colors.HIGHLIGHT_BLUE
  } !important;`;
};

const getMinHeight = (props) => {
  if (props.border) return '40px !important';
  if (props.multiple) return 'initial';
  return '40px !important';
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => getSize(props.size)}
  position: relative;
  outline: none !important;

  .input__optional-label {
    position: absolute;
    right: 0;
    top: 5px;
  }

  .ui.active {
    .default.text {
      color: ${colors.GREY} !important;
      transition: all 0.3s ease;
    }
  }
`;

const Select = styled.div`
  .select-dropdown, .ui.selection.dropdown {
    width: 100%;
    min-width: auto !important;
    border: none !important;
    outline-width: 0 !important;
    font-size: 17px;
    color: ${colors.DARK_GREY};
    box-shadow: none !important;
    margin: 0 !important;
    padding: ${(props) =>
      props.search ? '0px 0px !important' : '0px 10px !important'};
    height:  ${(props) => (props.multiple ? 'initial' : '40px !important')};
    ${(props) => getBorder(props.border, props.invalid)}
    display: flex !important;
    align-items: center !important;
    min-height: ${(props) => (props.border ? 'none' : 'initial !important')};
  }

    .default.text {
      display: flex !important;
      align-items: center !important;
      color: ${colors.DARK_GREY} !important;
      opacity: 0.6 !important;
      transition: all 0.3s ease !important;
      font-weight: 200 !important;
      font-size: 16px !important;
      height: ${(props) =>
        props.border ? '30px !important' : '20px !important'};
    }

    .ui.search.dropdown>input.search {
      max-height:  ${(props) =>
        props.multiple ? '38px !important' : '40px !important'};
      min-height:  ${(props) =>
        props.multiple ? '38px !important' : '40px !important'};
      height:  ${(props) =>
        props.multiple ? '38px !important' : '40px !important'};
    }

    .search {
      padding: ${(props) =>
        props.border ? '0px 10px !important' : '0px !important'};
      margin: 0 !important;
      height:  ${(props) => (props.multiple ? 'initial' : '40px !important')};
      min-height: ${(props) => getMinHeight(props)};
      font-weight: 100 !important;
      flex-wrap: ${(props) => (props.border ? 'wrap' : 'initial')};
    }

    .ui.disabled.search {
      background-color: #fafafa;
      border: solid 1px #dddddd !important;
      color: #1b1b1b !important;
      opacity: 1 !important;
    }

    .ui.selection.active.dropdown {
      ${(props) =>
        getBorderHighlight(props.border, props.clientBranding?.color)}
    }

    .visible.menu.transition {
      margin-top: 3px !important;
      border: solid 1px #d4d4d5 !important;
      border-radius: 4px;
    }

    .ui.multiple.dropdown .dropdown.icon {
      padding: 0 !important;
      margin: auto !important;
    }

    .ui.multiple.search.dropdown>.text {
      margin: 5px 0 !important;
    }

    .ui.selection.dropdown>.dropdown.icon {
      padding: 0 !important;
      margin: auto !important;
    }

    .text {
      font-weight: 300;
    }

    .ui.label {
      margin: 0px !important;
      margin-top: 2px !important;
      margin-bottom: 2px !important;
      margin-right: 5px !important;
      background: none !important;
      color: color.DARK_GREY;
      font-weight: 300 !important;

      &:active {
        color: color.DARK_GREY !important;
      }
      &:hover {
        color: color.DARK_GREY !important;
      }
    }
  }
`;

export default InputComponent;
