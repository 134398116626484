import moment from "moment";

import { bpLegend, oxyLegend, bmiLegend, cardioRange, hypertensionRange, coronaryRange, nafldRange, diabetesRange, bpLegendKeys,
  oxyLegendKeys,
  bmiLegendKeys,
  cardioLegendKeys,
  hypertensionLegendKeys,
  coronaryLegendKeys,
  nafldLegendKeys,
  diabetesLegendKeys } from './legendValues';

const blue = "#becaf6"
// const green = "#00C48C"
const greenish = "#0e8301"
// const greenish2 = "#12b001"
const yellow = "#FFD443"
const orange = "#ffaf3e"
const red = "#FF6666"
// const red2 = "#ff3a3a"
const red3 = "#ff0000"
const grey = "#9FABBD"

const getColor = (status) => {
  if(!status) return grey;
  const lowerCaseStatus = status.toLowerCase();
  switch (lowerCaseStatus) {
    // case null:
    //   return grey;
    // case 'no user inputs':
    //   return grey;
    case 'underweight':
      return orange;
    case 'low':
      return greenish;
    case 'normal':
      return greenish;
    case 'elevated':
      return yellow;
    case 'normal-high':
      return yellow;
    case 'overweight':
      return yellow;
    case 'borderline':
      return yellow;
    case 'intermediate':
      return orange;
    case 'high':
      return red;
    case 'high risk':
      return red;
    case 'obese':
      return orange;
    case 'very obese':
      return red;
    case 'high blood pressure':
      return red;
    case 'very high risk':
      return red3;
    case 'hypertension crisis':
      return red3;
    default:
      return blue;
  }
}

const timeScopeOptions = [
    { text: 'Week', value: 'week' },
    { text: 'Month', value: 'month' },
    // { text: 'Quarter', value: 'quarter' },
]

const formatTimeLabel = (date, timeSpan, full) => {
  const dates = date.split(".")
  if(timeSpan === 'month') return moment(dates[0], 'YYYY-MM-DD').format(full? 'MMMM' : 'MMMM').toString();
  return moment(dates[0], 'YYYY-MM-DD').format('DD MMM').toString() + ' - ' + moment(dates[1], 'YYYY-MM-DD').format('DD MMM').toString();
}

const formatData = (data, selectedTile, timeSpan) => {
  const legendKeys = getLegendKeys(selectedTile);
  const fData = data.map(dat => {
    let newBasePercentage = 0;

    let point = {
      name: formatTimeLabel(dat.date, timeSpan, false),
      count: 0
    };

    // Set placeholder values for all statuses
    legendKeys.forEach(key => {
      if(!key) return;
      const val = { percentage: 0, count: 0};
      point[key] = val.percentage;
    })

    dat.value.forEach(val => {
      if(!val._id) return;
      newBasePercentage += val.percentage
    })

    dat.value.forEach(val => {
      if(!val._id) return;
      point[val._id] = Math.round(Number(val.percentage / newBasePercentage) * 1000) / 10;
      point.count += val.count;
      point.keys = legendKeys;
    })

    return point;
  })
  return fData;
}

// TODO: format all the tooltip data when data is received, instead of on the go
const formatTooltip = (label, data) => {
  let selData = null
  if(!data || !data.length) return {total: 0, values: []};

  data?.forEach((dat) => {
    if(dat.name === label) selData = dat;
  })
  const values = [];

  if(selData?.keys) {
    selData?.keys?.forEach(key => {
      if(key !== 'count' && key !== 'name') values.push({ text: key, value: selData[key] || 0, color: getColor(key)})
    })
  }

  return {
    total: selData?.count,
    values
  }
}

const formatLegendData = (data) => {
  return data?.map(dat => ({
    color: getColor(dat.status),
    colorHex: getColor(dat.status).replace('#', ''),
    label: dat.status,
    ranges: dat.ranges
  }))
}

const formatInputsByTime = (data, timeSpan) => {
  const fData = [];
  const objKeys = Object.keys(data);
  objKeys.forEach(key => {
    const count = data[key];
    const previousCount = fData[fData.length - 1]?.value || 0

    fData.push({
      value: count,
      date: formatTimeLabel(key, timeSpan, true),
      diff: previousCount === 0 ? null : Math.round(((count - previousCount) / previousCount) * 100)
    });
  }) 

  return fData;
}

const headerToCategory = (selectedCat) => {
  if(selectedCat === 'Blood pressure') return 'bloodPressureStatus';
  if(selectedCat === 'Pulse Oxy') return 'pulseOxStatus';
  if(selectedCat === 'Body mass index') return 'bmiStatus';
  if(selectedCat === 'Cardiovascular') return 'cardiovascularStatus';
  if(selectedCat === 'Hypertension') return 'hypertensionStatus';
  if(selectedCat === 'Diabetes') return 'diabetesStatus';
  if(selectedCat === 'Non-alcholic Fatty Liver') return 'liverStatus';
  if(selectedCat === 'Coronary Heart Disease') return 'coronaryHeartDiseaseStatus';
}

const formatLegend = (data) => {
  if(data === 'Blood pressure') return formatLegendData(bpLegend);
  if(data === 'Pulse Oxy') return formatLegendData(oxyLegend);
  if(data === 'Body mass index') return formatLegendData(bmiLegend);
  if(data === 'Cardiovascular') return formatLegendData(cardioRange);
  if(data === 'Hypertension') return formatLegendData(hypertensionRange);
  if(data === 'Diabetes') return formatLegendData(diabetesRange);
  if(data === 'Non-alcholic Fatty Liver') return formatLegendData(nafldRange);
  if(data === 'Coronary Heart Disease') return formatLegendData(coronaryRange);
  return [];
}

const getLegendKeys = (data) => {
  if(data === 'Blood pressure') return bpLegendKeys;
  if(data === 'Pulse Oxy') return oxyLegendKeys;
  if(data === 'Body mass index') return bmiLegendKeys;
  if(data === 'Cardiovascular') return cardioLegendKeys;
  if(data === 'Hypertension') return hypertensionLegendKeys;
  if(data === 'Diabetes') return diabetesLegendKeys;
  if(data === 'Non-alcholic Fatty Liver') return nafldLegendKeys;
  if(data === 'Coronary Heart Disease') return coronaryLegendKeys;
  return [];
}

export { formatData, formatTooltip, formatLegend, timeScopeOptions, headerToCategory, formatInputsByTime }
