import React, { useState, useEffect } from 'react';
import { Button, ErrorToast } from 'components';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import DepartmentService from 'api/department';

import UserManagement_Table from './UserManagement_Table';
import UserManagement_Modal from './UserManagement_Modal';

import './userManagement.scss';

const DepartmentManagement = ({ users = [], fetchUsers }) => {
  const { userDepartments } = useSelector((state) => state.general);
  const { writeDepts } = useSelector((state) => state.general?.userRightsObj?.users);

  const [isUserCreateToggled, setUserCreateToggle] = useState(false);
  const [objDepts, setObjDepts] = useState({});
  const [deptDropOptions, setdeptDropOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [formattedWriteDepts, setFormattedWriteDepts] = useState([]);

  useEffect(() => {
    fetchInitialData();
  }, []);
  
  useEffect(() => {
    setWriteDepts();
  }, []);

  useEffect(() => {
    if(!isUserCreateToggled) setSelectedUser(null);
  }, [isUserCreateToggled]);

  const onCreateNew = () => {
    setUserCreateToggle(true);
  }

  const fetchInitialData = async () => {
    // fetchDepartments(); 
    fetchAllDepartments();
  }

  const setWriteDepts = () => {
    if(!userDepartments) return;
    const _writeDepts = [];

    userDepartments.forEach(dept => {
      if(writeDepts.includes(dept._id)) _writeDepts.push(dept);
    })
    const deptOptions =  formatDeptsDropdown(_writeDepts);
    setdeptDropOptions(deptOptions)
    setFormattedWriteDepts(_writeDepts);
  }

  // const fetchDepartments = async () => {
  //   try {
  //     // const userDeptIds = userDepartments.map(dept => dept._id);
  //     // const filter = userDeptIds ? {filters: { ids: userDeptIds, isDeleted: false }} : {filters: { isDeleted: false }};

  //     // const {
  //     //   data: { data },
  //     // } = await DepartmentService.getAll(filter);
  //     const deptOptions = formatDeptsDropdown(userDepartments);
  //     setdeptDropOptions(deptOptions)
  //   } catch (error) {
  //     toast.error(<ErrorToast error={error} />);
  //   }
  // };

  const fetchAllDepartments = async () => {
    try {
      const filter = {filters: { isDeleted: false }};

      const {
        data: { data },
      } = await DepartmentService.getAll(filter);
      const fDepts = formatDepts(data);
      setObjDepts(fDepts);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const formatDepts = (depts) => {
    const fDepts = {};

    depts.forEach(dept => {
      fDepts[dept._id] = dept.name;
    })

    return fDepts
  }

  const formatDeptsDropdown = (depts) => {
    const options = depts?.map(dept => (
      { value: dept._id, text: dept.name }
    ))
    return options;
  }

  const onEditClick = (user) => {
    setSelectedUser(user)
    setUserCreateToggle(true);
  }

  return (
    <div className='dept-manag'>
      {isUserCreateToggled && <UserManagement_Modal objDepts={objDepts} fetchUsers={fetchUsers} toggleModal={setUserCreateToggle} isModalToggled={isUserCreateToggled} deptDropOptions={deptDropOptions} user={selectedUser} />}
      <UserManagement_Table filteredData={users} fetchUsers={fetchUsers} objDepts={objDepts} onEditClick={onEditClick} />
      <div className="client-det-f__buttons-wrapper">
        <Button
          type="submit"
          color="blue"
          size="fluid"
          onClick={onCreateNew}
        >
          Invite new user
        </Button>
      </div>
   </div>
  );
};

export default DepartmentManagement;
