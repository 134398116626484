import axios from '../axiosInstances/identityAxiosInstance';
// import axios from '../testAxios';

const userApi = {
  async getCurrentUser() {
    return axios.post('/myid/me');
  },

  async getAllUsers({ offset = 0, limit = 100, search }) {
    return axios.post(
      '/myid/backoffice/GetAll',
      { search },
      {
        params: {
          limit,
          offset,
        },
      }
    );
  },

  async switchClient(client_id) {
    return axios.post('/myid/switch-client', { client_id });
  },

  async adminSwitchClient(clientId, userId, safeId) {
    return axios.post('/myclient/backoffice/user/switch-client', {
      clientId,
      userId,
      safeId,
      disableCheckClientId: true,
    });
  },

  async createUser(payload) {
    // const phone = {
    //   country_code, national_number,
    // }
    return axios.post('myclient/backoffice/client/InviteUser', payload);
  },

  async updateUser(user) {
    delete user.email;
    return axios.post('/myid/backoffice/UpdateUser', user);
  },

  async updateStatus(payload) {
    return axios.post('/myid/backoffice/UpdateStatus', payload);
  },

  async deleteUser(id) {
    return axios.post('/myid/backoffice/DeleteUser', { id });
  },

  async createPassword({ otp_id, otp, username, password, confirm_password }) {
    return axios.post('/myid/CreatePassword', {
      otp_id,
      otp,
      username,
      password,
      confirm_password,
    });
  },
  async resendActivationEmail(id) {
    return axios.post('/myid/backoffice/ResendCreatePasswordOTP', { id });
  },
};

export default userApi;
