import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import {
  Text,
  ErrorToast,
  Switch,
  Button,
  Icon,
  PageHeader,
  SuccessToast,
} from 'components';
import ClientService from 'api/client/client';
import IdClientService from 'api/client/client';

import ClientDetailsForm from './ClientDetails_Form';
import ClientDetailsDisplay from './ClientDetails_Display.js';
import UserManagement from '../userManagement/UserManagement';
import history from '../../../../history';

import './clientDetails.scss';

const ClientDetails = ({ data, showUsers = false, users = [], match }) => {
  const userInfo = useSelector((state) => state.general.user);

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [clientUsers, setClientUsers] = useState(users);
  const [clientData, setClientData] = useState(data);

  useEffect(() => {
    if (showUsers && !users.length) fetchClientUsers();
    if (!data) fetchClient();
  }, []);

  const fetchClientUsers = async () => {
    try {
      const clientId = match.params.id || localStorage.getItem('BR_CLIENT_ID');

      const {
        data: { users },
      } = await IdClientService.getClientUsers(clientId);
      setClientUsers(users);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const fetchClient = async () => {
    try {
      const clientId = match.params.id || localStorage.getItem('BR_CLIENT_ID');
      const {
        data: { data },
      } = await IdClientService.getClientById(clientId);
      setClientData(data);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const formatPayload = (payload) => {
    const clientId = match.params.id || localStorage.getItem('BR_CLIENT_ID');

    const fPayload = {
      id: clientId,
      name: payload?.name,
      industry: payload?.industry,
      country: payload?.country,
      address: payload?.address,
      identifiers: {
        regNo: payload?.registrationNo,
        classNo: payload?.classificationNo,
      },
      support: {
        email: payload?.supportEmail,
        phone: payload?.supportPhone,
      },
      contact: {
        name: payload?.contantName,
        website: payload?.website,
        fax: payload?.faxNo,
        phone: payload?.contactPhone,
        email: payload?.contactEmail,
      },
      hasMedEncounter: payload?.hasMedEncounter,
      hasWellnessKPI: payload?.hasWellnessKPI,
      hasMentalEncounter: payload?.hasMentalEncounter,
      hasMentalPEME: payload?.hasMentalPEME,
      logo: payload?.logo,
      language: 'en',
    };
    return fPayload;
  };

  const editClient = async (payload) => {
    try {
      setIsSaving(true);
      const fPayload = formatPayload(payload);
      await ClientService.updateClientById('', fPayload);
      await fetchClient();
      setIsSaving(false);
      setIsEditing(false);
    } catch (error) {
      setIsSaving(false);
      toast.error(<ErrorToast error={error} />);
    }
  };
  return (
    <div className="client-details">
      <div className="client-details__header">
        {match?.params?.id && (
          <Button
            icon
            size="xsmall"
            onClick={() => history.goBack()}
            color="blue"
            className="mr-3"
            backgroundColor="blue"
          >
            <Icon name="arrowLeft" color="white" fontSize="1.3em" />
          </Button>
        )}
        <Text bold size="bigger">
          Account Details
        </Text>
      </div>

      {!!clientData && (
        <div className="client-details__content">
          {isEditing ? (
            <ClientDetailsForm
              onSubmit={editClient}
              onCancelClick={() => setIsEditing(false)}
              isSaving={isSaving}
              client={clientData}
            />
          ) : (
            <div>
              {userInfo?.email?.includes('@riverr.ai') && (
                <div className="client-settings">
                  <div className="client-settings__sub-section">
                    <Text
                      id="settings_acct.riverr_admins_only"
                      dark
                      size="small"
                      className="mb-3"
                    >
                      Riverr Admins Only
                    </Text>
                    <div className="client-settings__row">
                      <div className="client-settings__row">
                        <Switch
                          onChange={() =>
                            editClient({
                              ...clientData,
                              hasMedEncounter: !Boolean(
                                clientData?.hasMedEncounter
                              ),
                            })
                          }
                          checked={Boolean(clientData?.hasMedEncounter)}
                          className="client-settings__toggle"
                        />
                        <div className="client-settings__row__text">
                          <Text
                            id="settings_acct.has_webshop"
                            bold
                            size="small"
                            className="ml-3"
                          >
                            Medical Encounter Insights
                          </Text>
                          <Text
                            id="settings_acct.add_webshop_features"
                            size="tinniest"
                            className="ml-3"
                          >
                            Add medical encounter insights dashboard for this
                            client.
                          </Text>
                        </div>
                      </div>
                    </div>

                    <div className="client-settings__row">
                      <div className="client-settings__row">
                        <Switch
                          onChange={() => {
                            editClient({
                              ...clientData,
                              hasWellnessKPI: !Boolean(
                                clientData?.hasWellnessKPI
                              ),
                            });
                          }}
                          checked={Boolean(clientData?.hasWellnessKPI)}
                          className="client-settings__toggle"
                        />
                        <div className="client-settings__row__text">
                          <Text
                            id="settings_acct.enable_test_ordering"
                            bold
                            size="small"
                            className="ml-3"
                          >
                            Wellness KPI Insights
                          </Text>
                          <Text
                            id="settings_acct.enable_test_ordering_description"
                            size="tinniest"
                            className="ml-3"
                          >
                            Enable wellness KPI dashboard for this client.
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div className="client-settings__row">
                      <div className="client-settings__row">
                        <Switch
                          onChange={() => {
                            editClient({
                              ...clientData,
                              hasMentalPEME: !Boolean(
                                clientData?.hasMentalPEME
                              ),
                            });
                          }}
                          checked={Boolean(clientData?.hasMentalPEME)}
                          className="client-settings__toggle"
                        />
                        <div className="client-settings__row__text">
                          <Text
                            id="settings_acct.react_reports"
                            bold
                            size="small"
                            className="ml-3"
                          >
                            Mental health PEME
                          </Text>
                          <Text
                            id="settings_acct.activate_react_reports"
                            size="tinniest"
                            className="ml-3"
                          >
                            Enable Mental Health PEME insights for this client
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <ClientDetailsDisplay
                onEditClick={() => setIsEditing(true)}
                client={clientData}
              />
            </div>
          )}
        </div>
      )}

      {showUsers && (
        <div className="mt-5">
          <Text className="client-details__header" dark size="big">
            Users
          </Text>
          <div className="client-details__content">
            <UserManagement fetchUsers={fetchClientUsers} users={clientUsers} />
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(ClientDetails);
