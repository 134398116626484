export const SET_CLIENT_INFORMATION = 'GENERAL_STATE/SET_CLIENT_INFORMATION';
export const SET_USER = 'GENERAL_STATE/SET_USER';
export const SET_CLIENT_BRANDING = 'GENERAL_STATE/SET_CLIENT_BRANDING';
export const SET_USER_CLIENTS = 'GENERAL_STATE/SET_USER_CLIENTS';
export const SET_CLIENT_CLINICS = 'GENERAL_STATE/SET_CLIENT_CLINICS';
export const SET_DOCUMENT_TYPES = 'GENERAL_STATE/SET_DOCUMENT_TYPES';
export const SET_DEV_MODE = 'GENERAL_STATE/SET_DEV_MODE';
export const SET_SHOW_SIDE_BAR = 'SET_SHOW_SIDE_BAR';
export const SET_CUSTOM_URL_CLIENT = 'SET_CUSTOM_URL_CLIENT';
export const SET_CLINIC_OPTIONS = 'GENERAL_STATE/SET_CLINIC_OPTIONS';
export const SET_USER_RIGHTS = 'GENERAL_STATE/SET_USER_RIGHTS';
export const SET_USER_DEPARTMENTS = 'GENERAL_STATE/SET_USER_DEPARTMENTS';

/**
 * @function setClientInformation
 * @description Set information of the logged in client
 */
export const setClientInformation = (clientInfo) => ({
  type: SET_CLIENT_INFORMATION,
  payload: clientInfo,
});

/**
 * @function setClientTheme
 * @description Set information of the logged in client
 */
export const setClientBranding = (clientBranding) => ({
  type: SET_CLIENT_BRANDING,
  payload: clientBranding,
});

/**
 * @function setUser
 * @description Set state of logged in user
 */
export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

/**
 * @function setUserClients
 * @description Set state of logged in user
 */
export const setUserClients = (clients) => ({
  type: SET_USER_CLIENTS,
  payload: clients,
});

/**
 * @function setClientClinics
 * @description Set information of all lab clinics
 */

export const setClientClinics = (clinics) => ({
  type: SET_CLIENT_CLINICS,
  payload: clinics,
});

/**
 * @function setDevMode
 * @description Set state of logged in user
 */
export const setDevMode = (isDevMode) => ({
  type: SET_DEV_MODE,
  payload: isDevMode,
});

export const setDocumentTypes = (documentTypes) => ({
  type: SET_DOCUMENT_TYPES,
  payload: documentTypes,
});

/**
 * @function setSideBarState
 * @description Set state of side bar
 */
export const setSideBarState = (state) => ({
  type: SET_SHOW_SIDE_BAR,
  payload: state,
});

/**
 * @function setCustomUrlClientId
 * @description Set state of side bar
 */
export const setCustomUrlClientId = (state) => ({
  type: SET_CUSTOM_URL_CLIENT,
  payload: state,
});

/**
 * @function setCustomUrlClientId
 * @description Set state of side bar
 */
export const setClinicOptions = (state) => ({
  type: SET_CLINIC_OPTIONS,
  payload: state,
});

/**
 * @function setCustomUrlClientId
 * @description Set state of side bar
 */
export const setUserRights = (state) => ({
  type: SET_USER_RIGHTS,
  payload: state,
});

/**
 * @function setCustomUrlClientId
 * @description Set state of side bar
 */
export const setUserDepartments = (state) => ({
  type: SET_USER_DEPARTMENTS,
  payload: state,
});
