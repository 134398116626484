import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'semantic-ui-react';
import { ErrorToast } from 'components';

import MemberInviteForm from './MemberInvite_Form';

import EmployeeService from 'api/employees';

import './memberInvite.scss';

const MemberInviteModal = ({
  toggleModal,
  isModalToggled,
  member,
  fetchEmployees,
  departments,
  onDelete,
  isDeleting,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = async (payload) => {
    try {
      setIsSaving(true);
      const fPayload = formatPayload(payload);
      if (!!member) await udpateEmployee(fPayload);
      else await createEmployee(fPayload);
      await fetchEmployees();
      toggleModal(false);
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      toast.error(<ErrorToast error={error} />);
    }
  };

  const formatPayload = (payload) => {
    const fPayload = {};
    const clientId = localStorage.getItem('ROOT_ID');
    fPayload.clientId = clientId;
    fPayload.firstName = payload.firstName;
    fPayload.lastName = payload.lastName;
    fPayload.email = payload.email;
    fPayload.idType = payload.idType;
    fPayload.idNo = payload.ssn;
    fPayload.designation = payload.designation;
    fPayload.departments = payload.department ? [payload.department] : [];

    if (fPayload.idType === 'PPN') {
      fPayload.passNo = payload.ssn;
      fPayload.idNo = null;
      fPayload.idType = null;
    }

    return fPayload;
  };

  const createEmployee = async (payload) => {
    await EmployeeService.create(payload);
  };

  const udpateEmployee = async (payload) => {
    await EmployeeService.update(member._id, payload);
  };

  return (
    <Modal
      className="modal-full send-results"
      open={isModalToggled}
      onClose={() => toggleModal(false)}
      closeIcon
      size="tiny"
    >
      <Modal.Header>
        {!!member ? 'Edit member details' : 'New member invite'}
      </Modal.Header>
      <Modal.Content>
        <div className="memb-inv-modal">
          <MemberInviteForm
            onSubmit={onSubmit}
            onDelete={onDelete}
            isSaving={isSaving}
            member={member}
            depts={departments}
            isDeleting={isDeleting}
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default MemberInviteModal;
