import React, { useState, useEffect } from 'react';
import { Text } from 'components';

import './textInfoTile.scss';
import moment from 'moment';

const TextInfoTiles = ({
  title,
  subTitle,
  quantity,
  unit,
  type,
  updatedOn,
}) => {
  return (
    <div className="text-info-tiles">
      <div className="text-info-tiles__title">
        <Text size="medium" bold className="mb-3">
          {title}
        </Text>
        <Text size="tiny">{subTitle}</Text>
      </div>
      <div className="text-info-tiles__content">
        {type === 'money' ? (
          <span className="text-info-tiles__money">
            <sup className="text-info-tiles__unit">{unit}</sup>
            {new Intl.NumberFormat().format(quantity)}
          </span>
        ) : (
          ''
        )}
        {type === 'number' ? (
          <span className="text-info-tiles__number">
            {quantity} {unit}
          </span>
        ) : (
          ''
        )}
      </div>
      <div>
        {/* {updatedOn ? (
          <Text size="tiny">
            Updated on: {moment(updatedOn).format('DD MMM YYYY')}
          </Text>
        ) : (
          ''
        )} */}
      </div>
    </div>
  );
};

export default TextInfoTiles;
