import S3 from 'aws-s3-pro';
import { uploadModes } from '../constants';
window.Buffer = window.Buffer || require("buffer").Buffer;

const BEDROCK_S3_URL = 'https://bedrock-public.s3.ap-southeast-1.amazonaws.com';
export const s3Upload = {
  data: () => ({
    fileName: '',
    folder: null,
    rules: {
      required: (value) => !!value || 'Required.',
    },
  }),
  config() {
    return {
      bucketName: 'bedrock-public',
      dirName: this.folder,
      region: 'ap-southeast-1',
      accessKeyId: 'AKIAWPJZVTVQFWLGLDUV',
      secretAccessKey: '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',
      s3Url: BEDROCK_S3_URL,
    };
  },
  S3Client() {
    return new S3(this.config());
  },
  newFileName() {
    return Math.random().toString().slice(2);
  },
  url() {
    return `${BEDROCK_S3_URL}/${this.folder}/${this.newFileName}`;
  },
  removeFile(type) {
    const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1);
    // this.$emit(`remove${typeCapitalized}`);
  },
  onFileChange(files, type) {
    this.uploadFileToS3(type, files);
  },
  uploadFileToS3(type = 'image', files) {
    const user = JSON.parse(localStorage.getItem('USER_INFO'));
    const file = files;
    let folder = null;

    switch (type) {
      case uploadModes.clientLogo:
        folder = `${user.currentClientId}/client-logo`;
        break;
      default:
        folder = `${user.currentClientId}/images`;
    }

    this.folder = folder;
    return this.S3Client().uploadFile(file, this.newFileName());
  },
};

export async function uploadFile(file) {
  const user = JSON.parse(localStorage.getItem('USER_INFO'));
  const extension = file.name.split('.').pop();
  const fileName = `${user.currentClientId}/client-logo/${Math.random()
    .toString()
    .slice(2)}.${extension}`;

  const formData = new FormData();
  formData.append('key', fileName);
  formData.append('file', file);
  formData.append('Content-Type', file.type);

  const response = await fetch(BEDROCK_S3_URL, {
    method: 'POST',
    body: formData,
  });
  console.log(response);
  if (response.ok) {
    console.log('File uploaded successfully');
    return `${BEDROCK_S3_URL}/${fileName}`;
  } else {
    console.error('Upload failed');
  }
}
