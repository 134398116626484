import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="inherit"
    height="inherit"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill={props.color}
      d="M7.413 27.15a.707.707 0 0 1-.099-.007 13.564 13.564 0 0 1-4.452-1.457.684.684 0 0 1 .644-1.207 12.155 12.155 0 0 0 4.003 1.31.685.685 0 0 1-.096 1.361ZM31.768 27.15a.684.684 0 0 1-.096-1.361 12.15 12.15 0 0 0 4.003-1.31.685.685 0 0 1 .644 1.207 13.564 13.564 0 0 1-4.453 1.457.81.81 0 0 1-.098.006ZM24.618 21.014a.683.683 0 0 1-.572-1.057 5.21 5.21 0 0 1 4.378-2.373.683.683 0 1 1 0 1.367c-1.31 0-2.518.655-3.233 1.752a.682.682 0 0 1-.573.31Z"
    />
    <path
      fill={props.color}
      d="M29.939 18.95c-2.424 0-4.47-2.046-4.47-4.469 0-2.423 2.046-4.47 4.47-4.47 2.422 0 4.47 2.047 4.47 4.47 0 2.423-2.048 4.47-4.47 4.47Zm0-7.571c-1.682 0-3.103 1.42-3.103 3.102s1.421 3.102 3.103 3.102c1.681 0 3.102-1.42 3.102-3.102s-1.42-3.102-3.102-3.102Z"
    />
    <path
      fill={props.color}
      d="M35.997 23.496a.683.683 0 0 1-.683-.683 3.864 3.864 0 0 0-3.86-3.86.683.683 0 1 1 0-1.367 5.233 5.233 0 0 1 5.227 5.227.683.683 0 0 1-.684.683Z"
    />
    <path
      fill={props.color}
      d="M31.453 18.951h-3.03a.683.683 0 1 1 0-1.367h3.03a.683.683 0 1 1 0 1.367ZM35.996 25.766a.683.683 0 0 1-.684-.684v-2.271a.683.683 0 1 1 1.368 0v2.271a.683.683 0 0 1-.684.684ZM11.514 26.27a.683.683 0 0 1-.684-.683 6.748 6.748 0 0 1 6.741-6.741.683.683 0 1 1 0 1.367 5.38 5.38 0 0 0-5.374 5.374.683.683 0 0 1-.683.683Z"
    />
    <path
      fill={props.color}
      d="M21.61 20.213h-4.039a.683.683 0 1 1 0-1.367h4.039a.683.683 0 1 1 0 1.367ZM14.563 21.014a.683.683 0 0 1-.573-.31 3.847 3.847 0 0 0-3.233-1.753.683.683 0 1 1 0-1.367 5.21 5.21 0 0 1 4.378 2.373.683.683 0 0 1-.572 1.057ZM11.514 29.3a.683.683 0 0 1-.684-.683v-3.03a.683.683 0 1 1 1.367 0v3.03a.683.683 0 0 1-.683.683Z"
    />
    <path
      fill={props.color}
      d="M19.59 20.214c-3.107 0-5.732-2.625-5.732-5.732S16.483 8.75 19.59 8.75s5.732 2.625 5.732 5.732-2.625 5.732-5.732 5.732Zm0-10.097c-2.366 0-4.364 1.999-4.364 4.365s1.998 4.365 4.364 4.365c2.366 0 4.365-2 4.365-4.365 0-2.366-1.999-4.365-4.365-4.365Z"
    />
    <path
      fill={props.color}
      d="M27.668 26.272a.683.683 0 0 1-.684-.683 5.38 5.38 0 0 0-5.374-5.374.683.683 0 1 1 0-1.367 6.748 6.748 0 0 1 6.741 6.74.683.683 0 0 1-.683.684Z"
    />
    <path
      fill={props.color}
      d="M27.667 29.3a.683.683 0 0 1-.684-.683v-3.03a.683.683 0 1 1 1.368 0v3.03a.683.683 0 0 1-.684.683Z"
    />
    <path
      fill={props.color}
      d="M19.59 31.324c-2.884 0-5.768-.701-8.398-2.103a.685.685 0 0 1 .644-1.207 16.45 16.45 0 0 0 15.51 0 .685.685 0 0 1 .644 1.207 17.85 17.85 0 0 1-8.4 2.103ZM9.24 18.95c-2.422 0-4.469-2.046-4.469-4.469 0-2.423 2.047-4.47 4.47-4.47 2.423 0 4.47 2.047 4.47 4.47 0 2.423-2.047 4.47-4.47 4.47Zm0-7.571c-1.68 0-3.101 1.42-3.101 3.102s1.42 3.102 3.102 3.102c1.681 0 3.102-1.42 3.102-3.102s-1.42-3.102-3.102-3.102Z"
    />
    <path
      fill={props.color}
      d="M3.184 23.496a.683.683 0 0 1-.684-.683 5.233 5.233 0 0 1 5.227-5.227.683.683 0 1 1 0 1.367 3.863 3.863 0 0 0-3.86 3.86.683.683 0 0 1-.683.683Z"
    />
    <path
      fill={props.color}
      d="M10.757 18.951h-3.03a.683.683 0 1 1 0-1.367h3.03a.683.683 0 1 1 0 1.367ZM3.184 25.766a.683.683 0 0 1-.684-.684v-2.271a.683.683 0 1 1 1.367 0v2.271a.683.683 0 0 1-.683.684Z"
    />
  </svg>
)
export default SvgComponent
