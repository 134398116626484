import axios from './axiosInstances/identityAxiosInstance';

const api = {
  async getEncounterData({ from, to, departments }) {
    const res = await axios.get('insight/encounter/status', {
      params: { to, from, departments },
    });
    return res.data;
  },
  async getWellnessKPI({ from, to, departments }) {
    const res = await axios.get('insight/wellness/status', {
      params: { to, from, departments },
    });
    return res.data;
  },
};
export default api;
