import * as yup from 'yup';

export const schema = yup.object().shape({
  firstName: yup.string().required('required'),
  lastName: yup.string().required('required'),
  // nationality: yup.string().required('required'),
  email: yup.string().required('required'),
  idType: yup.string().required('required'),
  ssn: yup.string().required('required'),
  department: yup.string(),
  designation: yup.string(),
});
