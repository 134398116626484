import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import { Input, Button, Text, ErrorToast, SuccessToast } from 'components';
import IdAuthService from 'api/identity/authentication';

import history from '../../history';
import './passwordReset.scss';

const PasswordReset = (props) => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [formErrors, setErrors] = useState({});
  const [isResetting, setIsResetting] = useState(false);
  const [otp_id, setToken] = useState('');
  const [otp, setOtp] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    setToken(params.get('otp_id'));
    setOtp(params.get('otp'));
  }, []); // eslint-disable-line

  const checkForm = () => {
    let isError = false;
    let errors = {
      password: '',
      passwordConfirm: '',
      email: false,
    };

    if (password !== passwordConfirm) {
      errors.password = 'Passwords must match';
      errors.passwordConfirm = 'Passwords must match';
      isError = true;
    }

    if (password.length < 8) {
      errors.password = 'Min 8 characters';
      isError = true;
    }

    setErrors(errors);
    return isError;
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    const isError = checkForm();
    if (isError) return;
    setIsResetting(true);
    try {
      await IdAuthService.resetPassword({
        otp_id,
        otp,
        password,
        confirm_password: passwordConfirm,
      });

      toast.success(<SuccessToast message="Password reset" />, {
        hideProgressBar: true,
        autoClose: 4000,
        pauseOnHover: true,
      });
      history.push('/');
    } catch (error) {
      toast.error(<ErrorToast message="Error resetting password" />);
      setIsResetting(false);
    }
  };
  return (
    <div className="password-reset">
      <div className="password-reset__content">
        <div className="password-reset__title-wrapper">
          <Text size="huge" bold>
            Password Reset
          </Text>
        </div>
        <form>
          <div className="password-reset__body-wrapper">
            <Input
              placeholder="*********"
              label="New password"
              border
              size="fullWidth"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              invalid={formErrors.password && formErrors.password.length > 0}
              errorMessage={formErrors.password}
              autoComplete="new-password"
            />
            <Input
              border
              placeholder="*********"
              label="Confirm password"
              size="fullWidth"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              type="password"
              invalid={
                formErrors.passwordConfirm &&
                formErrors.passwordConfirm.length > 0
              }
              errorMessage={formErrors.passwordConfirm}
              autoComplete="new-password"
            />
            <Button
              color="blue"
              size="fullWidth"
              onClick={resetPassword}
              isLoading={isResetting}
            >
              Reset Password
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(PasswordReset);
