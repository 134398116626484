import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { Text } from '../../../../components';
import Logo from 'assets/images/riverr-logo.png';

import './clientDetailsHeader.scss';

const ClientDetailsHeader = (props) => {
  const clientInfo = useSelector((state) => state.general.clientInformation);

  return (
    <div className="client-details-header">
      <div
        className="client-details-header__abbreviation"
        style={{ background: 'white' }}
      >
        {clientInfo.logo ? (
          <img
            src={clientInfo.logo}
            alt="logo"
            className="client-details-header__logo mt-0"
          />
        ) : (
          <Text color="black" size="big" bold>{props.name.charAt(0)}</Text>
        )}
      </div>
      <div className="client-details-header__content">
        <div className="client-details-header__content__name">
          <Text size="big" bold>
            {props.name}
          </Text>
        </div>
        <div className="client-details-header__content__date">
          <Text size="tiny" color="lightGrey">
            Created: {props.created}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ClientDetailsHeader;
