import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'semantic-ui-react';

import { Button, Text, Select, CheckBox, ErrorToast } from 'components';
import EmployeeSmallTable from '../employeeSmallTable/EmployeeSmallTable';

import DepartmentService from 'api/department';
import EmployeeService from 'api/employees';

import './updateDepartmentModal.scss';

const UpdateDepartmentModal = ({
  toggleModal,
  isModalToggled,
  selectedEmps,
  departments = [],
  fetchEmployees,
  formattedDepts
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [department, setDepartment] = useState(null);
  const [departs, setDepartments] = useState([]);
  const [employee, setEmployee] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [unassignDept, setUnassignDept] = useState(false);

  useEffect(() => {
    setEmployeeData();
    setUnassignDept(false);
  }, [isModalToggled])

  useEffect(() => {
    if(departments) {
      const fData = formatData(departments);
      setDepartments(fData);
    }
  }, [])

  const formatData = (data) => {
    const depts = data.map((dept) => ({
      text: dept.name,
      value: dept._id
    }))

    return depts;
  }

  const fetchDepartments = async () => {
    try {
      const {data: { data }} = await DepartmentService.getAll({});
      const depts = formatData(data);
      setDepartments(depts);
    } catch(error) {
      toast.error(<ErrorToast error={error} />);
    }
  }

  const setEmployeeData = async () => {
    const objKeys = Object.keys(selectedEmps);
    if(objKeys.length === 1) {
      setEmployee(selectedEmps[objKeys[0]])
      setDepartment(!!selectedEmps[objKeys[0]].departments.length ? selectedEmps[objKeys[0]].departments[0] : '');
      
    };
    const emps = objKeys.map(key => selectedEmps[key]);
    setEmployees(emps)
  }

  const onSubmit = async () => {
    try {
      const clientId = localStorage.getItem('ROOT_ID');
      setIsSaving(true);
      const payload = { clientId, departments: getDepartmentPayload() }
      if(employees.length === 1) await udpateEmployee(employee._id, payload);
      if(employees.length > 1) await updateBulkEmployees(payload);
      fetchEmployees();
      setIsSaving(false);
      toggleModal(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setIsSaving(false);
    }
  };

  const getDepartmentPayload = () => {
    if(unassignDept || !department) return [];
    return [department]
  }

  const updateBulkEmployees = async (payload) => {
    const ids = employees.map(emp => emp._id);
    await EmployeeService.bulkUpdate({ ids, data: payload });
  }

  const udpateEmployee = async (id, payload) => {
    await EmployeeService.update(id, payload);
  }

  const onUnAssignClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setUnassignDept(!unassignDept)
    setDepartment(null);
  }

  return (
    <Modal
      open={isModalToggled}
      onClose={() => toggleModal(false)}
      closeIcon
      size={!!employee ? "tiny" : ''}
    >
      <Modal.Header>Update department</Modal.Header>
      <Modal.Content>
        <div className="u-dept-modal">
          {!!employee ? (
            <>
              <div className="u-dept-modal__row">
                  <Text type="label">Name</Text>
                  <Text>{employee?.firstName} {employee?.lastName }</Text>
              </div>
              <div className="u-dept-modal__row">
                  <Text type="label">Email address</Text>
                  <Text>{employee?.email}</Text>
              </div>
              <div className="u-dept-modal__row">
                  <Text type="label">Designation</Text>
                  <Text>{employee?.designation}</Text>
              </div>
            </>
          ) : (
            <div className="u-dept-modal__table">
              <EmployeeSmallTable data={employees} formattedDepts={formattedDepts} />
            </div>
          )}
          <div className="u-dept-modal__row">
            <Select
              label="Department to assign"
              border
              placeholder="Select a department"
              disabled={unassignDept}
              value={department}
              onChange={(e, { value }) =>
                setDepartment(value)
              }
              options={departs}
              search
              showErrorMessage={false}
              size="fullWidth"
              selection
              className="memb-invite__input"
            />
          </div>
          <div className="u-dept-modal__checkbox" onClick={(e) => onUnAssignClick(e)}>
            <CheckBox checked={unassignDept} />
            <div className="u-dept-modal__checkbox__text">
              <Text className="ml-3">Un-assign Department</Text>
              <Text className="ml-3" size="tiny" color="lightGrey">Remove assigned department from selected members(s)</Text>
            </div>
          </div>
          <Button color="blue" onClick={onSubmit} isLoading={isSaving} size="fullWidth">Save</Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default UpdateDepartmentModal;
