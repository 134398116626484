import axios from './axiosInstances/identityAxiosInstance';

const api = {
  async getById(id) {
    return axios.post(`/myemployee/department/GetById/${id}`);
  },

  async getAll({ filters = { isDeleted: false }, search = ''}) {
    return axios.post(`/myemployee/department/GetAll?offset=0&limit=1000`, { filters, search });
  },

  async create(payload) {
    return axios.post(`/myemployee/department/create`, payload);
  },

  async update(id, payload) {
    return axios.post(`/myemployee/department/UpdateById/${id}`, payload);
  },

  async delete(id) {
    return axios.post(`/myemployee/department/SoftDeleteById/${id}`);
  },
}

export default api