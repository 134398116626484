import * as React from 'react';
const SvgComponent = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.0242 25.4619L13.4609 26.9997L9.23044 24.4612L5 26.9997V8.38594C4.99987 7.94139 5.08733 7.50116 5.2574 7.09042C5.42746 6.67968 5.67679 6.30648 5.99114 5.99213C6.30548 5.67778 6.67869 5.42846 7.08943 5.25839C7.50017 5.08833 7.94039 5.00086 8.38494 5.00099H24.4602C23.7873 5.00152 23.1412 5.2692 22.6655 5.7452C22.1899 6.22119 21.9227 6.86656 21.9227 7.53946V15.2881M24.4582 5C24.7917 4.99987 25.1218 5.06543 25.4299 5.19294C25.738 5.32045 26.018 5.50742 26.2538 5.74315C26.4896 5.97888 26.6767 6.25876 26.8043 6.5668C26.932 6.87485 26.9977 7.20502 26.9977 7.53846V13.4609H21.9207"
      stroke={props?.color || 'black'}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.2314 18.207H20.9728V20.9743H18.2056V24.2329H20.9728V27.0001H24.2314V24.2329H26.9987V20.9743H24.2314V18.207Z"
      stroke={props?.color || 'black'}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.7046 10.5694V8.74609"
      stroke={props?.color || 'black'}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.88232 15.4294C8.88232 16.3415 9.69598 16.6449 10.7046 16.6449C11.7132 16.6449 12.5269 16.6449 12.5269 15.4294C12.5269 13.6071 8.88232 13.6071 8.88232 11.7848C8.88232 10.5703 9.69598 10.5703 10.7046 10.5703C11.7132 10.5703 12.5269 11.0319 12.5269 11.7848"
      stroke={props?.color || 'black'}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.7046 16.6484V18.4707"
      stroke={props?.color || 'black'}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.7827 14.2422H17.4996"
      stroke={props?.color || 'black'}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.7827 10.9883H18.6246"
      stroke={props?.color || 'black'}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export default SvgComponent;
