import React, { useEffect, useState } from 'react';

import { useCustomForm, useCountries } from 'hooks';
import { Input, ErrorToast, Button, Select, FileUpload } from 'components';
import { schema } from './schema';
import { s3Upload, uploadFile } from 'utils/s3Upload';
import { uploadModes } from 'constants';
import { toast } from 'react-toastify';

import './clientDetails.scss';

const industries = [{ text: 'Maritime', value: 'maritime' }];

const initialData = {
  name: '',
  registrationNo: '',
  industry: '',
  classificationNo: '',
  address: '',
  country: '',
  website: '',
  supportEmail: '',
  supportPhone: '',
  faxNo: '',
  logo: '',
};

const ClientDetailsForm = ({
  client,
  onSubmit,
  isSaving,
  onCancelClick,
  isSmall = false,
  custButton,
}) => {
  const [logoFile, setLogoFile] = useState(false);
  const [logoPreview, setLogoPreview] = useState(null);

  const {
    Controller,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useCustomForm(schema, initialData);

  useEffect(() => {
    if (client) reset(setInitialData());
    setLogoPreview(client?.logo);
  }, []);

  const setInitialData = () => {
    return {
      name: client?.name,
      registrationNo: client?.identifiers?.regNo,
      industry: client?.industry,
      classificationNo: client?.identifiers?.classNo,
      address: client?.address,
      country: client?.country,
      website: client?.contact?.website,
      supportEmail: client?.support?.email,
      supportPhone: client?.support?.phone,
      faxNo: client?.contact?.fax,
      logo: client?.logo,
    };
  };
  // TODO: would make more sense to have the original list formatted this way instead of a hook formatting it
  const countries = useCountries().countriesList;

  const addLogo = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setLogoPreview(reader.result);
      setLogoFile(file);
    };

    reader.readAsDataURL(file);
  };

  const uploadLogo = async () => {
    const resp = await uploadFile(logoFile);
    return resp;
  };

  const onSubmitForm = async (data) => {
    try {
      const logoResponse =
        logoPreview && !logoFile ? logoPreview : await uploadLogo();

      onSubmit({ ...data, logo: logoResponse });
    } catch (error) {
      console.log(error);
      toast.error(<ErrorToast error={error} />);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} className="client-det-f">
      <div className="client-det-f__grid-wrapper">
        <div className="client-det-f__grid">
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Input
                border
                className="client-det-f__input"
                label="Company Name"
                placeholder="Riverr"
                showErrorMessage={false}
                size="fullWidth"
                value={value}
                invalid={errors?.name}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="registrationNo"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Input
                border
                className="client-det-f__input"
                label="Company registration no."
                placeholder="123456789"
                showErrorMessage={false}
                size="fullWidth"
                value={value}
                invalid={errors?.registrationNo}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="industry"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                border
                label="Industry"
                placeholder="Maritime"
                value={value}
                onChange={(e, { value }) => onChange(value)}
                options={industries}
                search
                showErrorMessage={false}
                size="fullWidth"
                selection
                className="client-det-f__input"
                invalid={errors?.industry}
              />
            )}
          />
          <Controller
            name="classificationNo"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Input
                border
                className="client-det-f__input"
                label="Classification no."
                placeholder="123456789"
                showErrorMessage={false}
                size="fullWidth"
                value={value}
                invalid={errors?.classificationNo}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="address"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Input
                border
                className="client-det-f__input"
                label="Company address"
                placeholder="123 Road, Zip, City"
                showErrorMessage={false}
                size="fullWidth"
                value={value}
                invalid={errors?.address}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="country"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                border
                label="Country"
                placeholder="Singapore"
                value={value}
                onChange={(e, { value }) => onChange(value)}
                options={countries}
                search
                showErrorMessage={false}
                size="fullWidth"
                selection
                className="client-det-f__input"
                invalid={errors?.country}
              />
            )}
          />
        </div>
        <div
          className={`client-det-f__grid-small ${
            isSmall && 'client-det-f__grid-small--smaller'
          }`}
        >
          <Controller
            name="website"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Input
                border
                className="client-det-f__input"
                label="Website"
                placeholder="www.riverr.ai"
                showErrorMessage={false}
                size="fullWidth"
                value={value}
                invalid={errors?.website}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="supportEmail"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Input
                border
                className="client-det-f__input"
                label="Support Email"
                placeholder="support@riverr.ai"
                showErrorMessage={false}
                size="fullWidth"
                value={value}
                invalid={errors?.supportEmail}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="supportPhone"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Input
                border
                className="client-det-f__input"
                label="Support contact no."
                placeholder="+12345678"
                showErrorMessage={false}
                size="fullWidth"
                value={value}
                invalid={errors?.supportPhone}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="faxNo"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                border
                className="client-det-f__input"
                label="Fax no."
                placeholder="+12345678"
                showErrorMessage={false}
                size="fullWidth"
                value={value}
                invalid={errors?.faxNo}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div
          className={`client-det-f__grid-small ${
            isSmall && 'client-det-f__grid-small--smaller'
          }`}
        >
          <div className={'client-det-f__content__file-upload mt-4'}>
            {logoPreview ? (
              <img
                alt="logo"
                src={logoPreview}
                className="client-det-f__content__branding__file-upload__image"
              />
            ) : (
              <div className="client-det-f__content__file-upload__image-placeholder" />
            )}
            <Controller
              name="logo"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FileUpload
                  onSelect={(e) => {
                    addLogo(e);
                    onChange(e.target.files[0].name);
                  }}
                  label={logoPreview ? 'Change logo' : 'Upload logo'}
                  accept="image/x-png,image/jpeg"
                  invalid={errors?.logo?.message}
                  errorMessage={errors?.logo?.message}
                />
              )}
            />
          </div>
        </div>
      </div>
      {!custButton ? (
        <div className="client-det-f__buttons-wrapper">
          <Button
            inverted
            color="blue"
            disabled={isSaving}
            onClick={onCancelClick}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="blue"
            disabled={isSaving}
            isLoading={isSaving}
          >
            Save
          </Button>
        </div>
      ) : (
        custButton
      )}
    </form>
  );
};

export default ClientDetailsForm;
