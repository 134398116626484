import React, { useState } from 'react';
import { PieChart, Pie, Sector, Cell } from 'recharts';

const renderActiveShape = (props) => {
  // const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  // const sin = Math.sin(-RADIAN * midAngle);
  // const cos = Math.cos(-RADIAN * midAngle);
  // const sx = cx + (outerRadius + 10) * cos;
  // const sy = cy + (outerRadius + 10) * sin;
  // const mx = cx + (outerRadius + 30) * cos;
  // const my = cy + (outerRadius + 30) * sin;
  // const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  // const ey = my;
  // const textAnchor = cos >= 0 ? 'start' : 'end';

  const words = payload.name.split(' ');

  return (
    <g>
      {words.length > 2 ? (
        <>
          <text fontWeight="bold" x={cx} y={cy} textAnchor="middle" fill={fill}>
            <tspan x={cx} dy={-7}>
              {words.slice(0, 2).join(' ')}
            </tspan>
            <tspan x={cx} dy={20}>
              {' '}
              {words.slice(2).join(' ')}
            </tspan>
          </text>
        </>
      ) : (
        <text
          font-weight="bold"
          x={cx}
          y={cy}
          dy={-7}
          textAnchor="middle"
          fill={fill}
        >
          {payload.name}
        </text>
      )}

      <text
        font-weight="500"
        x={cx}
        y={words.length > 2 ? cy + 20 : cy + 5}
        dy={12}
        textAnchor="middle"
        fill={fill}
      >
        {`${Math.round(percent * 1000) / 10}%`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" /> */}
      {/* <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
        {`${Math.round(percent * 1000) / 10}%`}
      </text> */}
      {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`${Math.round(percent * 1000) / 10}%`}
      </text> */}
    </g>
  );
};

const DonutGraph = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return (
    // <ResponsiveContainer width="100%" height="100%">
      <PieChart width={300} height={300}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={85}
          outerRadius={105}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={onPieEnter}
          startAngle={-270}
        >
          {data?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    // </ResponsiveContainer>
  );
}

export default DonutGraph;

// import React, { useState } from 'react';
// import { PieChart, Pie, Label, Cell, ResponsiveContainer } from 'recharts';

// const DonutGraph = ({ data = [], color, status, percent}) => {
//   const COLORS = ['#F2FBFF', color];

//   const CustomLabel = ({ viewBox }) => {
//     const { cx, cy } = viewBox;
//     return (
//       <React.Fragment>
//         <text x={cx - 32} y={cy}>
//           <tspan
//             style={{
//               fontWeight: 900,
//               fontSize: "2.1em",
//               fill: color,
//             }}
//           >
//             {percent}%
//           </tspan>
//         </text>
//         <text x={cx - 30} y={cy + 20}>
//           <tspan
//             style={{
//               fontSize: "1.1em",
//               fill: "black",
//             }}
//           >
//             {status}
//           </tspan>
//         </text>
//       </React.Fragment>
//     );
//   };

//   return (
//     // <ResponsiveContainer width="100%" height="100%">
//       <PieChart width={200} height={200}>
//         <Pie
//           data={data}
//           innerRadius={71}
//           outerRadius={80}
//           startAngle={-270}
//           fill="#8884d8"
//           dataKey="value"
//         >
//           {data.map((entry, index) => (
//             <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//           ))}
//           <Label
//             content={<CustomLabel />}
//             position="center"
//           />
//         </Pie>
//       </PieChart>
//     // </ResponsiveContainer>
//   );
// }

// export default DonutGraph;
