import React from 'react';

import { Text } from 'components';

const Legend = ({ data = [] }) => {
  return (
    <div className='insi-chart__legend'>
        {data?.map(value => (
            <div key={value.label} className="insi-chart__legend__section">
                <div className="insi-chart__legend__header">
                    {/* <div className={`insi-chart__legend__dot insi-chart__legend__dot--${value.color}`} /> */}
                    <Text dark color={value.color}>
                        {value.label}
                    </Text>
                </div>
                <div className="insi-chart__legend__values">
                {value?.ranges?.map((rangeVal) => (
                    <div key={rangeVal.label + rangeVal.value} className="insi-chart__legend__val">
                        <Text size="tiny" bold className="mr-2" uppercase>{rangeVal.label}</Text>
                        <Text size="tiny">{rangeVal.value}</Text>
                    </div>
                ))}
                </div>
            </div>
        ))}
    </div>
  );
}

export default Legend;
