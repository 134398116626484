import React, { useState, useRef, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Sidebar, Menu } from 'semantic-ui-react';

import { SideBarMenu, Text } from '../../components';
import history from '../../history';

import SettingsIcon from '../../assets/new/settings';
import GroupIcon from '../../assets/new/peopleGroup';
import InsightsIcon from '../../assets/new/insights';
import ClaimsIcon from '../../assets/new/claims';
import EncounterIcon from 'assets/new/encounter';
import WellnessIcon from 'assets/new/wellness';
import MentalHealthIcon from 'assets/new/mentalHealth';

import './sideBar.scss';

const SideBar = (props) => {
  const splitPath = props.location.pathname.split('/');

  const sidebarRef = useRef();
  // const sidebarToggleButtonRef = useRef();
  const subMenuRef = useRef();

  const [isSidebarToggled, setIsSidebarToggled] = useState(false);
  const [selectedTab, setSelectedTab] = useState('/workforce');
  const [subMenuItems, setSubMenuItems] = useState(false);

  const {
    user: userInfo,
    clientInformation,
    showSideBar,
    userRights,
  } = useSelector((state) => state.general);

  // const toggleSidebar = e => {
  //   setIsSidebarToggled(!isSidebarToggled);
  // };

  useEffect(() => {
    const getSelectedTab = () => {
      if (splitPath[1] === 'employees') return '/workforce';
      if (splitPath[1] === 'settinfs') return '/settings';
    };
    setSelectedTab(getSelectedTab());

    const handleClick = (e) => {
      if (
        sidebarRef.current.contains(e.target) ||
        // sidebarToggleButtonRef.current.contains(e.target) ||
        (subMenuRef.current && subMenuRef.current.contains(e.target))
      ) {
        return;
      }
      setIsSidebarToggled(false);
    };
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []); // eslint-disable-line

  const navigationItems = [
    {
      title: 'Members',
      icon: GroupIcon,
      urlKey: '/workforce',
      // isHide: (!userRights?.includes('viewMembers') || !userRights?.includes('manageMembers')) && !!userRights?.length
    },
    {
      title: 'Insights',
      icon: InsightsIcon,
      urlKey: '/insights',
      // isHide: !userRights?.includes('viewStats') && !!userRights?.length
      // isHide: true
    },
    {
      title: 'Wellness KPI',
      icon: WellnessIcon,
      urlKey: '/wellness',
      // isHide: !userRights?.includes('viewStats') && !!userRights?.length
      isHide: Boolean(!clientInformation?.hasWellnessKPI),
    },
    {
      title: 'Encounter',
      icon: EncounterIcon,
      urlKey: '/encounter',
      // isHide: !userRights?.includes('viewStats') && !!userRights?.length
      isHide: Boolean(!clientInformation?.hasMedEncounter),
    },
    {
      title: 'Mental Health',
      icon: MentalHealthIcon,
      urlKey: '/mental-health',
      // isHide: !userRights?.includes('viewStats') && !!userRights?.length
      isHide: Boolean(!clientInformation?.hasMentalPEME),
    },
    {
      title: 'Claims',
      icon: ClaimsIcon,
      urlKey: '/claims',
      // isHide: !userRights?.includes('viewStats') && !!userRights?.length
      // isHide: true
    },
    {
      title: 'Settings',
      icon: SettingsIcon,
      urlKey: '/settings',
      // isHide: !userRights?.includes('settings') && !!userRights?.length
    },
  ];

  const containerClassNames = classNames('side-bar__container', {
    'side-bar__container--open': showSideBar,
    'side-bar__container--closed': !showSideBar,
  });

  const selectMenuItem = (item) => {
    item.subMenu && setSubMenuItems(item.subMenu);
    !item.subMenu && setSubMenuItems([]);

    if (typeof item.redirect !== 'undefined') return;
    // setSelectedTab(item.redirectUrl || item.urlKey);
    props.history.push(item.redirectUrl || item.urlKey);
  };

  // TODO: move this to useEffect
  const renderItems = useCallback(() => {
    let items = [];

    navigationItems.forEach((item, index) => {
      if (
        !item.isHide &&
        (!item.role || item.role[userInfo.role]) &&
        (!item.clientRole || item.clientRole[clientInformation.role]) &&
        (!item.type || item.type === clientInformation.type)
      )
        items.push(
          <div
            className={
              `/${splitPath[1]}` === item.urlKey
                ? item.subMenu
                  ? `/${splitPath[1]}` === item.urlKey
                    ? 'side-bar__item side-bar__item--selected'
                    : 'side-bar__item side-bar__item--selected-nested'
                  : 'side-bar__item side-bar__item--selected'
                : `/${splitPath[1]}` === item.urlKey
                ? 'side-bar__item side-bar__item--selected'
                : 'side-bar__item'
            }
            key={item.urlKey}
            onClick={() => selectMenuItem(item)}
          >
            <div className="side-bar__item__icon-wrapper">
              <div
                style={{
                  width: 28,
                  minWidth: 28,
                  maxWidth: 28,
                  height: 28,
                  minWeight: 28,
                  maxWeight: 28,
                  margin: 'auto',
                  marginBottom: '2px',
                }}
              >
                <item.icon
                  color={
                    splitPath[1] === item.urlKey
                      ? item.subMenu
                        ? `/${splitPath[1]}` === item.urlKey
                          ? '#fff'
                          : '#6f6f6f'
                        : '#fff'
                      : `/${splitPath[1]}` === item.urlKey
                      ? '#fff'
                      : '#6f6f6f'
                  }
                />
              </div>
              <Text
                className="side-bar__item__title"
                bold
                color={
                  splitPath[1] === item.urlKey
                    ? item.subMenu
                      ? `/${splitPath[1]}` === item.urlKey
                        ? '#fff'
                        : '#6f6f6f'
                      : '#fff'
                    : `/${splitPath[1]}` === item.urlKey
                    ? '#fff'
                    : '#6f6f6f'
                }
              >
                {item.title}
              </Text>
            </div>
          </div>
        );
    });

    return items;
  }, [clientInformation, userInfo, selectedTab, history.location.pathname]);

  return (
    <Sidebar.Pushable as="section">
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        vertical
        visible={showSideBar}
      >
        <div className="side-bar">
          <div className={containerClassNames} ref={sidebarRef}>
            <div className="side-bar__content">{renderItems()}</div>
          </div>
          {subMenuItems.length > 0 && (
            <div className="side-bar__sub-menu">
              <SideBarMenu
                clientType={clientInformation.type}
                role={userInfo.role}
                subMenuRef={subMenuRef}
                sidebarRef={sidebarRef}
                history={props.history}
                items={subMenuItems}
                closeMenu={() => setSubMenuItems([])}
                location={history.location}
                setIsSidebarToggled={setIsSidebarToggled}
              />
            </div>
          )}
        </div>
      </Sidebar>
    </Sidebar.Pushable>
  );
};

export default withRouter(SideBar);
