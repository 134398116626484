import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useCustomForm } from 'hooks';
import { Button, Input, Select, Text } from 'components';

import { schema } from './schema'

import './userManagement.scss';

const initialData = {
    name: '',
    email: '',
    role: null,
    department: null,
    rights: [],
}

const roles = [
  {
    text: 'Admin',
    value: 'admin',
    info: "Can do everything",
  },
  {
    text: 'User',
    value: 'user',
    info: 'Set custom user rights',
  },
];

const allRights = [
  {
    text: 'Settings - Manage users',
    value: 'manageUsers',
    info: "Can access and manage department users",
  },
  {
    text: 'Settings - Manage department',
    value: 'manageDepartments',
    info: "Can access department settings and edit department settings",
  },
  {
    text: 'Members - Manage',
    value: 'manageMembers',
    info: "Can add, remove, edit and view members",
  },
  {
    text: 'Members - View',
    value: 'viewMembers',
    info: "Can view members, but can't edit, remove or add new members",
  },
  {
    text: 'Insights - View',
    value: 'viewStats',
    info: "Can view Insights",
  },
]

// const settingRights = [
//   {
//     text: 'Settings - Manage',
//     value: 'settings',
//     info: "Can access and manage account settings",
//   },
// ]

// const memberRights = [
//   {
//     text: 'Members - Manage',
//     value: 'manageMembers',
//     info: "Can add, remove, edit and view members",
//   },
//   {
//     text: 'Members - View',
//     value: 'viewMembers',
//     info: "Can view members, but can't edit, remove or add new members",
//   },
// ]

// const insightRights = [
//   {
//     text: 'Insights - View',
//     value: 'viewStats',
//     info: "Can view Insights",
//   },
// ]

const DropdownOption = ({ text, info }) => {
  return (
    <div className="drpdwn-opt">
      <Text size="tiny" bold>
        {text}
      </Text>
      <Text size="tinniest" className="mt-1" color="lightGrey">
        {info}
      </Text>
    </div>
  );
};

const UserManagementForm = ({
  onSubmit,
  isLoading,
  deptDropOptions,
  user,
  isEdit,
  objDepts
}) => {
  const {
    Controller,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    control,
  } = useCustomForm(schema, initialData);
  const userInfo = useSelector((state) => state.general.user);
  const userDepts = useSelector((state) => state.general?.userRightsObj?.users.writeDepts);

  const [role, setRole] = useState(null);
  const [depts, setDepts] = useState([]);
  const [rights, setRights] = useState([]);
  const [rightOptions, setRightOptions] = useState([]);
  const [deptsOptions, setDeptDrodownOptions] = useState([]);
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if(user) {
      reset(setInitialData());
      setDepts(user?.departments?.departments || [])
      setRole(user?.metadata?.role);
    }
    rightSelectOptions()
  }, [])

  useEffect(() => {
    if(isInitialRender) return setIsInitialRender(false);
    getDeptDrodownOptions()
  }, [role])

  const setInitialData = () => {
    return {
      name: user?.metadata?.name,
      email: user?.email,
      role: user?.metadata?.role,
      department: user?.departments?.departments,
      rights: user?.metadata?.rights || [],
    }
  }

  const getDeptDrodownOptions = () => {
    const options = [...deptDropOptions];
    if(userInfo?.metadata?.role === 'admin' && role === 'admin') {
      options.unshift({ value: null, text: 'All'})
    } else {
      const removedAllDepts = depts.filter(n => n);
      setDepts(removedAllDepts)
      reset({...getValues(), department: removedAllDepts});
    }
    setDeptDrodownOptions(options);
  }

  const roleSelectOptions = () => {
    if(userInfo?.metadata?.role !== 'admin')
      return [{
        ...roles[1],
        content: <DropdownOption text={roles[1].text} info={roles[1].info} />,
      }];

    return roles?.map((role) => ({
      ...role,
      content: <DropdownOption text={role.text} info={role.info} />,
    }));
  };

  const rightSelectOptions = () => {
    const rightOptions = allRights?.map((right) => ({
      ...right,
      content: <DropdownOption text={right.text} info={right.info} />,
    }))

    setRightOptions(rightOptions);
  };

  return (
    <form  onSubmit={handleSubmit(onSubmit)} className='user-manag-f'>
      <div className='user-manag-f__tag-wrapper'>
        <Controller
        name="name"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Input
            border
            className='user-manag-f__input'
            size="fullWidth"
            label="Name"
            placeholder="Kevin Tao"
            value={value}
            invalid={errors?.name}
            errorMessage={false}
            onChange={onChange}
          />
        )}/>
      </div>
      <div className='user-manag-f__tag-wrapper'>
        <Controller
        name="email"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Input
            disabled={!!user}
            border
            className='user-manag-f__input'
            size="fullWidth"
            label="Email address"
            placeholder="kevin@riverr.ai"
            value={value}
            invalid={errors?.email}
            errorMessage={false}
            onChange={onChange}
          />
        )}/>
      </div>
      {((userInfo?.metadata?.role === 'admin') || (userInfo?.metadata?.role !== 'admin' && user?.metadata?.role !== 'admin')) && (
        <div className='user-manag-f__tag-wrapper'>
          <Controller
          name="role"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Select
            className='user-manag-f__input'
              border
              label="User role"
              placeholder={userInfo?.metadata?.role === 'admin' ? "Admin" : "User"}
              value={value}
              onChange={(e, { value }) => {
                onChange(value)
                setRole(value);
              }}
              // multiple
              options={roleSelectOptions()}
              search
              showErrorMessage={false}
              size="fullWidth"
              selection
            />
          )}/>
        </div>
      )}
      {((role === 'user')) && (
        <div className='user-manag-f__tag-wrapper'>
          <Controller
            name="department"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                className='user-manag-f__input'
                label="Departments"
                border
                placeholder="All departments"
                value={value}
                onChange={(e, { value }) => {
                  setDepts(value);
                  onChange(value);
                }}
                options={deptsOptions}
                search
                showErrorMessage={false}
                size="fullWidth"
                selection
                multiple
                invalid={errors?.department}
              />
            )}
          />
        </div>
      )}
      {((!!depts.length && ((role === 'user' && userInfo?.metadata?.role === 'admin') || (role === 'user' && userInfo?.metadata?.role !== 'admin')))) && (
        <div className='user-manag-f__dept-wrapper'>
          <Text type="label">User rights</Text>
          <Text size="tiny" className="mb-3">Set custom user rights for each department</Text>
          {depts.map(dept => (
            <div key={dept} className='user-manag-f__dept-wrapper user-manag-f__dept-wrapper--light'>
              <Text bold size="small" className="mb-3">{objDepts[dept]}</Text>
              <div className='user-manag-f__tag-wrapper'>
                <Controller
                name="rights"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    disabled={userInfo?.metadata?.role !== 'admin'  && !userDepts?.includes(dept)}
                    border
                    placeholder="Select user rights"
                    value={value[dept] || []}
                    onChange={(e, {value}) =>
                    {
                      onChange({ ...getValues().rights, [dept]: value})
                      setRights({ ...rights, [dept]: value})
                    }}
                    multiple
                    options={rightOptions}
                    search
                    showErrorMessage={false}
                    size="fullWidth"
                    selection
                  />
                )}/>
              </div>
            </div>
          ))}
        </div>
      )}
      <Button type="submit" isLoading={isLoading} className='user-manag-f__button' color="blue" size="fullWidth">
          Save
      </Button>
    </form>
  );
};

export default UserManagementForm;
