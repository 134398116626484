import React, { useState, useCallback, useEffect } from 'react';
import { Table, Dropdown } from 'semantic-ui-react';
import moment from 'moment';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';

import IdClientService from 'api/client/client';
import history from '../../../../history';

import { Text, Button, Search, ErrorToast } from 'components';
import ClientCreate_modal from '../clientDetails/ClientCreate_Modal';

import './clientOverview.scss';

const statuses = {
  'DE-ACTIVATED': {
    color: 'red'
  },
  'ACTIVE': {
    color: 'green'
  }
}

const SignUpStatus = ({ status }) => {
  const stat = statuses[status];
  return (
    <div className={`table-status table-status--${stat?.color}`}>
    <div className="table-status__dot" />
      <Text size="small" color={stat?.color} bold>{status}</Text>
    </div>
  )
}

const ClientOverview = () => {
  const [isDeleting, setIsDeleting] = useState('');
  const [search, setSearch] = useState('');
  const [isSearching, setIsSearching] = useState(true);
  const [isCreateModalToggled, toggleCreateModal] = useState(false);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    if (!clients || clients?.length === 0) searchTable({});
  }, []); //eslint-disable-line

  const fetchClients = async (search = '') => {
    try {
      // const clientId = props.match.params.id || clientInfo.clientId;
      const { data } = await IdClientService.getAllClients({ search });
      setClients(data.data.clients);
      setIsSearching(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setIsSearching(false);
    }
  };

  const handleSearch = useCallback(
    debounce((search) => searchTable({ searchValue: search }), 750),
    []
  );

  const onSearchChange = (searchValue) => {
    setSearch(searchValue);
    handleSearch(searchValue);
  };

  const searchTable = async ({ searchValue = null }) => {
    try {
      setIsSearching(true);
      await fetchClients(searchValue);
      setIsSearching(false);
    } catch (error) {
      setIsSearching(false);
      toast.error(<ErrorToast error={error} />);
    }
  };

  const deleteClient = async (client) => {
    setIsDeleting(client.id);
    await IdClientService.updateClientById(client._id, {
      ...client,
      id: client._id,
      isDeleted: !client.isDeleted,
    });
    setIsDeleting('');
    fetchClients();
  };

  const renderTableData = () => {
    return clients.map((client, index) => {
      return (
        <Table.Row
          key={client.id + index}
          style={{ cursor: 'pointer' }}
          className="table-row"
          onClick={() => history.push(`/settings/clients/${client._id}`)}
        >
          <Table.Cell>
            <div className="client-ov__table__user-abbreviation">
              <Text>{client.name.charAt(0)}</Text>
            </div>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{client.name}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{client._id}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">
              {moment(client.createdAt).format('DD-MM-YYYY')}
            </Text>
          </Table.Cell>
          <Table.Cell>
            <SignUpStatus
              status={client.isDeleted ? 'DE-ACTIVATED' : 'ACTIVE'}
            />
          </Table.Cell>
          <Table.Cell className="table-row__cell--center">
            <Dropdown
              className="user-management__more-dropdown"
              icon="ellipsis vertical"
              floating
              labeled
              button
              direction="left"
              loading={isDeleting === client.id}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  text={
                    client.isDeleted
                      ? 'Activate account'
                      : 'De-activate account'
                  }
                  onClick={() => deleteClient(client)}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <div className="client-ov">
      <div className="client-ov__header">
        <Search
          size="large"
          dropdown={false}
          onSearchChange={(e, { value }) => onSearchChange(value)}
          isFilter={true}
          value={search}
          isLoading={isSearching}
        />
        <Button
          size="medium"
          color="blue"
          onClick={() => toggleCreateModal(true)}
          className="ml-3"
        >
          Create client
        </Button>
      </div>
      <ClientCreate_modal toggleModal={toggleCreateModal} isModalToggled={isCreateModalToggled}/>
      <div className="client-ov__table-wrapper">
        <Table selectable unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ width: '80px' }} />
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Created on</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell style={{ width: '80px' }} />
            </Table.Row>
          </Table.Header>
          <Table.Body>{renderTableData()}</Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default ClientOverview;
