import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup.string().required('required'),
  registrationNo: yup.string().required('required'),
  industry: yup.string().required('required'),
  classificationNo: yup.string().required('required'),
  address: yup.string().required('required'),
  country: yup.string().required('required'),
  website: yup.string(),
  supportEmail: yup.string(),
  supportPhone: yup.string(),
  faxNo: yup.string(),
  logo: yup.string().required('required'),
});
