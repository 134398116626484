import React from 'react';
import DatePicker from 'react-date-picker';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';

import { Text } from 'components';

import './datePicker.scss';

const Date = (props) => {
  const clientBranding = useSelector((state) => state.general.clientBranding);
  return (
    <PickerWrapper clientBranding={clientBranding} invalid={props.invalid}>
      {props.label && <Text type="label">{props.label}</Text>}
      <DatePicker
        className={props.className}
        onChange={props.onChange}
        value={props.value}
        format={props?.format || "dd-MM-y"}
        calendarIcon={null}
        disabled={props.disabled}
        minDate={props.minDate}
        maxDate={props.maxDate}
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="YYYY"
        maxDetail={props?.maxDetail}
        clearIcon={props.noCloseIcon ? null : <Icon name="close" />}
      />
      {props.invalid && props.showErrorMessage && (
        <Text className="input-error-text" color="red" size="tiny">
          {props.errorMessage ?? 'This field is required'}
        </Text>
      )}
    </PickerWrapper>
  );
};

Date.defaultProps = {
  showErrorMessage: true,
};

const getBorder = (invalid) => {
  if (invalid)
    return `border: solid 1px red; border-radius: 10px; padding: 10px; height: 40px;`;
  return `border: solid 1px #dddddd; border-radius: 10px; padding: 10px; height: 40px;`;
};

const PickerWrapper = styled.div`
  position: relative;
  .react-date-picker {
    border-radius: 10px !important;
    width: 100%;
    &__inputGroup {
      line-height: 1.25em !important;
    }

    &__button {
      padding: 0;
    }

    &--open {
      .react-date-picker__wrapper {
        border: solid 1px ${(props) => props.clientBranding?.color || '#5076ff'};
      }
    }

    &__calendar {
      &--open {
        margin-top: 5px !important;
      }
    }

    &__wrapper {
      height: 40px;
      outline-width: 0;
      font-size: 16px;
      color: #1b1b1b;
      font-weight: 200;
      background: transparent;
      ${(props) => getBorder(props.invalid)}
      border-radius: 10px !important;
      padding: 10px;
      background: white;
      margin-top: 0 !important;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
    }

    &__inputGroup {
      width: 127px;
      lineheight: initial;

      &__input {
        text-align: center;
        font-size: 17px;
        color: #1b1b1b;
        font-weight: 200;

        ::placeholder {
          color: #808080;
          opacity: 0.8;
          transition: all 0.3s ease;
          font-weight: 200;
          font-size: 16px;
        }

        :-ms-input-placeholder {
          color: #808080;
        }

        ::-ms-input-placeholder {
          color: #808080;
        }

        &:focus {
          outline: none;

          ::placeholder {
            color: #808080;
            opacity: 1;
          }

          :-ms-input-placeholder {
            color: #1b1b1b;
          }

          ::-ms-input-placeholder {
            color: #808080;
          }
          &:invalid {
            background: none;
          }
        }
      }

      &__divider {
        padding: 1px 1px;
      }
    }
  }

  .react-calendar {
    border: none;
    border-radius: 10px;
    box-shadow: 2px 2px 12px 0px #cdcdcd;

    &__navigation button:enabled:hover,
    &__navigation button:enabled:focus {
      background-color: rgba(102, 102, 102, 0.18) !important;
      border-radius: 2px !important;
    }

    &__navigation {
      padding: 10px;
      background: ${(props) => props.clientBranding?.color || '#5076ff'};
      border-radius: 10px;
      margin-bottom: 0.6em;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        height: 32px;
        color: white;
        font-weight: 200;
        font-size: 18px;
        margin-top: 6px;
      }

      &__label {
        padding-top: 0px;
        margin-top: 0px !important;
      }

      &__arrow {
        padding-top: 0px;
        margin-top: 0px !important;
      }
    }

    &__viewContainer {
      padding: 20px;
      padding-top: 0px;
    }

    &__month-view {
      &__weekdays__weekday {
        color: #1b1b1b;
        abbr[title] {
          text-decoration: none;
        }
      }

      &__days__day--weekend {
        color: #1b1b1b;
      }

      &__days__day--neighboringMonth {
        color: #cdcdcd;
      }
    }

    &__tile {
      &:enabled:hover {
        background-color: #fafafa;
        border-radius: 10px;
        color: black;
      }

      &--now {
        border: solid 1px #e2e2e2;
        border-radius: 10px;
        background: none;
      }

      &--active {
        background: transparent !important;
        border-radius: 10px;
        border: solid 1px ${(props) => props.clientBranding?.color || '#5076ff'} !important;
        color: ${(props) =>
          props.clientBranding?.color || '#5076ff'} !important;
      }
    }
  }

  .react-calendar__navigation button[disabled] {
    background-color: initial;
  }
`;

export default Date;
