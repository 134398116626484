import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CustomTooltip = ({ active, payload, label, getToolTipData, timeSpan }) => {
  const info = getToolTipData(label);
  if (active && payload && payload.length && info) {
    return (
      <div className="claims-chart__chart__tooltip">
        <div className="claims-chart__chart__tooltip__time">
          <p>{label}</p>
        </div>
        {/* <div className="claims-chart__chart__tooltip__inputs">
          <p>{`${info.total} active inputs `}</p>
        </div> */}

        {!!info?.values && !!info?.values?.length && info.values.map((val) => (
          <p style={{ color: val.color, fontWeight: 'bold' }}>{val.text}: {val.value}</p>
        ))}
      </div>
    );
  }

  return null;
};

const BarChartWrapper = ({ data = [], legend, getToolTipData, timeSpan }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="2 2" />
        <XAxis dataKey="name" />
        <YAxis domain={[0]} unit='' />
        <Tooltip content={<CustomTooltip getToolTipData={getToolTipData} timeSpan={timeSpan} />} />
        {legend.map(value => (
          <Bar key={value.label} fill={`#${value.colorHex}`} dataKey={value.label} radius={[10, 10, 0, 0]} />
        ))} 
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BarChartWrapper;
