import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { debounce } from 'lodash';
import downloadFile from 'js-file-download';
import axios from 'axios';

import { Text, ErrorToast, Select, Search, DatePicker, Icon } from 'components';
import { StatBox } from 'features';

import DepartmentService from 'api/department';
import PEMEService from 'api/peme';

import MentalHealthIcon from 'assets/new/mentalHealth';
import OverviewTable from './components/overviewTable/overviewTable';

import './mentalPeme.scss';
const SUMMARY_ORDER = [
  {
    id: 'pending review',
    header: 'Total Under Review',
    color: 'gold',
  },
  {
    id: 'approved',
    header: 'Total Approved',
    color: 'green',
  },
  {
    id: 'declined',
    header: 'Total Declined',
    color: 'red',
  },
];
const MentalPeme = () => {
  const { userDepartments } = useSelector((state) => state.general);

  const [viewingAccount, setViewingAccount] = useState(null);
  const [loadingDepartments, setLoadingDepartments] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [summaryObj, setSummaryObj] = useState({});
  const [submissionData, setSubmissionData] = useState([]);

  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [fromDate, setFromDate] = useState(
    moment().subtract(3, 'month').toDate()
  );
  const [toDate, setToDate] = useState(moment().toDate());
  const [pemeStatQuery, setPemeStatQuery] = useState({
    departments: [],
    from: fromDate.toISOString(),
    to: toDate.toISOString(),
    search: searchValue,
    sort: 'joiningDate',
    order: 'desc',
  });

  useEffect(() => {
    fetchDepartments();
  }, []);

  useEffect(() => {
    fetchPemeSummary();
    fetchPemeSubmissions();
  }, [activePage, pemeStatQuery]);

  const fetchPemeSummary = async () => {
    try {
      setLoading(true);
      const { data } = await PEMEService.getPEMEStatusSummaryData({
        ...pemeStatQuery,
      });
      setSummaryObj(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPemeSubmissions = async () => {
    try {
      setIsSearching(true);
      const _activePage = activePage || 1;
      const limit = 25;
      const offset = limit * (_activePage - 1);
      const { data } = await PEMEService.getPEMESubmissions({
        ...pemeStatQuery,
        skip: offset,
        limit,
      });
      setSubmissionData(data?.userQuestionnaires);
      setTotalPages(Math.ceil(data?.totalRecords / limit) || 0);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSearching(false);
    }
  };

  const formatDeptsDropdown = (depts) => {
    const options = depts?.map((dept) => ({
      value: dept._id,
      text: dept.name,
    }));
    if (depts.length > 1) options.unshift({ value: null, text: 'All' });
    return options;
  };

  const onChangeViewingAccount = (deptId) => {
    if (deptId) setActivePage(1);
    setViewingAccount(deptId);
    localStorage.setItem('S_DEPT_ID', deptId);

    setPemeStatQuery({
      ...pemeStatQuery,
      departments: deptId ? [deptId] : [],
    });
  };

  const fetchDepartments = async () => {
    try {
      setLoadingDepartments(true);
      const userDeptIds = userDepartments?.map((dept) => dept._id);
      const filter = userDeptIds
        ? { filters: { ids: userDeptIds, isDeleted: false } }
        : { filters: { isDeleted: false } };

      const {
        data: { data },
      } = await DepartmentService.getAll(filter);
      const deptOptions = formatDeptsDropdown(data);
      setDropdownOptions(deptOptions);
      setLoadingDepartments(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setLoadingDepartments(false);
    }
  };

  const downloadPemeCertificate = async (e, userQuestionnaireId, fullName) => {
    e.stopPropagation();
    try {
      setIsSearching(true);
      const { data } = await PEMEService.downloadPemeCertificate(
        userQuestionnaireId
      );
      const resp = await axios({
        url: data,
        method: 'GET',
        responseType: 'blob',
      });
      downloadFile(resp.data, `Mental Health PEME Certificate_${fullName}.pdf`);
    } catch (error) {
      console.log(error);
      toast.error(
        <ErrorToast
          message={error.response?.data?.message || 'Failed to download cert'}
        />
      );
    } finally {
      setIsSearching(false);
    }
  };

  const handleSearch = useCallback(
    debounce(
      (search) =>
        setPemeStatQuery({
          ...pemeStatQuery,
          search,
        }),
      750
    ),
    []
  );

  const onSearchChange = async (value) => {
    setSearchValue(value);
    if (value) setActivePage(1);
    handleSearch(value);
  };

  const goToPreviousPage = () => {
    if (activePage <= 1) return;
    const page = Math.max(1, activePage - 1);
    setActivePage(page);
  };

  const goToNextPage = () => {
    const page = Math.min(totalPages, activePage + 1);
    setActivePage(page);
  };

  const renderOtherSummaryStats = () => {
    return SUMMARY_ORDER.map((sum) => {
      const data =
        summaryObj?.status &&
        summaryObj.status.find((item) => item._id === sum.id);
      return (
        <StatBox
          color={sum.color}
          header={sum.header}
          value={summaryObj && data ? data?.percentage : 0}
          unit="%"
          count={data?.count}
          totalCount={data?.totalCount}
          valueName="crew"
        />
      );
    });
  };

  return (
    <div className="mental-Peme">
      <div className="mental-Peme__header">
        <MentalHealthIcon className="mental-Peme__header__icon" color="black" />
        <Text bold size="bigger">
          Mental Health PEME
        </Text>
      </div>
      <div className="mental-Peme__view-acc-wrapper">
        <div className="mental-Peme__view-acc">
          <Text>Viewing department: </Text>
          <Select
            size="small"
            label=""
            disabled={loadingDepartments}
            border
            className="ml-3"
            placeholder="All"
            search
            selection
            value={viewingAccount}
            onChange={(e, { value }) => {
              onChangeViewingAccount(value);
            }}
            options={dropdownOptions}
          />
        </div>
        <div className="mental-Peme__right">
          <div className="mental-Peme__filters">
            <div style={{ display: 'flex' }}>
              <div className="mr-3" style={{ position: 'relative' }}>
                <Text
                  size="tinniest"
                  color="lightGrey"
                  className="mental-Peme__filters__date-tag"
                >
                  From
                </Text>
                <DatePicker
                  noCloseIcon={true}
                  onChange={(date) => {
                    setFromDate(date);
                    setPemeStatQuery({
                      ...pemeStatQuery,
                      from: moment(date).toISOString(),
                      to: moment(toDate).toISOString(),
                    });
                  }}
                  value={fromDate}
                  // disabled={!hasViewRights}
                  maxDate={toDate}
                />
              </div>
              <div style={{ position: 'relative' }}>
                <Text
                  size="tinniest"
                  color="lightGrey"
                  className="mental-Peme__filters__date-tag"
                >
                  To
                </Text>
                <DatePicker
                  noCloseIcon={true}
                  onChange={(date) => {
                    setToDate(date);
                    setPemeStatQuery({
                      ...pemeStatQuery,
                      from: moment(toDate).toISOString(),
                      to: moment(date).toISOString(),
                    });
                  }}
                  value={toDate}
                  // disabled={!hasViewRights}
                  minDate={fromDate}
                  maxDate={new Date()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mental-Peme__stats">
        <StatBox
          color="black"
          header="Total Received"
          value={
            summaryObj
              ? Math.round(
                  (summaryObj?.totalUserQuestionnaireCount /
                    summaryObj?.totalCrewCount) *
                    10000
                ) / 100
              : 0
          }
          unit="%"
          valueText=""
          count={summaryObj?.totalUserQuestionnaireCount}
          totalCount={summaryObj?.totalCrewCount}
          valueName="crew"
        />
        {summaryObj && renderOtherSummaryStats()}
      </div>
      <div className="mental-Peme__table-wrapper mt-4">
        <Text dark bold>
          All PEME Submissions
        </Text>
        <div className="mental-Peme__table-wrapper__search mt-4">
          <Search
            isLoading={isSearching}
            size="fullWidth"
            border
            value={searchValue}
            dropdown={false}
            onSearchChange={(e, { value }) => onSearchChange(value)}
            isFilter={true}
            placeholder="Search"
          />
        </div>
        {!isLoading && (
          <OverviewTable
            filteredData={submissionData}
            order={pemeStatQuery?.order}
            setOrder={(val) =>
              setPemeStatQuery({
                ...pemeStatQuery,
                order: val,
              })
            }
            downloadCertificate={downloadPemeCertificate}
          />
        )}
      </div>
      {!isLoading && parseInt(totalPages) > 0 && submissionData.length > 0 && (
        <div className="mental-Peme__pagination">
          <Text color="grey">{'Page ' + activePage + ' of ' + totalPages}</Text>
          {parseInt(totalPages) > 1 && (
            <div className="mental-Peme__pagination__section">
              <div className="mental-Peme__pagination__section__angles">
                <div
                  id="left_angle"
                  className={`mental-Peme__pagination__section__angle ${
                    activePage === 1
                      ? 'mental-Peme__pagination__section__angle__disabled'
                      : ''
                  }`}
                  onClick={() => goToPreviousPage()}
                >
                  <Icon fontSize="20px" name="angleLeft" />
                </div>
                <div
                  className={`mental-Peme__pagination__section__angle ${
                    activePage === totalPages
                      ? 'mental-Peme__pagination__section__angle__disabled'
                      : ''
                  }`}
                  onClick={() => goToNextPage()}
                >
                  <Icon fontSize="20px" name="angleRight" />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default MentalPeme;
