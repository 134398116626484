import React, { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { Text, ErrorToast, SuccessToast } from 'components';
import { DeleteModal } from 'features';
import { Table, Dropdown } from 'semantic-ui-react';

import threeDotsSvg from 'assets/new/threeDots.svg';
import UserService from 'api/identity/user';

import './userManagement.scss';

const statuses = {
  'ACTIVE': {
    color: 'green'
  },
  'INACTIVE': {
    color: 'grey'
  },
  'LOCKED': {
    color: 'grey'
  },
  'INVITED': {
    color: 'blue'
  },
  'DELETED': {
    color: 'red'
  },
  'BANNED': {
    color: 'red'
  },
}

const getStatus = (statusCode) => {
  switch (statusCode) {
    case 1:
      return 'INVITED';
    case 2:
      return 'ACTIVE';
    case 3:
      return 'LOCKED';
    case 4:
      return 'BANNED';
    case 5:
      return 'DELETED';
    default:
      return 'UNSPECIFIED';
  }
};

const SignUpStatus = ({ status }) => {
  const stat = statuses[status];
  return (
    <div className={`table-status table-status--${stat?.color}`}>
    <div className="table-status__dot" />
      <Text size="small" color={stat?.color} bold>{status}</Text>
    </div>
  )
}

const UserManagementTable = ({filteredData, fetchUsers, objDepts, onEditClick}) => {
  const userInfo = useSelector((state) => state.general.user);

  const [isDeleteModalToggled, toggleDeleteModal] = useState(false)
  const [selectedUser, setDeleteUser] = useState(null)

  const toggleDelete = async (user) => {
    if(user) setDeleteUser(user)
    else setDeleteUser(null)
    toggleDeleteModal(!isDeleteModalToggled);
  }

  const deleteUser = async () => {
    try {
      await UserService.deleteUser(selectedUser.id);
      await fetchUsers();
    } catch (error) {
        toast.error(<ErrorToast error={error} />);
    }
  }

  const updateStatus = async (id, status) => {
    try {
      await UserService.updateStatus({
        id: id,
        status: status,
      });
      await fetchUsers();
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  }
  
  const reInviteUser = async (id) => {
    try {
      await UserService.resendActivationEmail(id);
      toast.success(<SuccessToast message="User invite sent" />);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  }

  const renderUserDepartments = (depts) => {
    const fDepts = [];

    depts.forEach((deptId) => {
      if(!!objDepts[deptId]) fDepts.push(objDepts[deptId]);
    })

    return (
      <>
        {fDepts?.map((dept, index) => (
          <span key={dept}>
          {index !== 0 && ', '}
          {dept}
          </span>
        ))}
      </>
    )
  }

  const renderTableData = () => {
    return filteredData?.map((user) => {
      return (
        <Table.Row key={user?.id}>
          <Table.Cell>
            <Text size="small" bold>{user?.metadata?.name}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small" bold>{user?.email}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small" bold>{user?.departments?.departments?.length ? renderUserDepartments(user?.departments?.departments) : 'All'}</Text>
          </Table.Cell>
          <Table.Cell style={{ minWidth: '150px'}}>
            {/* <Select
              border
              placeholder="Admin"
              value={user.metadata.roles}
              onChange={(e, { value }) =>
                updateUser({...user, metadata: { ...user.metadata, roles: value }})
              }
              multiple
              options={roleSelectOptions()}
              search
              showErrorMessage={false}
              size="fullWidth"
              selection
              className="user-manag-t__input"
            /> */}
            <Text size="small" bold uppercase>{user?.metadata?.role}</Text>
          </Table.Cell>
          <Table.Cell>
            <SignUpStatus status={getStatus(user?.status)} />
          </Table.Cell>
          <Table.Cell>
            <Text size="small" bold>{moment(user?.createdAt).format('DD MMM YYYY')}</Text>
          </Table.Cell>
          <Table.Cell className="user-manag-t__small-cell">
            {(userInfo?.metadata?.role === 'admin' || (userInfo?.metadata?.role !== 'admin' && user?.metadata?.role !== 'admin')) && (
              <div className="user-manag-t__small-dots">
                <Dropdown
                  selectOnBlur={false}
                  icon={null}
                  floating
                  direction='left'
                  trigger={
                    <img src={threeDotsSvg} alt="dots" />
                  }
                  >
                  <Dropdown.Menu>
                    {(user.status === 3 || user.status === 4 || user.status === 5) && ( 
                      <Dropdown.Item onClick={() => updateStatus(user.id, 2)}>
                        <Text>Activate user</Text>
                      </Dropdown.Item>
                    )}
                    {/* {user.status === 'ACTIVE' && ( 
                      <Dropdown.Item onClick={() => updateStatus(user.id, { status: 'INACTIVE' })}>
                        <Text>Deactivate user</Text>
                      </Dropdown.Item>
                    )} */}
                    {user.status === 1 && ( 
                      <Dropdown.Item onClick={() => reInviteUser(user.id)}>
                        <Text>Re-invite user</Text>
                      </Dropdown.Item>
                    )}
                    {user.status !== 5 && (
                      <Dropdown.Item onClick={() => onEditClick(user)}>
                        <Text>Edit user</Text>
                      </Dropdown.Item>
                    )}
                    {user.status !== 5 && (
                      <Dropdown.Item onClick={() => toggleDelete(user)}>
                        <Text color="red">Delete user</Text>
                      </Dropdown.Item>
                    )}

                  </Dropdown.Menu>
                  </Dropdown>
              </div>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <>
      <DeleteModal
        toggleModal={toggleDelete}
        isModalToggled={isDeleteModalToggled}
        header={"Are you sure you want to delete this user?"}
        content={<Text style={{ display: 'flex'}}>Deleted users won't be able to login anymore and can be recovered</Text>}
        onDelete={deleteUser}
      />
      <Table className="user-manag-t" striped>
        <Table.Header>
        <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Departments</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Created on</Table.HeaderCell>
            <Table.HeaderCell textAlign='center' className="user-manag-t__small-cell">Action</Table.HeaderCell>
        </Table.Row>
        </Table.Header>
        <Table.Body>{renderTableData()}</Table.Body>
      </Table>
    </>
  );
};

export default UserManagementTable;
