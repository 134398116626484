import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import colors from '../styles/colors';

const Text = memo((props) => {
  return (
    <TextWrapper
      size={props.size}
      color={props.color}
      textType={props.type}
      bold={props.bold}
      center={props.center}
      underline={props.underline}
      dark={props.dark}
      darker={props.darker}
      uppercase={props.uppercase}
      capitalize={props.capitalize}
      lowercase={props.lowercase}
      height={props.height}
      ellipsis={props.ellipsis}
      style={props.style}
      className={props.className}
      onClick={props.onClick}
      italic={props.italic}
    >
      {props.children}
    </TextWrapper>
  );
});

Text.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
};

Text.defaultProps = {
  size: 'medium',
  height: 'medium',
  color: 'grey',
  type: 'default',
};

const getSize = (size) => {
  if (size === 'nano') return '8px';
  if (size === 'micro') return '10px';
  if (size === 'tinniest') return '12px';
  if (size === 'tiny') return '14px';
  if (size === 'small') return '15px';
  if (size === 'medium') return '16px';
  if (size === 'big') return '18px';
  if (size === 'bigish') return '20px';
  if (size === 'bigger') return '23px';
  if (size === 'huge') return '28px';
  if (size === 'huger') return '37px';
  if (size === 'massive') return '45px';
};

const getLineHeight = (size) => {
  if (size === 'nano') return '10px';
  if (size === 'micro') return '12px';
  if (size === 'tinniest') return '15px';
  if (size === 'tiny') return '17px';
  if (size === 'small') return '19px';
  if (size === 'medium') return '22px';
  if (size === 'big') return '24px';
  if (size === 'bigish') return '25px';
  if (size === 'bigger') return '27px';
  if (size === 'huge') return '31px';
  if (size === 'huger') return '38px';
  if (size === 'massive') return '46px';
};

// TODO: Move to helper
const getColor = (color) => {
  if (color === 'grey') return colors.DARK_GREY;
  if (color === 'lightGrey') return '#727272';
  if (color === 'link') return '#54b1f3';
  if (color === 'black') return 'black';
  if (color === 'white') return 'white';
  if (color === 'blue') return '#411CC2';
  if (color === 'lightBlue') return colors.HIGHLIGHT_BLUE;
  if (color === 'darkBlue') return colors.DARK_BLUE;
  if (color === 'yellow') return 'rgb(255 196 29)';
  if (color === 'green') return '#249F2A';
  if (color === 'greenish') return '#0e8301';
  if (color === 'greenish2') return '#12b001';
  if (color === 'hightlightGreen') return colors.HIGHLIGHT_GREEN;
  if (color === 'red') return '#E51D1D';
  if (color === 'red2') return '#ff3a3a';
  if (color === 'orange') return 'rgba(255, 175, 62, 1)';

  return color;
};

const textTypes = (type) => {
  if (type === 'button')
    return {
      'font-weight': '300',
    };
  if (type === 'pageHeader')
    return {
      marginTop: '10px',
      marginBottom: '10px',
      fontSize: '23px',
      lineHeight: '25px',
      // 'text-transform': 'uppercase',
      'font-weight': '500',
    };
  if (type === 'header')
    return {
      height: '19px',
      marginBottom: '10px',
      fontSize: '22px',
      'font-weight': '500',
      'text-transform': 'capitalize',
    };
  if (type === 'buttonHeader')
    return {
      height: '19px',
      fontSize: '22px',
      'font-weight': '400',
      'text-transform': 'capitalize',
    };

  if (type === 'label')
    return {
      'font-weight': '500',
      'margin-bottom': '3px',
      fontSize: '15px',
    };

  if (type === 'dataLabel')
    return {
      'font-weight': '300',
      'margin-bottom': '1px',
      fontSize: '13px',
    };

  if (type === 'data')
    return {
      'font-weight': '500',
      fontSize: '15px',
      lineHeight: '18px',
    };

  return {
    'font-weight': '300',
  };
};

const ellipsis = (ellipsis) => {
  if (ellipsis)
    return {
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
    };
  return null;
};

const fontStyles = (props) => {
  let styles = {};
  if (props.bold) styles['font-weight'] = '500';
  if (props.dark) styles['font-weight'] = '600';
  if (props.darker) styles['font-weight'] = '800';
  if (props.uppercase) styles['text-transform'] = 'capitalize';
  if (props.capitalize) styles['text-transform'] = 'uppercase';
  if (props.lowercase) styles['text-transform'] = 'lowercase';
  if (props.center) {
    styles['display'] = 'flex';
    styles['align-items'] = 'center';
    styles['justify-content'] = 'center';
    styles['text-align'] = 'center';
  }
  if (props.underline) styles['text-decoration'] = 'underline';
  if (props.italic) styles['font-style'] = 'italic';

  return styles;
};

const TextWrapper = styled.div`
  font-family: 'IBM plex sans', sans-serif;
  color: ${(props) => getColor(props.color)};
  font-size: ${(props) => getSize(props.size)};
  line-height: ${(props) => getLineHeight(props.size)};
  ${(props) => textTypes(props.textType)};
  ${(props) => fontStyles(props)};
  ${(props) => ellipsis(props.ellipsis)};
`;

export default Text;
