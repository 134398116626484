import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'semantic-ui-react';

import { ErrorToast } from 'components';

import DepartmentService from 'api/department';

import './departmentManagement.scss';

import DepartmentManagementForm from './DepartmentManagement_Form';

const DepartmentManagementModal = ({
  toggleModal,
  isModalToggled,
  fetchData,
  editDept
}) => {
  const [isSaving, setIsSaving] = useState(false);
  
  const onSubmit = async (payload) => {
    const fPayload = formatPayload(payload);
    if(!!editDept) return updateDepartment(fPayload)
    createDepartment(fPayload)
  };

  const formatPayload = (payload) => {
    const clientId = localStorage.getItem('BR_CLIENT_ID');

    const fPayload = {
      clientId,
      name: payload.name,
      tags: payload.tag ? [payload.tag] : []

    }
    return fPayload;
  }
    
  const createDepartment = async (fPayload) => {
    try {
      setIsSaving(true);
      await DepartmentService.create(fPayload)
      await fetchData();
      setIsSaving(false);
      toggleModal(false);
    } catch (error) {
      setIsSaving(false);
      toast.error(<ErrorToast error={error} />);
    }
  }
  
  const updateDepartment = async (fPayload) => {
    try {
      setIsSaving(true);
      await DepartmentService.update(editDept._id, fPayload)
      await fetchData();
      setIsSaving(false);
      toggleModal(false);
    } catch (error) {
      setIsSaving(false);
      toast.error(<ErrorToast error={error} />);
    }
  }
  
  return (
    <Modal
      className="modal-full"
      open={isModalToggled}
      onClose={() => toggleModal(false)}
      closeIcon
      size="tiny"
    >
      <Modal.Header>{!!editDept ? 'Edit department' : 'Create new department'}</Modal.Header>
      <Modal.Content>
        <div className="dept-manag-m">
            <DepartmentManagementForm onSubmit={onSubmit} isLoading={isSaving} editDept={editDept} />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default DepartmentManagementModal;
