import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, useRouteMatch } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Route } from 'react-router-dom';

import { ErrorToast } from 'components';
import ClientDetails from './features/clientDetails/ClientDetails';
import UserManagement from './features/userManagement/UserManagement';
import DepartmentManagement from './features/departmentManagement/DepartmentManagement';
import ClientOverview from './features/clientOverview/ClientOverview';
import Header from './components/header/Header';
// import ClientBranding from './components/clientBranding/ClientBranding';

import { setClientInformation } from 'redux/generalState.actions';
import IdClientService from 'api/client/client';

import history from '../../history';

import './settings.scss';

const Settings = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);
  const [panes, setPanes] = useState([]);
  const [panesIndexMap, setPanesIndexMap] = useState({});

  const clientInfo = useSelector((state) => state.general.clientInformation);
  const userInfo = useSelector((state) => state.general.user);
  const userRightsObj = useSelector((state) => state.general?.userRightsObj);

  const matchPath = useRouteMatch('/settings/clients/:id');

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    getPanes();
  }, [users, isLoading, clientInfo, userRightsObj, userInfo]);

  const setTabBasedOnRoute = ({ panesMap = panesIndexMap }) => {
    const path = history.location.pathname;
    if (path.includes('settings/departments'))
      return setActiveIndex(panesMap['/settings/departments']);
    else if (path.includes('settings/users'))
      return setActiveIndex(panesMap['/settings/users']);
    else if (path.includes('settings/clients'))
      return setActiveIndex(panesMap['/settings/clients']);
    else if (path.includes('settings')) return setActiveIndex(0);
  };

  const fetchInitialData = async () => {
    fetchClient();
    fetchClientUsers();
  };

  const fetchClient = async () => {
    try {
      setIsLoading(true);
      const {
        data: { client },
      } = await IdClientService.getCurrentClient();
      dispatch(setClientInformation(client));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(<ErrorToast error={error} />);
    }
  };

  const fetchClientUsers = async (cliId) => {
    try {
      const clientId = cliId || localStorage.getItem('BR_CLIENT_ID');
      const {
        data: { users },
      } = await IdClientService.getClientUsers(clientId);
      setUsers(users);
      setLoadingUsers(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setLoadingUsers(false);
    }
  };

  const handleTabChange = (e, { activeIndex }) => {
    if (panesIndexMap['/settings'] === activeIndex)
      window.history.pushState({ path: '/settings' }, '', '/settings');
    if (panesIndexMap['/settings/departments'] === activeIndex)
      window.history.pushState(
        { path: '/settings/departments' },
        '',
        '/settings/departments'
      );
    if (panesIndexMap['/settings/users'] === activeIndex)
      window.history.pushState(
        { path: '/settings/users' },
        '',
        '/settings/users'
      );
    if (panesIndexMap['/settings/clients'] === activeIndex)
      window.history.pushState(
        { path: '/settings/clients' },
        '',
        '/settings/clients'
      );

    setActiveIndex(activeIndex);
  };

  const getPanes = () => {
    const panes = [];
    const panesMap = {};

    if (userInfo?.metadata?.role === 'admin') {
      panesMap['/settings'] = panes.length;
      panes.push({
        menuItem: 'Account',
        render: () => (
          <Tab.Pane loading={isLoading}>
            {!!clientInfo?._id && (
              <ClientDetails
                data={clientInfo}
                userInfo={userInfo}
                fetchClient={fetchClient}
                fetchClientUsers={fetchClientUsers}
                users={users}
              />
            )}
          </Tab.Pane>
        ),
      });
    }

    if (
      userInfo.metadata.role === 'admin' ||
      userRightsObj?.departments?.writeDepts?.length
    ) {
      panesMap['/settings/departments'] = panes.length;
      panes.push({
        menuItem: 'Department management',
        render: () => (
          <Tab.Pane loading={isLoading}>
            <DepartmentManagement />
          </Tab.Pane>
        ),
      });
    }
    // panes.push({
    //   menuItem: 'Roles & permissions',
    //   render: () => (
    //     <Tab.Pane loading={isLoading || loadingUsers}>
    //       <ClientUsers users={users} fetchClientUsers={fetchClientUsers} />
    //     </Tab.Pane>
    //   ),
    // });
    if (
      userInfo.metadata.role === 'admin' ||
      userRightsObj?.users?.writeDepts?.length
    ) {
      panesMap['/settings/users'] = panes.length;
      panes.push({
        menuItem: 'User management',
        render: () => (
          <Tab.Pane loading={isLoading}>
            <UserManagement users={users} fetchUsers={fetchClientUsers} />
          </Tab.Pane>
        ),
      });
    }

    if (userInfo?.email?.includes('@riverr.ai')) {
      panesMap['/settings/clients'] = panes.length;
      const riverrPanes = [
        {
          menuItem: 'All clients',
          render: () => (
            <Tab.Pane loading={isLoading}>
              {!matchPath && <ClientOverview />}
              <div className="mt-5">
                <Route
                  exact
                  path="/settings/clients/:id"
                  component={() => <ClientDetails showUsers={true} />}
                />
              </div>
            </Tab.Pane>
          ),
        },
      ];

      panes.push(...riverrPanes);
    }
    setPanesIndexMap(panesMap);
    setPanes(panes);
    setTabBasedOnRoute({ panesMap });
  };

  return (
    <div className="settings">
      <div className="settings__content">
        {clientInfo.name && (
          <Header
            name={clientInfo.name}
            created={moment(clientInfo.createdAt).format('DD-MM-YYYY')}
          />
        )}
        <div className="settings__content__tab">
          <Tab panes={panes} menu={{ secondary: true }} onTabChange={handleTabChange} activeIndex={activeIndex}/>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Settings);
