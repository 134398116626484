import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Text } from '../components';

const Tabs = (props) => {
  return (
    <TabsContainer style={props.style} className={props.className}>
      {props.children?.map((tab) => (
        <Tab
          selected={props.selectedTab === tab.id}
          onClick={() => tab.onClick()}
        >
          <Text
            style={{ fontWeight: 500 }}
            color={props.selectedTab === tab.id ? 'white' : 'grey'}
          >
            {tab.label}
          </Text>
        </Tab>
      ))}
    </TabsContainer>
  );
};

Tabs.propTypes = {
  label: PropTypes.string,
  selectedTab: PropTypes.string,
  children: PropTypes.array,
};

const TabsContainer = styled.div`
  height: 55px;
  display: flex;
`;

const Tab = styled.div`
  top: 5px;
  width: 300px;
  height: 100%;
  background: white;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.selected ? '#5076ff' : 'none')};
  cursor: pointer;
  user-select: none;
`;

export default Tabs;
