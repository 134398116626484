import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Modal, Icon } from 'semantic-ui-react';

import EmployeeSmallTable from '../employeeSmallTable/EmployeeSmallTable';
import { ErrorToast, Button, Text, SuccessToast } from 'components';

import employeeService from 'api/employees';

import './reInviteModal.scss'

const ReInviteModal = ({ toggleModal, isModalToggled, selectedEmps }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    setEmployeeData();
  }, [])

  const setEmployeeData = async () => {
    const objKeys = Object.keys(selectedEmps);

    if(objKeys.length === 1) setEmployees(selectedEmps[objKeys[0]]);
    const emps = [];
    objKeys.forEach(key => {
      if(selectedEmps[key].signupStatus === 'INVITED' || selectedEmps[key].signupStatus === 'BOUNCED') emps.push(selectedEmps[key])
    });

    setEmployees(emps)
  }

  const reInvite = async () => {
    try {
      setIsSaving(true);
      Promise.all(employees.map(emp => {
        employeeService.reInvite({email: emp.email, patientId: emp.patientId});
      }))
      setIsSaving(false);
      toggleModal(false);
      toast.success(<SuccessToast message="Re-invites sent" />);
    } catch(error) {
      toast.error(<ErrorToast error={error} />);
      setIsSaving(false);
    }
  };

  return (
    <Modal
    className="re-inv-m"
    closeIcon
    size="small"
    open={isModalToggled}
    onClose={() => toggleModal(false)}
  >
    <Modal.Header>
      <Text dark>Re-invite members</Text>
    </Modal.Header>
    <Modal.Content className="re-inv-m__content">
      <Text center bold className="mb-3">Only users with status "Invited" or "Bounced" will be re-invited</Text>
      <div className="re-inv-m__table">
        <EmployeeSmallTable data={employees} noDepts={true} />
      </div>
      <Button
        size="fullWidth"
        color="blue"
        disabled={!employees.length}
        onClick={reInvite}
        isLoading={isSaving}
      >
        <Icon name="upload" /> Re-invite members
      </Button>
    </Modal.Content>
  </Modal>
  );
};

export default ReInviteModal;
