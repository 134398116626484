import axios from 'axios';
import moment from 'moment';

import { signOutUser, refreshUserToken } from 'auth/authentication';

import store from '../../store';

function createAxiosInstance(endpoint, options = {}) {
  const axiosInstance = axios.create({
    baseURL: endpoint,
  });

  if (options.timeout) {
    axiosInstance.defaults.timeout = options.timeout;
  }

  const requestHandler = (request) => {
    if (
      localStorage.getItem('AUTH_TOKEN') &&
      JSON.parse(localStorage.getItem('AUTH_TOKEN'))?.token
    ) {
      request.headers.Authorization = `Bearer ${
        JSON.parse(localStorage.getItem('AUTH_TOKEN'))?.token
      }`;
    }
    // request.headers['x-universal-id'] = 'BEDROCK';
    const clientId = store?.getState()?.general?.user?.currentClientId;
    request.headers['x-client-id'] =
      request.headers['x-client-id'] || clientId || 'zHAzw9fw2H4cGE8udj50';
    return request;
  };

  const responseHandler = (response) => {
    if (response?.status === 401) {
      console.log('er responseHandler');
      signOutUser(true);
    }

    return response;
  };

  const errorHandler = (error) => {
    try {
      const localRefreshToken = JSON.parse(
        localStorage.getItem('REFRESH_TOKEN')
      );
      const authToken = JSON.parse(localStorage.getItem('AUTH_TOKEN'));

      if (!localRefreshToken && !authToken) {
        return;
      }

      if (
        localRefreshToken &&
        moment.utc(localRefreshToken.expires).isBefore(moment()) &&
        (error?.response?.status === 401 || error?.response?.status === 403) &&
        error.response.config.url.substring(0, 5) !== '/auth'
      ) {
        console.log('er errorHandler log out user');
        return signOutUser(true);
      }
      if (
        localRefreshToken &&
        moment.utc(localRefreshToken.expires).isAfter(moment()) &&
        (error?.response?.status === 401 || error?.response?.status === 403) &&
        error.response.config.url.substring(0, 5) !== '/auth'
      ) {
        // return signOutUser(true);
        return refreshUserToken();
      }
      return Promise.reject(error);
    } catch (error) {
      return signOutUser(true);
    }
  };

  axiosInstance.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
  );

  return axiosInstance;
}

const axiosInstance = createAxiosInstance(
  process.env.REACT_APP_API_V1_END_POINT
);

export default axiosInstance;

export { createAxiosInstance };
