import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Text } from '../components';
import colors from '../styles/colors';
import { Loader, Segment } from 'semantic-ui-react';

const CheckBox = (props) => {
  return (
    <CheckBoxWrapper
      isLabel={!!props.label}
      className={props.className}
      style={props.style}
    >
      {!!props.label && (
        <CheckBoxLabel>
          <Text
            type="label"
            style={{ paddingTop: '3px !important' }}
            className={props.labelClassName}
          >
            {props.label}
          </Text>
        </CheckBoxLabel>
      )}
      {!props.loading ? (
        <>
          <CheckBoxInput type="checkbox" onClick={() => props.onClick()} />
          <Checkmark checked={props.checked} />
        </>
      ) : (
        <div className={props.loaderClassName}>
          <Loader size="tiny" active />
        </div>
      )}
    </CheckBoxWrapper>
  );
};

CheckBox.propTypes = {
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
};

CheckBox.defaultProps = {
  onClick: () => {},
  checked: false,
  label: '',
};

const CheckBoxWrapper = styled.label`
  display: block;
  position: relative;
  padding-left: ${(props) => (props.isLabel ? '35px' : '0')};
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 22px;
  width: 22px;
`;

const CheckBoxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const CheckBoxLabel = styled.div`
  position: relative;
  line-height: 26px;
  width: 150px;
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  border-radius: 7px;
  width: 25px;
  border: solid 1px ${colors.GREY};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #eee;
  }

  &:after {
    content: '';
    display: ${(props) => (props.checked ? 'inline-block' : 'none')};
    height: 80%;
    width: 80%;
    border-radius: 5px;
    border: solid 2px rgba(51, 87, 216, 0.8);
    background: rgba(51, 87, 216, 0.8);
  }
`;

export default CheckBox;
