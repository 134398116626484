import React from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';

import { Text } from 'components';
import { Table } from 'semantic-ui-react';

import './employeeSmallTable.scss';

const EmployeeSmallTable = ({data, noDepts = false, formattedDepts}) => {
  const hasActionColumn = data.some(emp => !!emp?.action)
  const hasStatusColumn = data.some(emp => !!emp?.status)
  const hasErrorColumn = data.some(emp => !!emp?.error)
  const renderTableData = () => {
    return data?.map((emp) => {
      return (
        <Table.Row
          key={emp?.id}
          style={
            hasErrorColumn && emp?.status !== 'OK'
              ? { background: '#fb0e0e1f' }
              : {}
          }
          // error={emp?.status !== 'OK'}
        >
          <Table.Cell>
            <div style={{ display: 'flex' }}>
              <Text size="small" className="mr-1">
                {emp?.firstName}
              </Text>
              <Text size="small">{emp?.lastName}</Text>
            </div>
          </Table.Cell>
          <Table.Cell>
            <Text size="tiny">
              {!!emp?.idNo ? emp?.idNo : 'N.A'} /{' '}
              {!!emp?.passNo ? emp?.passNo : 'N.A'}
            </Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="tiny">{emp?.email}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="tiny">{emp?.sendActivationEmail ? 'Yes' : 'No'}</Text>
          </Table.Cell>
          {!noDepts && (
            <Table.Cell>
              {/* <Text size="tiny">{emp?.department?.length && emp?.department.join(', ')}</Text> */}
              <Text size="tiny">
                {emp?.departments?.length
                  ? formattedDepts[emp?.departments[0]]
                  : ''}
              </Text>
            </Table.Cell>
          )}
          {hasActionColumn && (
            <Table.Cell>
              {/* <Text size="tiny">{emp?.department?.length && emp?.department.join(', ')}</Text> */}
              <Text size="tiny">{emp?.action}</Text>
            </Table.Cell>
          )}
          {hasStatusColumn && (
            <Table.Cell>
              <Text size="tiny">{emp?.status}</Text>
            </Table.Cell>
          )}
          {hasErrorColumn && (
            <Table.Cell style={{ minWidth: '200px' }}>
              <Text size="tiny">{emp?.error}</Text>
            </Table.Cell>
          )}
        </Table.Row>
      );
    });
  };

  return (
    <Table className="emp-table" unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>National ID/Passport No.</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Send Activation Email</Table.HeaderCell>
          {!noDepts && <Table.HeaderCell>Department</Table.HeaderCell>}
          {hasActionColumn && <Table.HeaderCell>Action</Table.HeaderCell>}
          {hasStatusColumn && <Table.HeaderCell>Status</Table.HeaderCell>}
          {hasErrorColumn && (
            <Table.HeaderCell style={{ minWidth: '200px' }}>
              Error
            </Table.HeaderCell>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderTableData()}</Table.Body>
    </Table>
  );
};

export default withRouter(EmployeeSmallTable);
