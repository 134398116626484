import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { Text, Select, DatePicker, Button, Icon, ErrorToast } from 'components';

import EncounterIcon from 'assets/new/encounter';
import PieInfoTile from 'pages/claims/components/pieInfoTile/PieInfoTile';
import BarChartComponent from './components/barChart';
import { ENCOUNTER_DATA_ORDER, PIE_COLORS } from 'helpers/encounterData';

import EncounterService from 'api/encounter';
import DepartmentService from 'api/department';

import './encounter.scss';

const EncounterDashboard = () => {
  const { userDepartments } = useSelector((state) => state.general);

  const [viewingAccount, setViewingAccount] = useState(null);
  const [loadingDepartments, setLoadingDepartments] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);

  const [fromDate, setFromDate] = useState(
    moment().subtract(3, 'month').toDate()
  );
  const [toDate, setToDate] = useState(moment().toDate());
  const [encounterStatQuery, setEncounterStatQuery] = useState({
    departments: [],
    from: fromDate.toISOString(),
    to: toDate.toISOString(),
  });
  const [selectedDept, setSelectedDepartments] = useState([]);
  const [statsData, setStatsData] = useState({});

  useEffect(() => {
    fetchDepartments();
  }, []);

  useEffect(() => {
    fetchEncounterStatsData();
  }, [encounterStatQuery]);

  const onChangeViewingAccount = (deptId) => {
    setViewingAccount(deptId);
    localStorage.setItem('S_DEPT_ID', deptId);
  };

  const fetchEncounterStatsData = async () => {
    try {
      let query = encounterStatQuery;
      if (!viewingAccount) {
        query.departments = [];
      }
      const { data } = await EncounterService.getEncounterData(query);
      setStatsData(data);
    } catch (error) {
      console.log(error?.response?.data);
      if (error?.response?.data?.status === 400) {
        toast.info(<ErrorToast error={error?.response?.data?.message} />);
      }
    } finally {
    }
  };

  const formatDeptsDropdown = (depts) => {
    const options = depts?.map((dept) => ({
      value: dept._id,
      text: dept.name,
    }));
    if (depts.length > 1) options.unshift({ value: null, text: 'All' });
    return options;
  };

  const fetchDepartments = async () => {
    try {
      setLoadingDepartments(true);
      const userDeptIds = userDepartments?.map((dept) => dept._id);
      const filter = userDeptIds
        ? { filters: { ids: userDeptIds, isDeleted: false } }
        : { filters: { isDeleted: false } };

      const {
        data: { data },
      } = await DepartmentService.getAll(filter);
      const deptOptions = formatDeptsDropdown(data);
      setDropdownOptions(deptOptions);
      setLoadingDepartments(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setLoadingDepartments(false);
    }
  };

  const formatGraphData = (data) => {
    return data?.map((c, indx) => ({
      ...c,
      name: c?._id,
      color: PIE_COLORS[indx],
    }));
  };

  const calcBarHeight = (value) => {
    if (value === 4) return 200;
    if (value === 3) return 180;
    return null;
  };

  const renderGraphs = useCallback(() => {
    return ENCOUNTER_DATA_ORDER.map((enc, index) => {
      if (enc.type === 'pie')
        return (
          <PieInfoTile
            key={enc?.name + index}
            title={enc?.title}
            data={formatGraphData(statsData[enc?.name])?.sort(
              (a, b) => enc?.order.indexOf(a.name) - enc?.order.indexOf(b.name)
            )}
            dataKey={'count'}
            innerRadius={50}
            width={350}
            legend
          />
        );
      if (enc.type === 'bar')
        return (
          <BarChartComponent
            key={enc?.name + index}
            title={enc?.title}
            data={formatGraphData(statsData[enc?.name])?.sort(
              (a, b) => enc?.order.indexOf(a.name) - enc?.order.indexOf(b.name)
            )}
            colors={PIE_COLORS}
            height={calcBarHeight(statsData[enc?.name]?.length)}
            {...enc}
          />
        );
    });
  }, [statsData]);

  return (
    <div className="encounter">
      <div className="encounter__header">
        <EncounterIcon className="encounter__header__icon" color="black" />
        <Text bold size="bigger">
          Medical Encounter Metrics
        </Text>
      </div>
      <div className="encounter__view-acc-wrapper">
        <div className="encounter__view-acc">
          <Text>Viewing department: </Text>
          <Select
            size="small"
            label=""
            disabled={loadingDepartments}
            border
            className="ml-3"
            placeholder="All"
            search
            selection
            value={viewingAccount}
            onChange={(e, { value }) => {
              setEncounterStatQuery({
                ...encounterStatQuery,
                departments: [value],
              });
              onChangeViewingAccount(value);
            }}
            options={dropdownOptions}
          />
        </div>
        <div className="encounter__right">
          <div className="encounter__filters">
            <div style={{ display: 'flex' }}>
              <div className="mr-3" style={{ position: 'relative' }}>
                <Text
                  size="tinniest"
                  color="lightGrey"
                  className="encounter__filters__date-tag"
                >
                  From
                </Text>
                <DatePicker
                  noCloseIcon={true}
                  onChange={(date) => {
                    setFromDate(date);
                    setEncounterStatQuery({
                      ...encounterStatQuery,
                      from: moment(date).toISOString(),
                      to: moment(toDate).toISOString(),
                    });
                  }}
                  value={fromDate}
                  // disabled={!hasViewRights}
                  maxDate={toDate}
                />
              </div>
              <div style={{ position: 'relative' }}>
                <Text
                  size="tinniest"
                  color="lightGrey"
                  className="encounter__filters__date-tag"
                >
                  To
                </Text>
                <DatePicker
                  noCloseIcon={true}
                  onChange={(date) => {
                    setToDate(date);
                    setEncounterStatQuery({
                      ...encounterStatQuery,
                      from: moment(toDate).toISOString(),
                      to: moment(date).toISOString(),
                    });
                  }}
                  value={toDate}
                  // disabled={!hasViewRights}
                  minDate={fromDate}
                  maxDate={new Date()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="encounter__grid">{renderGraphs()}</div>
    </div>
  );
};
export default EncounterDashboard;
