import React from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { Text, CheckBox } from 'components';
import { Table, Icon } from 'semantic-ui-react';

import './employeesTable.scss';

const statuses = {
  'ACTIVE': {
    color: 'green'
  },
  'UN-ASSIGNED': {
    color: 'grey'
  },
  'INVITED': {
    color: 'blue'
  },
  'RE-INVITE': {
    color: 'yellow'
  },
  'BOUNCED': {
    color: 'red'
  },
}

const SignUpStatus = ({ status }) => {
  const stat = statuses[status];
  return (
    <div className={`table-status table-status--${stat?.color}`}>
    <div className="table-status__dot" />
      <Text size="small" color={stat?.color} bold>{status}</Text>
    </div>
  )
}

const EmployeesTable = ({ selectedRows, filteredData, onRowSelect, selectAllRows, onEditClick, formattedDepts, hasEditRights }) => {
  const renderTableData = () => {
    return filteredData.map((emp) => {
      return (
        <Table.Row key={emp?._id}>
          {/* {(userRights?.includes('manageMembers') || !userRights.length) && ( */}
          {hasEditRights && (

            <Table.Cell
              onClick={(e) => onRowSelect(e, emp._id, emp)}
            >
              <div>
                <CheckBox
                  className="emp-table__checkbox"
                  checked={!!selectedRows[emp._id]}
                />
              </div>
            </Table.Cell>
          )}
          {/* )} */}
          <Table.Cell>
            <div style={{ display: 'flex' }}>
              <Text size="small" className="mr-1" bold>{emp?.firstName}</Text>
              <Text size="small" bold>{emp?.lastName}</Text>
            </div>
            <Text size="tiny">{emp?.email}</Text>
          </Table.Cell>
          <Table.Cell>
            {/* <Text size="tiny" bold>{emp?.department?.length && emp?.department.join(', ')}</Text> */}
            <Text size="tiny" bold>{emp?.departments?.length ? formattedDepts[emp?.departments[0]] : ''}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small" bold>{emp?.designation}</Text>
          </Table.Cell>
          <Table.Cell>
            <SignUpStatus status={emp?.signupStatus} />
          </Table.Cell>
          <Table.Cell>
            <Text size="small" bold>{moment(emp?.updatedAt).format('DD MMM YYYY, HH:mm a')}</Text>
          </Table.Cell>
          {/* {(userRights?.includes('manageMembers') || !userRights.length) && ( */}
          {hasEditRights && (
            <Table.Cell>
              <Icon name="edit outline" className="emp-table__icon" onClick={() => onEditClick(emp)} />
            </Table.Cell>
          )}
          {/* )} */}
        </Table.Row>
      );
    });
  };

  return (
    <Table className="emp-table" striped>
      <Table.Header>
      <Table.Row>
        {/* {(userRights?.includes('manageMembers') || !userRights.length) && ( */}
        {hasEditRights && (
          <Table.HeaderCell className="emp-table__checkbox-cell">
              <div onClick={(e) => selectAllRows(e)}>
              <CheckBox
                className="emp-table__checkbox"
                checked={Object.keys(selectedRows)?.length === filteredData?.length}
              />
              </div>
          </Table.HeaderCell>
        )}
        <Table.HeaderCell>Member</Table.HeaderCell>
        <Table.HeaderCell>Department</Table.HeaderCell>
        <Table.HeaderCell>Designation</Table.HeaderCell>
        <Table.HeaderCell>Sign-up Status</Table.HeaderCell>
        <Table.HeaderCell>Latest Action Update</Table.HeaderCell>
        {/* {(userRights?.includes('manageMembers') || !userRights.length) && ( */}
        {hasEditRights && (
          <Table.HeaderCell className="emp-table__checkbox-cell" />
        )}
        {/* )} */}
      </Table.Row>
      </Table.Header>
      <Table.Body>{renderTableData()}</Table.Body>
    </Table>
  );
};

export default withRouter(EmployeesTable);
