import React, { useState, useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import moment from 'moment';
import { toast } from 'react-toastify';

import { Text, Select, ErrorToast } from 'components';

import AreaChart from './components/LineGraph';
// import AreaChart from './components/AreaChart';
import BarChart from './components/BarChart';
import Legend from './components/Legend';

import areaChartSvg from 'assets/new/areaChart.svg'
import barChartSvg from 'assets/new/barChart.svg'

import InsightsService from 'api/insights';

import InputsByTimes from './components/InputsByTime/InputsByTime';

import { formatData, formatTooltip, formatLegend, timeScopeOptions, headerToCategory, formatInputsByTime } from './helpers/helpers';

import './areaChartSection.scss';

const AreaChartSection = ({ getDepartmentsFilter, setIsLoadingGraphData, selectedTile, setGraphTimeScope, graphTimeScope, isLoadingGraphData, fromDate, toDate, viewingAccount }) => {
  const [selectedChart, setSelectedChart] = useState('bar');
  const [data, setData] = useState([]);
  const [hasData, setHasData] = useState(true);
  const [legend, setLegend] = useState([]);
  const [inputsByTime, setInputsByTime] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  useEffect(() => {
    getData();
    fetchDynamicInputs();
  }, [selectedTile, fromDate, toDate, viewingAccount, graphTimeScope])

  const getData = async () => {
    try {
      const departmentFilter = getDepartmentsFilter();
      if(departmentFilter === 'NA') return;

      setIsLoading(true);
      setIsLoadingGraphData(true);
      getLegend();
      const category = headerToCategory(selectedTile.header);
      const filters = { from: moment(fromDate).startOf('day').toISOString(), to:  moment(toDate).endOf('day').toISOString(), departments: departmentFilter, category, groupBy: graphTimeScope }
      const { data: { data }} = await InsightsService.getInsightsDateRange(filters);
      if(!data[category]?.length) {
        setHasData(false);
        setIsLoadingGraphData(false)
        return setIsLoading(false);
      }
      setHasData(true);
      const fData = formatData(data[category], selectedTile.header, graphTimeScope);
      // getInputsByTime(data[category]);
      setData(fData);
      setIsLoadingGraphData(false)
      setIsLoading(false);
    } catch(error) {
      setIsLoadingGraphData(false)
      setIsLoading(false);
      toast.error(<ErrorToast error={error} />)
    }
  }

  const fetchDynamicInputs = async () => {
    try {
      const departmentFilter = getDepartmentsFilter();
      if(departmentFilter === 'NA') return;
      const filters = { from: moment(fromDate).startOf('day').toISOString(), to:  moment(toDate).endOf('day').toISOString(), groupBy: graphTimeScope }
      if(departmentFilter?.length > 1) filters.departments = departmentFilter;
      if(departmentFilter?.length === 1) filters.department = departmentFilter[0];
      const { data: { data } } = await InsightsService.getDynamicsInputRange(filters)
      getInputsByTime(data);
    } catch(error) {
      toast.error(<ErrorToast error={error} />)
    }
  }

  const getLegend = () => {
    const fLegend = formatLegend(selectedTile.header)
    setLegend(fLegend)
  };

  const getInputsByTime = (data) => {
    const fInputsByTime = formatInputsByTime(data, graphTimeScope)
    setInputsByTime(fInputsByTime)
  };

  return (
    <div className='insi-chart'>
      <div className='insi-chart__inputs-bt'>
        <Text size="big" bold className="mb-2">Dynamic inputs per period</Text>
        <InputsByTimes data={inputsByTime} />
      </div>
      <div className='insi-chart__container'>
        <div className='insi-chart__header-bar'>
          <div className='insi-chart__header'>
            <img className='insi-chart__icon' src={selectedTile.icon} alt="icon" />
            <Text bold size="bigish">{selectedTile.header}</Text>
            {(isLoadingGraphData|| isLoading) && (
              <div className='insi-chart__header__loader'>
                <Loader
                  active
                  size="small"
                  inline
                />
              </div>
            )}
          </div>
          <div className='insi-chart__filters'>
            <div className='insi-chart__type-select'>
              <div onClick={() => setSelectedChart('bar')} className={`insi-chart__type-select__icon-wrapper ${selectedChart === 'bar' && 'insi-chart__type-select__icon-wrapper--selected'}`}>
                <img className='insi-chart__type-select__icon' src={barChartSvg} alt="bar" />
              </div>
              <div onClick={() => setSelectedChart('area')} className={`insi-chart__type-select__icon-wrapper ${selectedChart === 'area' && 'insi-chart__type-select__icon-wrapper--selected'}`}>
                <img className='insi-chart__type-select__icon' src={areaChartSvg} alt="area" />
              </div>
            </div>
            <Select
              border
              size='small'
              value={graphTimeScope}
              onChange={(e, { value }) => {
                setGraphTimeScope(value);
              }}
              options={timeScopeOptions}
            />
          </div>
        </div>
        <div className='insi-chart__chart-wrapper'>
          <div className='insi-chart__chart'>
          {!hasData ? (
            <div className='insi-chart__chart__placeholder'>
              <Text bold size="small" color="lightGrey">No historical data</Text>
            </div>
          ) : (
            <>
              {selectedChart === 'area' ?
                <AreaChart data={data} legend={legend} getToolTipData={(label) => formatTooltip(label, data)} />
              :
                <BarChart data={data} legend={legend} getToolTipData={(label) => formatTooltip(label, data)} />
              }
            </>
          )}
          </div>
          <Legend data={legend} />
        </div>
      </div>
    </div>
  );
}
  
  export default AreaChartSection;
  