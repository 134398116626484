import React from 'react';

import { Button, Text } from '../../../../components';

import './clientDetails.scss';

const ClientDetailsDisplay = ({ client, onEditClick }) => {
  return (
    <div className="client-det-f">
        <div className="client-det-f__grid-wrapper">
            <div className="client-det-f__grid">
                <div className="client-det-f__data-set">
                    <Text type="dataLabel">Company Name</Text>
                    <Text type="data">{client.name}</Text>
                </div>
                <div className="client-det-f__data-set">
                    <Text type="dataLabel">Company registration no.</Text>
                    <Text type="data">{client?.identifiers?.regNo}</Text>
                </div>
                <div className="client-det-f__data-set">
                    <Text type="dataLabel">Industry</Text>
                    <Text type="data" uppercase>{client.industry}</Text>
                </div>
                <div className="client-det-f__data-set">
                    <Text type="dataLabel">Classification no.</Text>
                    <Text type="data">{client?.identifiers?.classNo}</Text>
                </div>
                <div className="client-det-f__data-set">
                    <Text type="dataLabel">Company address</Text>
                    <Text type="data">{client.address}</Text>
                </div>
                <div className="client-det-f__data-set">
                    <Text type="dataLabel">Country</Text>
                    <Text type="data">{client.country}</Text>
                </div>
            </div>
            <div className="client-det-f__grid-small">
                {client?.contact?.website && (
                    <div className="client-det-f__data-set">
                        <Text type="dataLabel">Website</Text>
                        <Text type="data">{client.contact.website}</Text>
                    </div>
                )}
                {client?.support?.email && (
                    <div className="client-det-f__data-set">
                        <Text type="dataLabel">Support email</Text>
                        <Text type="data">{client.support.email}</Text>
                    </div>
                )}
                {client?.support?.phone && (
                    <div className="client-det-f__data-set">
                        <Text type="dataLabel">Support contact no.</Text>
                        <Text type="data">{client.support.phone}</Text>
                    </div>
                )}
                {client?.contact?.fax && (
                    <div className="client-det-f__data-set">
                        <Text type="dataLabel">Fax no.</Text>
                        <Text type="data">{client.contact.fax}</Text>
                    </div>
                )}
            </div>

            {/*display list of connectedClinics in client.connectedClinics*/}
            <div className="client-det-f__grid-small">
                <div className="client-det-f__data-set">
                    <Text type="dataLabel">Connected clinics</Text>
                    <div className="client-det-f__data-list">
                        {client?.connectedClinics?.map((clinic) => (
                            <Text type="data" key={clinic.id}>{clinic.name}</Text>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        <div className="client-det-f__buttons-wrapper">
            <Button
                type="submit"
                color="blue"
                onClick={onEditClick}
            >
                Edit
            </Button>
        </div>
    </div>
  );
};

export default ClientDetailsDisplay;
