import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';

import { Text, Button } from '../components';

import AddButton from './AddButton';

const FileUpload = (props) => {
  const clientBranding = useSelector((state) => state.general.clientBranding);

  return (
    <Button
      color="blue"
      inverted
      size={props.size || 'fullWidth'}
      style={props.style}
      className={props.className}
      invalid={props.invalid}
      isIcon={props.icon}
      clientBranding={clientBranding}
      isLoading={props.isLoading}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {props.icon && <Icon name="upload" color="#5b53f3" />}
        <Text size="small" bold color="#5b53f3">
          {props.isDropzone && props.fileName ? (
            <>
              <Icon name="file pdf" color='red' size='large' /> {props.fileName}
            </>
          ) : (
            props.label || 'Upload file'
          )}
        </Text>
        <Input
          onChange={props.onSelect}
          accept={props.accept}
          type="file"
          disabled={props.disabled}
        />
      </div>
      {props.invalid && (
        <Text className="input-error-text" color="red" size="tiny">
          {props?.errorMessage || 'This field is required'}
        </Text>
      )}
    </Button>
  );
};

FileUpload.propTypes = {
  label: PropTypes.string,
  onSelect: PropTypes.func,
  style: PropTypes.object,
};

FileUpload.defaultProps = {
  onSelect: () => {},
};

const Wrapper = styled.div`
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px !important;
  padding: 5px;
  position: relative;
  border: solid 1px ${(props) => props.clientBranding?.color || '#5b53f3'};
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    opacity: 0.9;
  }
`;

const Input = styled.input`
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  opacity: 0;
  width: 100%;
  z-index: 99999999;

  &::-webkit-file-upload-button {
    visibility: hidden;
  }
`;

export default FileUpload;
