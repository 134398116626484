import React, { useState } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';

import { Text, Select, DatePicker, Button, Icon } from 'components';
import ClaimsIcon from 'assets/new/claims';
import AreaChartSection from '../../features/AreaChartSection/AreaChartSection';
import InfoTiles from '../../features/InfoTiles/InfoTiles';
import ClaimsTable from '../../features/ClaimsTable/ClaimsTable';
import ClaimUploadModal from '../../components/claimUploadModal/ClaimUploadModal';
import BulkClaimUploadModal from '../../components/bulkClaimUploadModal/BulkClaimUploadModal';
import { vesselTypes } from 'helpers/claimData';

import history from '../../../../history';

import './claimsDashboard.scss';

const ClaimsDashboard = ({
  formattedDepts,
  claimsGraphdata,
  claimSettings,
  viewingAccount,
  loadingDepartments,
  isLoadingGraphData,
  dropdownOptions,
  hasViewRights,
  filteredData,
  searchValue,
  isSearching,
  onSearchChange,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  selectAllRows,
  onEditClick,
  onRowSelect,
  getDepartmentsFilter,
  getClaimsGraphdata,
  claimStatFilteredData,
  selectedRows,
  getClaims,
  getClaimsStatData,
  graphTimeScope,
  setGraphTimeScope,
  onChangeViewingAccount,
  claimStatQuery,
  setClaimStatQuery,
}) => {
  const [isModalToggled, toggleModal] = useState(false);
  const [isBulkModalToggled, toggleBulkModal] = useState(false);

  const [vesselType, setVesselType] = useState(null);
  console.log("claimSettings", claimSettings)
  return (
    <div className="claims-dashboard">
      {isModalToggled && (
        <ClaimUploadModal
          toggleModal={toggleModal}
          isModalToggled={isModalToggled}
          deductableAmount={claimSettings.insuranceDeductiblesAmount}
          currency={claimSettings.currency}
        />
      )}
      {isBulkModalToggled && (
        <BulkClaimUploadModal
          toggleModal={toggleBulkModal}
          isModalToggled={isBulkModalToggled}
          getClaims={getClaims}
        />
      )}
      <div className="claims-dashboard__header">
        <ClaimsIcon className="claims-dashboard__header__icon" color="black" />
        <Text bold size="bigger">
          Claims
        </Text>
      </div>
      <div className="claims-dashboard__view-acc-wrapper">
        <div className="claims-dashboard__view-acc">
          <Select
            size="small"
            label="Viewing department"
            disabled={loadingDepartments}
            border
            className=""
            placeholder="All"
            search
            selection
            value={viewingAccount}
            onChange={(e, { value }) => {
              setClaimStatQuery({
                ...claimStatQuery,
                department: value,
              });
              onChangeViewingAccount(value);
            }}
            options={dropdownOptions}
          />
          <Select
            label="Vessel Type"
            size="small"
            disabled={loadingDepartments}
            border
            className="ml-3"
            placeholder="All"
            search
            selection
            value={vesselType}
            onChange={(e, { value }) => {
              setClaimStatQuery({ ...claimStatQuery, vesselType: value });
              setVesselType(value);
            }}
            options={[{ key: 'all', text: 'All', value: null }, ...vesselTypes]}
          />
        </div>
        <div className="claims-dashboard__right">
          <div className="claims-dashboard__filters">
            <div style={{ display: 'flex' }}>
              <div className="mr-3" style={{ position: 'relative' }}>
                <Text
                  size="tinniest"
                  color="lightGrey"
                  className="claims-dashboard__filters__date-tag"
                >
                  From
                </Text>
                <DatePicker
                  noCloseIcon={true}
                  onChange={(date) => {
                    setFromDate(date);
                    setClaimStatQuery({
                      ...claimStatQuery,
                      startDate: moment(date).toISOString(),
                      endDate: moment(toDate).toISOString(),
                    });
                  }}
                  value={fromDate}
                  disabled={!hasViewRights}
                  maxDate={toDate}
                />
              </div>
              <div style={{ position: 'relative' }}>
                <Text
                  size="tinniest"
                  color="lightGrey"
                  className="claims-dashboard__filters__date-tag"
                >
                  To
                </Text>
                <DatePicker
                  noCloseIcon={true}
                  onChange={(date) => {
                    setToDate(date);
                    setClaimStatQuery({
                      ...claimStatQuery,
                      startDate: moment(toDate).toISOString(),
                      endDate: moment(date).toISOString(),
                    });
                  }}
                  value={toDate}
                  disabled={!hasViewRights}
                  minDate={fromDate}
                  maxDate={new Date()}
                />
              </div>
            </div>
          </div>
          <div className="claims-dashboard__action-btns">
            <Popup
              position="bottom left"
              disabled={!!claimSettings}
              content="Set claims settings to enable uploads"
              trigger={
                <div className="claims-dashboard__action-btns__upload-claims mr-3">
                  <Button size="medium" onClick={() => toggleModal(true)} disabled={!claimSettings}>
                    <Text color="inherit">Create claim</Text>
                  </Button>
                </div>
              }
            />
            <Popup
              position="bottom right"
              disabled={!!claimSettings}
              content="Set claims settings to enable uploads"
              trigger={
                <div className="claims-dashboard__action-btns__upload-claims mr-3">
                  <Button size="medium" onClick={() => toggleBulkModal(true)} disabled={!claimSettings}>
                    <Text color="inherit">Upload claims</Text>
                  </Button>
                </div>
              }
            />
            <div className="claims-dashboard__action-btns__settings">

                <Button
                size="fluid"
                onClick={() => history.push('/claims/settings')}
                >
                  <Icon fill="white" name="settings" />
                </Button>

            </div>
  
          </div>
        </div>
      </div>
      {hasViewRights ? (
        <div className="claims-dashboard__content">
          <InfoTiles
            fromDate={fromDate}
            toDate={toDate}
            claimStatFilteredData={claimStatFilteredData}
            claimSettings={claimSettings}
          />
          <AreaChartSection
            getDepartmentsFilter={getDepartmentsFilter}
            fromDate={fromDate}
            toDate={toDate}
            viewingAccount={viewingAccount}
            setGraphTimeScope={setGraphTimeScope}
            graphTimeScope={graphTimeScope}
            isLoadingGraphData={isLoadingGraphData}
            getGraphdata={getClaimsGraphdata}
            claimsGraphdata={claimsGraphdata}
            setClaimStatQuery={setClaimStatQuery}
            claimStatQuery={claimStatQuery}
          />
        </div>
      ) : (
        <div className="insi-w__content">
          <div className="insi-w__content__no-rights">
            <Text bold center>
              You don't have Insight view rights for this department
            </Text>
          </div>
        </div>
      )}
      <ClaimsTable
        hasEditRights={true}
        formattedDepts={formattedDepts}
        filteredData={filteredData}
        selectedRows={selectedRows}
        onRowSelect={onRowSelect}
        selectAllRows={selectAllRows}
        onEditClick={onEditClick}
        searchValue={searchValue}
        onSearchChange={onSearchChange}
        isSearching={isSearching}
        getClaims={getClaims}
        getClaimsStatData={getClaimsStatData}
      />
    </div>
  );
};

export default withRouter(ClaimsDashboard);
