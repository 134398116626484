import React from 'react';
import moment from 'moment';

import { Text } from 'components';

import upTrendSvg from 'assets/new/upTrend.svg'
import downTrendSvg from 'assets/new/downTrend.svg'

import './inputsByTime.scss'

const Legend = ({ data = [] }) => {
  return (
    <div className='inputs-bt'>
      {data?.map((value) => (
        <div className='inputs-bt__section'>
          <div className='inputs-bt__section__value'>
            <Text dark size="bigger" className="mr-3">{value.value}</Text>
            <Text size="small">{value.date}</Text>
          </div>
          {(!!value.diff || value.diff === 0) && (
            <div className={`inputs-bt__section__diff inputs-bt__section__diff${value.diff < 0 ? '--red': '--green'}`}>
              <img className="inputs-bt__section__diff__icon" src={value.diff < 0 ? downTrendSvg : upTrendSvg} />
              <Text color={value.diff < 0 ? 'red': 'green'}>{value.diff > 0 && '+'}{value.diff}%</Text>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Legend;
