import React, { useState, useEffect } from 'react';
import { Loader } from 'semantic-ui-react';

import { Text, Select } from 'components';

import AreaChart from './components/LineGraph';
import BarChart from './components/BarChart';
import Legend from './components/Legend';

import areaChartSvg from 'assets/new/areaChart.svg';
import barChartSvg from 'assets/new/barChart.svg';

import {
  formatTooltip,
  timeScopeOptions,
  getColor,
} from './helpers/helpers';
import { claimTypes, selectDropDownClaimOptions } from 'helpers/claimData';

import './areaChartSection.scss';

const AreaChartSection = ({
  setGraphTimeScope,
  graphTimeScope,
  isLoadingGraphData,
  claimsGraphdata,
  claimStatQuery,
  setClaimStatQuery
}) => {
  const [selectedChart, setSelectedChart] = useState('bar');
  const [yAxisUnitType, setYAxisUnitType] = useState('noc');
  const [legend, setLegend] = useState([
    {
      color: getColor('death'),
      colorHex: getColor('death').replace('#', ''),
      label: 'death',
    },
    {
      color: getColor('injury'),
      colorHex: getColor('injury').replace('#', ''),
      label: 'injury',
    },
    {
      color: getColor('illness'),
      colorHex: getColor('illness').replace('#', ''),
      label: 'illness',
    },
  ]);
  const [claimCause, setClaimCause] = useState(null);
  const [claimType, setClaimType] = useState(null);
  const [claimCauseOptions, setFilteredClaimCauseOptions] = useState([]);

  useEffect(() => {
    setClaimStatQuery({ ...claimStatQuery, version: yAxisUnitType, claimType, claimCause })
  }, [yAxisUnitType, claimType, claimCause])

  useEffect(() => {
    const filteredClaimCauses = filterClaimCauses();
    setFilteredClaimCauseOptions(filteredClaimCauses);
  }, [claimType])

  const filterClaimCauses = () => {
    if(!claimType) return selectDropDownClaimOptions;
    return selectDropDownClaimOptions?.filter((cause) => cause?.claimType === claimType) || []
  };

  return (
    <div className="claims-chart">
      <div className="claims-chart__container">
        <div className="claims-chart__header-bar">
          <div className="claims-chart__header">
            <Select
              size="small"
              label="Claim types"
              border
              className="emp-o__view-acc__select"
              placeholder="All"
              search
              selection
              value={claimType}
              onChange={(e, { value }) => {
                setClaimType(value);
              }}
              options={[
                { key: 'all', text: 'All', value: null },
                ...claimTypes,
              ]}
            />
            <Select
              size="small"
              label="Claim cause"
              border
              className="emp-o__view-acc__select ml-3"
              placeholder="All"
              search
              selection
              value={claimCause}
              onChange={(e, { value }) => {
                setClaimCause(value);
              }}
              options={[
                { key: 'all', text: 'All', value: null },
                ...claimCauseOptions,
              ]}
            />
            {(isLoadingGraphData) && (
              <div className="claims-chart__header__loader">
                <Loader active size="small" inline />
              </div>
            )}
          </div>
          <div className="claims-chart__filters">
            <div className="claims-chart__type-select">
              <div
                onClick={() => setSelectedChart('bar')}
                className={`claims-chart__type-select__icon-wrapper ${
                  selectedChart === 'bar' &&
                  'claims-chart__type-select__icon-wrapper--selected'
                }`}
              >
                <img
                  className="claims-chart__type-select__icon"
                  src={barChartSvg}
                  alt="bar"
                />
              </div>
              <div
                onClick={() => setSelectedChart('area')}
                className={`claims-chart__type-select__icon-wrapper ${
                  selectedChart === 'area' &&
                  'claims-chart__type-select__icon-wrapper--selected'
                }`}
              >
                <img
                  className="claims-chart__type-select__icon"
                  src={areaChartSvg}
                  alt="area"
                />
              </div>
            </div>
            <Select
              border
              size="small"
              value={graphTimeScope}
              onChange={(e, { value }) => {
                setGraphTimeScope(value);
              }}
              options={timeScopeOptions}
            />
          </div>
        </div>
        <div className="claims-chart__header-bar">
          <div className="claims-chart__header">
            <Text bold size="bigish">
              Total number of claims against period
            </Text>
            {(isLoadingGraphData) && (
              <div className="claims-chart__header__loader">
                <Loader active size="small" inline />
              </div>
            )}
          </div>
          <div className="claims-chart__filters">
            <div className="claims-chart__quantity-cost-wrapper">
              <Select
                border
                size="normal"
                value={yAxisUnitType}
                onChange={(e, { value }) => {
                  setYAxisUnitType(value);
                }}
                options={[
                  {
                    key: 'noc',
                    text: 'No. of Claims',
                    value: 'noc',
                  },
                  {
                    key: 'coc',
                    text: 'Cost of Claims',
                    value: 'coc',
                  },
                  {
                    key: 'nor',
                    text: 'No. of Repatriations',
                    value: 'nor',
                  },
                  {
                    key: 'total_insuranace',
                    text: 'Total insurance approved claims',
                    value: 'total_insuranace',
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="claims-chart__chart-wrapper">
          <div className="claims-chart__chart">
            {!claimsGraphdata?.length ? (
              <div className="claims-chart__chart__placeholder">
                <Text bold size="small" color="lightGrey">
                  No historical data
                </Text>
              </div>
            ) : (
              <>
                {selectedChart === 'area' ? (
                  <AreaChart
                    data={claimsGraphdata}
                    legend={legend}
                    getToolTipData={(label) => formatTooltip(label, claimsGraphdata)}
                    timeSpan={graphTimeScope}
                  />
                ) : (
                  <BarChart
                    data={claimsGraphdata}
                    legend={legend}
                    getToolTipData={(label) => formatTooltip(label, claimsGraphdata)}
                    timeSpan={graphTimeScope}
                  />
                )}
              </>
            )}
          </div>
          <Legend data={legend} />
        </div>
      </div>
    </div>
  );
};

export default AreaChartSection;
