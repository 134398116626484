import axios from './axiosInstances/identityAxiosInstance';
// import axios from './axiosInstances/testAxios';

const api = {
  async getById(id) {
    return axios.post(`/myemployee/employee/GetById/${id}`);
  },

  async getAll({ filters = {}, search = '', limit = 30, offset = 0 }) {
    return axios.post(
      `/myemployee/employee/GetAll?offset=${offset}&limit=${limit}`,
      {
        filters,
        search,
      }
    );
  },

  async create(payload) {
    return axios.post(`/myemployee/employee/create`, payload);
  },

  async createMultiple(payload) {
    return axios.post(`/myemployee/employee/createMultiple`, payload);
  },

  async bulkUpload(payload) {
    return axios.post(`/myemployee/employee/bulkUpload`, payload);
  },

  async reInvite(payload) {
    return axios.post(`/myemployee/employee/re-invite`, payload);
  },

  async update(id, payload) {
    return axios.post(`/myemployee/employee/UpdateById/${id}`, payload);
  },

  async bulkUpdate(payload) {
    return axios.post('/myemployee/employee/UpdateByIds', payload);
  },

  async delete(payload) {
    return axios.post(`/myemployee/employee/SoftDeleteById`, payload);
  },

  async removeDepartmentFromAll(deptId) {
    return axios.post(`/myemployee/employee/RemoveDepartments`, {
      departments: [deptId],
    });
  },

  async getStatCount(filters) {
    return axios.post(`/myemployee/employee/GetStats`, filters);
  },

  async assignClinic(employees) {
    return axios.post(`/myemployee/employee/AssignClinic`, employees);
  },
};

export default api
