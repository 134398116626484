import axios from '../axiosInstances/identityAxiosInstance';
// import axios from '../axiosInstances/testAxios';

const userApi = {
  async getCurrentClient() {
    return axios.post('/myid/client');
  },
  async getAllClients({ offset = 0, limit = 100, search }) {
    return axios.post(
      '/myclient/backoffice/client/GetAll',
      { search },
      {
        params: {
          limit,
          offset,
        },
      }
    );
  },
  async getClientById(clientId) {
    return axios.post(
      `/myclient/backoffice/client/GetByClientId?id=${clientId}`
    );
  },
  async getPublicClientById(clientId) {
    return axios.post(`/myclient/client/GetByClientId?id=${clientId}`);
  },
  async getClientUsers(clientId) {
    return axios.post(
      `/myclient/backoffice/user/GetByClient?client-id=${clientId}`
    );
  },
  async createClient(client) {
    return axios.post('/myclient/backoffice/client', client);
  },
  async updateClientById(clientId, client) {
    delete client.integrations;
    delete client._id;
    delete client.__v;
    delete client.createdAt;
    delete client.updatedAt;
    return axios.post(`/myclient/backoffice/client/UpdateClient`, client);
  },
};

export default userApi;
