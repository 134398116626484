import moment from "moment";

const blue = "#411cc2"
// const green = "#00C48C"
const greenish = "#0e8301"
// const greenish2 = "#12b001"
const yellow = "#f69c1c"
const orange = "#ffaf3e"
const red = "#FF6666"
// const red2 = "#ff3a3a"
const red3 = "#ff0000"
const grey = "#9FABBD"

const getColor = (status) => {
  if(!status) return grey;
  const lowerCaseStatus = status.toLowerCase();
  switch (lowerCaseStatus) {
    case 'death':
      return red;
    case 'injury':
      return blue;
    case 'illness':
      return yellow;
    default:
      return blue;
  }
}

const timeScopeOptions = [
    // { text: 'Week', value: 'week' },
    { text: 'Month', value: 'month' },
    { text: 'Quarter', value: 'quarter' },
]

const formatTimeLabel = (date, timeSpan, full) => {
  const dates = date.split(".")
  if(timeSpan === 'month') return moment(dates[0], 'YYYY-MM-DD').format(full? 'MMMM' : 'MMMM').toString();
  return moment(dates[0], 'YYYY-MM-DD').format('DD MMM').toString() + ' - ' + moment(dates[1], 'YYYY-MM-DD').format('DD MMM').toString();
}

const formatData = (data, selectedTile, timeSpan) => {
  const legendKeys = getLegendKeys(selectedTile);
  const fData = data.map(dat => {
    let newBasePercentage = 0;

    let point = {
      name: formatTimeLabel(dat.date, timeSpan, false),
      count: 0
    };

    // Set placeholder values for all statuses
    legendKeys.forEach(key => {
      if(!key) return;
      const val = { percentage: 0, count: 0};
      point[key] = val.percentage;
    })

    dat.value.forEach(val => {
      if(!val._id) return;
      newBasePercentage += val.percentage
    })

    dat.value.forEach(val => {
      if(!val._id) return;
      point[val._id] = Math.round(Number(val.percentage / newBasePercentage) * 1000) / 10;
      point.count += val.count;
      point.keys = legendKeys;
    })

    return point;
  })
  return fData;
}

// TODO: format all the tooltip data when data is received, instead of on the go
const formatTooltip = (label, data) => {
  let selData = null
  if(!data || !data.length) return {total: 0, values: []};

  data?.forEach((dat) => {
    if(dat.name === label) selData = dat;
  })
  const values = [];

  if(selData?.keys) {
    selData?.keys?.forEach(key => {
      if(key !== 'count' && key !== 'name') values.push({ text: key, value: selData[key] || 0, color: getColor(key)})
    })
  }

  return {
    total: selData?.count,
    values
  }
}

const formatLegendData = (data) => {
  return data?.map(dat => ({
    color: getColor(dat.status),
    colorHex: getColor(dat.status).replace('#', ''),
    label: dat.status,
    ranges: dat.ranges
  }))
}

const formatInputsByTime = (data, timeSpan) => {
  const fData = [];
  const objKeys = Object.keys(data);
  objKeys.forEach(key => {
    const count = data[key];
    const previousCount = fData[fData.length - 1]?.value || 0

    fData.push({
      value: count,
      date: formatTimeLabel(key, timeSpan, true),
      diff: previousCount === 0 ? null : Math.round(((count - previousCount) / previousCount) * 100)
    });
  }) 

  return fData;
}

export { formatData, formatTooltip, timeScopeOptions, formatInputsByTime, getColor }
